import { path, httpService } from '@commons';

/////////////////////////////////////////////////////////////////////////////////
const args = { ...path, path: '/estoque/movimentacao' };
/////////////////////////////////////////////////////////////////////////////////

export function getReposicaoEstoqueMinimo(idPropriedade, where) {
  return httpService.get({ ...args, path: '/estoque/movimentacao/estoqueReposicao/' + idPropriedade + where });
}

export function getReposicaoEstoqueMinimoPdf(idPropriedade, where) {
  return httpService.getPdf({ ...args, path: '/estoque/movimentacao/estoqueReposicao/pdf/' + idPropriedade + where });
}


export function getReposicaoEstoqueMinimoByFornecedor(idPropriedade, where) {
  return httpService.get({ ...args, path: '/estoque/movimentacao/estoqueReposicaoByFornecedor/' + idPropriedade + where });
}

export function getReposicaoEstoqueMinimoByFornecedorPdf(idPropriedade, where) {
  return httpService.getPdf({ ...args, path: '/estoque/movimentacao/estoqueReposicaoByFornecedor/pdf/' + idPropriedade + where });
}
