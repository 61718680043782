import { takeLatest, put } from 'redux-saga/effects';
import moment from 'moment';

import {
  controleContaCaixa,
  NEW_CONTROLE_CONTA_CAIXA,
  NEW_CONTROLE_CONTA_CAIXA_SUCCESS,
} from '@handler';

function* sagaNewControleContaCaixa(action) {

  yield put({ type: NEW_CONTROLE_CONTA_CAIXA_SUCCESS, registro: 
    {
      ...controleContaCaixa.newRegistro,
      data: moment().startOf('day').format('YYYY-MM-DD'),
      competencia: moment().startOf('day').format('YYYY-MM-DD'),
      tipoEntidade: 'PESSOA',
      conta: action.contaCaixa
    } });
}

export default function* watchNewControleContaCaixa() {
  yield takeLatest(NEW_CONTROLE_CONTA_CAIXA, sagaNewControleContaCaixa)
}
