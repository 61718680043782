import { takeLatest, put, call } from 'redux-saga/effects';

import { visualizarDanfeXmlManualFile } from '../../service';
import {
  VIEW_DANFE_XML_MANUAL_ENTRADA_ESTOQUE,
  VIEW_DANFE_XML_MANUAL_ENTRADA_ESTOQUE_PENDING,
  VIEW_DANFE_XML_MANUAL_ENTRADA_ESTOQUE_SUCCESS,
  VIEW_DANFE_XML_MANUAL_ENTRADA_ESTOQUE_FAILURE
} from '../../actionTypes';
import { AlertError } from '@components/common/AlertToast';

function* sagaViewDanfeXmlManualEntradaEstoque(action) {
  yield put({ type: VIEW_DANFE_XML_MANUAL_ENTRADA_ESTOQUE_PENDING })

  try {
    yield call(visualizarDanfeXmlManualFile, action.id);

    yield put({ type: VIEW_DANFE_XML_MANUAL_ENTRADA_ESTOQUE_SUCCESS })
  } catch (error) {
    yield put({ type: VIEW_DANFE_XML_MANUAL_ENTRADA_ESTOQUE_FAILURE })

    AlertError('Não foi possível gerar a DANFE da nota importada via arquivo XML!');

  }
}

export default function* watchViewDanfeXmlManualEntradaEstoque() {
  yield takeLatest(VIEW_DANFE_XML_MANUAL_ENTRADA_ESTOQUE, sagaViewDanfeXmlManualEntradaEstoque)
}
