import { takeLatest, put, call } from 'redux-saga/effects';

import { findUnidadeMedidaConversaoCadastro } from '../service';
import {
  FIND_UNIDADE_MEDIDA_CONVERSAO_CADASTRO,
  FIND_UNIDADE_MEDIDA_CONVERSAO_CADASTRO_PENDING,
  FIND_UNIDADE_MEDIDA_CONVERSAO_CADASTRO_SUCCESS,
  FIND_UNIDADE_MEDIDA_CONVERSAO_CADASTRO_FAILURE
} from '../actionTypes';
import { formatNumber } from '@components/common/format';

function* sagaFindUnidadeMedidaConversaoCadastro(action) {
  yield put({ type: FIND_UNIDADE_MEDIDA_CONVERSAO_CADASTRO_PENDING, id: action.id })

  try {

    const registro = yield call(findUnidadeMedidaConversaoCadastro, action.id);

    yield put({
      type: FIND_UNIDADE_MEDIDA_CONVERSAO_CADASTRO_SUCCESS, registro: {
        ...registro,
        qtdConversao: convertStringToNumber(formatNumber(registro.qtdConversao, undefined, true)),
      }
    })

  } catch (error) {
    yield put({ type: FIND_UNIDADE_MEDIDA_CONVERSAO_CADASTRO_FAILURE })
    // console.error(error) // eslint-disable-line
  }
}

// função usada pois não renderiza alguns valores, como 0,0000002 (retornava 2e-7)
function convertStringToNumber(value) {
  // Verifica se o valor é uma string e contém vírgula
  if (typeof value === 'string' && value.includes(',')) {
    // Substitui a vírgula por ponto para a conversão
    const normalizedValue = value.replace(',', '.');
    // Converte para número sem usar notação científica
    return Number(normalizedValue).toFixed(10); // Ajuste o número de casas decimais conforme necessário
  }
  // Retorna o valor diretamente formatado se for um número
  return Number(value).toFixed(10); // Ajuste o número de casas decimais conforme necessário
}


export default function* watchFindUnidadeMedidaConversaoCadastro() {
  yield takeLatest(FIND_UNIDADE_MEDIDA_CONVERSAO_CADASTRO, sagaFindUnidadeMedidaConversaoCadastro)
}
