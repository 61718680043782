import { takeLatest, put, call } from 'redux-saga/effects';

import { history } from '@commons';

import { createEntradaEstoque, updateEntradaEstoque, entradaPodeSerEditadaExcluida } from '../service';
import {
  CREATE_ENTRADA_ESTOQUE,
  CREATE_ENTRADA_ESTOQUE_PENDING,
  CREATE_ENTRADA_ESTOQUE_SUCCESS,
  CREATE_ENTRADA_ESTOQUE_FAILURE,
  CONFIG_SESSION
} from '@handler';

import { storage } from '@commons/storage';

import { AlertSuccess, AlertError, AlertWarning } from '@components/common/AlertToast'
import { formatJsonRemoveEmptyOrNull } from '@components/common/format';

function* sagaCreateEntradaEstoque(action) {
  yield put({ type: CREATE_ENTRADA_ESTOQUE_PENDING })

  var processar = true;
  if (action.data.id !== undefined && action.data.id !== null) {
    processar = yield call(entradaPodeSerEditadaExcluida, action.data.id);
  }

  if (processar) {
    try {
      if (!action.data.lancaProduto) {
        action.data.itens = [];
      }
      if (!action.data.movimentaEstoque) {
        action.data.localEstoque = null;
      }
      if (!action.data.entradaDup.geraDuplicata) {
        action.data.entradaDup.tipoDuplicata = null;
        action.data.entradaDup.contaGerencial = null;
        action.data.entradaDup.historico = '';
        action.data.entradaDup.tipoPagamento = null;
        action.data.entradaDup.contaCaixa = null;
        action.data.entradaDup.contaBanco = null;
        action.data.entradaDup.numeroParcelas = null;
        action.data.parcelas = [];
      } else if (action.data.entradaDup.geraDuplicata) {
        if (action.data.entradaDup.tipoDuplicata === 'A_VISTA') {
          action.data.entradaDup.numeroParcelas = null;
          action.data.parcelas = [];
          if (action.data.entradaDup.movimentaFinanceiro === false) {
            action.data.entradaDup.contaGerencial = null;
            action.data.entradaDup.historico = '';
            action.data.entradaDup.tipoPagamento = null;
            action.data.entradaDup.contaCaixa = null;
            action.data.entradaDup.contaBanco = null;
          }
        } else if (action.data.entradaDup.tipoDuplicata === 'PARCELADA' && action.data.entradaDup.movimentaFinanceiro === false) {
          for (var i = 0; i < action.data.parcelas.length; i++) {
            action.data.parcelas[i].contaGerencial = null;
            action.data.parcelas[i].historico = '';
            action.data.parcelas[i].parcelaPaga = false;
            action.data.parcelas[i].tipoPagamento = null;
            action.data.parcelas[i].contaCaixa = null;
            action.data.parcelas[i].contaBanco = null;
          }
        }
      }
      if (action.data.id === undefined || action.data.id === null) {
        yield call(createEntradaEstoque, formatJsonRemoveEmptyOrNull({
          ...action.data,
          propriedade: {
            id: JSON.parse(storage.get(CONFIG_SESSION)).fazendaSelecionada.id
          }
        }));
      } else {
        let lancamentosContaPagar = [];
        action.data.lancamentosContaPagar.forEach(element => {
          lancamentosContaPagar = [
            ...lancamentosContaPagar,
            {
              "forJson": "contapagar",
              "id": element.id,
              "valor": element.valor,
              "historico": element.historico,
              "propriedade": { "id": element.propriedade.id },
              "contaGerencial": {
                "id": element.contaGerencial.id,
                "tipoConta": "A"
              }
            }
          ]
        });
        yield call(updateEntradaEstoque,
          {
            ...action.data,
            lancamentosContaPagar: lancamentosContaPagar,
            id: action.data.id
          }
        );
      }

      yield put({ type: CREATE_ENTRADA_ESTOQUE_SUCCESS, registro: action.data })

      history.push('/financeiro/entradaNota');

      AlertSuccess('Entrada registrada com sucesso!');
    } catch (error) {
      yield put({ type: CREATE_ENTRADA_ESTOQUE_FAILURE, registro: action.data })

      AlertError('Falha ao salvar a entrada! Tente novamente...');
    }
  } else {
    yield put({ type: CREATE_ENTRADA_ESTOQUE_FAILURE, registro: action.data })

    AlertWarning('Entrada não pode ser editada, pois já houve movimentação no estoque');
  }
}

export default function* watchCreateEntradaEstoque() {
  yield takeLatest(CREATE_ENTRADA_ESTOQUE, sagaCreateEntradaEstoque)
}
