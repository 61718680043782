import { path, httpService } from '@commons';
// import { PRODUTO_INSUMO_GRUPO_ENUM } from '@handler';

/////////////////////////////////////////////////////////////////////////////////
const args = { ...path, path: '/estoque/movimentacao' };
/////////////////////////////////////////////////////////////////////////////////

export function getEstoquePorPeriodoAnalitico(idPropriedade, datas, filter) {
  return httpService.get({
    ...args, path: '/estoque/movimentacao/estoquePorPeriodoAnalitico?propriedade='
      + idPropriedade + getWhere(filter) + `&datas=${datas}`
  });
}

export function getEstoquePorPeriodoAnaliticoPdf(idPropriedade, datas, filter) {
  return httpService.getPdf({
    ...args, path: '/estoque/movimentacao/estoquePorPeriodoAnalitico/pdf?propriedade='
      + idPropriedade + getWhere(filter) + `&datas=${datas}`
  });
}

export function getEstoquePorPeriodoSintetico(idPropriedade, datas, filter) {
  return httpService.get({
    ...args, path: '/estoque/movimentacao/estoquePorPeriodoSintetico?propriedade='
      + idPropriedade + getWhere(filter) + `&datas=${datas}`
  });
}

export function getEstoquePorPeriodoSinteticoPdf(idPropriedade, datas, filter) {
  return httpService.getPdf({
    ...args, path: '/estoque/movimentacao/estoquePorPeriodoSintetico/pdf?propriedade='
      + idPropriedade + getWhere(filter) + `&datas=${datas}`
  });
}

function getWhere(filter) {
  var where = "";
  if (filter !== undefined && filter !== null && filter.produto !== undefined && filter.produto !== null) {
    where = where + '&produto=' + filter.produto.id
  }
  if (filter !== undefined && filter !== null && filter.mostrarEstoqueZerado !== undefined && filter.mostrarEstoqueZerado !== null) {
    where = where + '&mostrarEstoqueZerado=' + filter.mostrarEstoqueZerado
  }
  if (filter !== undefined && filter !== null && filter.localEstoque !== undefined && filter.localEstoque !== null) {
    where = where + '&localEstoque=' + filter.localEstoque.id
  }
  // if (filter !== undefined && filter !== null && filter.grupoProduto !== undefined && filter.grupoProduto !== null) {
  //   var grupos = PRODUTO_INSUMO_GRUPO_ENUM;
  //   where = where + '&grupoProduto=' + (grupos.find(element => element.id === filter.grupoProduto).idBD ?? -1)
  // }
  return where;
}