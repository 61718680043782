import { takeLatest, put, call } from 'redux-saga/effects';

import { baixarPagamento, attachFiles, deleteAttachFiles } from '../service';
import swal from 'sweetalert';
import {
  BAIXAR_CONTA_PAGAMENTO,
  BAIXAR_CONTA_PAGAMENTO_PENDING,
  BAIXAR_CONTA_PAGAMENTO_SUCCESS,
  BAIXAR_CONTA_PAGAMENTO_FAILURE
} from '../actionTypes';
import { AlertError } from '@components/common/AlertToast'
import { formatJsonRemoveEmptyOrNull } from '@components/common/format';

function* sagaBaixarPagamento(action) {
  yield put({ type: BAIXAR_CONTA_PAGAMENTO_PENDING })

  try {
    let urlTipoConta = '';
    if (action.urlTipoConta === 'pagar') {
      urlTipoConta = '/contas/pagar';
    } else {
      urlTipoConta = '/contas/receber';
    }

    let registro = {
      automatico: false,
      data: action.data.data,
      identificador: action.data.identificador,
      total: {
        valorCapital: action.data.valorCapital,
        valorDesconto: action.data.valorDesconto,
        valorJuro: action.data.valorJuro,
        valorMulta: action.data.valorMulta,
        valorOutrasDespesas: action.data.valorOutrasDespesas,
        valorTotal: action.data.valorTotal,
      }
    }

    if (action.data.tipoPagamento === 'DINHEIRO') {
      registro = {
        ...registro,
        tipoPagamento: "DINHEIRO",
        pagamentoDinheiro: {
          contaCaixa: {
            id: action.data.conta.id
          }
        }
      }
    }

    if (action.data.tipoPagamento === 'BOLETO_BANCARIO' || action.data.tipoPagamento === 'PIX' || action.data.tipoPagamento === 'TRANSF_BANCARIA'
    || action.data.tipoPagamento === 'DEBITO_EM_CONTA' || action.data.tipoPagamento === 'CARNE' || action.data.tipoPagamento === 'GUIA'
    || action.data.tipoPagamento === 'CREDITO_EM_CONTA') {
      registro = {
        ...registro,
        tipoPagamento: action.data.tipoPagamento,
        pagamentoBanco: {
          contaBanco: {
            id: action.data.conta.id
          }
        }
      }
    }

    if (action.data.tipoPagamento === 'CARTAO_CREDITO' || action.data.tipoPagamento === 'CARTAO_DEBITO' || action.data.tipoPagamento === 'CARTAO_VOUCHER') {
      registro = formatJsonRemoveEmptyOrNull(
        {
          ...registro,
          tipoPagamento: action.data.tipoPagamento,
          pagamentoBanco: {
            cartaoBandeira: action.data.cartaoBandeira,
            cartaoNsu: action.data.cartaoNsu,
            chequeEmitente: '',
            chequeNumeroCheque: '',
            chequeBanco: '',
            chequeNumeroAgencia: '',
            chequeDvAgencia: '',
            chequeContaCorrente: '',
            chequeDvContaCorrente: '',
            contaBanco: {
              id: action.data.conta.id
            }
          }
        }
      )
    }

    if (action.data.tipoPagamento === 'CHEQUE_A_VISTA' || action.data.tipoPagamento === 'CHEQUE_PRE_DATADO') {
      registro = formatJsonRemoveEmptyOrNull(
        {
          ...registro,
          tipoPagamento: action.data.tipoPagamento,
          pagamentoBanco: {
            // dataPrevisao: action.data.chequeData,
            cartaoBandeira: '',
            cartaoNsu: '',
            chequeEmitente: action.data.chequeEmitente,
            chequeNumeroCheque: action.data.chequeNro,
            chequeBanco: action.data.chequeBanco,
            chequeNumeroAgencia: action.data.chequeNroAg,
            chequeDvAgencia: action.data.chequeDvAg,
            chequeContaCorrente: action.data.chequeNroCc,
            chequeDvContaCorrente: action.data.chequeDvCc,
            contaBanco: {
              id: action.data.conta.id
            }
          }
        }
      )
    }


    const resp = yield yield call(baixarPagamento, urlTipoConta, action.idConta, registro);

    if (resp.status === 200) {
      try {
        if ( action.files.filesToAttach.length > 0 ) { yield call(attachFiles, urlTipoConta, action.idConta, action.files.filesToAttach); }
        if ( action.files.filesIdToDelete.length > 0 ) { yield call(deleteAttachFiles, urlTipoConta, action.idConta, action.files.filesIdToDelete); }
      } catch (error) {
        console.error('Error during file attachment:', error);
      }
    }

    yield put({ type: BAIXAR_CONTA_PAGAMENTO_SUCCESS });

    swal({
      title: 'Pagamento realizado com sucesso!',
      icon: 'success',
      buttons: {
        cancel: false,
        confirm: {
          text: 'OK',
          value: true,
          visible: true,
          className: 'btn-primary',
          closeModal: true
        }
      }
    }).then(() => {
      window.location.reload();
    });

  } catch (error) {
    yield put({ type: BAIXAR_CONTA_PAGAMENTO_FAILURE })

    AlertError('Falha ao realizar baixa. Tente novamente.');
  }
}

export default function* watchBaixarPagamento() {
  yield takeLatest(BAIXAR_CONTA_PAGAMENTO, sagaBaixarPagamento)
}
