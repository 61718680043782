import { takeLatest, put } from 'redux-saga/effects';

import {
  unidadeMedidaConversaoCadastro,
  NEW_UNIDADE_MEDIDA_CONVERSAO_CADASTRO,
  NEW_UNIDADE_MEDIDA_CONVERSAO_CADASTRO_SUCCESS,
} from '@handler';

function* sagaNewUnidadeMedidaConversaoCadastro(action) {

  yield put({
    type: NEW_UNIDADE_MEDIDA_CONVERSAO_CADASTRO_SUCCESS,
    registro: unidadeMedidaConversaoCadastro.newRegistro,
    isCadastroRapido: action.isCadastroRapido,
  });
}

export default function* watchNewUnidadeMedidaConversaoCadastro() {
  yield takeLatest(NEW_UNIDADE_MEDIDA_CONVERSAO_CADASTRO, sagaNewUnidadeMedidaConversaoCadastro)
}
