import { takeLatest, put, call } from 'redux-saga/effects';

import { history } from '@commons';
import { storage } from '@commons/storage';
import { createContaPagarRecorrente, updateContaPagarRecorrente } from '../service';
import {
  CREATE_CONTA_PAGAR_RECORRENTE,
  CREATE_CONTA_PAGAR_RECORRENTE_PENDING,
  CREATE_CONTA_PAGAR_RECORRENTE_SUCCESS,
  CREATE_CONTA_PAGAR_RECORRENTE_FAILURE,
  CONFIG_SESSION,
} from '@handler';

import { AlertSuccess, AlertError, AlertWarning } from '@components/common/AlertToast'

function* sagaCreateContaPagarRecorrente(action) {
  yield put({ type: CREATE_CONTA_PAGAR_RECORRENTE_PENDING })

  try {
    delete action.data.nomePessoaConta;
    
    if (action.data.id === undefined || action.data.id === null) {
      yield call(createContaPagarRecorrente, {
        ...action.data,
        propriedade: {
          id: JSON.parse(storage.get(CONFIG_SESSION)).fazendaSelecionada.id
        }
      });
    } else {
      yield call(updateContaPagarRecorrente,
        {
          ...action.data,
          id: action.data.id
        }
      );
    }

    yield put({ type: CREATE_CONTA_PAGAR_RECORRENTE_SUCCESS, registro: action.data })

    if (!action.isCadastroRapido) {
      history.push('/financeiro/contaPagar/recorrente');
    }

    AlertSuccess('Registro salvo com sucesso!');

  } catch (error) {
    yield put({ type: CREATE_CONTA_PAGAR_RECORRENTE_FAILURE, registro: action.data })

    if (error.response.status == 403) {
      AlertWarning('Sua licença atingiu o número máximo de contas recorrentes cadastradas. Para contratar mais contas, contate o comercial da plataforma.');
    } else {
      AlertError('Falha ao salvar o registro! Tente novamente...');
    }
  }

}

export default function* watchCreateContaPagarRecorrente() {
  yield takeLatest(CREATE_CONTA_PAGAR_RECORRENTE, sagaCreateContaPagarRecorrente)
}
