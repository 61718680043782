import { takeLatest, put, call } from 'redux-saga/effects';

import { history } from '@commons';

import { storage } from '@commons/storage';

import { createControleContaCaixa, createControleContaCaixaTransf, updateControleContaCaixa, updateControleContaCaixaTransf } from '../service';
import {
  CREATE_CONTROLE_CONTA_CAIXA,
  CREATE_CONTROLE_CONTA_CAIXA_PENDING,
  CREATE_CONTROLE_CONTA_CAIXA_SUCCESS,
  CREATE_CONTROLE_CONTA_CAIXA_FAILURE,
  CONFIG_SESSION
} from '@handler';

import { AlertSuccess, AlertError } from '@components/common/AlertToast'
import { formatJsonRemoveEmptyOrNull } from '@components/common/format';

function* sagaCreateControleContaCaixa(action) {
  yield put({ type: CREATE_CONTROLE_CONTA_CAIXA_PENDING })

  try {
    if (action.data.tipoMovimento === 'TRANSFERENCIA') {
      let transf = {
        ...action.data,
        contaOrigem: action.data.conta
      };

      delete transf.contaGerencial;
      delete transf.classificacao;
      delete transf.automatico;
      delete transf.conta;
      delete transf.tipoEntidade;
      delete transf.pessoa;
      delete transf.motorista;
      delete transf.funcionario;
      delete transf.nomeEntidadeOutros;

      if (transf.id === undefined || transf.id === null) {
        yield call(createControleContaCaixaTransf, formatJsonRemoveEmptyOrNull(
          {
            ...transf,
            propriedade: {
              id: JSON.parse(storage.get(CONFIG_SESSION)).fazendaSelecionada.id
            }
          }
        ));
      } else {
        yield call(updateControleContaCaixaTransf,
          formatJsonRemoveEmptyOrNull({
            ...transf,
            id: transf.id
          })
        );
      }
    } else {
      action.data.classificacao = action.data.contaGerencial.classificacao;
      delete action.data.contaDestino;

      if (action.data.id === undefined || action.data.id === null) {
        yield call(createControleContaCaixa, formatJsonRemoveEmptyOrNull(
          {
            ...action.data,
            propriedade: {
              id: JSON.parse(storage.get(CONFIG_SESSION)).fazendaSelecionada.id
            }
          }
        ));
      } else {
        yield call(updateControleContaCaixa,
          {
            ...action.data,
            id: action.data.id,
            fluxoRealizadoList: []
          }
        );
      }
    }

    yield put({ type: CREATE_CONTROLE_CONTA_CAIXA_SUCCESS, registro: action.data })

    history.push('/financeiro/movimentacoesCaixa');

    AlertSuccess('Registro salvo com sucesso!');
  } catch (error) {
    yield put({ type: CREATE_CONTROLE_CONTA_CAIXA_FAILURE, registro: action.data })

    AlertError('Falha ao salvar o registro! Tente novamente...');
  }
}

export default function* watchCreateControleContaCaixa() {
  yield takeLatest(CREATE_CONTROLE_CONTA_CAIXA, sagaCreateControleContaCaixa)
}
