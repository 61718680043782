import { takeLatest, put, call } from 'redux-saga/effects';

import { findNFeEmissao } from '../service';
import {
  FIND_NFE_EMISSAO,
  FIND_NFE_EMISSAO_PENDING,
  FIND_NFE_EMISSAO_SUCCESS,
  FIND_NFE_EMISSAO_FAILURE
} from '../actionTypes';

function* sagaFindNFeEmissao(action) {
  yield put({ type: FIND_NFE_EMISSAO_PENDING, id: action.id })

  try {

    const registro = yield call(findNFeEmissao, action.id);

    yield put({ type: FIND_NFE_EMISSAO_SUCCESS, registro: registro })

  } catch (error) {

    yield put({ type: FIND_NFE_EMISSAO_FAILURE })
    // console.error(error) // eslint-disable-line

  }
}

export default function* watchFindNFeEmissao() {
  yield takeLatest(FIND_NFE_EMISSAO, sagaFindNFeEmissao)
}
