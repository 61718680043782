import { takeLatest, put, call } from 'redux-saga/effects';

import { markNotificationAsRead } from '../service';
import {
  MARK_NOTIFICATION_AS_READ,
  MARK_NOTIFICATION_AS_READ_PENDING,
  MARK_NOTIFICATION_AS_READ_SUCCESS,
  MARK_NOTIFICATION_AS_READ_FAILURE,
  NEW_FILTER_NOTIFICATION
} from '../actionTypes';

function* sagaMarkNotificationAsRead(action) {
  yield put({ type: MARK_NOTIFICATION_AS_READ_PENDING });

  try {
    yield call(markNotificationAsRead, action.userNotificationIdList);
    yield put({ type: NEW_FILTER_NOTIFICATION });

    yield put({ type: MARK_NOTIFICATION_AS_READ_SUCCESS });
  } catch (error) {
    yield put({ type: MARK_NOTIFICATION_AS_READ_FAILURE });
  }
}

export default function* watchMarkNotificationAsRead() {
  yield takeLatest(MARK_NOTIFICATION_AS_READ, sagaMarkNotificationAsRead);
}
