import { path, httpService } from '@commons';

/////////////////////////////////////////////////////////////////////////////////
const args = { ...path, path: '/unidadeMedida' };
/////////////////////////////////////////////////////////////////////////////////

export function createUnidadeMedidaCadastro(payload) {
  return httpService.post(args, payload);
}

export function updateUnidadeMedidaCadastro(payload) {
  const { id } = payload

  return httpService.put({ ...args, args: `/${id}` }, payload);
}

export function listUnidadeMedidaCadastro(param) {
  return httpService.get({...args, args: param});
}

export function findUnidadeMedidaCadastro(id) {
  return httpService.get({ ...args, args: `/${id}` });
}

export function findAllUnidadeMedidaCadastro() {
  return httpService.get({ ...args, path: '/unidadeMedida/all' });
}

export function deleteUnidadeMedidaCadastro(id) {
  return httpService.del({ ...args, args: `/${id}` });
}

export function listAllUnidadeMedidaGlobal(param) {
  return httpService.getApiManager(`/unidadeMedidaWhiteLabel/${param ?? ''}`, true);
}
