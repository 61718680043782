
import React, { Component } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';

import { httpAuthService } from '@commons';
import { path } from '@commons/path';
import { trackEvent } from 'mixpanel';

import blankProfile from '../../assets/images/users/blank-profile-picture.png';

class ProfileMenu extends Component {

    constructor(props) {
        super(props);
        this.state = {
            menu: false,
        };
        this.toggle = this.toggle.bind(this);
    }

    toggle() {
        this.setState(prevState => ({
            menu: !prevState.menu
        }));
    }


    render() {
        const { loginReducer, startSessionReducer } = this.props;

        return (
            <React.Fragment>
                <Dropdown isOpen={this.state.menu} toggle={this.toggle} className="d-inline-block" >
                    <DropdownToggle className="btn header-item waves-effect" id="page-header-user-dropdown" tag="button">
                        {/* <i className="mdi mdi-cowboy font-size-17 primary-color" style={{ marginRight: '5px' }} />
                        <span className="primary-color">
                            {loginReducer.user.name.split(' ').shift() + "  "}
                        </span>
                        <i className="fa fa-angle-down primary-color" /> */}
                        <img className="rounded-circle header-profile-user" src={blankProfile} alt="Header Avatar" />
                    </DropdownToggle>
                    <DropdownMenu right>
                        <React.Fragment>
                            <DropdownItem tag="a" href="/home" onClick={() => trackEvent('Clicou no Profile Menu > Página Inicial On Farm')}>
                                <i className="fa fa-home font-size-17 align-middle mr-1" />Página Inicial On Farm
                            </DropdownItem>
                            <div className="dropdown-divider"></div>
                        </React.Fragment>

                        <DropdownItem tag="a" href="/minhasFazendas" onClick={() => trackEvent('Clicou no Profile Menu > Fazendas')}>
                            <i className="mdi mdi-wallet font-size-17 align-middle mr-1" />Fazendas
                        </DropdownItem>
                        <DropdownItem tag="a" href="/seguranca/usuario" onClick={() => trackEvent('Clicou no Profile Menu > Usuários')}>
                            <i className="mdi mdi-account-multiple font-size-17 align-middle mr-1" />Usuários
                        </DropdownItem>
                        <hr />
                        <DropdownItem tag="a" href="/config" onClick={() => trackEvent('Clicou no Profile Menu > Configurações')}>
                            <i className="mdi mdi-settings font-size-17 align-middle mr-1" />Configurações
                        </DropdownItem>
                        <hr />
                        <DropdownItem tag="a" href="/centralAjuda" onClick={() => trackEvent('Clicou no Profile Menu > Central de Ajuda')}>
                            <i className="fa fa-headset font-size-17 align-middle mr-1" />Central de Ajuda
                        </DropdownItem>
                        <DropdownItem tag="a" href="https://forms.gle/pmP8qsG1MDYyLaCk8" target='_blank' onClick={() => trackEvent('Clicou no Profile Menu > Deixe seu Feedback')}>
                            <i className="mdi mdi-comment-question-outline font-size-17 align-middle mr-1" />Deixe seu Feedback
                        </DropdownItem>
                        <DropdownItem tag="a" href="https://forms.gle/zJtvoW8VJi89Vetp9" target='_blank' onClick={() => trackEvent('Clicou no Profile Menu > Sugira uma Funcionalidade')}>
                            <i className="mdi mdi-lightbulb font-size-17 align-middle mr-1" />Sugira uma Funcionalidade
                        </DropdownItem>
                        <DropdownItem tag="a" href="https://agrare.com.br/termos" target='_blank' onClick={() => trackEvent('Clicou no Profile Menu > Termos de Uso')}>
                            <i className="mdi mdi-alert font-size-17 align-middle mr-1" />Termos de Uso
                        </DropdownItem>
                        <div className="dropdown-divider"></div>
                        <Link
                            to="/login"
                            onClick={() => {
                                trackEvent('Clicou no Profile Menu > Sair do Sistema')
                                httpAuthService.logout({ path });
                            }}
                            className="dropdown-item">
                            <i className="mdi mdi-logout font-size-17 align-middle mr-1"></i>
                            <span>Sair do Sistema</span>
                        </Link>

                    </DropdownMenu>
                </Dropdown>
            </React.Fragment>
        );
    }
}

export function mapStateToProps(state) {
    const { loginReducer, startSessionReducer } = state.entities;
    return { loginReducer, startSessionReducer };
};

function mapDispatchToProps(dispatch) {
    return {}
}

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(ProfileMenu))




