import { path, httpService } from '@commons';

/////////////////////////////////////////////////////////////////////////////////
const args = { ...path, path: '/perfil' };
/////////////////////////////////////////////////////////////////////////////////

const permissoes = [
  'PROPRIEDADE',
  'SAFRA',
  'TALHAO',
  'PROCEDIMENTO',
  'PROD_INSUMO',
  'PLANEJAMENTO',
  'ATIV_PLANTIO',
  'ATIV_COLHEITA',
  'PLUVIOMETRO_DIGITAL',
  'USUARIO',
  'TIPO_EMBALAGEM',
  'FUNCIONARIO_CARGO',
  'FUNCIONARIO',
  'PATRIMONIO',
  'DESPESA_DIRETA',
  'AGRIC_CULTURA',
  'AGRIC_VENDAS_PRODUCAO',
  'AGRiC_RELATORIO_ESTOQUE_PRODUCAO_POR_PERIODO',
  'AGRIC_ESTOQUE_IMPLANTAR_ESTOQUE_PROD',
  'AGRIC_ESTOQUE_BAIXA_MANUAL_ESTOQUE_PROD',
  'AGRIC_ESTOQUE_TRANSF_ESTOQUE_PROD',
  'ESTOQUE_LOCAL_ESTOQUE',
  'TRIBUTARIO_NATUREZA_OPERACAO',
  'ESTOQUE_ENTRADA_ESTOQUE',
  'ESTOQUE_IMPLANTAR_ESTOQUE',
  'ESTOQUE_BAIXAR_MANUAL_ESTOQUE',
  'ESTOQUE_RELATORIO_ESTOQUE_ATUAL',
  'ESTOQUE_RELATORIO_ESTOQUE_POR_PERIODO',
  'ESTOQUE_TRANSF_ESTOQUE',
  'CLIENTE',
  'FORNECEDOR',
  'TRANSPORTADORA',
  'VEICULO',
  'IDENTIFICADOR',
  'MOTORISTA',
  'FIN_CONTAS_PAGAR',
  'FIN_CONTAS_RECEBER',
  'FIN_CONTROLE_CAIXA',
  'FIN_CONTROLE_BANCO',
  'FIN_FLUXO_CAIXA',
  'FIN_CAIXA',
  'FIN_BANCO',
  'FIN_PLANO_CONTA',
  'FIN_TRANS_BANCO_CAIXA',
  'PAINEL_AGRARE_VISAO_AGRIC',
  'PAINEL_AGRARE_VISAO_FIN',
  'PAINEL_AGRARE_VISAO_PAT',
  'FISCAL_EMITENTE',
  'FISCAL_MONITORAMENTO_NFE_COMPRA',
  'CONFIG',
  'MAPA',
  'FIN_CONTAS_PAGAR_RECORRENTE',
  'AGRIC_APLICACAO_ESTAGIO',
  'CALENDARIO',
  'AGRIC_NDVI',
  'ESTOQUE_RELATORIO_REPOSICAO_ESTOQUE_MINIMO',
  'UNIDADE_MEDIDA',
  'UNIDADE_MEDIDA_CONVERSAO',
  'AGRIC_PRODUTO_AGRICOLA',
  'NOTIF_GERAL_SISTEMA',
  'NOTIF_GERAL_CONTA',
  'NOTIF_GERAL_CAMPANHA',
  'NOTIF_CONTA_PAGAR',
  'NOTIF_CONTA_RECEBER',
  'NOTIF_CONTA_RECORRENTE',
  'NOTIF_AGRIC_COLHEITA',
  'NOTIF_AGRIC_PLANEJAMENTO',
  'NOTIF_REPOSICAO_ESTOQUE',
  'NOTIF_VAL_CERTIFICADO_DIGITAL',
  'NOTIF_VAL_CND',
  'NOTIF_MANUTENCAO_PREVENTIVA',
  'NOTIF_NOTA_RECEBIDA',
  'NOTIF_NOTA_ENCONTRADA',
  'NOTIF_NDVI',
  'VISUALIZAR_CUSTO_CADERNO_CAMPO',
  'PATRIMONIO_MANUTENCAO',
  'PATRIMONIO_MANUTENCAO_ALERTAS',
  'PATRIMONIO_ABASTECIMENTO',
  'PATRIMONIO_DEPRECIACAO',
];

export function getJsonPerfil(perfilDB) {
  var newRoles = [];
  permissoes.map((permissao) => {
      const index = perfilDB.roles.findIndex((role) => role.roleEnum.literal === permissao);

      if (index !== -1) {
        newRoles.push(perfilDB.roles[index]);
      } else {
        newRoles.push({
          "roleEnum": {
            "literal": permissao
          },
          "ativo": false
        });
      }
  });

  perfilDB = {
    ...perfilDB,
    roles: newRoles
  }
  return perfilDB;
}

export function getJsonPerfilWl(perfilDB) {
  var newRoles = [];
  permissoes.map((permissao) => {
      const index = perfilDB.roles.findIndex((role) => role.roleEnum.literal === permissao);

      if (index !== -1) {
        newRoles.push(perfilDB.roles[index]);
      } else {
        newRoles.push({
          "roleEnum": {
            "literal": permissao
          },
          "active": false
        });
      }
  });

  perfilDB = {
    ...perfilDB,
    roles: newRoles
  }
  return perfilDB;
}

export function createPerfil(payload) {
  return httpService.post(args, payload);
}

export function updatePerfil(payload) {
  const { id } = payload

  return httpService.put({ ...args, args: `/${id}` }, payload);
}

export function listPerfil(param) {
  return httpService.get({ ...args, args: param });
}

export function listAllPerfil() {
  return httpService.get({ ...args, args: '/all' });
}

export function findPerfil(id) {
  return httpService.get({ ...args, args: `/${id}` });
}

export function deletePerfil(id) {
  return httpService.del({ ...args, args: `/${id}` });
}

export function listTipoDoc() {
  return httpService.get({ ...args, args: '/tipo' });
}

export function novoPerfil() {
  return httpService.get({ ...args, args: '/novo' });
}
