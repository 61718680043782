import { takeLatest, put, call } from 'redux-saga/effects';

import { path, storage, history } from '@commons';

import {
  CREATE_USUARIO_WHITE_LABEL,
  CREATE_USUARIO_WHITE_LABEL_PENDING,
  CREATE_USUARIO_WHITE_LABEL_SUCCESS,
  CREATE_USUARIO_WHITE_LABEL_FAILURE
} from '../actionTypes';

import { AlertSuccess, AlertError } from '@components/common/AlertToast'
import { registerUserWhiteLabel, updateUserWhiteLabel } from '../service';

function* sagaCreateUsuarioWhiteLabel(action) {
  yield put({ type: CREATE_USUARIO_WHITE_LABEL_PENDING })

  try {
    let user = JSON.parse(storage.get(path.sessionUser));

    if (action.novoRegistro === true) {
      var newUser = {
        "name": action.data.name,
        "email": action.data.email.toLowerCase().trim(),
        "passwordTemp": action.data.passwordTemp,
        "whatsApp": action.data.whatsApp,
        "perfil": action.data.perfil,
        "idFarmPermissionWhiteLabel": action.data.farmPermissionWhiteLabel.id,
        "identificationCodeWhiteLabel": user.identificationCodeWhiteLabel,
        "acessoTodasContasAgrare": action.data.acessoTodasContasAgrare ?? false
      }

      if ((action.data.perfil === 'PARCEIRO_ADMIN'
        || action.data.perfil === 'PARCEIRO_REPRESENTANTE') &&
        (user.idPartnerWhiteLabel === undefined || user.idPartnerWhiteLabel === null)) {
        newUser = {
          ...newUser,
          "idPartnerWhiteLabel": action.data.partnerWhiteLabel.id,
        }
      } else if ((action.data.perfil === 'PARCEIRO_ADMIN'
        || action.data.perfil === 'PARCEIRO_REPRESENTANTE') &&
        (user.idPartnerWhiteLabel !== undefined && user.idPartnerWhiteLabel !== null)) {
        newUser = {
          ...newUser,
          "idPartnerWhiteLabel": user.idPartnerWhiteLabel,
        }
      }

      const gruposClienteAgrare = [];

      action.data.gruposClienteAgrare.forEach(grupo => {
        var novoGrupo = {
          "idClienteAgrareGrupo": grupo.clienteAgrareGrupo.id
        }
        gruposClienteAgrare.push(novoGrupo);
      });
      newUser = {
        ...newUser,
        "gruposClienteAgrare": gruposClienteAgrare,
      }

      yield call(registerUserWhiteLabel, newUser);
    } else {
      const gruposClienteAgrare = [];

      action.data.gruposClienteAgrare.forEach(grupo => {
        var novoGrupo = {
          "clienteAgrareGrupo": {
            "id": grupo.clienteAgrareGrupo.id
          }
        }
        gruposClienteAgrare.push(novoGrupo);
      });
      action.data = {
        ...action.data,
        "gruposClienteAgrare": gruposClienteAgrare,
      }

      yield call(updateUserWhiteLabel, action.data);
    }

    yield put({ type: CREATE_USUARIO_WHITE_LABEL_SUCCESS, registro: action.data });

    history.push('/corporate/usuarios');

    AlertSuccess(`Usuário ${action.novoRegistro ? 'cadastrado' : 'atualizado'} com sucesso!`);
  } catch (error) {
    yield put({ type: CREATE_USUARIO_WHITE_LABEL_FAILURE, registro: action.data })

    AlertError('Falha ao cadastrar usuário! Verifique se o e-mail não está cadastrado em outro usuário/conta/fazenda e tente novamente...');
  }

}

export default function* watchCreateUsuarioWhiteLabel() {
  yield takeLatest(CREATE_USUARIO_WHITE_LABEL, sagaCreateUsuarioWhiteLabel)
}
