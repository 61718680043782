import { takeLatest, put } from 'redux-saga/effects';
import moment from 'moment';

import {
  nfeEmissao,
  NEW_NFE_EMISSAO,
  NEW_NFE_EMISSAO_SUCCESS,
} from '@handler';

function* sagaNewNFeEmissao(action) {

  yield put({ type: NEW_NFE_EMISSAO_SUCCESS, registro: 
    {
      ...nfeEmissao.newRegistro,
      data: moment().startOf('day').format('YYYY-MM-DD'),
    } });
}

export default function* watchNewNFeEmissao() {
  yield takeLatest(NEW_NFE_EMISSAO, sagaNewNFeEmissao)
}
