import axios from 'axios';
import { storage } from './';
import { createBrowserHistory } from 'history';
import { path } from '@commons';

import qs from 'qs'

import db from 'firebase.config';
import { findUserAuthResumed } from '@handler';
import { trackEvent } from 'mixpanel';

export const httpAuthService = {
    login,
    tokenByRefresh,
    logout,
    register,
    recoverPassword,
    registerNewScheme,
    updateSesssionUser
};

const getAppEmProcessamento = async () => {
    const response = db.collection('config').doc('1');
    const snapshot = await response.get();
    return snapshot.data()['app_em_processamento_web'] ?? false;
}

async function login(path, { email, password }) {
    storage.clear();

    let appEmProcessamento = await getAppEmProcessamento();

    if (appEmProcessamento == true) {
        return Promise.reject({ text: 'Estamos atualizando seu sistema com novas melhorias! Tente novamente em instantes...' });
    } else {
        const body = {
            client: 'rear',
            username: email.toLowerCase().trim(),
            password: password,
            grant_type: 'password'
        }

        var data = await req({ path: path, body: body });

        await storage.set(path.token, data.access_token);

        let user = await findUserAuthResumed(data.user_id, null)
        await updateSesssionUser(user);
        trackEvent('Logou no Sistema');

        createBrowserHistory().push('/home');
        window.location.reload();
        return data;
    }
}

async function updateSesssionUser(user){
    await storage.set(path.sessionUser, JSON.stringify(user));
}

async function tokenByRefresh(path) {
    // const body = {
    //     client: 'rear',
    //     grant_type: 'refresh_token'
    // }

    // const tenant = await storage.content(path.token).tenant

    // var data = await req({dominio: tenant, path: path, body: body});

    // await storage.set(path.token, data.access_token);
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + await storage.get(path.token);
};


function logout({ path }) {
    storage.clear();
}


async function req({ path, body }) {
    axios.defaults.withCredentials = true;

    const headers = {
        'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
        'Authorization': 'Basic cmVhcjpAcG9ydGhhbDEyMw==',
        'TenantID': "agrare"
    };

    return await axios.request({
        withCredentials: true,
        url: '/oauth/token',
        method: 'POST',
        baseURL: path.base,
        timeout: 30000,
        headers: headers,
        data: qs.stringify(body),
    }).then(({ data }) => {
        return data;
    }).catch(error => {
        try {
            if (error.response.status === 401) {
                logout(path);
                return Promise.reject({ text: 'Credenciais inválidas! Verifique os dados!', errorDescription: error.response.data.error_description });
            }
            if (error.response.status === 400) {
                return Promise.reject({ text: 'Credenciais inválidas! Verifique os dados!' });
            }
        } catch (err) {
            return Promise.reject({ text: 'Serviço temporariamente indisponível! Tente novamente em instantes...' });
        }

    })
};

async function register(values, registerAuto) {
    return await axios.request({
        url: registerAuto !== undefined && registerAuto !== null ? `/userAuth/register?registerAuto=${registerAuto}` : '/userAuth/register',
        method: 'POST',
        baseURL: path.baseManager,
        timeout: 30000,
        data: values,
    }).then(({ data }) => {
        return data;
    }).catch(error => {
        if (error === null || error === undefined || error.response?.data === null || error.response?.data === undefined) {
            return Promise.reject({ text: 'Serviço temporariamente indisponível! Tente novamente em instantes...' });
        } else {
            return Promise.reject({ text: error.response.data });
        }
    })
}

async function recoverPassword(values) {
    const encodedEmail = encodeURIComponent(values.email);
    return await axios.request({
        url: '/userAuth/requestPasswordReset?email=' + encodedEmail,
        method: 'POST',
        baseURL: path.baseManager,
        timeout: 30000,
    }).then(({ data }) => {
        return data;
    }).catch(error => {
        if (error === null || error === undefined || error.response?.data === null || error.response?.data === undefined) {
            return Promise.reject({ text: 'Serviço temporariamente indisponível! Tente novamente em instantes...' });
        } else {
            return Promise.reject({ text: error.response.data });
        }
    })
}

async function registerNewScheme(values) {
    // gera nome schema
    var schemeName = await axios.request({
        url: '/userAuth/generateSchemeName',
        method: 'POST',
        baseURL: path.baseManager,
        timeout: 30000,
    }).then(({ data }) => {
        return data;
    }).catch(error => {
        return Promise.reject({ text: 'Serviço temporariamente indisponível! Tente novamente em instantes...' });
    })

    values = {
        ...values,
        'nomeSchema': schemeName
    }

    // cria conta
    return await axios.request({
        url: "/" + schemeName + '/clientAgrare/create',
        method: 'POST',
        baseURL: path.base,
        timeout: 300000,
        data: values,
    }).then(({ data }) => {
        return data;
    }).catch(error => {
        if (error === null || error === undefined || error.response?.data === null || error.response?.data === undefined) {
            return Promise.reject({ text: 'Serviço temporariamente indisponível! Tente novamente em instantes...' });
        } else {
            return Promise.reject({ text: error.response.data });
        }
    })
}