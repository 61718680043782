import { CANCEL_UNIDADE_MEDIDA_CONVERSAO_CADASTRO_RAPIDO, CANCEL_UNIDADE_MEDIDA_CONVERSAO_CADASTRO_RAPIDO_SUCCESS } from '@handler';
import {
    NEW_UNIDADE_MEDIDA_CONVERSAO_CADASTRO_SUCCESS,
    TABLE_UNIDADE_MEDIDA_CONVERSAO_CADASTRO_PENDING,
    TABLE_UNIDADE_MEDIDA_CONVERSAO_CADASTRO_SUCCESS,
    TABLE_UNIDADE_MEDIDA_CONVERSAO_CADASTRO_FAILURE,
    LIST_UNIDADE_MEDIDA_CONVERSAO_CADASTRO_PENDING,
    LIST_UNIDADE_MEDIDA_CONVERSAO_CADASTRO_SUCCESS,
    LIST_UNIDADE_MEDIDA_CONVERSAO_CADASTRO_FAILURE,
    FIND_UNIDADE_MEDIDA_CONVERSAO_CADASTRO_PENDING,
    FIND_UNIDADE_MEDIDA_CONVERSAO_CADASTRO_SUCCESS,
    FIND_UNIDADE_MEDIDA_CONVERSAO_CADASTRO_FAILURE,
    DELETE_UNIDADE_MEDIDA_CONVERSAO_CADASTRO_PENDING,
    DELETE_UNIDADE_MEDIDA_CONVERSAO_CADASTRO_SUCCESS,
    DELETE_UNIDADE_MEDIDA_CONVERSAO_CADASTRO_FAILURE,
    CREATE_UNIDADE_MEDIDA_CONVERSAO_CADASTRO_PENDING,
    CREATE_UNIDADE_MEDIDA_CONVERSAO_CADASTRO_SUCCESS,
    CREATE_UNIDADE_MEDIDA_CONVERSAO_CADASTRO_FAILURE,
} from './actionTypes';


const initialState = { list: null, loading: false }


function unidadeMedidaConversaoCadastroReducer(state = initialState, action) {

    switch (action.type) {
        case NEW_UNIDADE_MEDIDA_CONVERSAO_CADASTRO_SUCCESS:
            return {
                ...state,
                loading: false,
                registro: action.registro,
                isCadastroRapido: action.isCadastroRapido ?? false,
            }
        case LIST_UNIDADE_MEDIDA_CONVERSAO_CADASTRO_PENDING:
        case FIND_UNIDADE_MEDIDA_CONVERSAO_CADASTRO_PENDING:
        case DELETE_UNIDADE_MEDIDA_CONVERSAO_CADASTRO_PENDING:
        case CREATE_UNIDADE_MEDIDA_CONVERSAO_CADASTRO_PENDING:
        case TABLE_UNIDADE_MEDIDA_CONVERSAO_CADASTRO_PENDING:
            return {
                ...state,
                loading: true,
            }
        // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        case CANCEL_UNIDADE_MEDIDA_CONVERSAO_CADASTRO_RAPIDO:
        case CANCEL_UNIDADE_MEDIDA_CONVERSAO_CADASTRO_RAPIDO_SUCCESS:
            return {
                ...state,
                isCadastroRapido: false,
            }
        // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        case LIST_UNIDADE_MEDIDA_CONVERSAO_CADASTRO_SUCCESS:
            return {
                ...state,
                list: action.list,
                loading: false
            }
        case LIST_UNIDADE_MEDIDA_CONVERSAO_CADASTRO_FAILURE:
            return {
                ...state,
                list: null,
                loading: false
            }
        // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        case TABLE_UNIDADE_MEDIDA_CONVERSAO_CADASTRO_SUCCESS:
            return {
                ...state,
                table: action.table,
                loading: false
            }
        case TABLE_UNIDADE_MEDIDA_CONVERSAO_CADASTRO_FAILURE:
            return {
                ...state,
                table: null,
                loading: false
            }
        // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        case FIND_UNIDADE_MEDIDA_CONVERSAO_CADASTRO_SUCCESS:
            return {
                ...state,
                registro: action.registro,
                loading: false
            }
        case FIND_UNIDADE_MEDIDA_CONVERSAO_CADASTRO_FAILURE:
            return {
                ...state,
                registro: null,
                loading: false
            }
        // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        case DELETE_UNIDADE_MEDIDA_CONVERSAO_CADASTRO_SUCCESS: {
            return {
                ...state,
                loading: false
            }
        }
        // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        case CREATE_UNIDADE_MEDIDA_CONVERSAO_CADASTRO_SUCCESS:
        case CREATE_UNIDADE_MEDIDA_CONVERSAO_CADASTRO_FAILURE:
            return {
                ...state,
                loading: false,
                registro: action.registro,
                isCadastroRapido: false,
            }
        case DELETE_UNIDADE_MEDIDA_CONVERSAO_CADASTRO_FAILURE:
            return {
                ...state,
                loading: false,
            }
        // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        default:
            return state
    }
}

export { unidadeMedidaConversaoCadastroReducer };