import { path, httpService } from '@commons';

/////////////////////////////////////////////////////////////////////////////////
const args = { ...path, path: '/patrimonio/abastecimento' };
/////////////////////////////////////////////////////////////////////////////////

export function createPatrimonioAbastecimentoCadastro(payload) {
  return httpService.post(args, payload);
}

export function updatePatrimonioAbastecimentoCadastro(payload) {
  const { id } = payload

  return httpService.put({ ...args, args: `/${id}` }, payload);
}

export function listPatrimonioAbastecimentoCadastro(param) {
  return httpService.get({...args, args: param});
}

export function findPatrimonioAbastecimentoCadastro(id) {
  return httpService.get({ ...args, args: `/${id}` });
}

export function findAllPatrimonioAbastecimentoCadastro() {
  return httpService.get({ ...args, path: '/patrimonio/abastecimento/all' });
}

export function deletePatrimonioAbastecimentoCadastro(id) {
  return httpService.del({ ...args, args: `/${id}` });
}

export function attachFiles(itemId, payload) {
  return httpService.putAttachFiles({ ...args, args: `/attachFiles/${itemId}` }, payload);
}

export function deleteAttachFiles(itemId, filesIdList) {
  return httpService.putDeleteFiles({ ...args, args: `/deleteFiles/${itemId}` }, filesIdList);
}

export function filterPatrimonioAbastecimento(idPropriedade, datas, where) {
  return httpService.get({ ...args, args: `/filterAbastecimento?propriedade=${idPropriedade}&datas=${datas}${where}` });
}
