import { takeLatest, put, call } from 'redux-saga/effects';

import { findAllPatrimonioManutencaoCadastro } from '../service';
import {
  LIST_PATRIMONIO_MANUTENCAO_CADASTRO,
  LIST_PATRIMONIO_MANUTENCAO_CADASTRO_PENDING,
  LIST_PATRIMONIO_MANUTENCAO_CADASTRO_SUCCESS,
  LIST_PATRIMONIO_MANUTENCAO_CADASTRO_FAILURE
} from '../actionTypes';

function* sagaListAllPatrimonioManutencaoCadastro(action) {
  yield put({ type: LIST_PATRIMONIO_MANUTENCAO_CADASTRO_PENDING })

  try {
    const list = yield call(findAllPatrimonioManutencaoCadastro)
    
    yield put({ type: LIST_PATRIMONIO_MANUTENCAO_CADASTRO_SUCCESS, list: list })
  } catch (error) {
    yield put({ type: LIST_PATRIMONIO_MANUTENCAO_CADASTRO_FAILURE })
  }
}

export default function* watchListAllPatrimonioManutencaoCadastro() {
  yield takeLatest(LIST_PATRIMONIO_MANUTENCAO_CADASTRO, sagaListAllPatrimonioManutencaoCadastro)
}
