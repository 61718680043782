import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { formatDateDDMMYYYY } from '@components/common/format';
import { Button } from "reactstrap";
import { CLEAR_NDVI_DATA, LIST_ALL_NDVI_BY_SAFRA, TABLE_NDVI, SELECT_NDVI, HAS_AUTHORITY } from "@handler";
import { decodePath, getPolygonCenter } from "../../utils/mapFunctions";
import IconTextInfoNoRegisterComponent from "@components/common/IconTextInfoNoRegisterComponent";
import PageLoader from "@components/common/PageLoader";
import { storage } from '@commons';
import { CONFIG_SESSION } from '@handler/common/startSession';
import { TEXT_COMPANY_NAME } from "@commons/consts";
import './styles.css';

function NdviWindow({
    onClose,
    setCenter
}) {
    const dispatch = useDispatch();
    const [currentPage, setCurrentPage] = useState(0);
    const [allItems, setAllItems] = useState([]);
    const [isLoading, setLoading] = useState(false);

    const configSession = JSON.parse(storage.get(CONFIG_SESSION));

    const startSessionReducer = useSelector((state) => state.entities.startSessionReducer);
    const { fazendaSelecionada } = startSessionReducer.configSession;
    const ndviReducer = useSelector((state) => state.entities.ndviReducer);

    useEffect(() => {
        setCurrentPage(0);
        setAllItems([]);
        setLoading(false);

        if (configSession.contaAgrare.moduloNdvi) {
            dispatch({ type: HAS_AUTHORITY, role: 'AGRIC_NDVI', license: 'MODULO_NDVI' });
        }

        return () => {
            dispatch({ type: CLEAR_NDVI_DATA });
            setCurrentPage(0);
            setAllItems([]);
            setLoading(false);
        };
    }, []);

    useEffect(() => {
        setLoading(true);
        const config = startSessionReducer.configSession;
        const propriedade = fazendaSelecionada.id;
        const safra = config.safraAtiva.id;
        const page = currentPage;
        const size = 5;
        dispatch({ type: LIST_ALL_NDVI_BY_SAFRA, args: { propriedade, safra, page, size }});
    }, [currentPage]);

    useEffect(() => {
        if (ndviReducer.table) {
            if(ndviReducer.table.number == currentPage){
                setAllItems(prevItems => [...prevItems, ...ndviReducer.table.content]);
            }
            setLoading(false);
        }
    }, [ndviReducer.table]);

    const handleScroll = (event) => {
        const { scrollTop, clientHeight, scrollHeight } = event.currentTarget;
        const threshold = 5;
    
        if (scrollHeight - scrollTop <= clientHeight + threshold) {
            if (currentPage < ndviReducer.table.totalPages - 1) {
                setCurrentPage(prevPage => prevPage + 1);
            }
        }
    };

    const renderCard = (item) => {
        return (
            <div
                className="ndvi-card"
                key={`ndvi_card_${item.id}`}
                onClick={() => {
                    dispatch({ type: SELECT_NDVI, args: item })
                    setCenter(getPolygonCenter(decodePath(item.polygonPath)));
                }}
            >
                <img
                    className="ndvi-image"
                    src={item.presignedUrl}
                    alt={item.fileName}
                />
                <div className="ndvi-footer">
                    <h4 className="ndvi-info">{formatDateDDMMYYYY(item.date)}</h4>
                    <h5 className="ndvi-info-subtitle">{item.talhao?.nome ?? ''}</h5>
                    <span className="ndvi-info-subtitle">Satélite: Sentinel 2 L2A</span>
                </div>
            </div>
        );
    }

    return (
        <div className="map-menu-window-container">
            <div className="map-drawer-header">
                <h4>Imagens Satélite - NDVI</h4>
                <Button className="btn btn-light" onClick={onClose}>
                    <i className='fa fa-arrow-left' />
                </Button>
            </div>
            { configSession.contaAgrare.moduloNdvi ? (
                <div className="scrollable-div" onScroll={handleScroll}>
                    {(allItems.length > 0) ?
                        allItems.map((item) => (
                            renderCard(item)
                        )) : !isLoading ? <IconTextInfoNoRegisterComponent title='Nenhuma imagem NDVI encontrada' /> : <></>
                    }

                    {isLoading && <div className="loading-indicator"><PageLoader /></div>}
                </div>
            ) : (
                <div className="d-flex flex-column align-items-center justify-content-center" style={{ border: '2px dashed #ccc', minHeight: 200, textAlign: 'center' }}>
                    <i className="fas fa-satellite fa-3x"></i>
                    <p style={{ marginTop: '10px' }}>Sua licença não possui esse serviço. Entre em contato com a {TEXT_COMPANY_NAME} para contratar o módulo de monitoramento NDVI.</p>
                </div>
            )}
        </div>
    );
}

export default React.memo(NdviWindow);
