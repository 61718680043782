
import { storage } from '@commons';
import { CONFIG_SESSION } from '@handler/common/startSession';

export function hasRequiredRole(role) {
    const configSession = JSON.parse(storage.get(CONFIG_SESSION));
    const perfilUser = configSession.perfilUser;

    let hasAuthority = false;
  
    if (perfilUser.id == 1) {
      hasAuthority = true;
    } else {
      for (let i = 0; i < configSession.perfilUser.perfilPropriedades.length; i++) {
        if (configSession.perfilUser.perfilPropriedades[i].propriedade.id === configSession.fazendaSelecionada.id && configSession.perfilUser.perfilPropriedades[i].permiteAcesso) {
          hasAuthority = true;
        }
      }
      if (hasAuthority) {
        hasAuthority = false;
        for (let i = 0; i < perfilUser.roles.length; i++) {
          if (perfilUser.roles[i].roleEnum.literal === role && perfilUser.roles[i].ativo) {
            hasAuthority = true;
          }
        }
      }
    }
    return hasAuthority;
  }
