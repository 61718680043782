import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { formatDateDDMMYYYY } from '@components/common/format';
import { CLEAR_NDVI_DATA, TABLE_NDVI, SELECT_NDVI, HAS_AUTHORITY } from "@handler";
import IconTextInfoNoRegisterComponent from "@components/common/IconTextInfoNoRegisterComponent";
import { decodePath, getPolygonCenter } from "../../../utils/mapFunctions";
import PageLoader from "@components/common/PageLoader";
import { storage } from '@commons';
import { CONFIG_SESSION } from '@handler/common/startSession';
import { TEXT_COMPANY_NAME } from "@commons/consts";


function NdviTab({
    area,
    setCenter,
    updateScrollHandler
}) {
    const dispatch = useDispatch();
    const [currentPage, setCurrentPage] = useState(0);
    const [allItems, setAllItems] = useState([]);
    const [isLoading, setLoading] = useState(false);

    const configSession = JSON.parse(storage.get(CONFIG_SESSION));

    const startSessionReducer = useSelector((state) => state.entities.startSessionReducer);
    const { fazendaSelecionada } = startSessionReducer.configSession;
    const ndviReducer = useSelector((state) => state.entities.ndviReducer);

    useEffect(() => {
        if (configSession.contaAgrare.moduloNdvi) {
            dispatch({ type: HAS_AUTHORITY, role: 'AGRIC_NDVI', license: 'MODULO_NDVI' });
        }

        updateScrollHandler(handleScroll);

        return () => {
            dispatch({ type: CLEAR_NDVI_DATA });
            setAllItems([]);
          };
    }, []);

    useEffect(() => {
        setLoading(true);
        dispatch({
            type: TABLE_NDVI,
            args: `?propriedade=${fazendaSelecionada.id}&talhao=${area.id}&page=${currentPage}&size=5`
        });
    }, [currentPage]);

    useEffect(() => {
        if (ndviReducer.table) {
            if(ndviReducer.table.number == currentPage){
                setAllItems(prevItems => [...prevItems, ...ndviReducer.table.content]);
            }
            setLoading(false);
        }
        updateScrollHandler(handleScroll);
    }, [ndviReducer.table]);
    
    const handleScroll = (event) => {
        const { scrollTop, clientHeight, scrollHeight } = event.currentTarget;
        const threshold = 5;
    
        if (scrollHeight - scrollTop <= clientHeight + threshold) {
            if (currentPage < ndviReducer?.table?.totalPages - 1) {
                setCurrentPage(prevPage => prevPage + 1);
            }
        }
    };

    const renderCard = (item) => {
        return (
            <div
                className="ndvi-card"
                key={`area_ndvi_card_${item.id}`}
                onClick={() => {
                    dispatch({ type: SELECT_NDVI, args: item })
                    setCenter(getPolygonCenter(decodePath(item.talhao.mapaPolygonPath)));
                }}
            >
                <img
                    className="ndvi-image"
                    src={item.presignedUrl}
                    alt={item.fileName}
                />
                <div className="ndvi-footer">
                    <h4 className="ndvi-info">{formatDateDDMMYYYY(item.date)}</h4>
                    <h5 className="ndvi-info-subtitle">{item.talhao?.nome ?? ''}</h5>
                    <span className="ndvi-info-subtitle">Satélite: Sentinel 2 L2A</span>
                </div>
            </div>
        );
    }

    return (
        <>
        { configSession.contaAgrare.moduloNdvi ? (
            <div className="map-menu-ndvi-tab-container">
                {(allItems.length > 0) ?
                    allItems.map((item) => (
                        renderCard(item)
                    )) : !isLoading ? <IconTextInfoNoRegisterComponent title='Nenhuma imagem NDVI encontrada' /> : <></>
                }
    
                {isLoading && <div className="loading-indicator"><PageLoader /></div>}
            </div>
        ) : (
            <div className="d-flex flex-column align-items-center justify-content-center" style={{ border: '2px dashed #ccc', minHeight: 200, textAlign: 'center' }}>
                <i className="fas fa-satellite fa-3x"></i>
                <p style={{ marginTop: '10px' }}>Sua licença não possui esse serviço. Entre em contato com a {TEXT_COMPANY_NAME} para contratar o módulo de monitoramento NDVI.</p>
            </div>
        )}
        </>
    );
}

export default React.memo(NdviTab);
