import watchNewProcedimentoCadastro from './saga/new';
import watchFindProcedimentoCadastro from './saga/find';
import watchCreateProcedimentoCadastro from './saga/create';
import watchListProcedimentoCadastro from './saga/listTable';
import watchDeleteProcedimentoCadastro from './saga/delete';
import watchCancelProcedimentoCadastroRapido from './saga/cancel';

export const procedimentoCadastroSaga = [
    watchNewProcedimentoCadastro(),
    watchFindProcedimentoCadastro(),
    watchCreateProcedimentoCadastro(),
    watchListProcedimentoCadastro(),
    watchDeleteProcedimentoCadastro(),
    watchCancelProcedimentoCadastroRapido(),
]

export * from './actionTypes';
export * from './reducer';
export * from './service';
export * from './entity';
