import { takeLatest, put, call } from 'redux-saga/effects';
import { storage } from '@commons';

import {
    NEW_FILTER_ENTRADA_ESTOQUE,
    NEW_FILTER_ENTRADA_ESTOQUE_PENDING,
    NEW_FILTER_ENTRADA_ESTOQUE_SUCCESS,
    NEW_FILTER_ENTRADA_ESTOQUE_FAILURE,
    CONFIG_SESSION,
} from '@handler';
import { formatDateYYYYMMDD } from '@components/common/format';
import { listAllEntradaEstoque } from '../service';
import { formatDateDDMMYYYY } from '@components/common/format';
import { CONST_FILTER_ENTRADA_ESTOQUE, CONST_ROWS_PER_PAGE } from '@commons/consts';

function* sagaNewFilterEntradaEstoque(action) {
    try {
        yield put({ type: NEW_FILTER_ENTRADA_ESTOQUE_PENDING })

        const idFazenda = JSON.parse(storage.get(CONFIG_SESSION)).fazendaSelecionada.id;
        let datas = '';
        let dataInicial = '';
        let dataFinal = '';
        var date = new Date();
        let where = '';
        let newFilter = {
            fornecedor: null,
            numero: null,
            naturezaOperacao: null,
            localEstoque: null,
            identificador: null,
            patrimonio: null,
            dataInicial: '',
            dataFinal: '',
            rows: CONST_ROWS_PER_PAGE,
            page: 0,
            where: ''
        };

        let filterSaved = sessionStorage.getItem(CONST_FILTER_ENTRADA_ESTOQUE) !== null ? JSON.parse(sessionStorage.getItem(CONST_FILTER_ENTRADA_ESTOQUE)) : null;

        if (filterSaved === null || filterSaved === undefined) {
            // seta data do mes na primeira vez que entra na pagina
            dataInicial = formatDateYYYYMMDD(new Date(date.getFullYear(), 0, 1));
            dataFinal = formatDateYYYYMMDD(new Date(date.getFullYear(), date.getMonth() + 1, 0));

            datas = formatDateDDMMYYYY(dataInicial) + '_' + formatDateDDMMYYYY(dataFinal);

            newFilter = {
                ...newFilter,
                dataInicial,
                dataFinal,
            };
        } else {
            // se seta filtro manual
            if (action.newFilter) {
                // seta filtro indicado
                // typePeriodo = 0 -> periodo manual
                // typePeriodo = 1 -> hoje
                // typePeriodo = 2 -> esta semana
                // typePeriodo = 3 -> mes passado
                // typePeriodo = 4 -> este mes
                // typePeriodo = 5 -> proximo mes
                // typePeriodo = 6 -> este ano
                if (action.typePeriodo === undefined || action.typePeriodo === 0) {
                    dataInicial = action.args.dataInicial;
                    dataFinal = action.args.dataFinal;

                    datas = formatDateDDMMYYYY(action.args.dataInicial) + '_' + formatDateDDMMYYYY(action.args.dataFinal);
                } else if (action.typePeriodo === 1) {
                    dataInicial = formatDateYYYYMMDD(new Date(date));
                    dataFinal = formatDateYYYYMMDD(new Date(date));

                    datas = formatDateDDMMYYYY(dataInicial) + '_' + formatDateDDMMYYYY(dataFinal);
                }
                else if (action.typePeriodo === 2) {
                    const primeiro = date.getDate() - date.getDay();

                    dataInicial = formatDateYYYYMMDD(new Date(date.getFullYear(), date.getMonth(), primeiro));
                    dataFinal = formatDateYYYYMMDD(new Date(date.getFullYear(), date.getMonth(), primeiro + 6));

                    datas = formatDateDDMMYYYY(dataInicial) + '_' + formatDateDDMMYYYY(dataFinal);
                } else if (action.typePeriodo === 3) {
                    dataInicial = formatDateYYYYMMDD(new Date(date.getFullYear(), date.getMonth() - 1, 1));
                    dataFinal = formatDateYYYYMMDD(new Date(date.getFullYear(), date.getMonth(), 0));
                
                    datas = formatDateDDMMYYYY(dataInicial) + '_' + formatDateDDMMYYYY(dataFinal);
                } else if (action.typePeriodo === 4) {
                    dataInicial = formatDateYYYYMMDD(new Date(date.getFullYear(), date.getMonth(), 1));
                    dataFinal = formatDateYYYYMMDD(new Date(date.getFullYear(), date.getMonth() + 1, 0));
                
                    datas = formatDateDDMMYYYY(dataInicial) + '_' + formatDateDDMMYYYY(dataFinal);
                } else if (action.typePeriodo === 5) {
                    dataInicial = formatDateYYYYMMDD(new Date(date.getFullYear(), date.getMonth() + 1, 1));
                    dataFinal = formatDateYYYYMMDD(new Date(date.getFullYear(), date.getMonth() + 2, 0));
                
                    datas = formatDateDDMMYYYY(dataInicial) + '_' + formatDateDDMMYYYY(dataFinal);
                } else if (action.typePeriodo === 6) {
                    dataInicial = formatDateYYYYMMDD(new Date(date.getFullYear(), 0, 1));
                    dataFinal = formatDateYYYYMMDD(new Date(date.getFullYear(), 12, 0));
                
                    datas = formatDateDDMMYYYY(dataInicial) + '_' + formatDateDDMMYYYY(dataFinal);
                }

                if (action.args.numero !== null && action.args.numero !== '') {
                    where += `&numero=${action.args.numero}`;
                }
                if (action.args.fornecedor !== null) {
                    where += `&pessoa=${action.args.fornecedor.id}`;
                }
                if (action.args.naturezaOperacao !== null) {
                    where += `&naturezaOperacao=${action.args.naturezaOperacao.id}`;
                }
                if (action.args.localEstoque !== null) {
                    where += `&localEstoque=${action.args.localEstoque.id}`;
                }
                if (action.args.identificador !== null) {
                    where += `&identificador=${action.args.identificador.id}`;
                }
                if (action.args.patrimonio !== null) {
                    where += `&patrimonio=${action.args.patrimonio.id}`;
                }

                newFilter = {
                    ...newFilter,
                    ...action.args,
                    dataInicial,
                    dataFinal,
                    where: where
                };
            } else {
                datas = formatDateDDMMYYYY(filterSaved.dataInicial) + '_' + formatDateDDMMYYYY(filterSaved.dataFinal);

                if (filterSaved.numero !== null && filterSaved.numero !== '') {
                    where += `&numero=${filterSaved.numero}`;
                }
                if (filterSaved.fornecedor !== null) {
                    where += `&pessoa=${filterSaved.fornecedor.id}`;
                }
                if (filterSaved.naturezaOperacao !== null) {
                    where += `&naturezaOperacao=${filterSaved.naturezaOperacao.id}`;
                }
                if (filterSaved.localEstoque !== null) {
                    where += `&localEstoque=${filterSaved.localEstoque.id}`;
                }
                if (filterSaved.identificador !== null) {
                    where += `&identificador=${filterSaved.identificador.id}`;
                }
                if (filterSaved.patrimonio !== null) {
                    where += `&patrimonio=${filterSaved.patrimonio.id}`;
                }

                newFilter = {
                    ...filterSaved,
                    rows: CONST_ROWS_PER_PAGE,
                    page: 0,
                    where: where
                };
            }
        }

        // busca registros
        const list = yield call(listAllEntradaEstoque, idFazenda, datas, `&page=0&size=${CONST_ROWS_PER_PAGE}${where}`);

        sessionStorage.setItem(CONST_FILTER_ENTRADA_ESTOQUE, JSON.stringify(newFilter));

        yield put({
            type: NEW_FILTER_ENTRADA_ESTOQUE_SUCCESS, list: list, filter: newFilter
        });
    } catch (_) {
        yield put({ type: NEW_FILTER_ENTRADA_ESTOQUE_FAILURE })
    }
}

export default function* watchNewFilterEntradaEstoque() {
    yield takeLatest(NEW_FILTER_ENTRADA_ESTOQUE, sagaNewFilterEntradaEstoque)
}
