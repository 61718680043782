import { takeLatest, put, call } from 'redux-saga/effects';

import { history } from '@commons';

import { createVeiculoCadastro, updateVeiculoCadastro } from '../service';
import {
  CREATE_VEICULO_CADASTRO,
  CREATE_VEICULO_CADASTRO_PENDING,
  CREATE_VEICULO_CADASTRO_SUCCESS,
  CREATE_VEICULO_CADASTRO_FAILURE,
} from '@handler';

import { AlertSuccess, AlertError } from '@components/common/AlertToast'

function* sagaCreateVeiculoCadastro(action) {
  yield put({ type: CREATE_VEICULO_CADASTRO_PENDING })

  try {
    action.data = { ...action.data, placa: action.data.placa.toUpperCase() }

    if (action.data.id === undefined || action.data.id === null) {
      yield call(createVeiculoCadastro, action.data);
    } else {
      yield call(updateVeiculoCadastro,
        {
          ...action.data,
          id: action.data.id
        }
      );
    }

    yield put({ type: CREATE_VEICULO_CADASTRO_SUCCESS, registro: action.data })

    if (!action.isCadastroRapido) {
      history.push('/veiculoCadastro');
    }

    AlertSuccess('Registro salvo com sucesso!');

  } catch (error) {
    yield put({ type: CREATE_VEICULO_CADASTRO_FAILURE, registro: action.data })

    AlertError('Falha ao salvar o registro! Tente novamente...');
  }

}

export default function* watchCreateVeiculoCadastro() {
  yield takeLatest(CREATE_VEICULO_CADASTRO, sagaCreateVeiculoCadastro)
}
