import * as Yup from 'yup';

const newRegistro = {
    tipoOcorrencia: 'UNICA',
    data: '',
    dataEmissao: '',
    competencia: '',
    numeroDoc: '',
    valor: 0,
    tipoEntidade: 'PESSOA',
    pessoa: null,
    motorista: null,
    funcionario: null,
    nomeEntidadeOutros: '',
    contaGerencial: null,
    identificador: null,
    historico: '',
    automatico: false,
    obs: '',
    forJson: 'contapagar',
    parcelas: [],
    dataPrimeiraParcela: '',
    numeroParcelas: null,
    contaPaga: false,
    tipoPagamento: null,
    contaCaixa: null,
    contaBanco: null,
    vincularPatrimonio: false,
    patrimonio: null,
    apropriarCustoSafra: false,
    despDireta: null,
    rateioSafras: []
};

const validation = Yup.object().shape({
    tipoOcorrencia: Yup.string()
        .typeError('Este campo é obrigatório')
        .required('Este campo é obrigatório'),
    data: Yup.date().when('tipoOcorrencia', {
        is: (tipoOcorrencia) => tipoOcorrencia === 'UNICA',
        then: Yup.date()
            .required('Este campo é obrigatório')
    }).nullable(),
    dataEmissao: Yup.date()
        .required('Este campo é obrigatório'),
    competencia: Yup.date().when('tipoOcorrencia', {
        is: (tipoOcorrencia) => tipoOcorrencia === 'UNICA',
        then: Yup.date()
            .required('Este campo é obrigatório')
    }).nullable(),
    tipoEntidade: Yup.string()
        .typeError('Este campo é obrigatório')
        .required('Este campo é obrigatório'),
    contaGerencial: Yup.object()
        .typeError('Este campo é obrigatório')
        .shape({
            id: Yup.number()
                .typeError('Este campo é obrigatório')
                .required("Este campo é obrigatório"),
        }),
    historico: Yup.string()
        .min(2, 'O campo deve ter no mínimo 2 caracteres')
        .max(255, 'Campo deve ter menos do que 255 caracteres')
        .required('Este campo é obrigatório'),
    valor: Yup.number()
        .typeError('Este campo é obrigatório')
        .positive('Por Favor, informe um valor maior que zero')
        .required("Este campo é obrigatório"),
    numeroDoc: Yup.string()
        .max(60, 'Campo deve ter menos do que 60 caracteres'),
    obs: Yup.string()
        .max(2000, 'Campo deve ter menos do que 2000 caracteres'),
    patrimonio: Yup.object().when('vincularPatrimonio', {
        is: (vincularPatrimonio) => vincularPatrimonio === true,
        then: Yup.object()
            .typeError('Este campo é obrigatório')
            .shape({
                id: Yup.number()
                    .typeError('Este campo é obrigatório')
                    .required("Este campo é obrigatório"),
            })
    }).nullable(),
    cartaoNsu: Yup.string()
        .max(20, 'Campo deve ter menos do que 20 caracteres'),
    chequeEmitente: Yup.string()
        .max(60, 'Campo deve ter menos do que 60 caracteres'),
    chequeNro: Yup.string()
        .max(15, 'Campo deve ter menos do que 15 caracteres'),
    chequeBanco: Yup.string()
        .max(60, 'Campo deve ter menos do que 60 caracteres'),
    chequeNroAg: Yup.string()
        .max(4, 'Campo deve ter menos do que 4 caracteres'),
    chequeDvAg: Yup.string()
        .max(2, 'Campo deve ter menos do que 2 caracteres'),
    chequeNroCc: Yup.string()
        .max(20, 'Campo deve ter menos do que 20 caracteres'),
    chequeDvCc: Yup.string()
        .max(2, 'Campo deve ter menos do que 2 caracteres'),
    parcelas: Yup.array().of(
        Yup.object().shape({
            competencia: Yup.date().required('Este campo é obrigatório'),
            cartaoNsu: Yup.string().max(20, 'Campo deve ter menos do que 20 caracteres'),
            chequeEmitente: Yup.string().max(60, 'Campo deve ter menos do que 60 caracteres'),
            chequeNro: Yup.string().max(15, 'Campo deve ter menos do que 15 caracteres'),
            chequeBanco: Yup.string().max(60, 'Campo deve ter menos do que 60 caracteres'),
            chequeNroAg: Yup.string().max(4, 'Campo deve ter menos do que 4 caracteres'),
            chequeDvAg: Yup.string().max(2, 'Campo deve ter menos do que 2 caracteres'),
            chequeNroCc: Yup.string().max(20, 'Campo deve ter menos do que 20 caracteres'),
            chequeDvCc: Yup.string().max(2, 'Campo deve ter menos do que 2 caracteres'),
        })
    ),
    despDireta: Yup.object().when('apropriarCustoSafra', {
        is: (apropriarCustoSafra) => apropriarCustoSafra === true,
        then: Yup.object()
            .typeError('Este campo é obrigatório')
            .shape({
                id: Yup.number()
                    .typeError('Este campo é obrigatório')
                    .required("Este campo é obrigatório"),
            })
    }).nullable(),
    rateioSafras: Yup.array()
        .of(
            Yup.object().shape({
                safra: Yup.object()
                    .typeError('Este campo é obrigatório')
                    .shape({
                        id: Yup.number()
                            .typeError('Este campo é obrigatório')
                            .required("Este campo é obrigatório"),
                    }),
                percentualRateio: Yup.number()
                    .positive('Por Favor, informe um valor maior que zero')
                    .max(100, 'Máximo 100%')
                    .transform(value => (isNaN(value) ? 0.0 : value))
                    .required('Este campo é obrigatório'),
            })
        ),
});

export const contaPagar = {
    newRegistro,
    validation
}