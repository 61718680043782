import { takeLatest, put, call } from 'redux-saga/effects';

import {
  VISUALIZAR_NOTA_MONITORAMENTO_NFE_COMPRA,
  VISUALIZAR_NOTA_MONITORAMENTO_NFE_COMPRA_PENDING,
  VISUALIZAR_NOTA_MONITORAMENTO_NFE_COMPRA_SUCCESS,
  VISUALIZAR_NOTA_MONITORAMENTO_NFE_COMPRA_FAILURE
} from '../actionTypes';
import { AlertError } from '@components/common/AlertToast';
import { visualizarDanfeXmlFile } from '../service';

function* sagaVisualizarNotaEntrada(action) {
  yield put({ type: VISUALIZAR_NOTA_MONITORAMENTO_NFE_COMPRA_PENDING })

  try {
    if (action.consulta_monitoramento) {
      yield call(visualizarDanfeXmlFile, action.notaEntradaApiDfe.id);
    } else {
      yield call(visualizarDanfeXmlFile, action.id);  
    }

    yield put({ type: VISUALIZAR_NOTA_MONITORAMENTO_NFE_COMPRA_SUCCESS })

  } catch (error) {
    yield put({ type: VISUALIZAR_NOTA_MONITORAMENTO_NFE_COMPRA_FAILURE })

    AlertError('Falha ao visualizar nota! Tente novamente...');
  }
}

export default function* watchVisualizarNotaEntrada() {
  yield takeLatest(VISUALIZAR_NOTA_MONITORAMENTO_NFE_COMPRA, sagaVisualizarNotaEntrada)
}
