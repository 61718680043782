import { takeLatest, put, call } from 'redux-saga/effects';
import { storage } from '@commons/storage';

import {
  CONFIG_SESSION,
  NEW_FILTER_CONTROLE_CONTA_BANCO,
  NEW_FILTER_CONTROLE_CONTA_BANCO_PEDING,
  NEW_FILTER_CONTROLE_CONTA_BANCO_SUCCESS,
  NEW_FILTER_CONTROLE_CONTA_BANCO_FAILURE,
  GET_TOTAIS_CONTROLE_CONTA_BANCO_PENDING,
  GET_TOTAIS_CONTROLE_CONTA_BANCO_SUCCESS,
  GET_TOTAIS_CONTROLE_CONTA_BANCO_FAILURE,
  findAllContaBancoCadastro,
  NEW_FILTER_CONTROLE_CONTA_BANCO_SUCCESS_PDF
} from '@handler';
import { formatDateDDMMYYYY, formatDateYYYYMMDD } from '@components/common/format';
import { getLancamentos, getRelatorioControleBanco, getTotaisExtrato } from '../service';
import { CONST_FILTER_MOVTO_BANCO, CONST_ROWS_PER_PAGE } from '@commons/consts';

function* sagaNewFilterControleContaBanco(action) {
  try {
    yield put({ type: NEW_FILTER_CONTROLE_CONTA_BANCO_PEDING })
    
    const idFazenda = JSON.parse(storage.get(CONFIG_SESSION)).fazendaSelecionada.id;
    let datas = '';
    let dataInicial = '';
    let dataFinal = '';
    var date = new Date();
    let where = '';
    let newFilter = {
      contaBanco: null,
      tipoEntidade: 'PESSOA',
      pessoa: null,
      motorista: null,
      funcionario: null,
      nomeEntidadeOutros: '',
      contaGerencial: null,
      historico: null,
      numeroDocumento: null,
      dataInicial: '',
      dataFinal: '',
      contasCadastro: null,
      rows: CONST_ROWS_PER_PAGE,
      page: 0,
      where: ''
    };

    let filterSaved = sessionStorage.getItem(CONST_FILTER_MOVTO_BANCO) !== null ? JSON.parse(sessionStorage.getItem(CONST_FILTER_MOVTO_BANCO)) : null;

    if (filterSaved === null || filterSaved === undefined) {
      // seta data do mes na primeira vez que entra na pagina
      dataInicial = formatDateYYYYMMDD(new Date(date.getFullYear(), date.getMonth(), 1));
      dataFinal = formatDateYYYYMMDD(new Date(date.getFullYear(), date.getMonth() + 1, 0));

      datas = formatDateDDMMYYYY(dataInicial) + '_' + formatDateDDMMYYYY(dataFinal);
      where = '';
      const contasCadastro = yield call(findAllContaBancoCadastro, idFazenda);
      let contaBanco = null;
      if (contasCadastro !== null && contasCadastro !== undefined && contasCadastro.length === 1) {
        contaBanco = contasCadastro[0];
      }
      
      newFilter = {
        ...newFilter,
        dataInicial,
        dataFinal,
        contaBanco: contaBanco,
        contasCadastro: contasCadastro,
        where: where
      };
    } else {
      // se seta filtro manual
      if (action.newFilter) {
        // seta filtro indicado
        // typePeriodo = 0 -> periodo manual
        // typePeriodo = 1 -> hoje
        // typePeriodo = 2 -> esta semana
        // typePeriodo = 3 -> mes passado
        // typePeriodo = 4 -> este mes
        // typePeriodo = 5 -> proximo mes
        // typePeriodo = 6 -> este ano
        if (action.typePeriodo === undefined || action.typePeriodo === 0) {
          dataInicial = action.args.dataInicial;
          dataFinal = action.args.dataFinal;

          datas = formatDateDDMMYYYY(action.args.dataInicial) + '_' + formatDateDDMMYYYY(action.args.dataFinal);
        } else if (action.typePeriodo === 1) {
          dataInicial = formatDateYYYYMMDD(new Date(date));
          dataFinal = formatDateYYYYMMDD(new Date(date));

          datas = formatDateDDMMYYYY(dataInicial) + '_' + formatDateDDMMYYYY(dataFinal);
        }
        else if (action.typePeriodo === 2) {
          const primeiro = date.getDate() - date.getDay();

          dataInicial = formatDateYYYYMMDD(new Date(date.getFullYear(), date.getMonth(), primeiro));
          dataFinal = formatDateYYYYMMDD(new Date(date.getFullYear(), date.getMonth(), primeiro + 6));

          datas = formatDateDDMMYYYY(dataInicial) + '_' + formatDateDDMMYYYY(dataFinal);
        } else if (action.typePeriodo === 3) {
          dataInicial = formatDateYYYYMMDD(new Date(date.getFullYear(), date.getMonth() - 1, 1));
          dataFinal = formatDateYYYYMMDD(new Date(date.getFullYear(), date.getMonth(), 0));

          datas = formatDateDDMMYYYY(dataInicial) + '_' + formatDateDDMMYYYY(dataFinal);
        } else if (action.typePeriodo === 4) {
          dataInicial = formatDateYYYYMMDD(new Date(date.getFullYear(), date.getMonth(), 1));
          dataFinal = formatDateYYYYMMDD(new Date(date.getFullYear(), date.getMonth() + 1, 0));

          datas = formatDateDDMMYYYY(dataInicial) + '_' + formatDateDDMMYYYY(dataFinal);
        } else if (action.typePeriodo === 5) {
          dataInicial = formatDateYYYYMMDD(new Date(date.getFullYear(), date.getMonth() + 1, 1));
          dataFinal = formatDateYYYYMMDD(new Date(date.getFullYear(), date.getMonth() + 2, 0));

          datas = formatDateDDMMYYYY(dataInicial) + '_' + formatDateDDMMYYYY(dataFinal);
        } else if (action.typePeriodo === 6) {
          dataInicial = formatDateYYYYMMDD(new Date(date.getFullYear(), 0, 1));
          dataFinal = formatDateYYYYMMDD(new Date(date.getFullYear(), 12, 0));

          datas = formatDateDDMMYYYY(dataInicial) + '_' + formatDateDDMMYYYY(dataFinal);
        }

        if (action.args.tipoEntidade !== null && action.args.tipoEntidade !== undefined && action.args.tipoEntidade !== '') {
          if (action.args.tipoEntidade === 'PESSOA') {
            if (action.args.pessoa !== null && action.args.pessoa !== undefined) {
              where = where + '&tipoEntidade=PESSOA&pessoa=' + action.args.pessoa.id;
            }
          }
          if (action.args.tipoEntidade === 'FUNCIONARIO') {
            if (action.args.funcionario !== null && action.args.funcionario !== undefined) {
              where = where + '&tipoEntidade=FUNCIONARIO&funcionario=' + action.args.funcionario.id;
            }
          }
          if (action.args.tipoEntidade === 'MOTORISTA') {
            if (action.args.motorista !== null && action.args.motorista !== undefined) {
              where = where + '&tipoEntidade=MOTORISTA&motorista=' + action.args.motorista.id;
            }
          }
          if (action.args.tipoEntidade === 'OUTROS') {
            if (action.args.nomeEntidadeOutros !== null && action.args.nomeEntidadeOutros !== undefined && action.args.nomeEntidadeOutros !== '') {
              where = where + '&tipoEntidade=OUTROS&nomeEntidadeOutros=' + action.args.nomeEntidadeOutros;
            }
          }
        } else {
          where = where + '&tipoEntidade=PESSOA';
        }
        if (action.args.contaGerencial !== null && action.args.contaGerencial !== undefined) {
          where = where + '&contaGerencial=' + action.args.contaGerencial.id;
        }
        if (action.args.historico !== null && action.args.historico !== undefined && action.args.historico !== '') {
          where = where + '&historico=' + action.args.historico;
        }
        if (action.args.numeroDocumento !== null && action.args.numeroDocumento !== undefined && action.args.numeroDocumento !== '') {
          where = where + '&numeroDocumento=' + action.args.numeroDocumento;
        }

        newFilter = {
          ...newFilter,
          ...action.args,
          dataInicial,
          dataFinal,
          where: where
        };
        
      } else {
        // busca filtro salvo
        datas = formatDateDDMMYYYY(filterSaved.dataInicial) + '_' + formatDateDDMMYYYY(filterSaved.dataFinal);

        if (filterSaved.tipoEntidade !== null && filterSaved.tipoEntidade !== undefined && filterSaved.tipoEntidade !== '') {
          if (filterSaved.tipoEntidade === 'PESSOA') {
            if (filterSaved.pessoa !== null && filterSaved.pessoa !== undefined) {
              where = where + '&tipoEntidade=PESSOA&pessoa=' + filterSaved.pessoa.id;
            }
          }
          if (filterSaved.tipoEntidade === 'FUNCIONARIO') {
            if (filterSaved.funcionario !== null && filterSaved.funcionario !== undefined) {
              where = where + '&tipoEntidade=FUNCIONARIO&funcionario=' + filterSaved.funcionario.id;
            }
          }
          if (filterSaved.tipoEntidade === 'MOTORISTA') {
            if (filterSaved.motorista !== null && filterSaved.motorista !== undefined) {
              where = where + '&tipoEntidade=MOTORISTA&motorista=' + filterSaved.motorista.id;
            }
          }
          if (filterSaved.tipoEntidade === 'OUTROS') {
            if (filterSaved.nomeEntidadeOutros !== null && filterSaved.nomeEntidadeOutros !== undefined && filterSaved.nomeEntidadeOutros !== '') {
              where = where + '&tipoEntidade=OUTROS&nomeEntidadeOutros=' + filterSaved.nomeEntidadeOutros;
            }
          }
        } else {
          where = where + '&tipoEntidade=PESSOA';
        }
        if (filterSaved.contaGerencial !== null && filterSaved.contaGerencial !== undefined) {
          where = where + '&contaGerencial=' + filterSaved.contaGerencial.id;
        }
        if (filterSaved.historico !== null && filterSaved.historico !== undefined && filterSaved.historico !== '') {
          where = where + '&historico=' + filterSaved.historico;
        }
        if (filterSaved.numeroDocumento !== null && filterSaved.numeroDocumento !== undefined && filterSaved.numeroDocumento !== '') {
          where = where + '&numeroDocumento=' + filterSaved.numeroDocumento;
        }

        newFilter = {
          ...filterSaved,
          rows: CONST_ROWS_PER_PAGE,
          page: 0,
          where: where
        };
      }
    }

    if (action.pdf === undefined || !action.pdf) {
      // busca registros
      let list = yield call(getLancamentos, idFazenda, newFilter.contaBanco?.id ?? -1, datas, `&page=0&size=${CONST_ROWS_PER_PAGE}${where}`);

      sessionStorage.setItem(CONST_FILTER_MOVTO_BANCO, JSON.stringify(newFilter));

      yield put({
        type: NEW_FILTER_CONTROLE_CONTA_BANCO_SUCCESS, list: list, filter: newFilter
      });

      // busca totais
      yield put({ type: GET_TOTAIS_CONTROLE_CONTA_BANCO_PENDING })

      const totais = yield call(getTotaisExtrato, idFazenda, newFilter.contaBanco?.id ?? -1, datas, where);

      yield put({
        type: GET_TOTAIS_CONTROLE_CONTA_BANCO_SUCCESS, totais: totais
      });
    } else {
      yield call(getRelatorioControleBanco, idFazenda, newFilter.contaBanco?.id ?? -1, datas, where);

      yield put({
        type: NEW_FILTER_CONTROLE_CONTA_BANCO_SUCCESS_PDF
      })
    }
  } catch (_) {
    yield put({ type: NEW_FILTER_CONTROLE_CONTA_BANCO_FAILURE })
    yield put({ type: GET_TOTAIS_CONTROLE_CONTA_BANCO_FAILURE })
  }
}

export default function* watchNewFilterControleContaBanco() {
  yield takeLatest(NEW_FILTER_CONTROLE_CONTA_BANCO, sagaNewFilterControleContaBanco)
}
