import { takeLatest, put } from 'redux-saga/effects';
import {
    CANCEL_PROCEDIMENTO_CADASTRO_RAPIDO,
    CANCEL_PROCEDIMENTO_CADASTRO_RAPIDO_SUCCESS
} from '../actionTypes';
function* sagaCancelProcedimentoCadastroRapido() {
  yield put({ type: CANCEL_PROCEDIMENTO_CADASTRO_RAPIDO_SUCCESS})
}
export default function* watchCancelProcedimentoCadastroRapido() {
  yield takeLatest(CANCEL_PROCEDIMENTO_CADASTRO_RAPIDO, sagaCancelProcedimentoCadastroRapido)
}