export const PRODUTO_INSUMO_GRUPO_DEFENSIVO_ENUM = [
    {
        id: 'ACARICIDA',
        descricao: "Acaricida",
        idBD: 0
    },
    {
        id: 'ADJUVANTE',
        descricao: "Adjuvante",
        idBD: 1
    },
    {
        id: 'BACTERICIDA',
        descricao: "Bactericida",
        idBD: 19
    },
    {
        id: 'DESSECANTE',
        descricao: "Dessecante",
        idBD: 3
    },
    {
        id: 'FEROMONIO',
        descricao: "Feromônio",
        idBD: 39
    },
    {
        id: 'FORMICIDA',
        descricao: "Formicida",
        idBD: 36
    },
    {
        id: 'FUNGICIDA',
        descricao: "Fungicida",
        idBD: 5
    },
    {
        id: 'FUNGICIDA_TS',
        descricao: "Fungicida T.S.",
        idBD: 34
    },
    {
        id: 'HERBICIDA',
        descricao: "Herbicida",
        idBD: 7
    },
    {
        id: 'HERBICIDA_FOLHA_ESTREITA',
        descricao: "Herbicida Folha Estreita",
        idBD: 23
    },
    {
        id: 'HERBICIDA_FOLHA_LARGA',
        descricao: "Herbicida Folha Larga",
        idBD: 22
    },
    {
        id: 'INDUTOR_RESISTENCIA',
        descricao: "Indutor de Resistência",
        idBD: 29
    },
    {
        id: 'INSETICIDA',
        descricao: "Inseticida",
        idBD: 8
    },
    {
        id: 'INSETICIDA_FISIOLOGICO',
        descricao: "Inseticida Fisiológico",
        idBD: 33
    },
    {
        id: 'INSETICIDA_PERCEVEJO_MOSCA_BRANCA',
        descricao: "Inseticida - Percevejo e Mosca Branca",
        idBD: 35
    },
    {
        id: 'FEROMONIO',
        descricao: "Feromônio",
        idBD: 39
    },
    {
        id: 'NEMATICIDA',
        descricao: "Nematicida",
        idBD: 38
    },
    {
        id: 'PROTETOR',
        descricao: "Protetor",
        idBD: 31
    },
    {
        id: 'REGULADOR_CRESCIMENTO',
        descricao: "Regulador de Crescimento",
        idBD: 37
    },
];



export const PRODUTO_INSUMO_GRUPO_FERTILIZANTE_ENUM = [
    {
        id: 'ADUBO',
        descricao: "Adubo",
        idBD: 2
    },
    {
        id: 'ADUBO_FOLIAR',
        descricao: "Adubo Foliar",
        idBD: 17
    },
    {
        id: 'BIOESTIMULANTE',
        descricao: "Bioestimulante",
        idBD: 32
    },
    {
        id: 'CORRETIVO',
        descricao: "Corretivo",
        idBD: 18
    },
    {
        id: 'FERTILIZANTE',
        descricao: "Fertilizante",
        idBD: 4
    },
    {
        id: 'INOCULANTE',
        descricao: "Inoculante",
        idBD: 26
    },
    {
        id: 'INOCULANTE_LIQUIDO',
        descricao: "Inoculante Líquido",
        idBD: 27
    },
    {
        id: 'INOCULANTE_TURFOSO',
        descricao: "Inoculante Turfoso",
        idBD: 28
    },
    {
        id: 'SUPRIMENTO_MINERAL',
        descricao: "Suprimento Mineral",
        idBD: 15
    },
];


export const PRODUTO_INSUMO_GRUPO_CULTIVAR_ENUM = [
    {
        id: 'SEMENTE',
        descricao: "Semente",
        idBD: 14
    },
    {
        id: 'MUDA',
        descricao: "Muda",
        idBD: 40
    },
];

export const PRODUTO_INSUMO_GRUPO_ITEM_ENUM = [
    {
        id: 'ITEM_MANUTENCAO',
        descricao: "Item Manutenção",
        idBD: 9
    },
    {
        id: 'MATERIAL_EPI',
        descricao: "Material EPI",
        idBD: 10
    },
    {
        id: 'MEDICAMENTO',
        descricao: "Medicamento",
        idBD: 11
    },
    {
        id: 'PRODUTO_VETERINARIO',
        descricao: "Produto Veterinário",
        idBD: 21
    },
    {
        id: 'RACAO',
        descricao: "Ração",
        idBD: 12
    },
    {
        id: 'SAL_MINERAL',
        descricao: "Sal Mineral",
        idBD: 13
    },
    {
        id: 'OLEO_MINERAL',
        descricao: "Óleo Mineral",
        idBD: 24
    },
    {
        id: 'OLEO_VEGETAL',
        descricao: "Óleo Vegetal",
        idBD: 25
    },
];

