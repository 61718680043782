import { path, httpService } from '@commons';

/////////////////////////////////////////////////////////////////////////////////
const args = { ...path, path: '/estoque/transferencia' };
/////////////////////////////////////////////////////////////////////////////////

export function createTransferenciaEstoque(payload) {
  return httpService.post(args, payload);
}

export function updateTransferenciaEstoque(payload) {
  const { id } = payload

  return httpService.put({ ...args, args: `/${id}` }, payload);
}

export function listTransferenciaEstoque(param) {
  return httpService.get({ ...args, args: param });
}

export function findTransferenciaEstoque(id) {
  return httpService.get({ ...args, args: `/${id}` });
}

export function findAllTransferenciaEstoque() {
  return httpService.get({ ...args, path: '/estoque/transferencia/all' });
}

export function deleteTransferenciaEstoque(id) {
  return httpService.del({ ...args, args: `/${id}` });
}

export function filterTransferenciaEstoque(idPropriedade, datas, where) {
  return httpService.get({ ...args, args: `?propriedade=${idPropriedade}&datas=${datas}${where}` });
}

export function transferenciaPodeSerEditadaExcluida(id) {
  return httpService.get({ ...args, path: '/estoque/transferencia/transferenciaPodeSerEditadaExcluida/' + id });
}

