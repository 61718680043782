import { takeLatest, put, call } from 'redux-saga/effects';

import { history, storage } from '@commons';

import { createPlantioManejoCustoDireto, updatePlantioManejoCustoDireto } from '../service';
import {
  CONFIG_SESSION,
  CREATE_PLANTIO_MANEJO_CUSTO_DIRETO,
  CREATE_PLANTIO_MANEJO_PENDING,
  CREATE_PLANTIO_MANEJO_SUCCESS,
  CREATE_PLANTIO_MANEJO_FAILURE,
} from '@handler';

import { AlertSuccess, AlertError, AlertWarning } from '@components/common/AlertToast'

import { formatDateDDMMYYYY, formatJsonRemoveEmptyOrNull, isDateValidVerifyDateBetween } from '@components/common/format'

function* sagaCreatePlantioManejoCustoDireto(action) {
  yield put({ type: CREATE_PLANTIO_MANEJO_PENDING })

  try {
    const safraAtiva = JSON.parse(storage.get(CONFIG_SESSION)).safraAtiva;
    var dateFrom = safraAtiva.dataInicial;
    var dateTo = safraAtiva.dataFinal;
    var dateCheck = action.data.data;

    if (!isDateValidVerifyDateBetween(dateFrom, dateTo, dateCheck)) {
      yield put({ type: CREATE_PLANTIO_MANEJO_FAILURE, registro: action.data })

      AlertWarning('Data informada fora do período da safra ativa. Período deve ser entre '
        + formatDateDDMMYYYY(dateFrom) + ' até ' + formatDateDDMMYYYY(dateTo) + '!');
    } else {
      if (!action.data.vincularPatrimonio) {
        action.data.patrimonio = null;
      }

      if (!action.data.financeiroDuplicata.geraDuplicata) {
        action.data.financeiroDuplicata.tipoDuplicata = null;
        action.data.financeiroDuplicata.contaGerencial = null;
        action.data.financeiroDuplicata.historico = '';
        action.data.financeiroDuplicata.tipoPagamento = null;
        action.data.financeiroDuplicata.contaCaixa = null;
        action.data.financeiroDuplicata.contaBanco = null;
        action.data.financeiroDuplicata.numeroParcelas = null;
        action.data.parcelas = [];
      } else if (action.data.financeiroDuplicata.geraDuplicata) {
        if (action.data.financeiroDuplicata.tipoDuplicata === 'A_VISTA') {
          action.data.financeiroDuplicata.numeroParcelas = null;
          action.data.parcelas = [];
          if (action.data.financeiroDuplicata.movimentaFinanceiro === false) {
            action.data.financeiroDuplicata.contaGerencial = null;
            action.data.financeiroDuplicata.historico = '';
            action.data.financeiroDuplicata.tipoPagamento = null;
            action.data.financeiroDuplicata.contaCaixa = null;
            action.data.financeiroDuplicata.contaBanco = null;
          }
        } else if (action.data.financeiroDuplicata.tipoDuplicata === 'PARCELADA' && action.data.financeiroDuplicata.movimentaFinanceiro === false) {
          for (var i = 0; i < action.data.parcelas.length; i++) {
            action.data.parcelas[i].contaGerencial = null;
            action.data.parcelas[i].historico = '';
            action.data.parcelas[i].parcelaPaga = false;
            action.data.parcelas[i].tipoPagamento = null;
            action.data.parcelas[i].contaCaixa = null;
            action.data.parcelas[i].contaBanco = null;
          }
        }
      }

      const registro = {
        data: action.data.data,
        identificador: action.data.identificador,
        despDireta: action.data.despDireta,
        obs: action.data.obs,
        valor: action.data.valor,
        vincularPatrimonio: action.data.vincularPatrimonio,
        patrimonio: action.data.patrimonio,
        financeiroDuplicata: action.data.financeiroDuplicata,
        parcelas: action.data.parcelas,
        pessoa: action.data.pessoa,
        numeroDoc: action.data.numeroDoc,
        propriedade: {
          id: JSON.parse(storage.get(CONFIG_SESSION)).fazendaSelecionada.id
        },
        safra: {
          id: JSON.parse(storage.get(CONFIG_SESSION)).safraAtiva.id
        },
        custoDiretoTalhao: action.data.custoDiretoTalhao,
        rateioPorNotaFiscal: action.data.rateioPorNotaFiscal,
        rateioPorNotaFiscalId: action.data.rateioPorNotaFiscalId
      }

      if (action.data.id === undefined || action.data.id === null) {
        yield call(createPlantioManejoCustoDireto, formatJsonRemoveEmptyOrNull(registro));
      } else {
        let lancamentosContaPagar = [];
        action.data.lancamentosContaPagar.forEach(element => {
          lancamentosContaPagar = [
            ...lancamentosContaPagar,
            {
              "forJson": "contapagar",
              "id": element.id,
              "valor": element.valor,
              "historico": element.historico,
              "propriedade": { "id": element.propriedade.id },
              "contaGerencial": {
                "id": element.contaGerencial.id,
                "tipoConta": "A"
              }
            }
          ]
        });
        yield call(updatePlantioManejoCustoDireto,
          {
            ...registro,
            lancamentosContaPagar: lancamentosContaPagar,
            id: action.data.id
          }
        );
      }

      yield put({ type: CREATE_PLANTIO_MANEJO_SUCCESS, registro: action.data })

      history.push('/agricultura/execucao');

      AlertSuccess('Registro salvo com sucesso!');
    }
  } catch (error) {
    yield put({ type: CREATE_PLANTIO_MANEJO_FAILURE, registro: action.data })

    AlertError('Falha ao salvar o registro! Tente novamente...');
  }
}

export default function* watchCreatePlantioManejoCustoDireto() {
  yield takeLatest(CREATE_PLANTIO_MANEJO_CUSTO_DIRETO, sagaCreatePlantioManejoCustoDireto)
}
