import * as Yup from 'yup';

const newRegistro = {
    status: true,
    placa: '',
    rntc: '',
    veiculoProprio: false,
    patrimonio: null,
    transportadora: null,
    emplacadoNoBrasil: true,
    estadoEmplacamento: null
};

const validation = Yup.object().shape({
    placa: Yup.string()
        .required('Este campo é obrigatório'),
    rntc: Yup.string()
        .max(20, 'Campo deve ter menos do que 20 caracteres'),
});

export const veiculoCadastro = {
    newRegistro,
    validation
}