import { takeLatest, put } from 'redux-saga/effects';

import {
    CANCEL_UNIDADE_MEDIDA_CONVERSAO_CADASTRO_RAPIDO,
    CANCEL_UNIDADE_MEDIDA_CONVERSAO_CADASTRO_RAPIDO_SUCCESS
} from '../actionTypes';

function* sagaCancelUnidadeMedidaConversaoCadastroRapido() {
  yield put({ type: CANCEL_UNIDADE_MEDIDA_CONVERSAO_CADASTRO_RAPIDO_SUCCESS})
}

export default function* watchCancelUnidadeMedidaConversaoCadastroRapido() {
  yield takeLatest(CANCEL_UNIDADE_MEDIDA_CONVERSAO_CADASTRO_RAPIDO, sagaCancelUnidadeMedidaConversaoCadastroRapido)
}