import { takeLatest, put, call } from 'redux-saga/effects';

import { listNotification } from '../service';
import {
  CONFIG_SESSION,
  TABLE_NOTIFICATION,
  TABLE_NOTIFICATION_PENDING,
  TABLE_NOTIFICATION_SUCCESS,
  TABLE_NOTIFICATION_FAILURE
} from '@handler';
import { storage, path } from "@commons";

function* sagaListNotification(action) {
  yield put({ type: TABLE_NOTIFICATION_PENDING });

  try {
    const user = JSON.parse(storage.get(path.sessionUser));
    const dominio = JSON.parse(storage.get(CONFIG_SESSION)).schemaSelecionado;
    const schema = dominio ? dominio : '';
    const propriedadeId = action.propriedade ? action.propriedade.id : '';

    const table = yield call(listNotification, user.id, schema, propriedadeId, action.args);
    yield put({ type: TABLE_NOTIFICATION_SUCCESS, table: table });
  } catch (error) {
    yield put({ type: TABLE_NOTIFICATION_FAILURE });
  }
}

export default function* watchListNotification() {
  yield takeLatest(TABLE_NOTIFICATION, sagaListNotification);
}
