import { takeLatest, put, call } from 'redux-saga/effects';

import { listAllNdviBySafra } from '../service';
import {
  LIST_ALL_NDVI_BY_SAFRA,
  LIST_ALL_NDVI_BY_SAFRA_PENDING,
  LIST_ALL_NDVI_BY_SAFRA_SUCCESS,
  LIST_ALL_NDVI_BY_SAFRA_FAILURE
} from '../actionTypes';

function* sagaListAllNdviBySafra(action) {
  yield put({ type: LIST_ALL_NDVI_BY_SAFRA_PENDING });

  try {
    const { propriedade, safra, page, size } = action.args;
    const response = yield call(listAllNdviBySafra, propriedade, safra, page, size);
    yield put({ type: LIST_ALL_NDVI_BY_SAFRA_SUCCESS, table: response });
  } catch (error) {
    yield put({ type: LIST_ALL_NDVI_BY_SAFRA_FAILURE });
  }
}

export default function* watchListAllNdviBySafra() {
  yield takeLatest(LIST_ALL_NDVI_BY_SAFRA, sagaListAllNdviBySafra);
}
