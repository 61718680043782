import React from 'react';
import './styles.css';

const NdviScale = () => {
  return (
    <div className="ndvi-legend">
      <h5>Índice de Vegetação</h5>
      <div className="legend-description">
        Baixo
        <div className="legend-gradient">
          <div className="gradient-color" style={{ background: '#67000d' }} />
          <div className="gradient-color" style={{ background: '#a50026' }} />
          <div className="gradient-color" style={{ background: '#d73027' }} />
          <div className="gradient-color" style={{ background: '#f46d43' }} />
          <div className="gradient-color" style={{ background: '#fdae61' }} />
          <div className="gradient-color" style={{ background: '#fee08b' }} />
          <div className="gradient-color" style={{ background: '#ffffbf' }} />
          <div className="gradient-color" style={{ background: '#d9ef8b' }} />
          <div className="gradient-color" style={{ background: '#a6d96a' }} />
          <div className="gradient-color" style={{ background: '#66bd63' }} />
          <div className="gradient-color" style={{ background: '#1a9850' }} />
          <div className="gradient-color" style={{ background: '#006837' }} />
        </div>
        Alto
      </div>
      <div className="cloud-description">
        <div className="gradient-color mr-2" style={{ background: '#B0B0B0' }} /> Nuvens
      </div>
    </div>
  );
};

export default NdviScale;
