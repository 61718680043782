import { takeLatest, put, call } from 'redux-saga/effects';

import { findEmitenteCadastro } from '../service';
import {
  FIND_EMITENTE_CADASTRO,
  FIND_EMITENTE_CADASTRO_PENDING,
  FIND_EMITENTE_CADASTRO_SUCCESS,
  FIND_EMITENTE_CADASTRO_FAILURE
} from '../actionTypes';

function* sagaFindEmitenteCadastro(action) {
  yield put({ type: FIND_EMITENTE_CADASTRO_PENDING, id: action.id })

  try {

    const registro = yield call(findEmitenteCadastro, action.id);

    yield put({
      type: FIND_EMITENTE_CADASTRO_SUCCESS, registro: {
        ...registro,
        certificadoDigital: {
          ...registro.certificadoDigital,
          senhaCertificadoTemp: ''
        },
        codigoRegimeTributario: registro.codigoRegimeTributario,
        formaContribuicaoFunrural: registro.formaContribuicaoFunrural,
        observacaoFunrural: registro.observacaoFunrural,
        emitenteSerieNfe: {
          serieNfe: registro.emitenteSerieNfe?.serieNfe ?? '',
          proximoNroNfe: registro.emitenteSerieNfe?.proximoNroNfe ?? '',
        },
        emitenteCnd: {
          informarCnd: registro.emitenteCnd?.informarCnd ?? false,
          numeroCnd: registro.emitenteCnd?.numeroCnd ?? '',
          dataValidadeCnd: registro.emitenteCnd?.dataValidadeCnd ?? null,
        }
      }
    })

  } catch (error) {

    yield put({ type: FIND_EMITENTE_CADASTRO_FAILURE })
    // console.error(error) // eslint-disable-line

  }
}

export default function* watchFindEmitenteCadastro() {
  yield takeLatest(FIND_EMITENTE_CADASTRO, sagaFindEmitenteCadastro)
}
