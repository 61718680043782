import { CONST_ROWS_PER_PAGE } from '@commons/consts';
import {
    NEW_NFE_EMISSAO_SUCCESS,
    FIND_NFE_EMISSAO_PENDING,
    FIND_NFE_EMISSAO_SUCCESS,
    FIND_NFE_EMISSAO_FAILURE,
    CREATE_NFE_EMISSAO_PENDING,
    CREATE_NFE_EMISSAO_SUCCESS,
    CREATE_NFE_EMISSAO_FAILURE,
    NEW_FILTER_NFE_EMISSAO_PEDING,
    NEW_FILTER_NFE_EMISSAO_SUCCESS,
    NEW_FILTER_NFE_EMISSAO_FAILURE,
    NEW_FILTER_NEXT_PAGE_NFE_EMISSAO_PENDING,
    NEW_FILTER_NEXT_PAGE_NFE_EMISSAO_SUCCESS,
    NEW_FILTER_NEXT_PAGE_NFE_EMISSAO_FAILURE,
} from './actionTypes';

const initialState = {
    list: null,
    loading: false,
    filter: { rows: CONST_ROWS_PER_PAGE, page: 0, where: '' },
}

function nfeEmissaoReducer(state = initialState, action) {

    switch (action.type) {
        case NEW_NFE_EMISSAO_SUCCESS:
            return {
                ...state,
                loading: false,
                registro: action.registro
            }
        case FIND_NFE_EMISSAO_PENDING:
        case CREATE_NFE_EMISSAO_PENDING:
        case NEW_FILTER_NFE_EMISSAO_PEDING:
        case NEW_FILTER_NEXT_PAGE_NFE_EMISSAO_PENDING:
            return {
                ...state,
                loading: true,
            }
        // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        case FIND_NFE_EMISSAO_SUCCESS:
            return {
                ...state,
                registro: action.registro,
                loading: false
            }
        case FIND_NFE_EMISSAO_FAILURE:
            return {
                ...state,
                registro: null,
                loading: false
            }
        // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        case NEW_FILTER_NFE_EMISSAO_SUCCESS: {
            return {
                ...state,
                list: action.list,
                filter: action.filter,
                loading: false
            }
        }
        case NEW_FILTER_NFE_EMISSAO_FAILURE: {
            return {
                ...state,
                loading: false
            }
        }
        // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        case NEW_FILTER_NEXT_PAGE_NFE_EMISSAO_SUCCESS:
            return {
                ...state,
                list: action.list,
                filter: action.filter,
                loading: false
            }
        case NEW_FILTER_NEXT_PAGE_NFE_EMISSAO_FAILURE:
            return {
                ...state,
                list: null,
                loading: false
            }
        // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        case CREATE_NFE_EMISSAO_SUCCESS:
        case CREATE_NFE_EMISSAO_FAILURE:
            return {
                ...state,
                loading: false,
                registro: action.registro
            }
        // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        default:
            return state
    }
}

export { nfeEmissaoReducer };