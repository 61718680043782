import React from 'react';

import {
    Col,
    FormGroup,
    Label,
    InputGroup,
    InputGroupAddon
} from 'reactstrap';

import { Field, getIn, ErrorMessage } from 'formik';

import MaskedField from 'react-masked-field';
import { InfoTooltip } from './InfoTooltip';

import './inputMask.css'

export const InputMask = (props) => {

    const { col, name, label, placeholder, plusChange, renderInfoTooltip, titleInfoTooltip, msgInfoTooltip, autoComplete,
        preffix, suffix } = props;

    return (
        <Col md={col}>

            <FormGroup>
                <Label htmlFor={'id_' + name} >{label} {" "}
                    {
                        renderInfoTooltip ?
                            <InfoTooltip
                                id={'idtool_' + name}
                                title={titleInfoTooltip}
                                msg={msgInfoTooltip}
                            />
                            : <React.Fragment />
                    }
                </Label>
                <Field name={name}>
                    {({ field, form, meta }) => (
                        <>
                            <InputGroup>
                                <InputGroupAddon addonType="append">{preffix}</InputGroupAddon>
                                <MaskedField
                                    {...field}
                                    name={name}
                                    mask={props.mask}
                                    placeholder={placeholder}
                                    id={'id_' + name}
                                    className={`form-control ${getIn(form.errors, name) && getIn(form.touched, name) ? 'is-invalid' : ''}`}
                                    autoComplete={autoComplete}
                                    invalid={getIn(props.errors, props.name) && getIn(props.touched, props.name)}
                                    onChange={(e) => {
                                        form.handleChange(e);
                                        plusChange(e);
                                    }}
                                />
                                <InputGroupAddon addonType="append">{suffix}</InputGroupAddon>
                            </InputGroup>
                            <ErrorMessage name={name} render={msg => <div className="error-input">{msg}</div>} />
                        </>
                    )}
                </Field>
            </FormGroup>

        </Col>
    )

};

InputMask.defaultProps = {
    col: 12,
    renderInfoTooltip: false,
    titleInfoTooltip: 'Informação',
    autoComplete:"off",
    plusChange: () => { }
};