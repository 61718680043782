import { CONST_ROWS_PER_PAGE } from '@commons/consts';
import {
    TABLE_NOTIFICATION_PENDING,
    TABLE_NOTIFICATION_SUCCESS,
    TABLE_NOTIFICATION_FAILURE,
    LIST_NOTIFICATION_PENDING,
    LIST_NOTIFICATION_SUCCESS,
    LIST_NOTIFICATION_FAILURE,
    FIND_NOTIFICATION_PENDING,
    FIND_NOTIFICATION_SUCCESS,
    FIND_NOTIFICATION_FAILURE,
    CLEAR_NOTIFICATION_DATA,
    NEW_FILTER_NOTIFICATION_PEDING,
    NEW_FILTER_NOTIFICATION_SUCCESS,
    NEW_FILTER_NOTIFICATION_FAILURE,
    NEW_FILTER_NEXT_PAGE_NOTIFICATION_PENDING,
    NEW_FILTER_NEXT_PAGE_NOTIFICATION_SUCCESS,
    NEW_FILTER_NEXT_PAGE_NOTIFICATION_FAILURE,
    MARK_NOTIFICATION_AS_READ_PENDING,
    MARK_NOTIFICATION_AS_READ_SUCCESS,
    MARK_NOTIFICATION_AS_READ_FAILURE,
    MARK_NOTIFICATION_ALL_AS_READ_PENDING,
    MARK_NOTIFICATION_ALL_AS_READ_SUCCESS,
    MARK_NOTIFICATION_ALL_AS_READ_FAILURE,
} from './actionTypes';

const initialState = {
    list: null,
    allLatest: null,
    item: null,
    loading: false,
    filter: { rows: CONST_ROWS_PER_PAGE, page: 0, where: '' },
};

function notificationReducer(state = initialState, action) {

    switch (action.type) {
        case LIST_NOTIFICATION_PENDING:
        case FIND_NOTIFICATION_PENDING:
        case TABLE_NOTIFICATION_PENDING:
        case NEW_FILTER_NOTIFICATION_PEDING:
        case NEW_FILTER_NEXT_PAGE_NOTIFICATION_PENDING:
        case MARK_NOTIFICATION_AS_READ_PENDING:
        case MARK_NOTIFICATION_ALL_AS_READ_PENDING:
            return {
                ...state,
                loading: true,
            }
        // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        case LIST_NOTIFICATION_SUCCESS:
            return {
                ...state,
                list: action.list,
                item: null,
                loading: false
            }
        case LIST_NOTIFICATION_FAILURE:
            return {
                ...state,
                list: null,
                item: null,
                loading: false
            }
        // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        case TABLE_NOTIFICATION_SUCCESS:
            return {
                ...state,
                table: action.table,
                item: null,
                loading: false
            }
        case TABLE_NOTIFICATION_FAILURE:
            return {
                ...state,
                table: null,
                item: null,
                loading: false
            }
        // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        case FIND_NOTIFICATION_SUCCESS:
            return {
                ...state,
                registro: action.registro,
                item: null,
                loading: false
            }
        case FIND_NOTIFICATION_FAILURE:
            return {
                ...state,
                registro: null,
                item: null,
                loading: false
            }
        // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        case NEW_FILTER_NOTIFICATION_SUCCESS: {
            return {
                ...state,
                list: action.list,
                filter: action.filter,
                loading: false
            }
        }
        case NEW_FILTER_NOTIFICATION_FAILURE: {
            return {
                ...state,
                loading: false
            }
        }
        // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        case NEW_FILTER_NEXT_PAGE_NOTIFICATION_SUCCESS:
            return {
                ...state,
                list: action.list,
                filter: action.filter,
                loading: false
            }
        case NEW_FILTER_NEXT_PAGE_NOTIFICATION_FAILURE:
            return {
                ...state,
                list: null,
                loading: false
            }
        // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        case MARK_NOTIFICATION_AS_READ_SUCCESS:
        case MARK_NOTIFICATION_ALL_AS_READ_SUCCESS: {
            return {
                ...state,
                loading: false
            }
        }
        case MARK_NOTIFICATION_AS_READ_FAILURE:
        case MARK_NOTIFICATION_ALL_AS_READ_FAILURE: {
            return {
                ...state,
                loading: false
            }
        }
        // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        case CLEAR_NOTIFICATION_DATA:
            return {
                ...state,
                table: [],
                item: null,
                loading: false
            }
        default:
            return state
    }
}

export { notificationReducer };
