import { takeLatest, put } from 'redux-saga/effects';
import moment from "moment";

import { storage } from '@commons';

import {
  colheita,
  CONFIG_SESSION,
  NEW_COLHEITA,
  NEW_COLHEITA_SUCCESS,
} from '@handler';

function* sagaNewColheita(action) {

  yield put({
    type: NEW_COLHEITA_SUCCESS, registro: {
      ...colheita.newRegistro,
      data: moment().startOf('day').format('YYYY-MM-DD'),
      cultura: JSON.parse(storage.get(CONFIG_SESSION)).safraAtiva.cultura,
    }
  });

}

export default function* watchNewColheita() {
  yield takeLatest(NEW_COLHEITA, sagaNewColheita)
}
