import * as Yup from 'yup';

const newRegistro = {
    source: null,
    target: null,
    qtdConversao: 0,
};

const validation = Yup.object().shape({
    source: Yup.object()
        .typeError('Este campo é obrigatório')
        .shape({
            id: Yup.number()
                .typeError('Este campo é obrigatório')
                .required("Este campo é obrigatório"),
        }),
    target: Yup.object()
        .typeError('Este campo é obrigatório')
        .shape({
            id: Yup.number()
                .typeError('Este campo é obrigatório')
                .required("Este campo é obrigatório"),
        }),
        qtdConversao: Yup.number()
        .positive('Por Favor, informe um valor maior que zero')
        .transform(value => (isNaN(value) ? 0.0 : value))
        .required('Este campo é obrigatório'),
});

export const unidadeMedidaConversaoCadastro = {
    newRegistro,
    validation
}