import { takeLatest, put, call } from 'redux-saga/effects';

import { history } from '@commons';

import {
  createPatrimonioManutencaoCadastro,
  updatePatrimonioManutencaoCadastro,
  attachFiles,
  deleteAttachFiles,
  findPatrimonioManutencaoCadastro,
} from '../service';
import {
  CONFIG_SESSION,
  CREATE_PATRIMONIO_MANUTENCAO_CADASTRO,
  CREATE_PATRIMONIO_MANUTENCAO_CADASTRO_PENDING,
  CREATE_PATRIMONIO_MANUTENCAO_CADASTRO_SUCCESS,
  CREATE_PATRIMONIO_MANUTENCAO_CADASTRO_FAILURE,
} from '@handler';
import { storage } from '@commons/storage';
import { AlertSuccess, AlertError, AlertWarning } from '@components/common/AlertToast';


function* sagaCreatePatrimonioManutencaoCadastro(action) {
  yield put({ type: CREATE_PATRIMONIO_MANUTENCAO_CADASTRO_PENDING })

  try {
    if (action.data.produtoInsumoList.length == 0) {
      yield put({ type: CREATE_PATRIMONIO_MANUTENCAO_CADASTRO_FAILURE, registro: action.data });
      AlertWarning('Nenhum insumo selecionado para a manutenção.');
    } else {
      const funcionariosResponsaveisList = action.data.funcionariosResponsaveisList || [];
      const funcionariosIds = funcionariosResponsaveisList.map(item => item.id);
      const uniqueFuncionariosIds = new Set(funcionariosIds);
      if (funcionariosIds.length !== uniqueFuncionariosIds.size) {
        yield put({ type: CREATE_PATRIMONIO_MANUTENCAO_CADASTRO_FAILURE, registro: action.data });
        AlertWarning('Existem funcionários duplicados na lista de responsáveis.');
      } else {
        action.data = {
          ...action.data,
          propriedade: {
            id: JSON.parse(storage.get(CONFIG_SESSION)).fazendaSelecionada.id
          },
        }

        var idManutencao;
        if (action.data.id === undefined || action.data.id === null) {
          const resp = yield call(createPatrimonioManutencaoCadastro, action.data);

          if (resp.status == 201) {
            if ( action.filesToUploadList.length > 0 ) { yield call(attachFiles, resp.data.id, action.filesToUploadList); }
          }
          idManutencao = resp.data.id;
        } else {
          idManutencao = action.data.id;
          yield call(updatePatrimonioManutencaoCadastro,
            {
              ...action.data,
              id: action.data.id
            }
          );

          if ( action.filesToUploadList.length > 0 ) { yield call(attachFiles, action.data.id, action.filesToUploadList); }
          if ( action.filesToDeleteList.length > 0 ) { yield call(deleteAttachFiles, action.data.id, action.filesToDeleteList); }
        }

        yield put({ type: CREATE_PATRIMONIO_MANUTENCAO_CADASTRO_SUCCESS, registro: action.data });

        if (!action.isCadastroRapido) {
          history.push('/patrimonio/manutencao');
        }

        AlertSuccess('Registro salvo com sucesso!');

        const novoRegistro = yield call(findPatrimonioManutencaoCadastro, idManutencao);
        if (novoRegistro.possuiPendenciaEstoque) {
          AlertWarning('Estoque não processado por falta de insumos. Verifique a manutenção...');
        }
      }
    }
  } catch (error) {
    yield put({ type: CREATE_PATRIMONIO_MANUTENCAO_CADASTRO_FAILURE, registro: action.data });

    AlertError('Falha ao salvar o registro! Tente novamente...');
  }
}

export default function* watchCreatePatrimonioManutencaoCadastro() {
  yield takeLatest(CREATE_PATRIMONIO_MANUTENCAO_CADASTRO, sagaCreatePatrimonioManutencaoCadastro)
}
