import * as Yup from 'yup';

const newRegistro = {
    data: '',
    status: 'EM_DIGITACAO',
    tipoOperacao: null,
    naturezaOperacao: null,
    finalidadeEmissao: 'NORMAL',
    numero: null,
    serie: null,
    identificador: null,
    emitente: {
        emitente: null,
        emitenteNome: null,
        emitenteApelido: null,
        emitenteTipoPessoa: null,
        emitenteCnpj: null,
        emitenteCpf: null,
        emitenteEmail: null,
        emitenteTelefone: null,
        emitenteCelular: null,
        emitenteIndicadorInscrEstadual: null,
        emitenteInscricaoEstadual: null,
        emitenteCep: null,
        emitenteLogradouro: null,
        emitenteBairro: null,
        emitenteComplemento: null,
        emitenteNumero: null,
        emitenteEstado: null,
        emitenteCidade: null,
    },
    destinatario: {
        destinatario: null,
        destinatarioNome: null,
        destinatarioApelido: null,
        destinatarioTipoPessoa: null,
        destinatarioCnpj: null,
        destinatarioCpf: null,
        destinatarioEmail: null,
        destinatarioTelefone: null,
        destinatarioCelular: null,
        destinatarioIndicadorInscrEstadual: null,
        destinatarioInscricaoEstadual: null,
        destinatarioCep: null,
        destinatarioLogradouro: null,
        destinatarioBairro: null,
        destinatarioComplemento: null,
        destinatarioNumero: null,
        destinatarioEstado: null,
        destinatarioCidade: null,
    },
    indicadorOperacaoConsumidor: 'NORMAL',
    consumidorPresenca: 'PRESENCIAL',
    mensagemInterna: '',
    mensagemNfeComplementar: '',
    mensagemNfeFisco: '',
    mensagemNfeAutomatico: '',
    transporte: {
        modalidadeFrete: '',
        transportadora: null,
        transportadoraNome: null,
        transportadoraApelido: null,
        transportadoraTipoPessoa: null,
        transportadoraCnpj: null,
        transportadoraCpf: null,
        transportadoraEmail: null,
        transportadoraTelefone: null,
        transportadoraCelular: null,
        transportadoraIndicadorInscrEstadual: null,
        transportadoraInscricaoEstadual: null,
        transportadoraCep: null,
        transportadoraLogradouro: null,
        transportadoraBairro: null,
        transportadoraComplemento: null,
        transportadoraNumero: null,
        transportadoraEstado: null,
        transportadoraCidade: null,
        veiculo: null,
        veiculoPlaca: null,
        veiculoEmplacadoNoBrasil: null,
        veiculoEstadoEmplacamento: null,
        veiculoRNTC: null,
        dataEntradaSaida: null,
        horaEntradaSaida: null,
        quantidadeTransporte: null,
        especieTransporte: '',
        marcaTransporte: '',
        numeracaoTransporte: '',
        pesoLiquido: 0,
        pesoBruto: 0,
        enderecoEntregaInformado: false,
        enderecoEntregaTipoPessoa: null,
        enderecoEntregaCnpj: null,
        enderecoEntregaCpf: null,
        enderecoEntregaNome: null,
        enderecoEntregaCep: null,
        enderecoEntregaLogradouro: null,
        enderecoEntregaBairro: null,
        enderecoEntregaComplemento: null,
        enderecoEntregaNumero: null,
        enderecoEntregaEstado: null,
        enderecoEntregaCidade: null,
        enderecoRetiradaInformado: false,
        enderecoRetiradaTipoPessoa: null,
        enderecoRetiradaCnpj: null,
        enderecoRetiradaCpf: null,
        enderecoRetiradaNome: null,
        enderecoRetiradaCep: null,
        enderecoRetiradaLogradouro: null,
        enderecoRetiradaBairro: null,
        enderecoRetiradaComplemento: null,
        enderecoRetiradaNumero: null,
        enderecoRetiradaEstado: null,
        enderecoRetiradaCidade: null,
    },
    itens: [],
};

const validation = Yup.object().shape({
    data: Yup.date()
        .required('Este campo é obrigatório'),
});

export const nfeEmissao = {
    newRegistro,
    validation
}