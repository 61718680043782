import { takeLatest, put } from 'redux-saga/effects';

import {
    CANCEL_PATRIMONIO_ABASTECIMENTO_CADASTRO_RAPIDO,
    CANCEL_PATRIMONIO_ABASTECIMENTO_CADASTRO_RAPIDO_SUCCESS
} from '../actionTypes';

function* sagaCancelPatrimonioAbastecimentoCadastroRapido() {
  yield put({ type: CANCEL_PATRIMONIO_ABASTECIMENTO_CADASTRO_RAPIDO_SUCCESS})
}

export default function* watchCancelPatrimonioAbastecimentoCadastroRapido() {
  yield takeLatest(CANCEL_PATRIMONIO_ABASTECIMENTO_CADASTRO_RAPIDO, sagaCancelPatrimonioAbastecimentoCadastroRapido)
}
