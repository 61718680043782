import { takeLatest, put } from 'redux-saga/effects';

import {
  produtoAgricolaCadastro,
  NEW_PRODUTO_AGRICOLA_CADASTRO,
  NEW_PRODUTO_AGRICOLA_CADASTRO_SUCCESS,
} from '@handler';

function* sagaNewProdutoAgricolaCadastro(action) {

  yield put({ 
    type: NEW_PRODUTO_AGRICOLA_CADASTRO_SUCCESS, 
    registro: produtoAgricolaCadastro.newRegistro,
    isCadastroRapido: action.isCadastroRapido,
  });
}

export default function* watchNewProdutoAgricolaCadastro() {
  yield takeLatest(NEW_PRODUTO_AGRICOLA_CADASTRO, sagaNewProdutoAgricolaCadastro)
}
