import { takeLatest, put, call } from 'redux-saga/effects';
import { storage } from '@commons/storage';

import {
  CONFIG_SESSION,
  NEW_FILTER_VENDA_PRODUCAO_AGRICOLA,
  NEW_FILTER_VENDA_PRODUCAO_AGRICOLA_PEDING,
  NEW_FILTER_VENDA_PRODUCAO_AGRICOLA_SUCCESS,
  GET_TOTAIS_VENDA_PRODUCAO_AGRICOLA_PENDING,
  GET_TOTAIS_VENDA_PRODUCAO_AGRICOLA_SUCCESS,
  NEW_FILTER_VENDA_PRODUCAO_AGRICOLA_SUCCESS_PDF,
  NEW_FILTER_VENDA_PRODUCAO_AGRICOLA_FAILURE,
  GET_TOTAIS_VENDA_PRODUCAO_AGRICOLA_FAILURE
} from '@handler';
import { formatDateDDMMYYYY, formatDateYYYYMMDD } from '@components/common/format';
import { getLancamentos, getLancamentosExcel, getLancamentosPdf } from '../service';
import { CONST_FILTER_VENDA_PRODUCAO_AGRICOLA, CONST_ROWS_PER_PAGE } from '@commons/consts';

function* sagaNewFilterVendaProducaoAgricola(action) {
  try {
    yield put({ type: NEW_FILTER_VENDA_PRODUCAO_AGRICOLA_PEDING })

    const idFazenda = JSON.parse(storage.get(CONFIG_SESSION)).fazendaSelecionada.id;
    let datas = '';
    let dataInicial = '';
    let dataFinal = '';
    var date = new Date();
    let where = '';
    let newFilter = {
      numero: '',
      localEstoque: null,
      naturezaOperacao: null,
      pessoa: null,
      talhaoSelecionado: null,
      identificador: null,
      dataInicial: '',
      dataFinal: '',
      rows: CONST_ROWS_PER_PAGE,
      page: 0,
      where: ''
    };
    const safraAtiva = JSON.parse(storage.get(CONFIG_SESSION)).safraAtiva;
    let filterSaved = sessionStorage.getItem(CONST_FILTER_VENDA_PRODUCAO_AGRICOLA) !== null ? JSON.parse(sessionStorage.getItem(CONST_FILTER_VENDA_PRODUCAO_AGRICOLA)) : null;

    if (filterSaved === null || filterSaved === undefined) {
      // seta data do mes na primeira vez que entra na pagina
      dataInicial = formatDateYYYYMMDD(new Date(date.getFullYear(), date.getMonth(), 1));
      dataFinal = formatDateYYYYMMDD(new Date(date.getFullYear(), date.getMonth() + 1, 0));

      datas = formatDateDDMMYYYY(dataInicial) + '_' + formatDateDDMMYYYY(dataFinal);
      where = `&safra=${safraAtiva?.id ?? -1}`;


      newFilter = {
        ...newFilter,
        dataInicial,
        dataFinal,
        where: where
      };
    } else {
      // se seta filtro manual
      if (action.newFilter) {
        // seta filtro indicado
        // typePeriodo = 0 -> periodo manual
        // typePeriodo = 1 -> hoje
        // typePeriodo = 2 -> esta semana
        // typePeriodo = 3 -> mes passado
        // typePeriodo = 4 -> este mes
        // typePeriodo = 5 -> proximo mes
        // typePeriodo = 6 -> este ano
        if (action.typePeriodo === undefined || action.typePeriodo === 0) {
          dataInicial = action.args.dataInicial;
          dataFinal = action.args.dataFinal;

          datas = formatDateDDMMYYYY(action.args.dataInicial) + '_' + formatDateDDMMYYYY(action.args.dataFinal);
        } else if (action.typePeriodo === 1) {
          dataInicial = formatDateYYYYMMDD(new Date(date));
          dataFinal = formatDateYYYYMMDD(new Date(date));

          datas = formatDateDDMMYYYY(dataInicial) + '_' + formatDateDDMMYYYY(dataFinal);
        }
        else if (action.typePeriodo === 2) {
          const primeiro = date.getDate() - date.getDay();

          dataInicial = formatDateYYYYMMDD(new Date(date.getFullYear(), date.getMonth(), primeiro));
          dataFinal = formatDateYYYYMMDD(new Date(date.getFullYear(), date.getMonth(), primeiro + 6));

          datas = formatDateDDMMYYYY(dataInicial) + '_' + formatDateDDMMYYYY(dataFinal);
        } else if (action.typePeriodo === 3) {
          dataInicial = formatDateYYYYMMDD(new Date(date.getFullYear(), date.getMonth() - 1, 1));
          dataFinal = formatDateYYYYMMDD(new Date(date.getFullYear(), date.getMonth(), 0));

          datas = formatDateDDMMYYYY(dataInicial) + '_' + formatDateDDMMYYYY(dataFinal);
        } else if (action.typePeriodo === 4) {
          dataInicial = formatDateYYYYMMDD(new Date(date.getFullYear(), date.getMonth(), 1));
          dataFinal = formatDateYYYYMMDD(new Date(date.getFullYear(), date.getMonth() + 1, 0));

          datas = formatDateDDMMYYYY(dataInicial) + '_' + formatDateDDMMYYYY(dataFinal);
        } else if (action.typePeriodo === 5) {
          dataInicial = formatDateYYYYMMDD(new Date(date.getFullYear(), date.getMonth() + 1, 1));
          dataFinal = formatDateYYYYMMDD(new Date(date.getFullYear(), date.getMonth() + 2, 0));

          datas = formatDateDDMMYYYY(dataInicial) + '_' + formatDateDDMMYYYY(dataFinal);
        } else if (action.typePeriodo === 6) {
          dataInicial = formatDateYYYYMMDD(new Date(date.getFullYear(), 0, 1));
          dataFinal = formatDateYYYYMMDD(new Date(date.getFullYear(), 12, 0));

          datas = formatDateDDMMYYYY(dataInicial) + '_' + formatDateDDMMYYYY(dataFinal);
        }

        //situacao duplicata
        where = `&safra=${safraAtiva?.id ?? -1}`;

        if (action.args.numero !== null && action.args.numero !== undefined && action.args.numero !== '') {
          where = where + '&numero=' + action.args.numero;
        }
        if (action.args.pessoa !== null && action.args.pessoa !== undefined) {
          where = where + '&pessoa=' + action.args.pessoa.id;
        }
        if (action.args.naturezaOperacao !== null && action.args.naturezaOperacao !== undefined) {
          where = where + '&naturezaOperacao=' + action.args.naturezaOperacao.id;
        }
        if (action.args.localEstoque !== null && action.args.localEstoque !== undefined) {
          where = where + '&localEstoque=' + action.args.localEstoque.id;
        }
        if (action.args.talhaoSelecionado !== null && action.args.talhaoSelecionado !== undefined) {
          where = where + '&talhao=' + action.args.talhaoSelecionado.talhao.id;
        }
        if (action.args.identificador !== null && action.args.identificador !== undefined) {
          where = where + '&identificador=' + action.args.identificador.id;
        }

        newFilter = {
          ...newFilter,
          ...action.args,
          dataInicial,
          dataFinal,
          where: where
        };
      } else {
        // busca filtro salvo
        datas = formatDateDDMMYYYY(filterSaved.dataInicial) + '_' + formatDateDDMMYYYY(filterSaved.dataFinal);
        
        where = `&safra=${safraAtiva?.id ?? -1}`;

        if (filterSaved.numero !== null && filterSaved.numero !== undefined && filterSaved.numero !== '') {
          where = where + '&numero=' + filterSaved.numero;
        }
        if (filterSaved.pessoa !== null && filterSaved.pessoa !== undefined) {
          where = where + '&pessoa=' + filterSaved.pessoa.id;
        }
        if (filterSaved.naturezaOperacao !== null && filterSaved.naturezaOperacao !== undefined) {
          where = where + '&naturezaOperacao=' + filterSaved.naturezaOperacao.id;
        }
        if (filterSaved.localEstoque !== null && filterSaved.localEstoque !== undefined) {
          where = where + '&localEstoque=' + filterSaved.localEstoque.id;
        }
        if (filterSaved.talhaoSelecionado !== null && filterSaved.talhaoSelecionado !== undefined) {
          where = where + '&talhao=' + filterSaved.talhaoSelecionado.talhao.id;
        }
        if (filterSaved.identificador !== null && filterSaved.identificador !== undefined) {
          where = where + '&identificador=' + filterSaved.identificador.id;
        }

        newFilter = {
          ...filterSaved,
          rows: CONST_ROWS_PER_PAGE,
          page: 0,
          where: where
        };
      }
    }

    if ((action.pdf === undefined || !action.pdf) && (action.excel === undefined || !action.excel)) {
      // busca registros
      let list = yield call(getLancamentos, idFazenda, datas, `&page=0&size=${CONST_ROWS_PER_PAGE}${where}`);

      sessionStorage.setItem(CONST_FILTER_VENDA_PRODUCAO_AGRICOLA, JSON.stringify(newFilter));

      yield put({
        type: NEW_FILTER_VENDA_PRODUCAO_AGRICOLA_SUCCESS, list: list, filter: newFilter
      });
    } else {
      if (action.pdf === undefined || !action.pdf) {
        // gera excel
        yield call(getLancamentosExcel, idFazenda, datas, where)
      } else {
        // gera pdf
        yield call(getLancamentosPdf, idFazenda, datas, where)
      }

      yield put({
        type: NEW_FILTER_VENDA_PRODUCAO_AGRICOLA_SUCCESS_PDF
      })
    }
  } catch (_) {
    yield put({ type: NEW_FILTER_VENDA_PRODUCAO_AGRICOLA_FAILURE })
  }
}

export default function* watchNewFilterVendaProducaoAgricola() {
  yield takeLatest(NEW_FILTER_VENDA_PRODUCAO_AGRICOLA, sagaNewFilterVendaProducaoAgricola)
}