import { takeLatest, put, call } from 'redux-saga/effects';

import { history } from '@commons';

import { storage } from '@commons/storage';

import { createContaPagar, updateContaPagar, attachFiles, deleteAttachFiles } from '../service';
import {
  CREATE_CONTA_PAGAR,
  CREATE_CONTA_PAGAR_PENDING,
  CREATE_CONTA_PAGAR_SUCCESS,
  CREATE_CONTA_PAGAR_FAILURE,
  CONFIG_SESSION
} from '@handler';

import { AlertSuccess, AlertError, AlertWarning } from '@components/common/AlertToast'
import { formatJsonRemoveEmptyOrNull } from '@components/common/format';
import uuid from 'react-uuid';
import { createContaPagarListAll } from '..';

function* sagaCreateContaPagar(action) {
  yield put({ type: CREATE_CONTA_PAGAR_PENDING })

  try {
    delete action.data.nomePessoaConta;
    
    // NOVA CONTA
    if (action.data.id === undefined || action.data.id === null) {
      // PROCESSA PARCELA UNICA
      if (action.data.tipoOcorrencia === 'UNICA') {
        delete action.data.parcelas;
        delete action.data.dataPrimeiraParcela;
        delete action.data.numeroParcelas;

        if (!action.data.vincularPatrimonio) {
          action.data.patrimonio = null;
        }

        // if (action.data.data < action.data.dataEmissao) {
        //   AlertWarning('Data de Vencimento menor que Data de Emissão. Verifique...');
        //   yield put({ type: CREATE_CONTA_PAGAR_FAILURE, registro: action.data })
        // } else {
          if (action.data.contaPaga === true) {
            let pagamentos = [];

            let registroPgto = {
              automatico: false,
              data: action.data.dataPgto,
              identificador: action.data.identificadorPgtoAVista,
              total: {
                valorCapital: action.data.valor,
                valorDesconto: 0.0,
                valorJuro: 0.0,
                valorMulta: 0.0,
                valorOutrasDespesas: 0.0,
                valorTotal: action.data.valor,
              }
            }

            if (action.data.tipoPagamento === 'DINHEIRO') {
              registroPgto = {
                ...registroPgto,
                tipoPagamento: "DINHEIRO",
                pagamentoDinheiro: {
                  contaCaixa: {
                    id: action.data.contaCaixa.id
                  }
                }
              }
            }

            if (action.data.tipoPagamento === 'BOLETO_BANCARIO' || action.data.tipoPagamento === 'PIX' || action.data.tipoPagamento === 'TRANSF_BANCARIA'
            || action.data.tipoPagamento === 'DEBITO_EM_CONTA' || action.data.tipoPagamento === 'CARNE' || action.data.tipoPagamento === 'GUIA'
            || action.data.tipoPagamento === 'CREDITO_EM_CONTA') {
              registroPgto = {
                ...registroPgto,
                tipoPagamento: action.data.tipoPagamento,
                pagamentoBanco: {
                  contaBanco: {
                    id: action.data.contaBanco.id
                  }
                }
              }
            }

            if (action.data.tipoPagamento === 'CARTAO_CREDITO' || action.data.tipoPagamento === 'CARTAO_DEBITO' || action.data.tipoPagamento === 'CARTAO_VOUCHER') {
              registroPgto = formatJsonRemoveEmptyOrNull(
                {
                  ...registroPgto,
                  tipoPagamento: action.data.tipoPagamento,
                  pagamentoBanco: {
                    cartaoBandeira: action.data.cartaoBandeira,
                    cartaoNsu: action.data.cartaoNsu,
                    chequeEmitente: '',
                    chequeNumeroCheque: '',
                    chequeBanco: '',
                    chequeNumeroAgencia: '',
                    chequeDvAgencia: '',
                    chequeContaCorrente: '',
                    chequeDvContaCorrente: '',
                    contaBanco: {
                      id: action.data.contaBanco.id
                    }
                  }
                }
              )
            }
        
            if (action.data.tipoPagamento === 'CHEQUE_A_VISTA' || action.data.tipoPagamento === 'CHEQUE_PRE_DATADO') {
              registroPgto = formatJsonRemoveEmptyOrNull(
                {
                  ...registroPgto,
                  tipoPagamento: action.data.tipoPagamento,
                  pagamentoBanco: {
                    // dataPrevisao: action.data.chequeData,
                    cartaoBandeira: '',
                    cartaoNsu: '',
                    chequeEmitente: action.data.chequeEmitente,
                    chequeNumeroCheque: action.data.chequeNro,
                    chequeBanco: action.data.chequeBanco,
                    chequeNumeroAgencia: action.data.chequeNroAg,
                    chequeDvAgencia: action.data.chequeDvAg,
                    chequeContaCorrente: action.data.chequeNroCc,
                    chequeDvContaCorrente: action.data.chequeDvCc,
                    contaBanco: {
                      id: action.data.contaBanco.id
                    }
                  }
                }
              )
            }

            pagamentos.push(registroPgto)

            action.data = {
              ...action.data,
              valorPendente: action.data.valor,
              pagamentos: pagamentos
            }
          }
          delete action.data.contaPaga;
          delete action.data.tipoPagamento;
          delete action.data.contaCaixa;
          delete action.data.contaBanco;

          const resp = yield call(createContaPagar, formatJsonRemoveEmptyOrNull(
            {
              ...action.data,
              propriedade: {
                id: JSON.parse(storage.get(CONFIG_SESSION)).fazendaSelecionada.id
              }
            }
          ));

          if (resp.status == 201) {
            if ( action.filesToAttach.length > 0 ) { yield call(attachFiles, resp.data.id, action.filesToAttach); }
          }

          yield put({ type: CREATE_CONTA_PAGAR_SUCCESS, registro: action.data })

          history.push('/financeiro/contaPagar');

          AlertSuccess('Conta salva com sucesso!');
        // }
      } else {
        // PROCESSA PARCELAS

        let idParcelasVinc = uuid();
        let parcelasASalvar = []

        for (let index = 0; index < action.data.parcelas.length; index++) {
          if (!action.data.vincularPatrimonio) {
            action.data.patrimonio = null;
          }

          let parcela = {
            ...action.data
          };

          parcela.data = action.data.parcelas[index].dataVencimento;
          parcela.competencia = action.data.parcelas[index].competencia;
          parcela.valor = action.data.parcelas[index].valorTotal;
          parcela.historico = action.data.historico + ' - Parc. ' + (index + 1) + '/' + action.data.parcelas.length;
          parcela.identificador = action.data.identificador;

          delete parcela.parcelas;
          delete parcela.dataPrimeiraParcela;
          delete parcela.numeroParcelas;


          if (action.data.parcelas[index].parcelaPaga === true) {
            let pagamentos = [];

            let registroPgto = {
              automatico: false,
              data: action.data.parcelas[index].dataPgto,
              identificador: action.data.parcelas[index].identificador,
              total: {
                valorCapital: parcela.valor,
                valorDesconto: 0.0,
                valorJuro: 0.0,
                valorMulta: 0.0,
                valorOutrasDespesas: 0.0,
                valorTotal: parcela.valor,
              }
            }

            if (action.data.parcelas[index].tipoPagamento === 'DINHEIRO') {
              registroPgto = {
                ...registroPgto,
                tipoPagamento: "DINHEIRO",
                pagamentoDinheiro: {
                  contaCaixa: {
                    id: action.data.parcelas[index].contaCaixa.id
                  }
                }
              }
            }

            if (action.data.parcelas[index].tipoPagamento === 'BOLETO_BANCARIO' || action.data.parcelas[index].tipoPagamento === 'PIX' ||
              action.data.parcelas[index].tipoPagamento === 'TRANSF_BANCARIA' ||
              action.data.parcelas[index].tipoPagamento === 'DEBITO_EM_CONTA' ||
              action.data.parcelas[index].tipoPagamento === 'CREDITO_EM_CONTA' ||
              action.data.parcelas[index].tipoPagamento === 'CARNE' ||
              action.data.parcelas[index].tipoPagamento === 'GUIA') {
              registroPgto = {
                ...registroPgto,
                tipoPagamento: action.data.parcelas[index].tipoPagamento,
                pagamentoBanco: {
                  contaBanco: {
                    id: action.data.parcelas[index].contaBanco.id
                  }
                }
              }
            }

            if (action.data.parcelas[index].tipoPagamento === 'CARTAO_CREDITO' || action.data.parcelas[index].tipoPagamento === 'CARTAO_DEBITO' || action.data.parcelas[index].tipoPagamento === 'CARTAO_VOUCHER') {
              registroPgto = formatJsonRemoveEmptyOrNull(
                {
                  ...registroPgto,
                  tipoPagamento: action.data.parcelas[index].tipoPagamento,
                  pagamentoBanco: {
                    cartaoBandeira: action.data.parcelas[index].cartaoBandeira,
                    cartaoNsu: action.data.parcelas[index].cartaoNsu,
                    chequeEmitente: '',
                    chequeNumeroCheque: '',
                    chequeBanco: '',
                    chequeNumeroAgencia: '',
                    chequeDvAgencia: '',
                    chequeContaCorrente: '',
                    chequeDvContaCorrente: '',
                    contaBanco: {
                      id: action.data.parcelas[index].contaBanco.id
                    }
                  }
                }
              )
            }
        
            if (action.data.parcelas[index].tipoPagamento === 'CHEQUE_A_VISTA' || action.data.parcelas[index].tipoPagamento === 'CHEQUE_PRE_DATADO') {
              registroPgto = formatJsonRemoveEmptyOrNull(
                {
                  ...registroPgto,
                  tipoPagamento: action.data.parcelas[index].tipoPagamento,
                  pagamentoBanco: {
                    // dataPrevisao: action.data.chequeData,
                    cartaoBandeira: '',
                    cartaoNsu: '',
                    chequeEmitente: action.data.parcelas[index].chequeEmitente,
                    chequeNumeroCheque: action.data.parcelas[index].chequeNro,
                    chequeBanco: action.data.parcelas[index].chequeBanco,
                    chequeNumeroAgencia: action.data.parcelas[index].chequeNroAg,
                    chequeDvAgencia: action.data.parcelas[index].chequeDvAg,
                    chequeContaCorrente: action.data.parcelas[index].chequeNroCc,
                    chequeDvContaCorrente: action.data.parcelas[index].chequeDvCc,
                    contaBanco: {
                      id: action.data.parcelas[index].contaBanco.id
                    }
                  }
                }
              )
            }

            pagamentos.push(registroPgto)

            parcela = {
              ...parcela,
              valorPendente: parcela.valor,
              pagamentos: pagamentos
            }
          }
          delete parcela.contaPaga;
          delete parcela.tipoPagamento;
          delete parcela.contaCaixa;
          delete parcela.contaBanco;
      
          const tempHash = `${new Date().getTime()}`;
          action.data.parcelas[index].tempHash = tempHash;

          parcelasASalvar.push(formatJsonRemoveEmptyOrNull(
            {
              ...parcela,
              idParcelasVinculadas: idParcelasVinc,
              propriedade: {
                id: JSON.parse(storage.get(CONFIG_SESSION)).fazendaSelecionada.id
              },
              tempHash
            }
          ));

          // yield call(createContaPagar, formatJsonRemoveEmptyOrNull(
          //   {
          //     ...parcela,
          //     idParcelasVinculadas: idParcelasVinc,
          //     propriedade: {
          //       id: JSON.parse(storage.get(CONFIG_SESSION)).fazendaSelecionada.id
          //     }
          //   }
          // ));
        }

        const resp = yield call(createContaPagarListAll, parcelasASalvar);

        if (resp.status === 200) {
          try {
            for (const cp of resp.data) {
              const matchedParcela = action.data.parcelas.find(parcela => parcela.tempHash === cp.tempHash);
              if (matchedParcela && matchedParcela.filesToUploadList.length > 0) {
                yield call(attachFiles, cp.id, matchedParcela.filesToUploadList);
              }
            }
          } catch (error) {
            console.error('Error during file attachment:', error);
          }
        }

        yield put({ type: CREATE_CONTA_PAGAR_SUCCESS, registro: action.data })

        history.push('/financeiro/contaPagar');

        AlertSuccess('Parcelas salvas com sucesso!');
      }
    } else {
      // EDITANDO CONTA
        const resp = yield call(updateContaPagar,
          formatJsonRemoveEmptyOrNull({
            ...action.data,
            valorPendente: action.data.valor,
            id: action.data.id
          })
        );

        if ( action.filesToAttach.length > 0 ) { yield call(attachFiles, action.data.id, action.filesToAttach); }
        if ( action.filesIdToDelete.length > 0 ) { yield call(deleteAttachFiles, action.data.id, action.filesIdToDelete); }

        yield put({ type: CREATE_CONTA_PAGAR_SUCCESS, registro: action.data })

        history.push('/financeiro/contaPagar');

        AlertSuccess('Conta salva com sucesso!');
    }
  } catch (error) {
    yield put({ type: CREATE_CONTA_PAGAR_FAILURE, registro: action.data })

    if (error.response === undefined || error.response.status === undefined || error.response.status !== 422) {
      AlertError('Falha ao salvar a conta! Tente novamente...');
    } else {
      AlertWarning(error.response.data.text);
    }
  }

}

export default function* watchCreateContaPagar() {
  yield takeLatest(CREATE_CONTA_PAGAR, sagaCreateContaPagar)
}
