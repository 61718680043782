import * as Yup from 'yup';

const newRegistro = {
    dataManutencao: '',
    tipo: 'PREVENTIVA',
    patrimonio: null,
    horimetro: null,
    custoTotal: 0,
    obs: '',
    identificador: null,
    propriedade: null,
    funcionariosResponsaveisList: [],
    produtoInsumoList: [],
    patrimonioManutencaoAnexoList: [],
    apropriarCustoSafra: false,
    despDireta: null,
    rateioSafras: []
};

const validation = Yup.object().shape({
    dataManutencao: Yup.date()
        .required('Este campo é obrigatório'),
    tipo: Yup.string()
        .typeError('Este campo é obrigatório')
        .required('Este campo é obrigatório'),
    patrimonio: Yup.object()
        .typeError('Este campo é obrigatório')
        .shape({
            id: Yup.number()
                .typeError('Este campo é obrigatório')
                .required("Este campo é obrigatório"),
        }),
    obs: Yup.string()
        .max(2000, 'Campo deve ter menos do que 2000 caracteres'),
    funcionariosResponsaveisList: Yup.array()
        .of(
            Yup.object().shape({
                funcionario: Yup.object()
                    .typeError('Este campo é obrigatório')
                    .shape({
                        id: Yup.number()
                            .typeError('Este campo é obrigatório')
                            .required("Este campo é obrigatório"),
                    }),
            })
        ),
    produtoInsumoList: Yup.array()
        .of(
            Yup.object().shape({
                produtoInsumo: Yup.object()
                    .typeError('Este campo é obrigatório')
                    .shape({
                        id: Yup.number()
                            .typeError('Este campo é obrigatório')
                            .required("Este campo é obrigatório"),
                    }),
                quantidade: Yup.number()
                    .positive('Por Favor, informe um valor maior que zero')
                    .transform(value => (isNaN(value) ? 0.0 : value))
                    .required('Este campo é obrigatório'),
                custo: Yup.number()
                    .typeError('Este campo é obrigatório')
                    .min(0, 'Por Favor, informe um valor válido'),
            })
        ),
    despDireta: Yup.object().when('apropriarCustoSafra', {
        is: (apropriarCustoSafra) => apropriarCustoSafra === true,
        then: Yup.object()
            .typeError('Este campo é obrigatório')
            .shape({
                id: Yup.number()
                    .typeError('Este campo é obrigatório')
                    .required("Este campo é obrigatório"),
            })
    }).nullable(),
    rateioSafras: Yup.array()
        .of(
            Yup.object().shape({
                safra: Yup.object()
                    .typeError('Este campo é obrigatório')
                    .shape({
                        id: Yup.number()
                            .typeError('Este campo é obrigatório')
                            .required("Este campo é obrigatório"),
                    }),
                percentualRateio: Yup.number()
                    .positive('Por Favor, informe um valor maior que zero')
                    .max(100, 'Máximo 100%')
                    .transform(value => (isNaN(value) ? 0.0 : value))
                    .required('Este campo é obrigatório'),
            })
        ),
});

export const patrimonioManutencaoCadastro = {
    newRegistro,
    validation
}
