import * as Yup from 'yup';

const newRegistro = {
    descricao: '',
    formulacao: '',
    fabricante: '',
    observacao: '',
    status: true,
    categoria: null,
    grupo: null,
    dosagemHa: 0,
    unidadeCompra: null,
    unidadeAplicacao: null,
    dosagemHa: 0,
    configLancAgricultura: false,
    configLancAgriculturaTipo: null,
};

const validation = Yup.object().shape({
    descricao: Yup.string()
        .min(2, 'O campo deve ter no mínimo 2 caracteres')
        .max(120, 'Campo deve ter menos do que 120 caracteres')
        .required('Este campo é obrigatório'),
    formulacao: Yup.string()
        .max(250, 'Campo deve ter menos do que 250 caracteres'),
    fabricante: Yup.string()
        .max(120, 'Campo deve ter menos do que 120 caracteres'),
    observacao: Yup.string()
        .max(5000, 'Campo deve ter menos do que 5000 caracteres'),
    categoria: Yup.string()
        .typeError('Este campo é obrigatório')
        .required('Este campo é obrigatório'),
    grupo: Yup.string()
        .typeError('Este campo é obrigatório')
        .required('Este campo é obrigatório'),
    unidadeCompra: Yup.object()
        .typeError('Este campo é obrigatório')
        .shape({
            id: Yup.number()
                .typeError('Este campo é obrigatório')
                .required("Este campo é obrigatório"),
        }),
    unidadeAplicacao: Yup.object().when('categoria', {
        is: (categoria) => categoria === 'DEFENSIVO' || categoria === 'FERTILIZANTE'
            || categoria === 'CULTIVAR' || categoria === 'COMBUSTIVEL',
        then: Yup.object()
            .typeError('Este campo é obrigatório')
            .shape({
                id: Yup.number()
                    .typeError('Este campo é obrigatório')
                    .required("Este campo é obrigatório"),
            })
    }).nullable(),
    // CAMPO DOSAGEM
    dosagemHa: Yup.number().when('categoria', {
        is: (categoria) => (
            categoria === 'DEFENSIVO'
            || categoria === 'FERTILIZANTE'),
        then: Yup.number()
            .typeError('Este campo é obrigatório')
            .min(0, 'Por Favor, informe um valor válido'),
    }),
});

export const catalagoProdutoWhiteLabel = {
    newRegistro,
    validation
}