import React, { Component } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Modal, ModalHeader, ModalBody, Button } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { TableNoLazy } from '@components/form';
import { formatCheck, formatDateDDMMYYYY } from '@components/common/format';
import { Column } from 'primereact/column';
import { SELECT_SAFRA_FAZENDA } from '@handler';
import { trackEvent } from 'mixpanel';

class SafraMenu extends Component {

    constructor(props) {
        super(props);
        this.state = {
            menu: false,
            modalSafraOpen: false,
            isMobile: window.innerWidth <= 990
        };
        this.toggle = this.toggle.bind(this);
        this.handleResize = this.handleResize.bind(this);
    }

    toggle() {
        this.setState(prevState => ({
            menu: !prevState.menu
        }));
    }

    handleResize() {
        this.setState({ isMobile: window.innerWidth <= 990 });
    }

    componentDidMount() {
        window.addEventListener('resize', this.handleResize);
        if (this.props.startSessionReducer.configSession !== null && this.props.startSessionReducer.configSession !== undefined 
            && this.props.startSessionReducer.configSession.safras !== null && this.props.startSessionReducer.configSession.safras !== undefined
            && this.props.startSessionReducer.configSession.safras.length > 1 && this.props.startSessionReducer.configSession.safraAtiva === null) {
            this.setState({
                modalSafraOpen: true
            })
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
    }

    renderColumnName = (rowData) => {
        if (this.state.isMobile) {
            return (
                <span>
                    {rowData.nome}<br />
                    <div style={{ fontSize: '12px', fontStyle: 'italic' }}>
                        {`${formatDateDDMMYYYY(rowData.dataInicial)} - ${formatDateDDMMYYYY(rowData.dataFinal)}`}
                    </div>
                </span>
            );
        }
        return rowData.nome;
    }

    renderColumnAtivo = (value) => formatCheck(value.status);

    renderColumnDataInicial = (value) => formatDateDDMMYYYY(value.dataInicial);

    renderColumnDataFinal = (value) => formatDateDDMMYYYY(value.dataFinal);

    render() {
        const { startSessionReducer } = this.props;

        return (
            <React.Fragment>
                <Dropdown isOpen={this.state.menu} toggle={this.toggle} className="d-inline-block" >
                <DropdownToggle className="btn header-item waves-effect" tag="button" style={{ display: 'flex', alignItems: 'center' }}>
                    <i className="fa fa-calendar-check font-size-17 primary-color" style={{ marginRight: '10px' }} />
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                        <span className='primary-color'>
                            {startSessionReducer.configSession == null || startSessionReducer.configSession.safraAtiva == null ?
                                "Nenhuma Safra Selecionada" : startSessionReducer.configSession.safraAtiva.nome
                            }
                        </span>
                        {startSessionReducer.configSession != null && startSessionReducer.configSession.fazendaSelecionada != null &&
                            <span className='primary-color' style={{ fontSize: '12px' }}>
                                {startSessionReducer.configSession.fazendaSelecionada.nome}
                            </span>
                        }
                    </div>
                    <i className="fa fa-angle-down primary-color" style={{ marginLeft: '5px' }} />
                </DropdownToggle>

                    <DropdownMenu right>
                        {startSessionReducer.configSession == null || startSessionReducer.configSession.safras == null || startSessionReducer.configSession.safras.length <= 1 ?
                            <React.Fragment /> :
                            <React.Fragment>
                                <DropdownItem >
                                    <div onClick={() => this.setState({
                                        modalSafraOpen: true
                                    })}>

                                        {startSessionReducer.configSession == null || startSessionReducer.configSession.safraAtiva == null || startSessionReducer.configSession.safraAtiva == null ?
                                            <i className="fa fa-check font-size-17 align-middle mr-1"></i> : <i className="fa fa-exchange-alt font-size-17 align-middle mr-1"></i>}
                                        {startSessionReducer.configSession == null || startSessionReducer.configSession.safraAtiva == null || startSessionReducer.configSession.safraAtiva == null ?
                                            "Acessar Safra" : "Trocar de Safra"}
                                    </div>
                                </DropdownItem>
                                <div className="dropdown-divider"></div>
                            </React.Fragment>
                        }
                        <DropdownItem tag="a" href="/safraCadastro" onClick={() => trackEvent('Clicou na Safra Menu > Gerenciar Safras')}>
                            <i className="mdi mdi-calendar font-size-17 align-middle mr-1" />Gerenciar Safras
                        </DropdownItem>
                    </DropdownMenu>
                </Dropdown>

                {/* MODAL ATIVAR SAFRA */}
                <Modal isOpen={this.state.modalSafraOpen} size="xl">
                    <ModalHeader>Acessar Safra</ModalHeader>
                    <ModalBody>
                        <h4 className="font-size-18">Selecione a safra que você deseja acessar:</h4>

                        <TableNoLazy list={startSessionReducer.configSession.safras}>
                            <Column field='status' header='Ativo' body={this.renderColumnAtivo} style={{ textAlign: 'center', width: '100px' }} />

                            <Column field='nome' header='Nome' body={this.renderColumnName} />

                            {!this.state.isMobile && (
                                <Column field='dataInicial' header='Data Inicial' body={this.renderColumnDataInicial} style={{ textAlign: 'center', width: '150px' }} />
                            )}

                            {!this.state.isMobile && (
                                <Column field='dataFinal' header='Data Final' body={this.renderColumnDataFinal} style={{ textAlign: 'center', width: '150px' }} />
                            )}

                            <Column style={{ textAlign: 'center', width: this.state.isMobile ? '35%' : '20%' }}
                                body={(rowData, column) => {
                                    return (
                                        <Button color="primary" onClick={() => this.props.selectSafraFazenda(rowData.id)} >
                                            <i className='fa fa-check' /> Acessar Safra
                                        </Button>
                                    );
                                }} />
                        </TableNoLazy>
                    </ModalBody>
                    {/* <ModalFooter>
                        <Button color="light" onClick={() => this.setState({
                            modalSafraOpen: false
                        })} >Fechar</Button>
                    </ModalFooter> */}
                </Modal>
            </React.Fragment>
        );
    }
}

export function mapStateToProps(state) {
    const { startSessionReducer } = state.entities;
    return { startSessionReducer };
};

function mapDispatchToProps(dispatch) {
    return {
        selectSafraFazenda: (id) => dispatch({ type: SELECT_SAFRA_FAZENDA, id })
    }
}

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(SafraMenu))
