import { takeLatest, put } from 'redux-saga/effects';
import moment from 'moment';

import { storage } from '@commons';

import {
  CONFIG_SESSION,
  implantacaoEstoqueProducao,
  NEW_IMPLANTACAO_ESTOQUE_PRODUCAO,
  NEW_IMPLANTACAO_ESTOQUE_PRODUCAO_SUCCESS,
} from '@handler';

function* sagaNewImplantacaoEstoqueProducao(action) {

  yield put({ type: NEW_IMPLANTACAO_ESTOQUE_PRODUCAO_SUCCESS, registro: 
    {
      ...implantacaoEstoqueProducao.newRegistro,
      data: moment().startOf('day').format('YYYY-MM-DD'),
      cultura: JSON.parse(storage.get(CONFIG_SESSION)).safraAtiva.cultura,
    } });
}

export default function* watchNewImplantacaoEstoqueProducao() {
  yield takeLatest(NEW_IMPLANTACAO_ESTOQUE_PRODUCAO, sagaNewImplantacaoEstoqueProducao)
}
