import React, { useState } from "react";

import { AsyncPaginate } from "react-select-async-paginate";

import {
    Button,
    Col,
    FormGroup,
    Label,
} from 'reactstrap';

import { Field } from 'formik';
import { listGrupoContaAgrareWhiteLabel } from "@handler";
import { storage, path } from "@commons";

export const SelectGrupoContaAgrareWhiteLabel = (props) => {
    const [page, setPage] = useState(0);

    const { col, name, label, placeholder, showNewRegister, showTitleNewRegister } = props;

    const loadOptions = async (search) => {
        let user = JSON.parse(storage.get(path.sessionUser));
        let where = '&clienteWhiteLabel_identificationCode=' + user.identificationCodeWhiteLabel;
        if (user.idPartnerWhiteLabel !== undefined && user.idPartnerWhiteLabel !== null) {
            where += '&partnerWhiteLabel=' + user.idPartnerWhiteLabel;
        }
        let pagAtual = '';
        var size = 'size=30';

        if (search.trim() !== '') {
            const encodedSearch = encodeURIComponent(search);
            where = where + '&nome=' + encodedSearch;
            pagAtual = 'page=0';
            setPage(0)
        } else {
            pagAtual = 'page=' + (page);
            setPage(page + 1)
        }

        let param = '?' + size + '&' + pagAtual + where;

        const registros = await listGrupoContaAgrareWhiteLabel(param);
        return {
            options: registros.content,
            hasMore: !registros.last
        };
    };

    return (
        <React.Fragment>
            <Col md={col}>
                <FormGroup>
                    <Label htmlFor={'id_' + name} >{label}</Label>
                    {showNewRegister ?
                        <>
                            <Button onClick={() => props.refresh()} size="sm" className="btn btn-dark" style={{
                                float: "right",
                                fontSize: showTitleNewRegister ? '11px' : '8px', marginLeft: "5px"
                            }}>
                                <i className='fa fa-sync' />
                            </Button>
                        </>
                        : <React.Fragment />
                    }
                    <Field name={name} >
                        {({ field, form, meta }) => (
                            <>
                                <AsyncPaginate
                                    {...field}
                                    required={true}
                                    id={'id_' + name}
                                    options={[]}
                                    value={field.value}
                                    loadOptions={loadOptions}
                                    onChange={(e) => {
                                        form.setFieldValue(field.name, e);
                                        props.plusChange(e);
                                    }}
                                    getOptionValue={(option) => option.id}
                                    getOptionLabel={(option) => option.nome}
                                    placeholder={placeholder}
                                    isClearable={true}
                                    isSearchable={true}
                                    escapeClearsValue={true}
                                    autoload={false}
                                    loadOptionsOnMenuOpen={true}
                                    loadingMessage={() => 'Carregando...'}
                                    noOptionsMessage={() => 'Nenhum registro encontrado'}
                                    isValidNewOption={meta.error && meta.touched}
                                    menuPosition={'fixed'}
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 4,
                                        colors: {
                                            ...theme.colors,
                                            primary25: '#e0dede',
                                            primary50: '#e0dede',
                                            primary: '#a6a4a4',

                                            neutral20: meta.touched && meta.error ? 'red' : '#CCC'
                                        },
                                    })}
                                />
                                {meta.touched && (meta.error && <p style={{ color: "red", fontSize: "12px", padding: "2px" }}>{meta.error}</p>)}
                            </>
                        )}
                    </Field>
                </FormGroup>
            </Col>
        </React.Fragment>
    );
};

SelectGrupoContaAgrareWhiteLabel.defaultProps = {
    col: 12,
    showNewRegister: false,
    showTitleNewRegister: false,
    plusChange: () => { },
};