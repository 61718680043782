import React from 'react';
import { Col, FormGroup } from 'reactstrap';
import { Field } from 'formik';
import { InfoTooltip } from '@components/form';
import { CheckboxWithLabel } from 'formik-material-ui';
import './Check.css';

export const Check = (props) => {
    const { id, name, col, label, onClick, disabled, showInfo, infoTitle, infoMsg, style, ...rest } = props;
    const idComponent = id === undefined ? 'id_' + name : 'id_' + id;

    return (
        <Col md={col} style={style}>
            <FormGroup>
                <div className='form-control' style={{ border: 'none', marginTop: '20px', ...style }}>
                    <Field
                        component={CheckboxWithLabel}
                        type="checkbox"
                        name={name}
                        Label={{ label: label }}
                        onClick={onClick}
                        disabled={disabled === undefined ? false : disabled}
                        {...rest}
                    />
                    {showInfo ? <InfoTooltip id={idComponent} title={infoTitle} msg={infoMsg} /> : null}
                </div>
            </FormGroup>
        </Col>
    );
};

Check.defaultProps = {
    col: 12,
    showInfo: false,
    infoTitle: '',
    infoMsg: '',
    onClick: () => { }
};
