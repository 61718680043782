import { takeLatest, put, call } from 'redux-saga/effects';

import { findPlantioManejo } from '../service';
import { storage } from '@commons';
import {
  CONFIG_SESSION,
  FIND_PLANTIO_MANEJO_CUSTO_DIRETO,
  FIND_PLANTIO_MANEJO_PENDING,
  FIND_PLANTIO_MANEJO_SUCCESS,
  FIND_PLANTIO_MANEJO_FAILURE
} from '@handler';

function* sagaFindPlantioManejoCustoDireto(action) {
  yield put({ type: FIND_PLANTIO_MANEJO_PENDING, id: action.id })

  try {

    const talhoesSafra = yield JSON.parse(storage.get(CONFIG_SESSION)).safraAtiva.safraTalhaoList;

    const registro = yield call(findPlantioManejo, action.id);

    const talhoesMap = new Map(registro.custoDireto.custoDiretoTalhao.map(talhao => [talhao.talhao.id, talhao.talhao]));

    // Percorrer talhoesSafra e verificar se cada talhão está presente no mapa
    talhoesSafra.forEach(talhaoSafra => {
      if (!talhoesMap.has(talhaoSafra.talhao.id)) {
        // Se o talhão não estiver presente, adicione-o à lista de talhões do registro
        registro.custoDireto.custoDiretoTalhao.push({
          talhao: talhaoSafra.talhao,
          areaTalhao: talhaoSafra.talhao.area,
          areaSelecionada: talhaoSafra.talhao.area,
          ativo: false,
        });
      }
    });

    registro.custoDireto.custoDiretoTalhao.sort((a, b) => a.talhao.nome.localeCompare(b.talhao.nome));

    yield put({
      type: FIND_PLANTIO_MANEJO_SUCCESS, registro: {
        ...registro.custoDireto,
        custoDiretoTalhao: registro.custoDireto.custoDiretoTalhao,
      }
    })
  } catch (error) {
    yield put({ type: FIND_PLANTIO_MANEJO_FAILURE })
    // console.error(error) // eslint-disable-line

  }
}

export default function* watchFindPlantioManejoCustoDireto() {
  yield takeLatest(FIND_PLANTIO_MANEJO_CUSTO_DIRETO, sagaFindPlantioManejoCustoDireto)
}
