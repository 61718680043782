import { takeLatest, put, call } from 'redux-saga/effects';

import { findPatrimonioAbastecimentoCadastro } from '../service';
import {
  FIND_PATRIMONIO_ABASTECIMENTO_CADASTRO,
  FIND_PATRIMONIO_ABASTECIMENTO_CADASTRO_PENDING,
  FIND_PATRIMONIO_ABASTECIMENTO_CADASTRO_SUCCESS,
  FIND_PATRIMONIO_ABASTECIMENTO_CADASTRO_FAILURE
} from '../actionTypes';

function* sagaFindPatrimonioAbastecimentoCadastro(action) {
  yield put({ type: FIND_PATRIMONIO_ABASTECIMENTO_CADASTRO_PENDING, id: action.id })

  try {

    const registro = yield call(findPatrimonioAbastecimentoCadastro, action.id);

    yield put({ type: FIND_PATRIMONIO_ABASTECIMENTO_CADASTRO_SUCCESS, registro: registro })

  } catch (error) {

    yield put({ type: FIND_PATRIMONIO_ABASTECIMENTO_CADASTRO_FAILURE })
    // console.error(error) // eslint-disable-line

  }
}

export default function* watchFindPatrimonioAbastecimentoCadastro() {
  yield takeLatest(FIND_PATRIMONIO_ABASTECIMENTO_CADASTRO, sagaFindPatrimonioAbastecimentoCadastro)
}
