import { takeLatest, put, call } from 'redux-saga/effects';

import { markNotificationAllAsRead } from '../service';
import {
  CONFIG_SESSION,
  MARK_NOTIFICATION_ALL_AS_READ,
  MARK_NOTIFICATION_ALL_AS_READ_PENDING,
  MARK_NOTIFICATION_ALL_AS_READ_SUCCESS,
  MARK_NOTIFICATION_ALL_AS_READ_FAILURE,
  NEW_FILTER_NOTIFICATION
} from '@handler';
import { storage, path } from "@commons";

function* sagaMarkNotificationAllAsRead(action) {
  yield put({ type: MARK_NOTIFICATION_ALL_AS_READ_PENDING });

  try {
    const user = JSON.parse(storage.get(path.sessionUser));
    const dominio = JSON.parse(storage.get(CONFIG_SESSION)).schemaSelecionado;
    const schema = dominio ? dominio : '';

    yield call(markNotificationAllAsRead, user.id, schema);
    yield put({ type: NEW_FILTER_NOTIFICATION });

    yield put({ type: MARK_NOTIFICATION_ALL_AS_READ_SUCCESS });
  } catch (error) {
    yield put({ type: MARK_NOTIFICATION_ALL_AS_READ_FAILURE });
  }
}

export default function* watchMarkNotificationAllAsRead() {
  yield takeLatest(MARK_NOTIFICATION_ALL_AS_READ, sagaMarkNotificationAllAsRead);
}
