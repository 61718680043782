import * as Yup from 'yup';

const newRegistro = {
    data: '',
    obs: '',
    safra: null,
    talhao: null,
    cultura: null,
    produtoAgricola: null,
    culturaFormaLancColheita: '',
    culturaUnidadeColheita: '',
    culturaUnidadeEstoqueProducao: '',
    cultivar: null,
    localEstoque: null,
    veiculo: null,
    motorista: null,
    transportadora: null,
    funcionario: null,
    romaneio: '',
    nroNfe: '',
    impureza: 0,
    umidade: 0,
    producaoBruta: 0,
    producaoLiquida: 0,
    producaoConvertida: 0,
    mediaColhida: 0,
    areaColhida: 0,
    obs: ''
};

const validation = Yup.object().shape({
    data: Yup.date()
        .required('Este campo é obrigatório'),
    talhao: Yup.object()
        .typeError('Este campo é obrigatório')
        .shape({
            id: Yup.number()
                .typeError('Este campo é obrigatório')
                .required("Este campo é obrigatório"),
        }),
    localEstoque: Yup.object()
        .typeError('Este campo é obrigatório')
        .shape({
            id: Yup.number()
                .typeError('Este campo é obrigatório')
                .required("Este campo é obrigatório"),
        }),
    produtoAgricola: Yup.object()
        .typeError('Este campo é obrigatório')
        .shape({
            id: Yup.number()
                .typeError('Este campo é obrigatório')
                .required("Este campo é obrigatório"),
        }),
    producaoBruta: Yup.number().when('culturaFormaLancColheita', {
        is: (formaLancColheita) => formaLancColheita === 'PESO',
        then: Yup.number()
            .positive('Por Favor, informe um valor maior que zero')
            .transform(value => (isNaN(value) ? 0.0 : value))
            .required('Este campo é obrigatório'),
    }).nullable(),
    producaoLiquida: Yup.number()
        .positive('Por Favor, informe um valor maior que zero')
        .transform(value => (isNaN(value) ? 0.0 : value))
        .required('Este campo é obrigatório'),
    romaneio: Yup.string()
        .max(50, 'Campo deve ter menos do que 50 caracteres'),
    nroNfe: Yup.string()
        .max(50, 'Campo deve ter menos do que 50 caracteres'),
    obs: Yup.string()
        .max(1000, 'Campo deve ter menos do que 1000 caracteres'),
    areaColhida: Yup.number()
        .positive('Por Favor, informe um valor maior que zero')
        .transform(value => (isNaN(value) ? 0.0 : value))
        .required('Este campo é obrigatório'),
});

export const colheita = {
    newRegistro,
    validation
}