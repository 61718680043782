import * as Yup from 'yup';

const newRegistro = {
    nome: '',
    status: true,
    cultura: null,
    formaLancColheita: null,
    unidadeColheita: null,
    unidadeEstoqueProducao: null,
    unidadeVenda: null,
};

const validation = Yup.object().shape({
    nome: Yup.string()
        .min(2, 'O campo deve ter no mínimo 2 caracteres')
        .max(120, 'Campo deve ter menos do que 120 caracteres')
        .required('Este campo é obrigatório'),
    cultura: Yup.object()
        .typeError('Este campo é obrigatório')
        .shape({
            id: Yup.number()
                .typeError('Este campo é obrigatório')
                .required("Este campo é obrigatório"),
        }),
    formaLancColheita: Yup.string()
        .typeError('Este campo é obrigatório')
        .required('Este campo é obrigatório'),
    unidadeColheita: Yup.object()
        .typeError('Este campo é obrigatório')
        .shape({
            id: Yup.number()
                .typeError('Este campo é obrigatório')
                .required("Este campo é obrigatório"),
        }),
    unidadeEstoqueProducao: Yup.object()
        .typeError('Este campo é obrigatório')
        .shape({
            id: Yup.number()
                .typeError('Este campo é obrigatório')
                .required("Este campo é obrigatório"),
        }),
    unidadeVenda: Yup.object()
        .typeError('Este campo é obrigatório')
        .shape({
            id: Yup.number()
                .typeError('Este campo é obrigatório')
                .required("Este campo é obrigatório"),
        }),
});

export const produtoAgricolaCadastro = {
    newRegistro,
    validation
}