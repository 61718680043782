import { path, httpService } from '@commons';

/////////////////////////////////////////////////////////////////////////////////
const args = { ...path, path: '/ndvi' };
/////////////////////////////////////////////////////////////////////////////////

export function listNdvi(param) {
  return httpService.get({...args, args: param});
}

export function listAllLatestNdvi(propriedadeId) {
  return httpService.get({ ...args, args: `/all-latest?propriedade=${propriedadeId}` });
}

export function findNdvi(id) {
  return httpService.get({ ...args, args: `/${id}` });
}

export function listAllNdviBySafra(propriedade, safra, page, size) {
  return httpService.get({ 
    ...args, 
    args: `/all-by-safra?propriedade=${propriedade}&safra=${safra}&page=${page}&size=${size}`
  });
}

export function listAllLatestNdviBySafra(propriedadeId, safraId) {
  return httpService.get({
    ...args,
    args: `/all-latest-safra?propriedade=${propriedadeId}&safra=${safraId}`
  });
}
