import { takeLatest, put, call } from 'redux-saga/effects';

import { history } from '@commons';

import { createNFeEmissao, updateNFeEmissao } from '../service';
import {
  CREATE_NFE_EMISSAO,
  CREATE_NFE_EMISSAO_PENDING,
  CREATE_NFE_EMISSAO_SUCCESS,
  CREATE_NFE_EMISSAO_FAILURE,
  CONFIG_SESSION
} from '@handler';

import { storage } from '@commons/storage';

import { AlertSuccess, AlertError, AlertWarning } from '@components/common/AlertToast'
import { formatJsonRemoveEmptyOrNull } from '@components/common/format';

function* sagaCreateNFeEmissao(action) {
  yield put({ type: CREATE_NFE_EMISSAO_PENDING })

  try {
    if (action.data.id === undefined || action.data.id === null) {
      yield call(createNFeEmissao, formatJsonRemoveEmptyOrNull({
        ...action.data,
        propriedade: {
          id: JSON.parse(storage.get(CONFIG_SESSION)).fazendaSelecionada.id
        }
      }));
    } else {
      yield call(updateNFeEmissao,
        {
          ...action.data,
          id: action.data.id
        }
      );
    }

    yield put({ type: CREATE_NFE_EMISSAO_SUCCESS, registro: action.data })

    history.push('/estoque/implantacao');

    AlertSuccess('Registro salvo com sucesso!');
  } catch (error) {
    yield put({ type: CREATE_NFE_EMISSAO_FAILURE, registro: action.data })

    AlertError('Falha ao salvar o registro! Tente novamente...');
  }
}

export default function* watchCreateNFeEmissao() {
  yield takeLatest(CREATE_NFE_EMISSAO, sagaCreateNFeEmissao)
}
