import React, { Component } from "react";
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';

// import images
import logodarkImg from "assets/images/logo/logo.png";
import logosmImg from "assets/images/logo/logo.png";
import logolightImg from "assets/images/logo/logo.png";

// Import other Dropdown
// import LanguageDropdown from "../../components/LanguageDropdown";
import NotificationDropdown from "../NotificationDropdown";

import ProfileMenu from "../ProfileMenu";
import SafraMenu from "../SafraMenu";
import AnoPecuarioMenu from "../AnoPecuarioMenu";

class TopBar extends Component {
  constructor(props) {
    super(props);
    this.state = {};

    this.toggleFullscreen = this.toggleFullscreen.bind(this);
    this.toggleMenu = this.toggleMenu.bind(this);
    this.toggleRightbar = this.toggleRightbar.bind(this);
  }

  /**
   * Toggle sidebar
   */
  toggleMenu() {
    // this.props.toggleMenuCallback();
    this.props.openLeftMenuCallBack();
  }

  /**
   * Toggles the sidebar
   */
  toggleRightbar() {
    this.props.toggleRightSidebar();
  }

  /**
   * Toggle full screen
   */
  toggleFullscreen() {
    if (
      !document.fullscreenElement &&
      /* alternative standard method */ !document.mozFullScreenElement &&
      !document.webkitFullscreenElement
    ) {
      // current working methods
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(
          Element.ALLOW_KEYBOARD_INPUT
        );
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      }
    }
  }

  render() {
    const { startSessionReducer } = this.props;

    const moduloAgricultura = startSessionReducer.configSession !== null && startSessionReducer.configSession.moduloAtivo !== null && startSessionReducer.configSession.moduloAtivo === 'AGRICULTURA';
    const moduloPecuaria = startSessionReducer.configSession !== null && startSessionReducer.configSession.moduloAtivo !== null && startSessionReducer.configSession.moduloAtivo === 'PECUARIA';

    return (
      <React.Fragment>
        <header id="page-topbar">
          <div className="navbar-header">
            <div className="d-flex">
              <div
                className="navbar-brand-box"
              >

                <Link to="/" className="logo logo-dark">
                  <span className="logo-sm">
                    <img
                      alt=""
                      height="50"
                      className={`logo-image-sm`}
                    /> 
                  </span>
                  <span className="logo-lg">
                    <img
                      alt=""
                      height="50"
                      className={`logo-image`}
                    /> 
                  </span>
                </Link>

                <Link to="/" className="logo logo-light">
                  <span className="logo-sm">
                    <img
                      alt=""
                      height="50"
                      className={`logo-image-sm`}
                    /> 
                  </span>
                  <span className="logo-lg">
                    <img
                      alt=""
                      height="50"
                      className={`logo-image`}
                    /> 
                  </span>
                </Link>
              </div>

              <button
                type="button"
                className="btn btn-sm mr-2 font-size-24 d-lg-none header-item waves-effect waves-light"
                onClick={this.toggleMenu}
              >
                <i className="mdi mdi-menu"></i>
              </button>
            </div>

            <div className="d-flex">
              {/* <div>
                <Formik
                    enableReinitialize
                    initialValues={initialValues}
                    onSubmit={(values) => {}}
                  >
                    {({ errors, touched, values }) => (
                      <Form>
                        <InputSelect name='fazendaSelecionada'
                            label='Fazenda Ativa'
                            col={12}
                            list={fazendaReducer.table.content} text='nome'
                        />
                      </Form>
                    )}
                </Formik>
              </div> */}

              {/* <form className="app-search d-none d-lg-block">
                <div className="position-relative">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search..."
                  />
                  <span className="fa fa-search"></span>
                </div>
              </form> */}

              {/* <LanguageDropdown /> */}



              {/* <div className="dropdown d-none d-lg-inline-block">
                <button
                  type="button"
                  className="btn header-item noti-icon waves-effect"
                  onClick={this.toggleFullscreen}
                >
                  <i className="mdi mdi-fullscreen" style={{ color: '#175B28' }} />
                </button>
              </div> */}

              {!moduloAgricultura ?
                <React.Fragment />
                :
                <SafraMenu />
              }
              {!moduloPecuaria ?
                <React.Fragment />
                :
                <AnoPecuarioMenu />
              }

              <NotificationDropdown />

              <ProfileMenu />

              {/* <div className="dropdown d-inline-block">
                <button
                  type="button"
                  onClick={this.toggleRightbar}
                  className="btn header-item noti-icon right-bar-toggle waves-effect"
                >
                  <i className="mdi mdi-settings-outline"></i>
                </button>
              </div>

              <div className="dropdown d-inline-block">
                <button
                  type="button"
                  onClick={this.toggleRightbar}
                  className="btn header-item noti-icon right-bar-toggle waves-effect"
                >
                  <i className="bx bx-cog bx-spin"></i>
                </button>
              </div> */}
            </div>
          </div>
        </header>
      </React.Fragment>
    );
  }
}

export function mapStateToProps(state) {
  const { startSessionReducer } = state.entities;
  return { startSessionReducer };
};

function mapDispatchToProps(dispatch) {
  return {}
}

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(TopBar))