import { takeLatest, put, call } from 'redux-saga/effects';
import { storage } from '@commons/storage';

import {
  CONFIG_SESSION,
  NEW_FILTER_IMPLANTACAO_ESTOQUE,
  NEW_FILTER_IMPLANTACAO_ESTOQUE_PEDING,
  NEW_FILTER_IMPLANTACAO_ESTOQUE_SUCCESS,
  NEW_FILTER_IMPLANTACAO_ESTOQUE_FAILURE,
} from '@handler';
import { formatDateDDMMYYYY, formatDateYYYYMMDD } from '@components/common/format';
import { filterImplantacaoEstoque } from '../service';
import { CONST_FILTER_IMPLANTACAO_ESTOQUE, CONST_ROWS_PER_PAGE } from '@commons/consts';

function* sagaNewFilterImplantacaoEstoque(action) {
  try {
    yield put({ type: NEW_FILTER_IMPLANTACAO_ESTOQUE_PEDING });

    const idFazenda = JSON.parse(storage.get(CONFIG_SESSION)).fazendaSelecionada.id;
    let datas = '';
    let dataInicial = '';
    let dataFinal = '';
    var date = new Date();
    let where = '';
    let newFilter = {
      produto: null,
      localEstoque: null,
      dataInicial: '',
      dataFinal: '',
      rows: CONST_ROWS_PER_PAGE,
      page: 0,
      where: ''
    };

    let filterSaved = sessionStorage.getItem(CONST_FILTER_IMPLANTACAO_ESTOQUE) !== null ? JSON.parse(sessionStorage.getItem(CONST_FILTER_IMPLANTACAO_ESTOQUE)) : null;

    if (filterSaved === null || filterSaved === undefined) {
      // seta data do mes na primeira vez que entra na pagina
      dataInicial = formatDateYYYYMMDD(new Date(date.getFullYear(), date.getMonth(), 1));
      dataFinal = formatDateYYYYMMDD(new Date(date.getFullYear(), date.getMonth() + 1, 0));

      datas = formatDateDDMMYYYY(dataInicial) + '_' + formatDateDDMMYYYY(dataFinal);

      newFilter = {
        ...newFilter,
        dataInicial,
        dataFinal,
      };

    } else {
      // se seta filtro manual
      if (action.newFilter) {
        // seta filtro indicado
        // typePeriodo = 0 -> periodo manual
        // typePeriodo = 1 -> hoje
        // typePeriodo = 2 -> esta semana
        // typePeriodo = 3 -> mes passado
        // typePeriodo = 4 -> este mes
        // typePeriodo = 5 -> proximo mes
        // typePeriodo = 6 -> este ano
        if (action.typePeriodo === undefined || action.typePeriodo === 0) {
          dataInicial = action.args.dataInicial;
          dataFinal = action.args.dataFinal;

          datas = formatDateDDMMYYYY(action.args.dataInicial) + '_' + formatDateDDMMYYYY(action.args.dataFinal);
        } else if (action.typePeriodo === 1) {
          dataInicial = formatDateYYYYMMDD(new Date(date));
          dataFinal = formatDateYYYYMMDD(new Date(date));

          datas = formatDateDDMMYYYY(dataInicial) + '_' + formatDateDDMMYYYY(dataFinal);
        }
        else if (action.typePeriodo === 2) {
          const primeiro = date.getDate() - date.getDay();

          dataInicial = formatDateYYYYMMDD(new Date(date.getFullYear(), date.getMonth(), primeiro));
          dataFinal = formatDateYYYYMMDD(new Date(date.getFullYear(), date.getMonth(), primeiro + 6));

          datas = formatDateDDMMYYYY(dataInicial) + '_' + formatDateDDMMYYYY(dataFinal);
        } else if (action.typePeriodo === 3) {
          dataInicial = formatDateYYYYMMDD(new Date(date.getFullYear(), date.getMonth() - 1, 1));
          dataFinal = formatDateYYYYMMDD(new Date(date.getFullYear(), date.getMonth(), 0));

          datas = formatDateDDMMYYYY(dataInicial) + '_' + formatDateDDMMYYYY(dataFinal);
        } else if (action.typePeriodo === 4) {
          dataInicial = formatDateYYYYMMDD(new Date(date.getFullYear(), date.getMonth(), 1));
          dataFinal = formatDateYYYYMMDD(new Date(date.getFullYear(), date.getMonth() + 1, 0));

          datas = formatDateDDMMYYYY(dataInicial) + '_' + formatDateDDMMYYYY(dataFinal);
        } else if (action.typePeriodo === 5) {
          dataInicial = formatDateYYYYMMDD(new Date(date.getFullYear(), date.getMonth() + 1, 1));
          dataFinal = formatDateYYYYMMDD(new Date(date.getFullYear(), date.getMonth() + 2, 0));

          datas = formatDateDDMMYYYY(dataInicial) + '_' + formatDateDDMMYYYY(dataFinal);
        } else if (action.typePeriodo === 6) {
          dataInicial = formatDateYYYYMMDD(new Date(date.getFullYear(), 0, 1));
          dataFinal = formatDateYYYYMMDD(new Date(date.getFullYear(), 12, 0));

          datas = formatDateDDMMYYYY(dataInicial) + '_' + formatDateDDMMYYYY(dataFinal);
        }

        if (action.args.produto !== null && action.args.produto !== undefined && action.args.produto !== '') {
            where = where + '&produto=' + action.args.produto.id;
        }
        if (action.args.localEstoque !== null && action.args.localEstoque !== undefined && action.args.localEstoque !== '') {
            where = where + '&localEstoque=' + action.args.localEstoque.id;
        }

        newFilter = {
          ...newFilter,
          ...action.args,
          dataInicial,
          dataFinal,
          where: where
        };

      } else {
        // busca filtro salvo
        datas = formatDateDDMMYYYY(filterSaved.dataInicial) + '_' + formatDateDDMMYYYY(filterSaved.dataFinal);
        
        if (filterSaved.produto !== null && filterSaved.produto !== undefined && filterSaved.produto !== '') {
          where = where + '&produto=' + filterSaved.produto.id;
        }
        if (filterSaved.localEstoque !== null && filterSaved.localEstoque !== undefined && filterSaved.localEstoque !== '') {
            where = where + '&localEstoque=' + filterSaved.localEstoque.id;
        }

        newFilter = {
          ...filterSaved,
          rows: CONST_ROWS_PER_PAGE,
          page: 0,
          where: where
        };
      }
    }

    // busca registros
    const list = yield call(filterImplantacaoEstoque, idFazenda, datas, `&page=0&size=${CONST_ROWS_PER_PAGE}${where}`);

    sessionStorage.setItem(CONST_FILTER_IMPLANTACAO_ESTOQUE, JSON.stringify(newFilter));

    yield put({
      type: NEW_FILTER_IMPLANTACAO_ESTOQUE_SUCCESS, list: list, filter: newFilter
    });
  } catch (_) {
    yield put({ type: NEW_FILTER_IMPLANTACAO_ESTOQUE_FAILURE })
  }
}

export default function* watchNewFilterImplantacaoEstoque() {
  yield takeLatest(NEW_FILTER_IMPLANTACAO_ESTOQUE, sagaNewFilterImplantacaoEstoque)
}
