import mixpanel from 'mixpanel-browser';
import { storage, path } from "@commons";
import { MIXPANEL_TOKEN } from '@commons/consts';

mixpanel.init(MIXPANEL_TOKEN, {
    // debug: true, // Debug Mode allows you to see the requests being sent by your implementation to Mixpanel, in real time, from your browser's developer console.
    track_pageview: true, // Registra automaticamente um evento de visualização de página sempre que a página é carregada.
    persistence: 'localStorage', 
    // ignore_dnt: true  // To ignore the "Do Not Track" browser setting
});

const trackEvent = (eventName, properties = {}) => {
    let user = JSON.parse(storage.get(path.sessionUser));

    if (user.email && !user.email.includes("@agrare.com.br")) {
        if (user.name && user.email && user.accountNameWhiteLabel) {
            const userData = {
                name: user.name,
                email: user.email,
                accountNameWhiteLabel: user.accountNameWhiteLabel
            };
            properties = Object.keys(properties).length > 0
                ? { ...properties, user: userData}
                : { user: userData};

            mixpanel.track(eventName, properties);
        } else if (Object.keys(properties).length > 0) {
            mixpanel.track(eventName, properties);
        } else {
            mixpanel.track(eventName);
        }
    }
};

export default mixpanel;
export { mixpanel, trackEvent };