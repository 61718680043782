import { takeLatest, put } from 'redux-saga/effects';

import {
  CANCEL_PRODUTO_AGRICOLA_CADASTRO_RAPIDO,
  CANCEL_PRODUTO_AGRICOLA_CADASTRO_RAPIDO_SUCCESS
} from '../actionTypes';

function* sagaCancelProdutoAgricolaCadastroRapido() {
  yield put({ type: CANCEL_PRODUTO_AGRICOLA_CADASTRO_RAPIDO_SUCCESS})
}

export default function* watchCancelProdutoAgricolaCadastroRapido() {
  yield takeLatest(CANCEL_PRODUTO_AGRICOLA_CADASTRO_RAPIDO, sagaCancelProdutoAgricolaCadastroRapido)
}