


import watchNewPatrimonioAbastecimentoCadastro from './saga/new';
import watchNewFilterPatrimonioAbastecimento from './saga/newFilter';
import watchNewFilterNextPagePatrimonioAbastecimento from './saga/newFilterNextPage';
import watchFindPatrimonioAbastecimentoCadastro from './saga/find';
import watchCreatePatrimonioAbastecimentoCadastro from './saga/create';
import watchListAllPatrimonioAbastecimentoCadastro from './saga/listAll';
import watchListPatrimonioAbastecimentoCadastro from './saga/listTable';
import watchDeletePatrimonioAbastecimentoCadastro from './saga/delete';
import watchCancelPatrimonioAbastecimentoCadastroRapido from './saga/cancel';

export const patrimonioAbastecimentoCadastroSaga = [
    watchNewPatrimonioAbastecimentoCadastro(),
    watchNewFilterPatrimonioAbastecimento(),
    watchNewFilterNextPagePatrimonioAbastecimento(),
    watchFindPatrimonioAbastecimentoCadastro(),
    watchCreatePatrimonioAbastecimentoCadastro(),
    watchListAllPatrimonioAbastecimentoCadastro(),
    watchListPatrimonioAbastecimentoCadastro(),
    watchDeletePatrimonioAbastecimentoCadastro(),
    watchCancelPatrimonioAbastecimentoCadastroRapido(),
]

export * from './actionTypes';
export * from './reducer';
export * from './service';
export * from './entity';
