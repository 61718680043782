import * as Yup from 'yup';

const newRegistro = {
    notaImportada: false,
    data: '',
    pessoa: null,
    naturezaOperacao: null,
    estoqueEntradaMovimentado: false,
    lancaProduto: false,
    movimentaEstoque: false,
    localEstoque: null,
    numero: '',
    serie: '',
    total: 0,
    totalItens: 0,
    totalDuplicatas: 0,
    obs: '',
    chaveAcessoNFe: '',
    vincularPatrimonio: false,
    patrimonio: null,
    entradaDup: {
        geraDuplicata: false,
        movimentaFinanceiro: false,
        tipoDuplicata: null,
        contaGerencial: null,
        historico: '',
        tipoPagamento: null,
        contaCaixa: null,
        contaBanco: null,
        numeroParcelas: null,
        competencia: '',
    },
    itens: [],
    parcelas: [],
    apropriarCustoSafra: false,
    despDireta: null,
    rateioSafras: []
};

const validation = Yup.object().shape({
    data: Yup.date()
        .required('Este campo é obrigatório'),
    numero: Yup.number()
        .typeError('Este campo é obrigatório')
        .required('Este campo é obrigatório'),
    pessoa: Yup.object()
        .typeError('Este campo é obrigatório')
        .shape({
            id: Yup.number()
                .typeError('Este campo é obrigatório')
                .required("Este campo é obrigatório"),
        }),
    naturezaOperacao: Yup.object()
        .typeError('Este campo é obrigatório')
        .shape({
            id: Yup.number()
                .typeError('Este campo é obrigatório')
                .required("Este campo é obrigatório"),
        }),
    total: Yup.number()
        .typeError('Este campo é obrigatório')
        .positive('Por Favor, informe um valor maior que zero')
        .required("Este campo é obrigatório"),
    entradaDup: Yup.object()
        .shape({
            historico: Yup.string()
                .min(2, 'O campo deve ter no mínimo 2 caracteres')
                .max(255, 'Campo deve ter menos do que 255 caracteres'),
        }),
    patrimonio: Yup.object().when('vincularPatrimonio', {
        is: (vincularPatrimonio) => vincularPatrimonio === true,
        then: Yup.object()
            .typeError('Este campo é obrigatório')
            .shape({
                id: Yup.number()
                    .typeError('Este campo é obrigatório')
                    .required("Este campo é obrigatório"),
            })
    }).nullable(),
    parcelas: Yup.array()
        .of(
            Yup.object().shape({
                dataVencimento: Yup.date()
                    .required('Este campo é obrigatório'),
                competencia: Yup.date()
                    .required('Este campo é obrigatório'),
                valorTotal: Yup.number()
                    .typeError('Este campo é obrigatório')
                    .positive('Por Favor, informe um valor maior que zero')
                    .required("Este campo é obrigatório"),
                historico: Yup.string()
                    .min(2, 'O campo deve ter no mínimo 2 caracteres')
                    .max(255, 'Campo deve ter menos do que 255 caracteres'),
            })
        ),
    obs: Yup.string()
        .max(5000, 'Campo deve ter menos do que 5000 caracteres'),
    localEstoque: Yup.object().when('movimentaEstoque', {
        is: (movimentaEstoque) => (
            movimentaEstoque === true),
        then: Yup.object()
            .typeError('Este campo é obrigatório')
            .shape({
                id: Yup.number()
                    .typeError('Este campo é obrigatório')
                    .required("Este campo é obrigatório"),
            }),
    }).nullable(),
    chaveAcessoNFe: Yup.string()
        .max(44, 'Campo deve ter menos do que 44 caracteres'),
    despDireta: Yup.object().when('apropriarCustoSafra', {
        is: (apropriarCustoSafra) => apropriarCustoSafra === true,
        then: Yup.object()
            .typeError('Este campo é obrigatório')
            .shape({
                id: Yup.number()
                    .typeError('Este campo é obrigatório')
                    .required("Este campo é obrigatório"),
            })
    }).nullable(),
    rateioSafras: Yup.array()
        .of(
            Yup.object().shape({
                safra: Yup.object()
                    .typeError('Este campo é obrigatório')
                    .shape({
                        id: Yup.number()
                            .typeError('Este campo é obrigatório')
                            .required("Este campo é obrigatório"),
                    }),
                percentualRateio: Yup.number()
                    .positive('Por Favor, informe um valor maior que zero')
                    .max(100, 'Máximo 100%')
                    .transform(value => (isNaN(value) ? 0.0 : value))
                    .required('Este campo é obrigatório'),
            })
        ),
});

export const entradaEstoque = {
    newRegistro,
    validation
}