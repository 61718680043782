import { httpServiceApiDfe } from '@commons/http.service.api.dfe';
import { pathApiDfe } from '@commons/path.api.dfe';
import { path, httpService } from '@commons';

///////////////////////////////////////////////////////////////////////////////
const argsApiDfe = { ...pathApiDfe };
const args = { ...path, path: '/estoque/entrada' };
///////////////////////////////////////////////////////////////////////////////

export function findAllNotasCompras(dominio, dataInicial, dataFinal, where) {
    return httpServiceApiDfe.get({ ...argsApiDfe, pathApiDfe: `notaEntrada?schemaAgrare=${dominio}&startDate=${dataInicial}&endDate=${dataFinal}${where}` });
}

export function getTotalNotas(dominio, dataInicial, dataFinal, where) {
    return httpServiceApiDfe.get({ ...argsApiDfe, pathApiDfe: `notaEntrada/totalNotas?schemaAgrare=${dominio}&startDate=${dataInicial}&endDate=${dataFinal}${where}` });
}

export function readXmlFile(notaEntradaApiDfe, naturezaOperacao, localEstoqueId, propriedade) {
    return httpService.post({ ...args, path: '/estoque/entrada/importarXmlApiDFe/' + naturezaOperacao.id + "/" + localEstoqueId + "/" + propriedade }, notaEntradaApiDfe);
}

export function visualizarDanfeXmlFile(idNota) {
    return httpService.getPdf({ ...args, path: `/estoque/entrada/gerarDanfeXmlApiDfe/${idNota}` });
}