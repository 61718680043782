import * as Yup from 'yup';

const newRegistro = {
    nome: '',
    categoria: null,
    status: true,
    culturaPerene: false,
    formaLancPlantio: null,
    formaLancColheita: null,
    unidadeColheita: null,
};

const validation = Yup.object().shape({
    nome: Yup.string()
        .min(2, 'O campo deve ter no mínimo 2 caracteres')
        .max(120, 'Campo deve ter menos do que 120 caracteres')
        .required('Este campo é obrigatório'),
    categoria: Yup.string()
        .typeError('Este campo é obrigatório')
        .required('Este campo é obrigatório'),
    formaLancPlantio: Yup.string()
        .typeError('Este campo é obrigatório')
        .required('Este campo é obrigatório'),
    formaLancColheita: Yup.string()
        .typeError('Este campo é obrigatório')
        .required('Este campo é obrigatório'),
    unidadeColheita: Yup.object()
        .typeError('Este campo é obrigatório')
        .shape({
            id: Yup.number()
                .typeError('Este campo é obrigatório')
                .required("Este campo é obrigatório"),
        }),
});

export const culturaCadastro = {
    newRegistro,
    validation
}