import { takeLatest, put } from 'redux-saga/effects';
import moment from 'moment';
import {
  patrimonioAbastecimentoCadastro,
  NEW_PATRIMONIO_ABASTECIMENTO_CADASTRO,
  NEW_PATRIMONIO_ABASTECIMENTO_CADASTRO_SUCCESS,
} from '@handler';

function* sagaNewPatrimonioAbastecimentoCadastro(action) {

  yield put({
    type: NEW_PATRIMONIO_ABASTECIMENTO_CADASTRO_SUCCESS,
    registro: {
      ...patrimonioAbastecimentoCadastro.newRegistro,
      dataAbastecimento: moment().startOf('day').format('YYYY-MM-DD'),
    },
    isCadastroRapido: action.isCadastroRapido,
    // status: true,
  });
}

export default function* watchNewPatrimonioAbastecimentoCadastro() {
  yield takeLatest(NEW_PATRIMONIO_ABASTECIMENTO_CADASTRO, sagaNewPatrimonioAbastecimentoCadastro)
}
