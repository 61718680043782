import watchNewTransferenciaEstoque from './saga/new';
import watchFindTransferenciaEstoque from './saga/find';
import watchCreateTransferenciaEstoque from './saga/create';
import watchListAllTransferenciaEstoque from './saga/listAll';
import watchListTransferenciaEstoque from './saga/listTable';
import watchDeleteTransferenciaEstoque from './saga/delete';
import watchNewFilterTransferenciaEstoque from './saga/newFilter';
import watchNewFilterNextPageTransferenciaEstoque from './saga/newFilterNextPage';

export const transferenciaEstoqueSaga = [
    watchNewTransferenciaEstoque(),
    watchFindTransferenciaEstoque(),
    watchCreateTransferenciaEstoque(),
    watchListAllTransferenciaEstoque(),
    watchListTransferenciaEstoque(),
    watchDeleteTransferenciaEstoque(),
    watchNewFilterTransferenciaEstoque(),
    watchNewFilterNextPageTransferenciaEstoque(),
]

export * from './actionTypes';
export * from './reducer';
export * from './service';
export * from './entity';
