import { path, httpService } from '@commons';

/////////////////////////////////////////////////////////////////////////////////
const args = { ...path, path: '/weather' };
/////////////////////////////////////////////////////////////////////////////////

export function getCurrentWeather(latitude, longitude) {
    return httpService.get({...args, args: `/current?latitude=${latitude}&longitude=${longitude}`});
}

export function getWeatherForecast(latitude, longitude) {
    return httpService.get({...args, args: `/forecast?latitude=${latitude}&longitude=${longitude}`});
}