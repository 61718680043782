import React, { useEffect, useState } from "react";
import { Divider } from "@material-ui/core";
import { Button } from "reactstrap";
import './styles.css';
import { decodePath, getPolygonCenter } from "../../utils/mapFunctions";
import AreaWindow from "../AreaWindow";
import { formatNumber } from "@components/common/format";
import IconTextInfoNoRegisterComponent from "@components/common/IconTextInfoNoRegisterComponent";

function AreasListWindow ({areas, areaSigla, isCadastro, setCenter, onClose}) {
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedArea, setSelectedArea] = useState(null);

    useEffect(() => {
    }, [areas]);

    const handleSearch = (event) => {
        setSearchTerm(event.target.value);
    };
    
    const filteredAreas = areas.filter((item) =>
        item.nome.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const renderCard = (item) => {
        return (
            item.realizaMapeamento && (
                <div 
                    className="card map-drawer-card flex-row align-items-center align-items-stretch border-0" 
                    key={`map-drawer-card-${item.id}`}
                    onClick={() => {
                        setCenter(getPolygonCenter(decodePath(item.mapaPolygonPath)));
                        if (!isCadastro) setSelectedArea(item);
                        // if (isMobile) {
                        //     setShowDrawer(false)
                        // };
                    }}
                >
                    <div className={`col-4 d-flex align-items-center bg-secondary justify-content-center rounded-left`}>
                        <em className={`fa fa-map fa-3x`} style={{ color: 'white' }} />
                    </div>
                    <div className="col-8 py-2 rounded-right">
                        <div className="h5 mt-0">{item.nome}</div>
                        <div className="area">{formatNumber(item.area, 2)} {' ' + areaSigla}</div>
                    </div>
                </div>
            )
        );
    }

    return (
        <>
            {!selectedArea ? (
                <div className="map-menu-window-container">
                    <div className="map-drawer-header">
                        <h4>{isCadastro ? 'Áreas Mapeadas' : 'Áreas Mapeadas da Safra'}</h4>
                        <Button className="btn btn-light" onClick={onClose}>
                            <i className='fa fa-arrow-left' />
                        </Button>
                    </div>
                    <Divider/>
                    <div className="scrollable-div">
                        <div className="map-search-container">
                            <input
                                type="text"
                                className="form-control"
                                placeholder={`Pesquisar mapeamento`}
                                value={searchTerm}
                                onChange={handleSearch}
                            />
                        </div>
        
                        <div>
                            { filteredAreas && filteredAreas.some(area => area.realizaMapeamento === true) ? (
                                filteredAreas.map((item) => (
                                    renderCard(item)
                                ))
                            ) : (
                                <IconTextInfoNoRegisterComponent title={`Nenhum mapeamento encontrado`} />
                            )}
                        </div>
                    </div>
                </div>
            ) : (
                <AreaWindow area={selectedArea} onClose={() => setSelectedArea(null)} setCenter={setCenter}/>
            )}
        </>
    );
}

export default React.memo(AreasListWindow);
