import { takeLatest, put, call } from 'redux-saga/effects';
import { storage } from '@commons/storage';


import {
    CONFIG_SESSION,
    TOTAIS_DASHBOARD_PATRIMONIO,
    TOTAIS_DASHBOARD_PATRIMONIO_PENDING,
    TOTAIS_DASHBOARD_PATRIMONIO_SUCCESS,
    TOTAIS_DASHBOARD_PATRIMONIO_FAILURE,
    GRAFICO_TOTAL_DESPESAS_CONTA_GERENCIAL_DASHBOARD_PATRIMONIO_PENDING,
    GRAFICO_TOTAL_DESPESAS_CONTA_GERENCIAL_DASHBOARD_PATRIMONIO_SUCCESS,
    GRAFICO_TOTAL_DESPESAS_CONTA_GERENCIAL_DASHBOARD_PATRIMONIO_FAILURE,
} from '@handler';
import { formatDateDDMMYYYY } from '@components/common/format';
import {
    getTotalPatrimonios, getTotalNroItens, getTotalHorasTrabalhadas, getTotalCustoDiretoPatrimonios,
    getTotalDespesasPatrimonios, getTotalDespesasEmAbertoPatrimonios, graficoTotalDespesasPatrimoniosPorContaGerencial,
    getTotalDespesasDashPatrimonio
} from '../service';
import { findPatrimonioCadastro } from '../../patrimonioCadastro/service';

function* sagaTotaisPatrimonio(action) {
    yield put({ type: TOTAIS_DASHBOARD_PATRIMONIO_PENDING })

    const idFazenda = JSON.parse(storage.get(CONFIG_SESSION)).fazendaSelecionada === null ? -1 : JSON.parse(storage.get(CONFIG_SESSION)).fazendaSelecionada.id;
    const idSafra = (JSON.parse(storage.get(CONFIG_SESSION)).safraAtiva === undefined || JSON.parse(storage.get(CONFIG_SESSION)).safraAtiva === null) ? -1 : JSON.parse(storage.get(CONFIG_SESSION)).safraAtiva.id;

    var whereSafra = '&safra=' + idSafra;
    var wherePatrimonio = '';
    if (action.patrimonio !== undefined && action.patrimonio !== null) {
        wherePatrimonio = '&patrimonio=' + action.patrimonio.id;
    }

    try {
        const totalHorasTrabalhadasNaFazenda = yield call(getTotalHorasTrabalhadas,
            idFazenda,
            formatDateDDMMYYYY(action.dataInicial),
            formatDateDDMMYYYY(action.dataFinal),
            wherePatrimonio);

        const totalHorasTrabalhadasNaSafra = yield call(getTotalHorasTrabalhadas,
            idFazenda,
            formatDateDDMMYYYY(action.dataInicial),
            formatDateDDMMYYYY(action.dataFinal),
            `${whereSafra}${wherePatrimonio}`);

        const totalDespesasDashPatrimonio = yield call(getTotalDespesasDashPatrimonio,
            idFazenda,
            formatDateDDMMYYYY(action.dataInicial),
            formatDateDDMMYYYY(action.dataFinal),
            `${whereSafra}${wherePatrimonio}`);

        const totalCustoDiretoPatrimonios = yield call(getTotalCustoDiretoPatrimonios,
            idFazenda,
            formatDateDDMMYYYY(action.dataInicial),
            formatDateDDMMYYYY(action.dataFinal),
            wherePatrimonio);

        const totalCustoDiretoPatrimoniosNaSafra = yield call(getTotalCustoDiretoPatrimonios,
            idFazenda,
            formatDateDDMMYYYY(action.dataInicial),
            formatDateDDMMYYYY(action.dataFinal),
            `${whereSafra}${wherePatrimonio}`);

        const totalDespesasPatrimonios = yield call(getTotalDespesasPatrimonios,
            idFazenda,
            formatDateDDMMYYYY(action.dataInicial),
            formatDateDDMMYYYY(action.dataFinal),
            wherePatrimonio);

        const totalDespesasEmAbertoPatrimonios = yield call(getTotalDespesasEmAbertoPatrimonios,
            idFazenda,
            formatDateDDMMYYYY(action.dataInicial),
            formatDateDDMMYYYY(action.dataFinal),
            wherePatrimonio);

        if (action.patrimonio !== undefined && action.patrimonio !== null) {
            const patrimonio = yield call(findPatrimonioCadastro, action.patrimonio.id);

            yield put({
                type: TOTAIS_DASHBOARD_PATRIMONIO_SUCCESS, filter: {
                    todosPatrimonios: false,
                    patrimonio: patrimonio,
                    totais: {
                        "totalPatrimonios": 0,
                        "totalNroItens": 0,
                        "totalHorasTrabalhadasNaFazenda": totalHorasTrabalhadasNaFazenda,
                        "totalHorasTrabalhadasNaSafra": totalHorasTrabalhadasNaSafra,
                        "totalCustoDiretoPatrimonios": totalCustoDiretoPatrimonios,
                        "totalCustoDiretoPatrimoniosNaSafra": totalCustoDiretoPatrimoniosNaSafra,
                        "totalDespesasPatrimonios": totalDespesasPatrimonios,
                        "totalDespesasEmAbertoPatrimonios": totalDespesasEmAbertoPatrimonios,
                        "totalManutencao": totalDespesasDashPatrimonio.totalManutencao,
                        "totalAbastecimento": totalDespesasDashPatrimonio.totalAbastecimento,
                    }
                }
            })
        } else {
            const totalPatrimonios = yield call(getTotalPatrimonios);
            const totalNroItens = yield call(getTotalNroItens);

            yield put({
                type: TOTAIS_DASHBOARD_PATRIMONIO_SUCCESS, filter: {
                    todosPatrimonios: true,
                    patrimonio: null,
                    totais: {
                        "totalPatrimonios": totalPatrimonios,
                        "totalNroItens": totalNroItens,
                        "totalHorasTrabalhadasNaFazenda": totalHorasTrabalhadasNaFazenda,
                        "totalHorasTrabalhadasNaSafra": totalHorasTrabalhadasNaSafra,
                        "totalCustoDiretoPatrimonios": totalCustoDiretoPatrimonios,
                        "totalCustoDiretoPatrimoniosNaSafra": totalCustoDiretoPatrimoniosNaSafra,
                        "totalDespesasPatrimonios": totalDespesasPatrimonios,
                        "totalDespesasEmAbertoPatrimonios": totalDespesasEmAbertoPatrimonios,
                        "totalManutencao": totalDespesasDashPatrimonio.totalManutencao,
                        "totalAbastecimento": totalDespesasDashPatrimonio.totalAbastecimento,
                    }
                }
            })
        }
    } catch (error) {
        yield put({ type: TOTAIS_DASHBOARD_PATRIMONIO_FAILURE })

    }

    // GET GRAFICO DESPESA POR CONTA GERENCIAL

    yield put({ type: GRAFICO_TOTAL_DESPESAS_CONTA_GERENCIAL_DASHBOARD_PATRIMONIO_PENDING })

    try {
        const dadosGraficoTotalDespesasPatrimoniosPorContaGerencial = yield call(graficoTotalDespesasPatrimoniosPorContaGerencial,
            idFazenda,
            formatDateDDMMYYYY(action.dataInicial),
            formatDateDDMMYYYY(action.dataFinal),
            wherePatrimonio);

        let chartData = [['Conta Gerencial', 'Total']];
        for (let i = 0; i < dadosGraficoTotalDespesasPatrimoniosPorContaGerencial.length; i += 1) {
            chartData.push([dadosGraficoTotalDespesasPatrimoniosPorContaGerencial[i].text, dadosGraficoTotalDespesasPatrimoniosPorContaGerencial[i].value])
        }

        yield put({ type: GRAFICO_TOTAL_DESPESAS_CONTA_GERENCIAL_DASHBOARD_PATRIMONIO_SUCCESS, dadosGraficoTotalDespesasPatrimoniosPorContaGerencial: chartData })

    } catch (error) {
        yield put({ type: GRAFICO_TOTAL_DESPESAS_CONTA_GERENCIAL_DASHBOARD_PATRIMONIO_FAILURE })

    }

}

export default function* watchTotaisPatrimonio() {
    yield takeLatest(TOTAIS_DASHBOARD_PATRIMONIO, sagaTotaisPatrimonio)
}
