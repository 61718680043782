import { takeLatest, put, call } from 'redux-saga/effects';

import { filterTransferenciaEstoque } from '../service';
import { storage } from '@commons/storage';
import {
  NEW_FILTER_NEXT_PAGE_TRANSFERENCIA_ESTOQUE,
  NEW_FILTER_NEXT_PAGE_TRANSFERENCIA_ESTOQUE_PENDING,
  NEW_FILTER_NEXT_PAGE_TRANSFERENCIA_ESTOQUE_SUCCESS,
  NEW_FILTER_NEXT_PAGE_TRANSFERENCIA_ESTOQUE_FAILURE,
} from '../actionTypes';
import { CONFIG_SESSION } from '@handler/common/startSession';
import { formatDateDDMMYYYY } from '@components/common/format';
import { CONST_FILTER_TRANSFERENCIA_ESTOQUE } from '@commons/consts';

function* sagaNewFilterNextPageTransferenciaEstoque(action) {
  yield put({ type: NEW_FILTER_NEXT_PAGE_TRANSFERENCIA_ESTOQUE_PENDING });

  try {
    const idFazenda = JSON.parse(storage.get(CONFIG_SESSION)).fazendaSelecionada.id;

    let filterSaved = sessionStorage.getItem(CONST_FILTER_TRANSFERENCIA_ESTOQUE) !== null ? JSON.parse(sessionStorage.getItem(CONST_FILTER_TRANSFERENCIA_ESTOQUE)) : null;

    const datas = formatDateDDMMYYYY(filterSaved.dataInicial) + '_' + formatDateDDMMYYYY(filterSaved.dataFinal);

    let newFilter = {
      ...filterSaved,
      rows: action.rows,
      page: action.page,
    };

    const list = yield call(filterTransferenciaEstoque, idFazenda, datas, `&page=${newFilter.page}&size=${newFilter.rows}${newFilter.where}`);

    sessionStorage.setItem(CONST_FILTER_TRANSFERENCIA_ESTOQUE, JSON.stringify(newFilter));

    yield put({
      type: NEW_FILTER_NEXT_PAGE_TRANSFERENCIA_ESTOQUE_SUCCESS, list: list, filter: newFilter
    });
  } catch (error) {
    yield put({ type: NEW_FILTER_NEXT_PAGE_TRANSFERENCIA_ESTOQUE_FAILURE })
  }
}

export default function* watchNewFilterNextPageTransferenciaEstoque() {
  yield takeLatest(NEW_FILTER_NEXT_PAGE_TRANSFERENCIA_ESTOQUE, sagaNewFilterNextPageTransferenciaEstoque)
}