import { path, httpService } from '@commons';

/////////////////////////////////////////////////////////////////////////////////
const args = { ...path, path: '/userNotification' };
/////////////////////////////////////////////////////////////////////////////////

export function listNotification(userId, scheme, idPropriedade, where) {
  return httpService.getApiManager(
    `/userNotification/user/${userId}?scheme=${scheme}&propriedade=${idPropriedade}${where}`,
    true
  );
}

export function findNotification(id) {
  return httpService.getApiManager(
    `/userNotification/details/${id}`
    , true
  );
}

export function filterNotification(userId, idPropriedade, scheme, datas, where) {
  return httpService.getApiManager(
    `/userNotification/user/${userId}?scheme=${scheme}&propriedade=${idPropriedade}&datas=${datas}${where}`
    , true
  );
}

export function markNotificationAsRead(userNotificationIdList) {
  return httpService.postApiManager(`/userNotification/mark-as-read`, userNotificationIdList);
}

export function markNotificationAllAsRead(userId, schema) {
  const payload = {
    userId: userId,
    schema: schema
  };

  return httpService.putApiManager(`/userNotification/mark-all-as-read`, payload);
}
