
export const NEW_FILTER_NOTIFICATION = 'NEW_FILTER_NOTIFICATION'
export const NEW_FILTER_NOTIFICATION_PEDING = 'NEW_FILTER_NOTIFICATION_PEDING'
export const NEW_FILTER_NOTIFICATION_SUCCESS = 'NEW_FILTER_NOTIFICATION_SUCCESS'
export const NEW_FILTER_NOTIFICATION_FAILURE = 'NEW_FILTER_NOTIFICATION_FAILURE'

export const NEW_FILTER_NEXT_PAGE_NOTIFICATION = 'NEW_FILTER_NEXT_PAGE_NOTIFICATION'
export const NEW_FILTER_NEXT_PAGE_NOTIFICATION_PENDING = 'NEW_FILTER_NEXT_PAGE_NOTIFICATION_PENDING'
export const NEW_FILTER_NEXT_PAGE_NOTIFICATION_SUCCESS = 'NEW_FILTER_NEXT_PAGE_NOTIFICATION_SUCCESS'
export const NEW_FILTER_NEXT_PAGE_NOTIFICATION_FAILURE = 'NEW_FILTER_NEXT_PAGE_NOTIFICATION_FAILURE'

export const TABLE_NOTIFICATION = 'TABLE_NOTIFICATION';
export const TABLE_NOTIFICATION_PENDING = 'TABLE_NOTIFICATION_PENDING';
export const TABLE_NOTIFICATION_SUCCESS = 'TABLE_NOTIFICATION_SUCCESS';
export const TABLE_NOTIFICATION_FAILURE = 'TABLE_NOTIFICATION_FAILURE';

export const LIST_NOTIFICATION = 'LIST_NOTIFICATION';
export const LIST_NOTIFICATION_PENDING = 'LIST_NOTIFICATION_PENDING';
export const LIST_NOTIFICATION_SUCCESS = 'LIST_NOTIFICATION_SUCCESS';
export const LIST_NOTIFICATION_FAILURE = 'LIST_NOTIFICATION_FAILURE';

export const FIND_NOTIFICATION = 'FIND_NOTIFICATION';
export const FIND_NOTIFICATION_PENDING = 'FIND_NOTIFICATION_PENDING';
export const FIND_NOTIFICATION_SUCCESS = 'FIND_NOTIFICATION_SUCCESS';
export const FIND_NOTIFICATION_FAILURE = 'FIND_NOTIFICATION_FAILURE';

export const SELECT_NOTIFICATION = 'SELECT_NOTIFICATION';
export const SELECT_NOTIFICATION_PENDING = 'SELECT_NOTIFICATION_PENDING';
export const SELECT_NOTIFICATION_SUCCESS = 'SELECT_NOTIFICATION_SUCCESS';
export const SELECT_NOTIFICATION_FAILURE = 'SELECT_NOTIFICATION_FAILURE';

export const MARK_NOTIFICATION_AS_READ = 'MARK_NOTIFICATION_AS_READ';
export const MARK_NOTIFICATION_AS_READ_PENDING = 'MARK_NOTIFICATION_AS_READ_PENDING';
export const MARK_NOTIFICATION_AS_READ_SUCCESS = 'MARK_NOTIFICATION_AS_READ_SUCCESS';
export const MARK_NOTIFICATION_AS_READ_FAILURE = 'MARK_NOTIFICATION_AS_READ_FAILURE';

export const MARK_NOTIFICATION_ALL_AS_READ = 'MARK_NOTIFICATION_ALL_AS_READ';
export const MARK_NOTIFICATION_ALL_AS_READ_PENDING = 'MARK_NOTIFICATION_ALL_AS_READ_PENDING';
export const MARK_NOTIFICATION_ALL_AS_READ_SUCCESS = 'MARK_NOTIFICATION_ALL_AS_READ_SUCCESS';
export const MARK_NOTIFICATION_ALL_AS_READ_FAILURE = 'MARK_NOTIFICATION_ALL_AS_READ_FAILURE';

export const CLEAR_NOTIFICATION_DATA = 'CLEAR_NOTIFICATION_DATA';
