export const PATRIMONIO_MANUTENCAO_TYPE_ENUM = [
    {
        id: 'PREVENTIVA',
        descricao: "Preventiva",
        idDB: 0
    },
    {
        id: 'CORRETIVA',
        descricao: "Corretiva",
        idDB: 1
    }
];
