import { takeLatest, put, call } from 'redux-saga/effects';

import { findNotification } from '../service';
import {
  FIND_NOTIFICATION,
  FIND_NOTIFICATION_PENDING,
  FIND_NOTIFICATION_SUCCESS,
  FIND_NOTIFICATION_FAILURE
} from '../actionTypes';

function* sagaFindNotification(action) {
  yield put({ type: FIND_NOTIFICATION_PENDING, id: action.id })

  try {
    const registro = yield call(findNotification, action.id);
    
    yield put({ type: FIND_NOTIFICATION_SUCCESS, registro: registro })
  } catch (error) {
    yield put({ type: FIND_NOTIFICATION_FAILURE })
  }
}

export default function* watchFindNotification() {
  yield takeLatest(FIND_NOTIFICATION, sagaFindNotification)
}
