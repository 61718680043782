import { takeLatest, put, call } from 'redux-saga/effects';
import { storage } from '@commons/storage';

import {
  CONFIG_SESSION,
  NEW_FILTER_CONTA_RECEBER,
  NEW_FILTER_CONTA_RECEBER_PEDING,
  NEW_FILTER_CONTA_RECEBER_SUCCESS,
  GET_TOTAIS_CONTA_RECEBER_PENDING,
  GET_TOTAIS_CONTA_RECEBER_SUCCESS,
  NEW_FILTER_CONTA_RECEBER_SUCCESS_PDF,
  NEW_FILTER_CONTA_RECEBER_FAILURE,
  GET_TOTAIS_CONTA_RECEBER_FAILURE
} from '@handler';
import { formatDateDDMMYYYY, formatDateYYYYMMDD } from '@components/common/format';
import { getLancamentos, getTotaisContasReceber, getLancamentosExcel, getLancamentosPdf, getPagamentosExcel, getPagamentosPdf, filterPagamentos } from '../service';
import { CONST_FILTER_CONTAS_RECEBER, CONST_ROWS_PER_PAGE } from '@commons/consts';

function* sagaNewFilterContaReceber(action) {
  try {
    yield put({ type: NEW_FILTER_CONTA_RECEBER_PEDING });

    const idFazenda = JSON.parse(storage.get(CONFIG_SESSION)).fazendaSelecionada.id;
    let datas = '';
    let dataInicial = '';
    let dataFinal = '';
    var date = new Date();
    let where = '';
    let newFilter = {
      tipoStatusSituacao: 'A_PAGAR',
      tipoEntidade: 'PESSOA',
      pessoa: null,
      motorista: null,
      funcionario: null,
      nomeEntidadeOutros: '',
      contaGerencial: null,
      historico: null,
      numeroDoc: null,
      identificador: null,
      valor: 0,
      dataInicial: '',
      dataFinal: '',
      rows: CONST_ROWS_PER_PAGE,
      page: 0,
      where: ''
    };

    let filterSaved = sessionStorage.getItem(CONST_FILTER_CONTAS_RECEBER) !== null ? JSON.parse(sessionStorage.getItem(CONST_FILTER_CONTAS_RECEBER)) : null;

    if (filterSaved === null || filterSaved === undefined) {
      // seta data do mes na primeira vez que entra na pagina
      dataInicial = formatDateYYYYMMDD(new Date(date.getFullYear(), date.getMonth(), 1));
      dataFinal = formatDateYYYYMMDD(new Date(date.getFullYear(), date.getMonth() + 1, 0));

      datas = formatDateDDMMYYYY(dataInicial) + '_' + formatDateDDMMYYYY(dataFinal);
      where = '&duplicataPaga=false';

      newFilter = {
        ...newFilter,
        dataInicial,
        dataFinal,
        where: where
      };
    } else {
      // se seta filtro manual
      if (action.newFilter) {
        // seta filtro indicado
        // typePeriodo = 0 -> periodo manual
        // typePeriodo = 1 -> hoje
        // typePeriodo = 2 -> esta semana
        // typePeriodo = 3 -> mes passado
        // typePeriodo = 4 -> este mes
        // typePeriodo = 5 -> proximo mes
        // typePeriodo = 6 -> este ano
        if (action.typePeriodo === undefined || action.typePeriodo === 0) {
          dataInicial = action.args.dataInicial;
          dataFinal = action.args.dataFinal;

          datas = formatDateDDMMYYYY(action.args.dataInicial) + '_' + formatDateDDMMYYYY(action.args.dataFinal);
        } else if (action.typePeriodo === 1) {
          dataInicial = formatDateYYYYMMDD(new Date(date));
          dataFinal = formatDateYYYYMMDD(new Date(date));

          datas = formatDateDDMMYYYY(dataInicial) + '_' + formatDateDDMMYYYY(dataFinal);
        }
        else if (action.typePeriodo === 2) {
          const primeiro = date.getDate() - date.getDay();

          dataInicial = formatDateYYYYMMDD(new Date(date.getFullYear(), date.getMonth(), primeiro));
          dataFinal = formatDateYYYYMMDD(new Date(date.getFullYear(), date.getMonth(), primeiro + 6));

          datas = formatDateDDMMYYYY(dataInicial) + '_' + formatDateDDMMYYYY(dataFinal);
        } else if (action.typePeriodo === 3) {
          dataInicial = formatDateYYYYMMDD(new Date(date.getFullYear(), date.getMonth() - 1, 1));
          dataFinal = formatDateYYYYMMDD(new Date(date.getFullYear(), date.getMonth(), 0));

          datas = formatDateDDMMYYYY(dataInicial) + '_' + formatDateDDMMYYYY(dataFinal);
        } else if (action.typePeriodo === 4) {
          dataInicial = formatDateYYYYMMDD(new Date(date.getFullYear(), date.getMonth(), 1));
          dataFinal = formatDateYYYYMMDD(new Date(date.getFullYear(), date.getMonth() + 1, 0));

          datas = formatDateDDMMYYYY(dataInicial) + '_' + formatDateDDMMYYYY(dataFinal);
        } else if (action.typePeriodo === 5) {
          dataInicial = formatDateYYYYMMDD(new Date(date.getFullYear(), date.getMonth() + 1, 1));
          dataFinal = formatDateYYYYMMDD(new Date(date.getFullYear(), date.getMonth() + 2, 0));

          datas = formatDateDDMMYYYY(dataInicial) + '_' + formatDateDDMMYYYY(dataFinal);
        } else if (action.typePeriodo === 6) {
          dataInicial = formatDateYYYYMMDD(new Date(date.getFullYear(), 0, 1));
          dataFinal = formatDateYYYYMMDD(new Date(date.getFullYear(), 12, 0));

          datas = formatDateDDMMYYYY(dataInicial) + '_' + formatDateDDMMYYYY(dataFinal);
        }

        //situacao duplicata
        if (action.args.tipoStatusSituacao !== undefined) {
          if (action.args.tipoStatusSituacao === 'A_PAGAR') {
            where = '&duplicataPaga=false';
          } else
            if (action.args.tipoStatusSituacao === 'PAGAS') {
              where = '&duplicataPaga=true';
            }
        }
        if (action.args.tipoEntidade !== null && action.args.tipoEntidade !== undefined && action.args.tipoEntidade !== '') {
          if (action.args.tipoEntidade === 'PESSOA') {
            if (action.args.pessoa !== null && action.args.pessoa !== undefined) {
              where = where + '&tipoEntidade=PESSOA&pessoa=' + action.args.pessoa.id;
            }
          }
          if (action.args.tipoEntidade === 'FUNCIONARIO') {
            if (action.args.funcionario !== null && action.args.funcionario !== undefined) {
              where = where + '&tipoEntidade=FUNCIONARIO&funcionario=' + action.args.funcionario.id;
            }
          }
          if (action.args.tipoEntidade === 'MOTORISTA') {
            if (action.args.motorista !== null && action.args.motorista !== undefined) {
              where = where + '&tipoEntidade=MOTORISTA&motorista=' + action.args.motorista.id;
            }
          }
          if (action.args.tipoEntidade === 'OUTROS') {
            if (action.args.nomeEntidadeOutros !== null && action.args.nomeEntidadeOutros !== undefined && action.args.nomeEntidadeOutros !== '') {
              where = where + '&tipoEntidade=OUTROS&nomeEntidadeOutros=' + action.args.nomeEntidadeOutros;
            }
          }
        } else {
          where = where + '&tipoEntidade=PESSOA';
        }
        if (action.args.contaGerencial !== null && action.args.contaGerencial !== undefined) {
          where = where + '&contaGerencial=' + action.args.contaGerencial.id;
        }
        if (action.args.historico !== null && action.args.historico !== undefined && action.args.historico !== '') {
          where = where + '&historico=' + action.args.historico;
        }
        if (action.args.numeroDoc !== null && action.args.numeroDoc !== undefined && action.args.numeroDoc !== '') {
          where = where + '&numeroDoc=' + action.args.numeroDoc;
        }
        if (action.args.valor !== null && action.args.valor !== undefined && action.args.valor !== '' && action.args.valor !== 0) {
          where = where + '&valor=' + action.args.valor;
        }
        if (action.args.identificador !== null && action.args.identificador !== undefined) {
          where = where + '&identificador=' + action.args.identificador.id;
        }

        newFilter = {
          ...newFilter,
          ...action.args,
          dataInicial,
          dataFinal,
          where: where
        };
      } else {
        // busca filtro salvo
        datas = formatDateDDMMYYYY(filterSaved.dataInicial) + '_' + formatDateDDMMYYYY(filterSaved.dataFinal);

        if (filterSaved.tipoStatusSituacao !== undefined) {
          if (filterSaved.tipoStatusSituacao === 'A_PAGAR') {
            where = '&duplicataPaga=false';
          } else
            if (filterSaved.tipoStatusSituacao === 'PAGAS') {
              where = '&duplicataPaga=true';
            }
        }
        if (filterSaved.tipoEntidade !== null && filterSaved.tipoEntidade !== undefined && filterSaved.tipoEntidade !== '') {
          if (filterSaved.tipoEntidade === 'PESSOA') {
            if (filterSaved.pessoa !== null && filterSaved.pessoa !== undefined) {
              where = where + '&tipoEntidade=PESSOA&pessoa=' + filterSaved.pessoa.id;
            }
          }
          if (filterSaved.tipoEntidade === 'FUNCIONARIO') {
            if (filterSaved.funcionario !== null && filterSaved.funcionario !== undefined) {
              where = where + '&tipoEntidade=FUNCIONARIO&funcionario=' + filterSaved.funcionario.id;
            }
          }
          if (filterSaved.tipoEntidade === 'MOTORISTA') {
            if (filterSaved.motorista !== null && filterSaved.motorista !== undefined) {
              where = where + '&tipoEntidade=MOTORISTA&motorista=' + filterSaved.motorista.id;
            }
          }
          if (filterSaved.tipoEntidade === 'OUTROS') {
            if (filterSaved.nomeEntidadeOutros !== null && filterSaved.nomeEntidadeOutros !== undefined && filterSaved.nomeEntidadeOutros !== '') {
              where = where + '&tipoEntidade=OUTROS&nomeEntidadeOutros=' + filterSaved.nomeEntidadeOutros;
            }
          }
        } else {
          where = where + '&tipoEntidade=PESSOA';
        }
        if (filterSaved.contaGerencial !== null && filterSaved.contaGerencial !== undefined) {
          where = where + '&contaGerencial=' + filterSaved.contaGerencial.id;
        }
        if (filterSaved.historico !== null && filterSaved.historico !== undefined && filterSaved.historico !== '') {
          where = where + '&historico=' + filterSaved.historico;
        }
        if (filterSaved.numeroDoc !== null && filterSaved.numeroDoc !== undefined && filterSaved.numeroDoc !== '') {
          where = where + '&numeroDoc=' + filterSaved.numeroDoc;
        }
        if (filterSaved.valor !== null && filterSaved.valor !== undefined && filterSaved.valor !== '' && filterSaved.valor !== 0) {
          where = where + '&valor=' + filterSaved.valor;
        }
        if (filterSaved.identificador !== null && filterSaved.identificador !== undefined) {
          where = where + '&identificador=' + filterSaved.identificador.id;
        }

        newFilter = {
          ...filterSaved,
          rows: CONST_ROWS_PER_PAGE,
          page: 0,
          where: where
        };
      }
    }

    if ((action.pdf === undefined || !action.pdf) && (action.excel === undefined || !action.excel)) {
      // busca registros
      let list = null;
      if (newFilter.tipoStatusSituacao === 'PAGAMENTOS') {
        list = yield call(filterPagamentos, idFazenda, datas, `&page=0&size=${CONST_ROWS_PER_PAGE}${where}`);
      } else {
        list = yield call(getLancamentos, idFazenda, datas, `&page=0&size=${CONST_ROWS_PER_PAGE}${where}`);
      }

      sessionStorage.setItem(CONST_FILTER_CONTAS_RECEBER, JSON.stringify(newFilter));

      yield put({
        type: NEW_FILTER_CONTA_RECEBER_SUCCESS, list: list, filter: newFilter
      });

      // busca totais
      yield put({ type: GET_TOTAIS_CONTA_RECEBER_PENDING })

      var totais = null;
      if (newFilter.tipoStatusSituacao === 'PAGAMENTOS') {
        totais = yield call(getTotaisContasReceber, idFazenda, datas, where, true);
      } else {
        totais = yield call(getTotaisContasReceber, idFazenda, datas, where, false);
      }

      yield put({
        type: GET_TOTAIS_CONTA_RECEBER_SUCCESS, totais: totais
      });
    } else {
      if (action.pdf === undefined || !action.pdf) {
        // gera excel
        if (newFilter.tipoStatusSituacao === 'PAGAMENTOS') {
          yield call(getPagamentosExcel, idFazenda, datas, where)
        } else {
          yield call(getLancamentosExcel, idFazenda, datas, where)
        }
      } else {
        // gera pdf
        if (newFilter.tipoStatusSituacao === 'PAGAMENTOS') {
          yield call(getPagamentosPdf, idFazenda, datas, where)
        } else {
          yield call(getLancamentosPdf, idFazenda, datas, where)
        }
      }

      yield put({
        type: NEW_FILTER_CONTA_RECEBER_SUCCESS_PDF
      })

    }
  } catch (_) {
    yield put({ type: NEW_FILTER_CONTA_RECEBER_FAILURE })
    yield put({ type: GET_TOTAIS_CONTA_RECEBER_FAILURE })
  }
}

export default function* watchNewFilterContaReceber() {
  yield takeLatest(NEW_FILTER_CONTA_RECEBER, sagaNewFilterContaReceber)
}
