import { takeLatest, put, call } from 'redux-saga/effects';

import {
  SELECT_PRODUCT_ITEM_XML_ENTRADA_ESTOQUE,
  SELECT_PRODUCT_ITEM_XML_ENTRADA_ESTOQUE_PENDING,
  SELECT_PRODUCT_ITEM_XML_ENTRADA_ESTOQUE_SUCCESS,
  SELECT_PRODUCT_ITEM_XML_ENTRADA_ESTOQUE_FAILURE
} from '../../actionTypes';
import { AlertError } from '@components/common/AlertToast';
import { findConversao, findProdutoInsumoCadastro } from '@handler';

function* sagaSelectProductItemXmlEntradaEstoque(action) {
  yield put({ type: SELECT_PRODUCT_ITEM_XML_ENTRADA_ESTOQUE_PENDING })

  try {
    const produto = yield call(findProdutoInsumoCadastro, action.produto.id);

    action.data.itens[action.itemNf].qtdConversao = 1;
    if (produto.controlaEstoque) {
      const possuiConversao = yield call(findConversao, produto.unidadeCompra, produto.unidadeEstoque);
      if (possuiConversao !== undefined && possuiConversao !== null && possuiConversao !== '') {
        action.data.itens[action.itemNf].qtdConversao = possuiConversao.qtdConversao;
      }
    }

    action.data.itens[action.itemNf].xmlCompraProdutoSelecionado = true;
    action.data.itens[action.itemNf].produto = produto;
    action.data.itens[action.itemNf].unidadeEstoque = produto.unidadeEstoque;
    action.data.itens[action.itemNf].unidadeCompra = produto.unidadeCompra;

    action.data.itens[action.itemNf].possuiConversaoCompra = false;
    action.data.itens[action.itemNf].tipoEmbalagem = null;
    action.data.itens[action.itemNf].tipoEmbalagemSigla = null;
    action.data.itens[action.itemNf].qtdeEmbalagem = null;

    if (action.vinculoFornecedor === null) {
      for (let index = 0; index < produto.produtoInsumoFornecedores.length; index++) {
        const prodForn = produto.produtoInsumoFornecedores[index];

        if (prodForn.fornecedor.id === action.data.pessoa.id) {
          action.data.itens[action.itemNf].possuiConversaoCompra = prodForn.possuiConversaoCompra ?? false;
          if (prodForn.possuiConversaoCompra) {
            action.data.itens[action.itemNf].tipoEmbalagem = prodForn.tipoEmbalagem;
            action.data.itens[action.itemNf].tipoEmbalagemSigla = prodForn.tipoEmbalagem.sigla;
            action.data.itens[action.itemNf].qtdeEmbalagem = 1;

            const possuiConversaoEmb = yield call(findConversao, prodForn.tipoEmbalagem, produto.unidadeCompra);
            if (possuiConversaoEmb !== undefined && possuiConversaoEmb !== null && possuiConversaoEmb !== '') {
              action.data.itens[action.itemNf].qtdeEmbalagem = possuiConversaoEmb.qtdConversao;
            }
          } else {
            action.data.itens[action.itemNf].tipoEmbalagem = null;
            action.data.itens[action.itemNf].tipoEmbalagemSigla = null;
            action.data.itens[action.itemNf].qtdeEmbalagem = null;
          }
        }
      }
    } else {
      action.data.itens[action.itemNf].possuiConversaoCompra = action.vinculoFornecedor.possuiConversaoCompra ?? false;
      if (action.vinculoFornecedor.possuiConversaoCompra) {
        action.data.itens[action.itemNf].tipoEmbalagem = action.vinculoFornecedor.tipoEmbalagem;
        action.data.itens[action.itemNf].tipoEmbalagemSigla = action.vinculoFornecedor.tipoEmbalagem.sigla;
        action.data.itens[action.itemNf].qtdeEmbalagem = 1;

        const possuiConversaoEmb = yield call(findConversao, action.vinculoFornecedor.tipoEmbalagem, produto.unidadeCompra);
        if (possuiConversaoEmb !== undefined && possuiConversaoEmb !== null && possuiConversaoEmb !== '') {
          action.data.itens[action.itemNf].qtdeEmbalagem = possuiConversaoEmb.qtdConversao;
        }
      } else {
        action.data.itens[action.itemNf].tipoEmbalagem = null;
        action.data.itens[action.itemNf].tipoEmbalagemSigla = null;
        action.data.itens[action.itemNf].qtdeEmbalagem = null;
      }
    }

    action.data.itens[action.itemNf].movimentaEstoque = produto.controlaEstoque;

    if (action.data.itens[action.itemNf].possuiConversaoCompra) {
      action.data.itens[action.itemNf].quantidadeNotaConv = action.data.itens[action.itemNf].quantidadeNota * action.data.itens[action.itemNf].qtdeEmbalagem;
      action.data.itens[action.itemNf].valorUnitario = Number((action.data.itens[action.itemNf].valorUnitarioNota / action.data.itens[action.itemNf].qtdeEmbalagem).toFixed(4));
    } else {
      action.data.itens[action.itemNf].quantidadeNotaConv = action.data.itens[action.itemNf].quantidadeNota;
      action.data.itens[action.itemNf].valorUnitario = action.data.itens[action.itemNf].valorUnitarioNota;
    }

    action.data.itens[action.itemNf].quantidade = action.data.itens[action.itemNf].quantidadeNotaConv * action.data.itens[action.itemNf].qtdConversao;

    yield put({ type: SELECT_PRODUCT_ITEM_XML_ENTRADA_ESTOQUE_SUCCESS, xmlReaded: action.data })

  } catch (error) {
    yield put({ type: SELECT_PRODUCT_ITEM_XML_ENTRADA_ESTOQUE_FAILURE })

    AlertError('Falha ao selecionar produto! Tente novamente...');

  }
}

export default function* watchSelectProductItemXmlEntradaEstoque() {
  yield takeLatest(SELECT_PRODUCT_ITEM_XML_ENTRADA_ESTOQUE, sagaSelectProductItemXmlEntradaEstoque)
}
