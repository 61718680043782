export function formatNumber(value, decimal, autoDecimal = false) {
    // Verifica se o valor é um número e converte valores em notação científica para decimal completo
    let parsedValue = (typeof value === 'number' && value.toString().includes('e'))
        ? Number(value).toFixed(20).replace(/\.?0+$/, '') // Remove zeros à direita
        : value;

    // Se autoDecimal estiver ativado, ajusta o número de casas decimais com base no valor
    if (autoDecimal) {
        const stringValue = parsedValue.toString();
        const decimalPart = stringValue.split('.')[1];

        if (decimal === undefined) {
            decimal = decimalPart ? decimalPart.length : 2;
        } else {
            if (decimalPart) {
                decimal = decimalPart.length < 2 ? 2 : Math.min(decimalPart.length, decimal);
            } else {
                decimal = 2;
            }
        }
    } else {
        // Se autoDecimal não estiver ativado e decimal for indefinido, usa 2 casas decimais
        if (decimal === undefined) {
            decimal = 2;
        }
    }

    // Formata o valor com as configurações definidas
    return Number(parsedValue).toLocaleString("pt-BR", {
        maximumFractionDigits: decimal,
        minimumFractionDigits: decimal
    });
}


export function formatOnlyNumber(number) {
    if (number === null || number === undefined) {
        return number;
    }
    return number.replace(/[^\d]+/g, '');
}