import { takeLatest, put, call } from 'redux-saga/effects';

import { storage, history } from '@commons';

import {
  IMPORTAR_NOTA_MONITORAMENTO_NFE_COMPRA,
  IMPORTAR_NOTA_MONITORAMENTO_NFE_COMPRA_PENDING,
  IMPORTAR_NOTA_MONITORAMENTO_NFE_COMPRA_SUCCESS,
  IMPORTAR_NOTA_MONITORAMENTO_NFE_COMPRA_FAILURE
} from '../actionTypes';
import { AlertError, AlertWarning } from '@components/common/AlertToast';
import { CONFIG_SESSION, READ_XML_ENTRADA_ESTOQUE_SUCCESS, READ_XML_ENTRADA_ESTOQUE_PENDING } from '@handler';
import { readXmlFile, verificarArquivoEFornecedor } from '../service';

function* sagaImportarNotaEntrada(action) {
  yield put({ type: READ_XML_ENTRADA_ESTOQUE_PENDING });
  yield put({ type: IMPORTAR_NOTA_MONITORAMENTO_NFE_COMPRA_PENDING });

  // Define uma variável para contar o número de tentativas. se der erro uma vez, tenta executar de novo
  let tentativas = 0;

  delete action.notaEntradaApiDfe.empresa;
  const { notaEntradaApiDfe, naturezaOperacao, localEstoqueId } = action;
  const fazendaSelecionadaId = JSON.parse(storage.get(CONFIG_SESSION)).fazendaSelecionada.id;

  while (tentativas < 2) {
    try {
      const nota = yield call(readXmlFile, {...notaEntradaApiDfe}, naturezaOperacao, localEstoqueId, fazendaSelecionadaId);

      yield put({ type: READ_XML_ENTRADA_ESTOQUE_SUCCESS, xmlReaded: nota.data });
      yield put({ type: IMPORTAR_NOTA_MONITORAMENTO_NFE_COMPRA_SUCCESS });

      history.push('/fiscal/entrada/importacaoXml');
      // Se a chamada foi bem-sucedida, sai do loop
      break;
    } catch (error) {
      // Incrementa o contador de tentativas após uma falha
      tentativas += 1;

      // Verifica se já tentou 2 vezes antes de mostrar o alerta de erro
      if (tentativas >= 2) {
        yield put({ type: IMPORTAR_NOTA_MONITORAMENTO_NFE_COMPRA_FAILURE });
        AlertError('Algo inesperado aconteceu ao importar a nota fiscal. Por favor, tente novamente. Se o problema persistir, não hesite em entrar em contato com nosso suporte. Estamos aqui para ajudar!');
      }
    }
  }
}

export default function* watchImportarNotaEntrada() {
  yield takeLatest(IMPORTAR_NOTA_MONITORAMENTO_NFE_COMPRA, sagaImportarNotaEntrada)
}
