import * as Yup from 'yup';

const newRegistroVincularFornecedor = {
  codigoProdutoFornecedor: '',
  possuiConversaoCompra: false,
  tipoEmbalagem: null
};

const validationVincularFornecedor = Yup.object().shape({
  codigoProdutoFornecedor: Yup.string()
    .max(50, 'Campo deve ter menos do que 50 caracteres!'),
});

export const produtoInsumoVincularFornecedor = {
  newRegistroVincularFornecedor,
  validationVincularFornecedor
}