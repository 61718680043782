import { takeLatest, put, call } from 'redux-saga/effects';

import { listAllLatestNdviBySafra } from '../service';
import {
  LIST_ALL_LATEST_NDVI_BY_SAFRA,
  LIST_ALL_LATEST_NDVI_BY_SAFRA_PENDING,
  LIST_ALL_LATEST_NDVI_BY_SAFRA_SUCCESS,
  LIST_ALL_LATEST_NDVI_BY_SAFRA_FAILURE
} from '../actionTypes';

function* sagaListAllLatestNdviBySafra(action) {
  yield put({ type: LIST_ALL_LATEST_NDVI_BY_SAFRA_PENDING });

  try {
    const { propriedadeId, safraId } = action.args;
    const allLatest = yield call(listAllLatestNdviBySafra, propriedadeId, safraId);
    yield put({ type: LIST_ALL_LATEST_NDVI_BY_SAFRA_SUCCESS, allLatest: allLatest });
  } catch (error) {
    yield put({ type: LIST_ALL_LATEST_NDVI_BY_SAFRA_FAILURE });
  }
}

export default function* watchListAllLatestNdviBySafra() {
  yield takeLatest(LIST_ALL_LATEST_NDVI_BY_SAFRA, sagaListAllLatestNdviBySafra);
}
