import React, { Suspense, lazy } from 'react';
import { withRouter, Switch, Redirect } from 'react-router-dom';
import { useSelector } from "react-redux";

import HorizontalLayout from "../@components/layout/HorizontalLayout";

import { PrivateRoute } from '@components/common/PrivateRoute';
import { PublicRoute } from '@components/common/PublicRoute';

/* loader component for Suspense*/
import PageLoader from '@components/common/PageLoader';
import NovaContaAgrare from 'pages/private/common/ContaAgrare/NovaContaAgrare';
import ContaAgrareEdit from 'pages/private/common/ContaAgrare/cadastro/edit';
import ContaAgrare from 'pages/private/common/ContaAgrare/index';
import ImporterKML from 'pages/private/common/ImporterKML';


// PUBLIC
const LoginPage = lazy(() => import('../pages/public/LoginPage/LoginPage'));
const RegisterPage = lazy(() => import('../pages/public/RegisterPage/RegisterPage'));
const ActivatePage = lazy(() => import('../pages/public/ActivatePage'));
const RecoverPasswordPage = lazy(() => import('../pages/public/RecoverPasswordPage'));
const ResetPasswordPage = lazy(() => import('../pages/public/ResetPasswordPage'));
const ActivatePageNewUserAccount = lazy(() => import('../pages/public/ActivatePageNewUserAccount'));



// INTRANET

// USUARIO INTRANET
const UsuarioWl = lazy(() => import('../pages/private/intranetWhiteLabel/UsuarioIntranetWl/UsuarioWl'));
const UsuarioWlEdit = lazy(() => import('../pages/private/intranetWhiteLabel/UsuarioIntranetWl/UsuarioWlEdit'));
const UsuarioWlNew = lazy(() => import('../pages/private/intranetWhiteLabel/UsuarioIntranetWl/UsuarioWlNew'));

// PERFIL USUARIO INTRANET
const PerfilWl = lazy(() => import('../pages/private/intranetWhiteLabel/PerfilUsuarioIntranetWl/PerfilWl'));
const PerfilWlNew = lazy(() => import('../pages/private/intranetWhiteLabel/PerfilUsuarioIntranetWl/PerfilWlNew'));
const PerfilWlEdit = lazy(() => import('../pages/private/intranetWhiteLabel/PerfilUsuarioIntranetWl/PerfilWlEdit'));

// PERFIL USUARIO INTRANET
const PartnerWl = lazy(() => import('../pages/private/intranetWhiteLabel/PartnerWl/index'));
const PartnerWlNew = lazy(() => import('../pages/private/intranetWhiteLabel/PartnerWl/cadastro/new'));
const PartnerWlEdit = lazy(() => import('../pages/private/intranetWhiteLabel/PartnerWl/cadastro/edit'));

// GRUPO CONTA PRODUTOR INTRANET
const GrupoContaAgrareWl = lazy(() => import('../pages/private/intranetWhiteLabel/GrupoContaAgrareWl/index'));
const GrupoContaAgrareWlNew = lazy(() => import('../pages/private/intranetWhiteLabel/GrupoContaAgrareWl/cadastro/new'));
const GrupoContaAgrareWlEdit = lazy(() => import('../pages/private/intranetWhiteLabel/GrupoContaAgrareWl/cadastro/edit'));

// CATALAGO PRODUTO INTRANET
const CatalagoProdutoWl = lazy(() => import('../pages/private/intranetWhiteLabel/CatalagoProdutoWl/index'));
const CatalagoProdutoWlNew = lazy(() => import('../pages/private/intranetWhiteLabel/CatalagoProdutoWl/cadastro/new'));
const CatalagoProdutoWlEdit = lazy(() => import('../pages/private/intranetWhiteLabel/CatalagoProdutoWl/cadastro/edit'));

// CORPORATE SEM PERMISSAO
const CorporateSemPermissao = lazy(() => import('../pages/private/intranetWhiteLabel/UsuarioIntranetWl/CorporateSemPermissao'));



// COMMOM
const HomeCorporate = lazy(() => import('../pages/private/home/HomeCorporate'));
const Config = lazy(() => import('../pages/private/config'));
const BreadcrumbCommon = lazy(() => import('../pages/private/common/Breadcrumb'));
const PrimeirosPassos = lazy(() => import('../pages/private/primeirosPassos'));
const NotificationPage = lazy(() => import('../pages/private/common/Notification'));
const NotificationDetails = lazy(() => import('../pages/private/common/Notification/NotificationDetails'));

//SUPORTE
const Suporte = lazy(() => import('../pages/private/common/Suporte/index'));
const SuporteOnFarm = lazy(() => import('../pages/private/common/Suporte/indexOnFarm'));

// CALENDARIO
const Calendario = lazy(() => import('../pages/private/common/Calendario'));

// PROPRIEDADE RURAL
const PropriedadeCadastro = lazy(() => import('../pages/private/common/PropriedadeCadastro'));
const NovaPropriedade = lazy(() => import('../pages/private/common/PropriedadeCadastro/cadastro/new'));
const EditarPropriedade = lazy(() => import('../pages/private/common/PropriedadeCadastro/cadastro/edit'));

// PERFIL USUARIO
const Perfil = lazy(() => import('../pages/private/common/Perfil/Perfil'));
const PerfilNew = lazy(() => import('../pages/private/common/Perfil/PerfilNew'));
const PerfilEdit = lazy(() => import('../pages/private/common/Perfil/PerfilEdit'));

// USUARIO
const Usuario = lazy(() => import('../pages/private/common/Usuario/Usuario'));
const UsuarioNew = lazy(() => import('../pages/private/common/Usuario/UsuarioNew'));
const UsuarioEdit = lazy(() => import('../pages/private/common/Usuario/UsuarioEdit'));
const MeuPerfil = lazy(() => import('../pages/private/common/Usuario/MeuPerfil'));
const SemPermissao = lazy(() => import('../pages/private/common/Usuario/SemPermissao'));
const SemLicenca = lazy(() => import('../pages/private/common/Usuario/SemLicenca'));


// TALHAO
const TalhaoCadastro = lazy(() => import('../pages/private/common/TalhaoCadastro'));
const NovoTalhao = lazy(() => import('../pages/private/common/TalhaoCadastro/cadastro/new'));
const EditarTalhao = lazy(() => import('../pages/private/common/TalhaoCadastro/cadastro/edit'));

// FUNCIONARIO
const FuncionarioCadastro = lazy(() => import('../pages/private/common/FuncionarioCadastro'));
const NovoFuncionario = lazy(() => import('../pages/private/common/FuncionarioCadastro/cadastro/new'));
const EditarFuncionario = lazy(() => import('../pages/private/common/FuncionarioCadastro/cadastro/edit'));

// FUNCIONARIO CARGO
const FuncionarioCargoCadastro = lazy(() => import('../pages/private/common/FuncionarioCargoCadastro'));
const NovoFuncionarioCargo = lazy(() => import('../pages/private/common/FuncionarioCargoCadastro/cadastro/new'));
const EditarFuncionarioCargo = lazy(() => import('../pages/private/common/FuncionarioCargoCadastro/cadastro/edit'));

// UNIDADE MEDIDA
const UnidadeMedidaCadastro = lazy(() => import('../pages/private/common/UnidadeMedidaCadastro'));
const NovoUnidadeMedida = lazy(() => import('../pages/private/common/UnidadeMedidaCadastro/cadastro/new'));
const EditarUnidadeMedida = lazy(() => import('../pages/private/common/UnidadeMedidaCadastro/cadastro/edit'));

const UnidadeMedidaConversaoCadastro = lazy(() => import('../pages/private/common/UnidadeMedidaConversaoCadastro'));
const NovoUnidadeMedidaConversao = lazy(() => import('../pages/private/common/UnidadeMedidaConversaoCadastro/cadastro/new'));
const EditarUnidadeMedidaConversao = lazy(() => import('../pages/private/common/UnidadeMedidaConversaoCadastro/cadastro/edit'));

// PATRIMONIO
const PatrimonioBreadcrumb = lazy(() => import('../pages/private/patrimonio/Breadcrumb'));

const PatrimonioCadastro = lazy(() => import('../pages/private/common/PatrimonioCadastro'));
const NovoPatrimonio = lazy(() => import('../pages/private/common/PatrimonioCadastro/cadastro/new'));
const EditarPatrimonio = lazy(() => import('../pages/private/common/PatrimonioCadastro/cadastro/edit'));

// PATRIMONIO - ABASTECIMENTO
const PatrimonioAbastecimentoCadastro = lazy(() => import('../pages/private/patrimonio/AbastecimentoCadastro'));
const NovoPatrimonioAbastecimento = lazy(() => import('../pages/private/patrimonio/AbastecimentoCadastro/cadastro/new'));
const EditarPatrimonioAbastecimento = lazy(() => import('../pages/private/patrimonio/AbastecimentoCadastro/cadastro/edit'));
const ViewPatrimonioAbastecimento = lazy(() => import('../pages/private/patrimonio/AbastecimentoCadastro/view/view'));

// PATRIMONIO - MANUTENCAO
const PatrimonioManutencaoCadastro = lazy(() => import('../pages/private/patrimonio/ManutencaoCadastro'));
const NovoPatrimonioManutencao = lazy(() => import('../pages/private/patrimonio/ManutencaoCadastro/cadastro/new'));
const EditarPatrimonioManutencao = lazy(() => import('../pages/private/patrimonio/ManutencaoCadastro/cadastro/edit'));
const ViewPatrimonioManutencao = lazy(() => import('../pages/private/patrimonio/ManutencaoCadastro/view/view'));

// PRODUTO-INSUMO
const ProdutoInsumoCadastro = lazy(() => import('../pages/private/common/ProdutoInsumoCadastro'));
const NovoProdutoInsumo = lazy(() => import('../pages/private/common/ProdutoInsumoCadastro/cadastro/new'));
const EditarProdutoInsumo = lazy(() => import('../pages/private/common/ProdutoInsumoCadastro/cadastro/edit'));

// CLIENTE
const ClienteCadastro = lazy(() => import('../pages/private/common/ClienteCadastro'));
const NovoCliente = lazy(() => import('../pages/private/common/ClienteCadastro/cadastro/new'));
const EditarCliente = lazy(() => import('../pages/private/common/ClienteCadastro/cadastro/edit'));

// FORNECEDOR
const FornecedorCadastro = lazy(() => import('../pages/private/common/FornecedorCadastro'));
const NovoFornecedor = lazy(() => import('../pages/private/common/FornecedorCadastro/cadastro/new'));
const EditarFornecedor = lazy(() => import('../pages/private/common/FornecedorCadastro/cadastro/edit'));

// TRANSPORTADORA
const TransportadoraCadastro = lazy(() => import('../pages/private/common/TransportadoraCadastro'));
const NovaTransportadora = lazy(() => import('../pages/private/common/TransportadoraCadastro/cadastro/new'));
const EditarTransportadora = lazy(() => import('../pages/private/common/TransportadoraCadastro/cadastro/edit'));

// PLUVIOMETRO DIGITAL
const PluviometroDigital = lazy(() => import('../pages/private/common/PluviometroDigital'));
const NovoPluviometroDigital = lazy(() => import('../pages/private/common/PluviometroDigital/cadastro/new'));
const EditarPluviometroDigital = lazy(() => import('../pages/private/common/PluviometroDigital/cadastro/edit'));

// MOTORISTA
const MotoristaCadastro = lazy(() => import('../pages/private/common/MotoristaCadastro'));
const NovoMotorista = lazy(() => import('../pages/private/common/MotoristaCadastro/cadastro/new'));
const EditarMotorista = lazy(() => import('../pages/private/common/MotoristaCadastro/cadastro/edit'));

// VEICULO
const VeiculoCadastro = lazy(() => import('../pages/private/common/VeiculoCadastro'));
const NovoVeiculo = lazy(() => import('../pages/private/common/VeiculoCadastro/cadastro/new'));
const EditarVeiculo = lazy(() => import('../pages/private/common/VeiculoCadastro/cadastro/edit'));

// IDENTIFICADOR
const IdentificadorCadastro = lazy(() => import('../pages/private/common/IdentificadorCadastro'));
const NovoIdentificador = lazy(() => import('../pages/private/common/IdentificadorCadastro/cadastro/new'));
const EditarIdentificador = lazy(() => import('../pages/private/common/IdentificadorCadastro/cadastro/edit'));



// AGRICULTURA
const BreadcrumbAgricCadastros = lazy(() => import('../pages/private/agricultura/Breadcrumb'));
const PainelAgrare = lazy(() => import('../pages/private/common/Dashboard'));

const VisaoFazenda = lazy(() => import('../pages/private/common/Dashboard/VisaoFazenda'));

// SAFRA
const SafraCadastro = lazy(() => import('../pages/private/agricultura/SafraCadastro'));
const NovaSafra = lazy(() => import('../pages/private/agricultura/SafraCadastro/cadastro/new'));
const EditarSafra = lazy(() => import('../pages/private/agricultura/SafraCadastro/cadastro/edit'));

// PROCEDIMENTO
const ProcedimentoCadastro = lazy(() => import('../pages/private/agricultura/ProcedimentoCadastro'));
const NovoProcedimento = lazy(() => import('../pages/private/agricultura/ProcedimentoCadastro/cadastro/new'));
const EditarProcedimento = lazy(() => import('../pages/private/agricultura/ProcedimentoCadastro/cadastro/edit'));

// DESPESA DIRETA
const DespesaDiretaCadastro = lazy(() => import('../pages/private/agricultura/DespesaDiretaCadastro'));
const NovoDespesaDireta = lazy(() => import('../pages/private/agricultura/DespesaDiretaCadastro/cadastro/new'));
const EditarDespesaDireta = lazy(() => import('../pages/private/agricultura/DespesaDiretaCadastro/cadastro/edit'));

// PLANEJAMENTO SAFRA
const Planejamento = lazy(() => import('../pages/private/agricultura/AtividadeAgricola/Planejamento'));
const NovoPlanejamento = lazy(() => import('../pages/private/agricultura/AtividadeAgricola/Planejamento/cadastro/new'));
const EditarPlanejamento = lazy(() => import('../pages/private/agricultura/AtividadeAgricola/Planejamento/cadastro/edit'));
const RealizarPlanejamento = lazy(() => import('../pages/private/agricultura/AtividadeAgricola/Planejamento/realizar'));
const ViewPlanejamento = lazy(() => import('../pages/private/agricultura/AtividadeAgricola/Planejamento/view/view'));

// ATIVIDADE PLANTIO E MANEJO
const PlantioManejo = lazy(() => import('../pages/private/agricultura/AtividadeAgricola/PlantioManejo'));
const NovoPlantioManejo = lazy(() => import('../pages/private/agricultura/AtividadeAgricola/PlantioManejo/cadastro/new'));
const EditarPlantioManejo = lazy(() => import('../pages/private/agricultura/AtividadeAgricola/PlantioManejo/cadastro/edit'));
const NovoPlantioManejoCustoDireto = lazy(() => import('../pages/private/agricultura/AtividadeAgricola/PlantioManejo/custoDireto/new'));
const EditarPlantioManejoCustoDireto = lazy(() => import('../pages/private/agricultura/AtividadeAgricola/PlantioManejo/custoDireto/edit'));
const ViewPlantioManejo = lazy(() => import('../pages/private/agricultura/AtividadeAgricola/PlantioManejo/view/view'));

// ATIVIDADE COLHEITA
const Colheita = lazy(() => import('../pages/private/agricultura/AtividadeAgricola/Colheita'));
const NovaColheita = lazy(() => import('../pages/private/agricultura/AtividadeAgricola/Colheita/cadastro/new'));
const EditarColheita = lazy(() => import('../pages/private/agricultura/AtividadeAgricola/Colheita/cadastro/edit'));
const ViewColheita = lazy(() => import('../pages/private/agricultura/AtividadeAgricola/Colheita/view/view'));

// CULTURA
const CulturaCadastro = lazy(() => import('../pages/private/agricultura/CulturaCadastro'));
const NovaCultura = lazy(() => import('../pages/private/agricultura/CulturaCadastro/cadastro/new'));
const EditarCultura = lazy(() => import('../pages/private/agricultura/CulturaCadastro/cadastro/edit'));

// PRODUTO AGRICOLa
const ProdutoAgricolaCadastro = lazy(() => import('../pages/private/agricultura/ProdutoAgricolaCadastro'));
const NovaProdutoAgricola = lazy(() => import('../pages/private/agricultura/ProdutoAgricolaCadastro/cadastro/new'));
const EditarProdutoAgricola = lazy(() => import('../pages/private/agricultura/ProdutoAgricolaCadastro/cadastro/edit'));

// VENDA PRODUCAO AGRICOLA
const VendaProducaoAgricola = lazy(() => import('../pages/private/agricultura/VendaProducaoAgricola'));
const NovaVendaProducaoAgricola = lazy(() => import('../pages/private/agricultura/VendaProducaoAgricola/cadastro/new'));
const EditarVendaProducaoAgricola = lazy(() => import('../pages/private/agricultura/VendaProducaoAgricola/cadastro/edit'));

// RELATORIO ESTOQUE PRODUCAO POR PERIODO
const RelatorioEstoqueProducaoPorPeriodo = lazy(() => import('../pages/private/agricultura/RelatorioEstoqueProducaoPorPeriodo'));

// ESTOQUE IMPLANTACAO PRODUCAO
const ImplantacaoEstoqueProducao = lazy(() => import('../pages/private/agricultura/ImplantacaoEstoqueProducao'));
const NovaImplantacaoEstoqueProducao = lazy(() => import('../pages/private/agricultura/ImplantacaoEstoqueProducao/cadastro/new'));
const EditarImplantacaoEstoqueProducao = lazy(() => import('../pages/private/agricultura/ImplantacaoEstoqueProducao/cadastro/edit'));

// ESTOQUE BAIXA MANUAL PRODUCAO
const BaixarManualEstoqueProducao = lazy(() => import('../pages/private/agricultura/BaixarManualEstoqueProducao'));
const NovaBaixaManualEstoqueProducao = lazy(() => import('../pages/private/agricultura/BaixarManualEstoqueProducao/cadastro/new'));
const EditarBaixaManualEstoqueProducao = lazy(() => import('../pages/private/agricultura/BaixarManualEstoqueProducao/cadastro/edit'));

// ESTOQUE TRANSFERENCIA PRODUCAO
const TransferenciaEstoqueProducao = lazy(() => import('../pages/private/agricultura/TransferenciaEstoqueProducao'));
const NovaTransferenciaEstoqueProducao = lazy(() => import('../pages/private/agricultura/TransferenciaEstoqueProducao/cadastro/new'));
const EditarTransferenciaEstoqueProducao = lazy(() => import('../pages/private/agricultura/TransferenciaEstoqueProducao/cadastro/edit'));

// ESTAGIO-APLICACAO
const EstagioAplicacaoCadastro = lazy(() => import('../pages/private/agricultura/EstagioAplicacao'));
const NovoEstagioAplicacao = lazy(() => import('../pages/private/agricultura/EstagioAplicacao/cadastro/new'));
const EditarEstagioAplicacao = lazy(() => import('../pages/private/agricultura/EstagioAplicacao/cadastro/edit'));


// FINANCEIRO
const BreadcrumbFinanceiro = lazy(() => import('../pages/private/financeiro/Breadcrumb'));

// PLANO DE CONTA GERENCIAL
const PlanoContaCadastro = lazy(() => import('../pages/private/financeiro/PlanoContaCadastro'));
const NovoPlanoConta = lazy(() => import('../pages/private/financeiro/PlanoContaCadastro/cadastro/new'));
const EditarPlanoConta = lazy(() => import('../pages/private/financeiro/PlanoContaCadastro/cadastro/edit'));

// CONTA CAIXA
const ContaCaixaCadastro = lazy(() => import('../pages/private/financeiro/ContaCaixaCadastro'));
const NovaContaCaixa = lazy(() => import('../pages/private/financeiro/ContaCaixaCadastro/cadastro/new'));
const EditarContaCaixa = lazy(() => import('../pages/private/financeiro/ContaCaixaCadastro/cadastro/edit'));

// CONTA CHEQUE PRE DATADO
// const ContaChequePreDatadoCadastro = lazy(() => import('../pages/private/financeiro/ContaChequePreDatadoCadastro'));
// const NovaContaChequePre = lazy(() => import('../pages/private/financeiro/ContaChequePreDatadoCadastro/cadastro/new'));
// const EditarContaChequePre = lazy(() => import('../pages/private/financeiro/ContaChequePreDatadoCadastro/cadastro/edit'));

// CONTA BANCO
const ContaBancoCadastro = lazy(() => import('../pages/private/financeiro/ContaBancoCadastro'));
const NovoBanco = lazy(() => import('../pages/private/financeiro/ContaBancoCadastro/cadastro/new'));
const EditarBanco = lazy(() => import('../pages/private/financeiro/ContaBancoCadastro/cadastro/edit'));

// CONTA CARTAO
const ContaCartaoCadastro = lazy(() => import('../pages/private/financeiro/ContaCartaoCadastro'));
const NovoCartao = lazy(() => import('../pages/private/financeiro/ContaCartaoCadastro/cadastro/new'));
const EditarCartao = lazy(() => import('../pages/private/financeiro/ContaCartaoCadastro/cadastro/edit'));

// CONTROLE CONTA CAIXA
const ControleContaCaixa = lazy(() => import('../pages/private/financeiro/ControleContaCaixa'));
const NovoLancamentoControleContaCaixa = lazy(() => import('../pages/private/financeiro/ControleContaCaixa/cadastro/new'));
const EditarLancamentoControleContaCaixa = lazy(() => import('../pages/private/financeiro/ControleContaCaixa/cadastro/edit'));

// CONTROLE CONTA BANCO
const ControleContaBanco = lazy(() => import('../pages/private/financeiro/ControleContaBanco'));
const NovoLancamentoControleContaBanco = lazy(() => import('../pages/private/financeiro/ControleContaBanco/cadastro/new'));
const EditarLancamentoControleContaBanco = lazy(() => import('../pages/private/financeiro/ControleContaBanco/cadastro/edit'));

// CONTROLE CONTA BANCO
const TransferenciaContaBancoCaixa = lazy(() => import('../pages/private/financeiro/TransferenciaContaBancoCaixa'));
const NovaTransferenciaContaBancoCaixa = lazy(() => import('../pages/private/financeiro/TransferenciaContaBancoCaixa/cadastro/new'));
const EditarTransferenciaContaBancoCaixa = lazy(() => import('../pages/private/financeiro/TransferenciaContaBancoCaixa/cadastro/edit'));

// FLUXO DE CAIXA
const FluxoCaixa = lazy(() => import('../pages/private/financeiro/FluxoCaixa'));

// CONTA PAGAR
const ContaPagar = lazy(() => import('../pages/private/financeiro/ContaPagar'));
const NovoLancamentoContaPagar = lazy(() => import('../pages/private/financeiro/ContaPagar/cadastro/new'));
const EditarLancamentoContaPagar = lazy(() => import('../pages/private/financeiro/ContaPagar/cadastro/edit'));
const ViewLancamentoContaPagar = lazy(() => import('../pages/private/financeiro/ContaPagar/view/view'));

// CONTA PAGAR
const ContaPagarRecorrente = lazy(() => import('../pages/private/financeiro/ContaPagarRecorrente'));
const NovoLancamentoContaPagarRecorrente = lazy(() => import('../pages/private/financeiro/ContaPagarRecorrente/cadastro/new'));
const EditarLancamentoContaPagarRecorrente = lazy(() => import('../pages/private/financeiro/ContaPagarRecorrente/cadastro/edit'));

// CONTA RECEBER
const ContaReceber = lazy(() => import('../pages/private/financeiro/ContaReceber'));
const NovoLancamentoContaReceber = lazy(() => import('../pages/private/financeiro/ContaReceber/cadastro/new'));
const EditarLancamentoContaReceber = lazy(() => import('../pages/private/financeiro/ContaReceber/cadastro/edit'));
const ViewLancamentoContaReceber = lazy(() => import('../pages/private/financeiro/ContaReceber/view/view'));


// ESTOQUE

const BreadcrumbEstoque = lazy(() => import('../pages/private/estoque/Breadcrumb'));

// LOCAL ESTOQUE
const LocalEstoqueCadastro = lazy(() => import('../pages/private/estoque/LocalEstoqueCadastro'));
const NovoLocalEstoque = lazy(() => import('../pages/private/estoque/LocalEstoqueCadastro/cadastro/new'));
const EditarLocalEstoque = lazy(() => import('../pages/private/estoque/LocalEstoqueCadastro/cadastro/edit'));

// ESTOQUE ENTRADA
const EntradaEstoque = lazy(() => import('../pages/private/estoque/EstoqueEntrada'));
const NovaEntradaEstoque = lazy(() => import('../pages/private/estoque/EstoqueEntrada/cadastro/new'));
const EditarEntradaEstoque = lazy(() => import('../pages/private/estoque/EstoqueEntrada/cadastro/edit'));

// ESTOQUE IMPLANTACAO
const ImplantacaoEstoque = lazy(() => import('../pages/private/estoque/ImplantacaoEstoque'));
const NovaImplantacaoEstoque = lazy(() => import('../pages/private/estoque/ImplantacaoEstoque/cadastro/new'));
const EditarImplantacaoEstoque = lazy(() => import('../pages/private/estoque/ImplantacaoEstoque/cadastro/edit'));

// ESTOQUE TRANSFERENCIA
const TransferenciaEstoque = lazy(() => import('../pages/private/estoque/TransferenciaEstoque'));
const NovaTransferenciaEstoque = lazy(() => import('../pages/private/estoque/TransferenciaEstoque/cadastro/new'));
const EditarTransferenciaEstoque = lazy(() => import('../pages/private/estoque/TransferenciaEstoque/cadastro/edit'));

// ESTOQUE BAIXA MANUAL
const BaixarManualEstoque = lazy(() => import('../pages/private/estoque/BaixarManualEstoque'));
const NovaBaixaManualEstoque = lazy(() => import('../pages/private/estoque/BaixarManualEstoque/cadastro/new'));
const EditarBaixaManualEstoque = lazy(() => import('../pages/private/estoque/BaixarManualEstoque/cadastro/edit'));

// RELATORIO ESTOQUE ATUAL
const RelatorioEstoqueAtual = lazy(() => import('../pages/private/estoque/RelatorioEstoqueAtual'));

// RELATORIO REPOSICAO ESTOQUE MINIMO
const RelatorioReposicaoEstoqueMinimo = lazy(() => import('../pages/private/estoque/RelatorioReposicaoEstoqueMinimo'));

// RELATORIO ESTOQUE POR PERIODO
const RelatorioEstoquePorPeriodo = lazy(() => import('../pages/private/estoque/RelatorioEstoquePorPeriodo'));

// ESTOQUE IMPORTACAO XML ENTRADA
const ImportacaoXmlEntrada = lazy(() => import('../pages/private/estoque/ImportacaoXmlEntrada'));

// TRIBUTARIO

// NATUREZA OPERACAO
const NaturezaOperacaoCadastro = lazy(() => import('../pages/private/tributario/NaturezaOperacaoCadastro'));
const NovaNaturezaOperacao = lazy(() => import('../pages/private/tributario/NaturezaOperacaoCadastro/cadastro/new'));
const EditarNaturezaOperacao = lazy(() => import('../pages/private/tributario/NaturezaOperacaoCadastro/cadastro/edit'));


// PECUARIA
const BreadcrumbPecuaria = lazy(() => import('../pages/private/pecuaria/Breadcrumb'));

const ProcedimentoPecuarioCadastro = lazy(() => import('../pages/private/pecuaria/ProcedimentoPecuaria'));
const NovoProcedimentoPecuario = lazy(() => import('../pages/private/pecuaria/ProcedimentoPecuaria/cadastro/new'));
const EditarProcedimentoPecuario = lazy(() => import('../pages/private/pecuaria/ProcedimentoPecuaria/cadastro/edit'));
// PECUARIA - ESPECIE
const AnimalEspecieCadastro = lazy(() => import('../pages/private/pecuaria/AnimalEspecie'));
const NovoAnimalEspecie = lazy(() => import('../pages/private/pecuaria/AnimalEspecie/cadastro/new'));
const EditarAnimalEspecie = lazy(() => import('../pages/private/pecuaria/AnimalEspecie/cadastro/edit'));
// PECUARIA - RACA
const AnimalRacaCadastro = lazy(() => import('../pages/private/pecuaria/AnimalRaca'));
const NovoAnimalRaca = lazy(() => import('../pages/private/pecuaria/AnimalRaca/cadastro/new'));
const EditarAnimalRaca = lazy(() => import('../pages/private/pecuaria/AnimalRaca/cadastro/edit'));
// PECUARIA - PELAGEM
const AnimalPelagemCadastro = lazy(() => import('../pages/private/pecuaria/AnimalPelagem'));
const NovoAnimalPelagem = lazy(() => import('../pages/private/pecuaria/AnimalPelagem/cadastro/new'));
const EditarAnimalPelagem = lazy(() => import('../pages/private/pecuaria/AnimalPelagem/cadastro/edit'));
// PECUARIA - CATEGORIA
const AnimalCategoriaCadastro = lazy(() => import('../pages/private/pecuaria/AnimalCategoria'));
const NovoAnimalCategoria = lazy(() => import('../pages/private/pecuaria/AnimalCategoria/cadastro/new'));
const EditarAnimalCategoria = lazy(() => import('../pages/private/pecuaria/AnimalCategoria/cadastro/edit'));
// PECUARIA - LOTE
const AnimalLoteCadastro = lazy(() => import('../pages/private/pecuaria/AnimalLote'));
const NovoAnimalLote = lazy(() => import('../pages/private/pecuaria/AnimalLote/cadastro/new'));
const EditarAnimalLote = lazy(() => import('../pages/private/pecuaria/AnimalLote/cadastro/edit'));
// PECUARIA - ANIMAL
const AnimalCadastro = lazy(() => import('../pages/private/pecuaria/Animal'));
// const NovoAnimal = lazy(() => import('../pages/private/pecuaria/Animal/cadastro/new'));
// const EditarAnimal = lazy(() => import('../pages/private/pecuaria/Animal/cadastro/edit'));
// PECUARIA - CAMPO
const CampoCadastro = lazy(() => import('../pages/private/pecuaria/Campo'));
const NovoCampo = lazy(() => import('../pages/private/pecuaria/Campo/cadastro/new'));
const EditarCampo = lazy(() => import('../pages/private/pecuaria/Campo/cadastro/edit'));
// PECUARIA - ANO PECUARIO 
const AnoPecuarioCadastro = lazy(() => import('../pages/private/pecuaria/AnoPecuario'));
const NovoAnoPecuario = lazy(() => import('../pages/private/pecuaria/AnoPecuario/cadastro/new'));
const EditarAnoPecuario = lazy(() => import('../pages/private/pecuaria/AnoPecuario/cadastro/edit'));
// PECUARIA - MANEJO PLAN
const ManejoPlanejamentoCadastro = lazy(() => import('../pages/private/pecuaria/ManejoPlanejamento'));
const NovoManejoPlanejamento = lazy(() => import('../pages/private/pecuaria/ManejoPlanejamento/cadastro/new'));
const EditarManejoPlanejamento = lazy(() => import('../pages/private/pecuaria/ManejoPlanejamento/cadastro/edit'));
const ViewManejoPlanejamento = lazy(() => import('../pages/private/pecuaria/ManejoPlanejamento/view/view'));
const RealizarManejoPlanejamento = lazy(() => import('../pages/private/pecuaria/ManejoPlanejamento/realizar'));
// PECUARIA - MANEJO EXEC
const ManejoExecucaoCadastro = lazy(() => import('../pages/private/pecuaria/ManejoExecucao'));
const NovoManejoExecucao = lazy(() => import('../pages/private/pecuaria/ManejoExecucao/cadastro/new'));
const EditarManejoExecucao = lazy(() => import('../pages/private/pecuaria/ManejoExecucao/cadastro/edit'));
const ViewManejoExecucao = lazy(() => import('../pages/private/pecuaria/ManejoExecucao/view/view'));
// PECUARIA - REL MOVIMENTACAO
const RelatorioMovimentacaoAnimal = lazy(() => import('../pages/private/pecuaria/RelatorioMovimentacaoAnimal'));
// PECUARIA - EVOLUCAO
const EvolucaoCadastro = lazy(() => import('../pages/private/pecuaria/Evolucao'));
const NovoEvolucao = lazy(() => import('../pages/private/pecuaria/Evolucao/cadastro/new'));
const EditarEvolucao = lazy(() => import('../pages/private/pecuaria/Evolucao/cadastro/edit'));
// PECUARIA - TRANSFERENCIA
const TransferenciaCadastro = lazy(() => import('../pages/private/pecuaria/Transferencia'));
const NovoTransferencia = lazy(() => import('../pages/private/pecuaria/Transferencia/cadastro/new'));
const EditarTransferencia = lazy(() => import('../pages/private/pecuaria/Transferencia/cadastro/edit'));
// PECUARIA - COMPRA
const CompraAnimal = lazy(() => import('../pages/private/pecuaria/CompraAnimal'));
const NovaCompraAnimal = lazy(() => import('../pages/private/pecuaria/CompraAnimal/cadastro/new'));
const EditarCompraAnimal = lazy(() => import('../pages/private/pecuaria/CompraAnimal/cadastro/edit'));
// PECUARIA - VENDA
const VendaAnimal = lazy(() => import('../pages/private/pecuaria/VendaAnimal'));
const NovaVendaAnimal = lazy(() => import('../pages/private/pecuaria/VendaAnimal/cadastro/new'));
const EditarVendaAnimal = lazy(() => import('../pages/private/pecuaria/VendaAnimal/cadastro/edit'));
// PECUARIA - IMPLANTACAO
const ImplantacaoAnimal = lazy(() => import('../pages/private/pecuaria/ImplantacaoAnimal'));
const NovaImplantacaoAnimal = lazy(() => import('../pages/private/pecuaria/ImplantacaoAnimal/cadastro/new'));
const EditarImplantacaoAnimal = lazy(() => import('../pages/private/pecuaria/ImplantacaoAnimal/cadastro/edit'));
// PECUARIA - OUTRAS SAIDA
const OutrasSaidaAnimal = lazy(() => import('../pages/private/pecuaria/OutrasSaidaAnimal'));
const NovaOutrasSaidaAnimal = lazy(() => import('../pages/private/pecuaria/OutrasSaidaAnimal/cadastro/new'));
const EditarOutrasSaidaAnimal = lazy(() => import('../pages/private/pecuaria/OutrasSaidaAnimal/cadastro/edit'));
// PECUARIA - CONTROLE DE PESO
const ControlePeso = lazy(() => import('../pages/private/pecuaria/ControlePeso'));


// FISCAL

// CADASTRO EMITENTE
const EmitenteCadastro = lazy(() => import('../pages/private/fiscal/EmitenteCadastro'));
const NovoEmitenteCadastro = lazy(() => import('../pages/private/fiscal/EmitenteCadastro/cadastro/new'));
const EditarEmitenteCadastro = lazy(() => import('../pages/private/fiscal/EmitenteCadastro/cadastro/edit'));

// MONITORAMENTO NFE COMPRA
const MonitoramentoNFeCompra = lazy(() => import('../pages/private/fiscal/MonitoramentoNFeCompra'));

// EMISSAO NFE
const NFeEmissao = lazy(() => import('../pages/private/fiscal/NFeEmissao'));
const NFeEmissaoNew = lazy(() => import('../pages/private/fiscal/NFeEmissao/cadastro/new'));


const listofPages = [
    '/login',
    // '/register',
    '/recover'
];

const listPrivateNoThemofPages = [
    '/home',
    '/meuPerfil',
    '/suporte',
    '/corporate/contaProdutor',
    '/corporate/contaProdutor/novo',
    '/corporate/usuarios',
    '/corporate/usuarios/novo',
    '/corporate/perfilAcesso',
    '/corporate/parceiros',
    '/corporate/grupoContaProdutor',
    '/corporate/catalagoInsumos',
    '/corporate/semPermissao',
];

const Routes = ({ location }) => {

    const startSessionReducer = useSelector(state => state.entities.startSessionReducer);

    const moduloAgricultura = startSessionReducer.configSession !== null && startSessionReducer.configSession.moduloAtivo !== null && startSessionReducer.configSession.moduloAtivo === 'AGRICULTURA';

    if (listofPages.indexOf(location.pathname) > -1
        || location.pathname.includes('/activate/')
        || location.pathname.includes('/activateUserAccount/')
        || location.pathname.includes('/resetPassword/')) {
        return (
            <Suspense fallback={<PageLoader />}>
                <Switch location={location}>
                    <PublicRoute path='/login' component={LoginPage} />
                    {/* <PublicRoute path='/register' component={RegisterPage} /> */}
                    <PublicRoute path='/activate/:token' component={ActivatePage} />
                    <PublicRoute path='/recover' component={RecoverPasswordPage} />
                    <PublicRoute path='/resetPassword/:token/:email' component={ResetPasswordPage} />
                    <PublicRoute path='/activateUserAccount/:token/:schema' component={ActivatePageNewUserAccount} />
                </Switch>
            </Suspense>
        )
    }
    else {
        if ((listPrivateNoThemofPages.indexOf(location.pathname) > -1)
            || location.pathname.includes('/corporate/usuarios/')
            || location.pathname.includes('/corporate/perfilAcesso/')
            || location.pathname.includes('/corporate/parceiros/')
            || location.pathname.includes('/corporate/contaProdutor/')
            || location.pathname.includes('/corporate/grupoContaProdutor/')
            || location.pathname.includes('/corporate/catalagoInsumos/')
            || location.pathname.includes('/corporate/semPermissao/')) {
            return (
                <Suspense fallback={<PageLoader />}>
                    <Switch location={location}>
                        <PrivateRoute exact path='/home' component={HomeCorporate} />
                        <PrivateRoute exact path='/meuPerfil' component={MeuPerfil} />
                        <PrivateRoute exact path='/suporte' component={Suporte} />
                        <PrivateRoute exact path='/corporate/contaProdutor/novo' component={NovaContaAgrare} />
                        <PrivateRoute exact path='/corporate/contaProdutor' component={ContaAgrare} />
                        <PrivateRoute path='/corporate/contaProdutor/editar/:id' component={ContaAgrareEdit} />
                        <PrivateRoute exact path='/corporate/usuarios' component={UsuarioWl} />
                        <PrivateRoute path='/corporate/usuarios/editar/:id' component={UsuarioWlEdit} />
                        <PrivateRoute path='/corporate/usuarios/novo' component={UsuarioWlNew} />
                        <PrivateRoute exact path='/corporate/perfilAcesso' component={PerfilWl} />
                        <PrivateRoute path='/corporate/perfilAcesso/novo' component={PerfilWlNew} />
                        <PrivateRoute path='/corporate/perfilAcesso/editar/:id' component={PerfilWlEdit} />
                        <PrivateRoute exact path='/corporate/parceiros' component={PartnerWl} />
                        <PrivateRoute path='/corporate/parceiros/novo' component={PartnerWlNew} />
                        <PrivateRoute path='/corporate/parceiros/editar/:id' component={PartnerWlEdit} />
                        <PrivateRoute exact path='/corporate/grupoContaProdutor' component={GrupoContaAgrareWl} />
                        <PrivateRoute path='/corporate/grupoContaProdutor/novo' component={GrupoContaAgrareWlNew} />
                        <PrivateRoute path='/corporate/grupoContaProdutor/editar/:id' component={GrupoContaAgrareWlEdit} />
                        <PrivateRoute exact path='/corporate/catalagoInsumos' component={CatalagoProdutoWl} />
                        <PrivateRoute path='/corporate/catalagoInsumos/novo' component={CatalagoProdutoWlNew} />
                        <PrivateRoute path='/corporate/catalagoInsumos/editar/:id' component={CatalagoProdutoWlEdit} />
                        <PrivateRoute exact path='/corporate/semPermissao' component={CorporateSemPermissao} />
                    </Switch>
                </Suspense>
            )
        }
        else {
            if (moduloAgricultura) {
                return (<HorizontalLayout>
                    <Suspense fallback={<PageLoader />}>
                        <Switch location={location}>

                            <PrivateRoute exact path='/primeirosPassos' component={PrimeirosPassos} />
                            <PrivateRoute exact path='/notifications' component={NotificationPage} />
                            <PrivateRoute exact path='/notifications/:id' component={NotificationDetails} />

                            <PrivateRoute exact path='/centralAjuda' component={SuporteOnFarm} />

                            <PrivateRoute exact path='/dashboard' component={PainelAgrare} />

                            <PrivateRoute exact path='/mapa' component={VisaoFazenda} />

                            <PrivateRoute exact path='/config' component={Config} />

                            <PrivateRoute exact path='/semPermissao' component={SemPermissao} />

                            <PrivateRoute exact path='/noLicense' component={SemLicenca} />

                            <PrivateRoute exact path='/cadastros' component={BreadcrumbCommon} />

                            <PrivateRoute exact path='/agricultura' component={BreadcrumbAgricCadastros} />

                            <PrivateRoute exact path='/calendario' component={Calendario} />

                            <PrivateRoute exact path='/minhasFazendas' component={PropriedadeCadastro} />
                            <PrivateRoute exact path='/minhasFazendas/novo' component={NovaPropriedade} />
                            <PrivateRoute path='/minhasFazendas/editar/:id' component={EditarPropriedade} />

                            <PrivateRoute exact path='/seguranca/perfilUsuario' component={Perfil} />
                            <PrivateRoute exact path='/seguranca/perfilUsuario/novo' component={PerfilNew} />
                            <PrivateRoute path='/seguranca/perfilUsuario/editar/:id' component={PerfilEdit} />

                            <PrivateRoute exact path='/seguranca/usuario' component={Usuario} />
                            <PrivateRoute path='/seguranca/usuario/novo' component={UsuarioNew} />
                            <PrivateRoute path='/seguranca/usuario/editar/:idUserAuth' component={UsuarioEdit} />

                            <PrivateRoute exact path='/talhaoCadastro' component={TalhaoCadastro} />
                            <PrivateRoute exact path='/talhaoCadastro/novo' component={NovoTalhao} />
                            <PrivateRoute path='/talhaoCadastro/editar/:id' component={EditarTalhao} />

                            <PrivateRoute path='/importer/kml' component={ImporterKML} />

                            <PrivateRoute exact path='/funcionarioCadastro' component={FuncionarioCadastro} />
                            <PrivateRoute exact path='/funcionarioCadastro/novo' component={NovoFuncionario} />
                            <PrivateRoute path='/funcionarioCadastro/editar/:id' component={EditarFuncionario} />

                            <PrivateRoute exact path='/funcionarioCargoCadastro' component={FuncionarioCargoCadastro} />
                            <PrivateRoute exact path='/funcionarioCargoCadastro/novo' component={NovoFuncionarioCargo} />
                            <PrivateRoute path='/funcionarioCargoCadastro/editar/:id' component={EditarFuncionarioCargo} />

                            <PrivateRoute exact path='/patrimonio' component={PatrimonioBreadcrumb} />

                            <PrivateRoute exact path='/patrimonioCadastro' component={PatrimonioCadastro} />
                            <PrivateRoute exact path='/patrimonioCadastro/novo' component={NovoPatrimonio} />
                            <PrivateRoute path='/patrimonioCadastro/editar/:id' component={EditarPatrimonio} />

                            <PrivateRoute exact path='/patrimonio/abastecimento' component={PatrimonioAbastecimentoCadastro} />
                            <PrivateRoute exact path='/patrimonio/abastecimento/novo' component={NovoPatrimonioAbastecimento} />
                            <PrivateRoute path='/patrimonio/abastecimento/editar/:id' component={EditarPatrimonioAbastecimento} />
                            <PrivateRoute path='/patrimonio/abastecimento/view/:id' component={ViewPatrimonioAbastecimento} />

                            <PrivateRoute exact path='/patrimonio/manutencao' component={PatrimonioManutencaoCadastro} />
                            <PrivateRoute exact path='/patrimonio/manutencao/novo' component={NovoPatrimonioManutencao} />
                            <PrivateRoute path='/patrimonio/manutencao/editar/:id' component={EditarPatrimonioManutencao} />
                            <PrivateRoute path='/patrimonio/manutencao/view/:id' component={ViewPatrimonioManutencao} />

                            <PrivateRoute exact path='/insumoCadastro' component={ProdutoInsumoCadastro} />
                            <PrivateRoute exact path='/insumoCadastro/novo' component={NovoProdutoInsumo} />
                            <PrivateRoute path='/insumoCadastro/editar/:id' component={EditarProdutoInsumo} />

                            <PrivateRoute exact path='/clienteCadastro' component={ClienteCadastro} />
                            <PrivateRoute exact path='/clienteCadastro/novo' component={NovoCliente} />
                            <PrivateRoute path='/clienteCadastro/editar/:id' component={EditarCliente} />

                            <PrivateRoute exact path='/fornecedorCadastro' component={FornecedorCadastro} />
                            <PrivateRoute exact path='/fornecedorCadastro/novo' component={NovoFornecedor} />
                            <PrivateRoute path='/fornecedorCadastro/editar/:id' component={EditarFornecedor} />

                            <PrivateRoute exact path='/transportadoraCadastro' component={TransportadoraCadastro} />
                            <PrivateRoute exact path='/transportadoraCadastro/novo' component={NovaTransportadora} />
                            <PrivateRoute path='/transportadoraCadastro/editar/:id' component={EditarTransportadora} />

                            <PrivateRoute exact path='/pluviometroDigital' component={PluviometroDigital} />
                            <PrivateRoute exact path='/pluviometroDigital/novo' component={NovoPluviometroDigital} />
                            <PrivateRoute path='/pluviometroDigital/editar/:id' component={EditarPluviometroDigital} />

                            <PrivateRoute exact path='/motoristaCadastro' component={MotoristaCadastro} />
                            <PrivateRoute exact path='/motoristaCadastro/novo' component={NovoMotorista} />
                            <PrivateRoute path='/motoristaCadastro/editar/:id' component={EditarMotorista} />

                            <PrivateRoute exact path='/veiculoCadastro' component={VeiculoCadastro} />
                            <PrivateRoute exact path='/veiculoCadastro/novo' component={NovoVeiculo} />
                            <PrivateRoute path='/veiculoCadastro/editar/:id' component={EditarVeiculo} />

                            <PrivateRoute exact path='/identificadorCadastro' component={IdentificadorCadastro} />
                            <PrivateRoute exact path='/identificadorCadastro/novo' component={NovoIdentificador} />
                            <PrivateRoute path='/identificadorCadastro/editar/:id' component={EditarIdentificador} />

                            <PrivateRoute exact path='/unidadeMedidaCadastro' component={UnidadeMedidaCadastro} />
                            <PrivateRoute exact path='/unidadeMedidaCadastro/novo' component={NovoUnidadeMedida} />
                            <PrivateRoute path='/unidadeMedidaCadastro/editar/:id' component={EditarUnidadeMedida} />

                            <PrivateRoute exact path='/unidadeMedidaConversaoCadastro' component={UnidadeMedidaConversaoCadastro} />
                            <PrivateRoute exact path='/unidadeMedidaConversaoCadastro/novo' component={NovoUnidadeMedidaConversao} />
                            <PrivateRoute path='/unidadeMedidaConversaoCadastro/editar/:id' component={EditarUnidadeMedidaConversao} />

                            <PrivateRoute exact path='/safraCadastro' component={SafraCadastro} />
                            <PrivateRoute exact path='/safraCadastro/novo' component={NovaSafra} />
                            <PrivateRoute path='/safraCadastro/editar/:id' component={EditarSafra} />

                            <PrivateRoute exact path='/procedimentoCadastro' component={ProcedimentoCadastro} />
                            <PrivateRoute exact path='/procedimentoCadastro/novo' component={NovoProcedimento} />
                            <PrivateRoute path='/procedimentoCadastro/editar/:id' component={EditarProcedimento} />

                            <PrivateRoute exact path='/despesaDiretaCadastro' component={DespesaDiretaCadastro} />
                            <PrivateRoute exact path='/despesaDiretaCadastro/novo' component={NovoDespesaDireta} />
                            <PrivateRoute path='/despesaDiretaCadastro/editar/:id' component={EditarDespesaDireta} />

                            <PrivateRoute exact path='/agricultura/planejamento' component={Planejamento} />
                            <PrivateRoute exact path='/agricultura/planejamento/novo' component={NovoPlanejamento} />
                            <PrivateRoute path='/agricultura/planejamento/editar/:id' component={EditarPlanejamento} />
                            <PrivateRoute exact path='/agricultura/planejamento/realizar' component={RealizarPlanejamento} />
                            <PrivateRoute path='/agricultura/planejamento/view/:id' component={ViewPlanejamento} />

                            <PrivateRoute exact path='/agricultura/execucao' component={PlantioManejo} />
                            <PrivateRoute exact path='/agricultura/execucao/novo' component={NovoPlantioManejo} />
                            <PrivateRoute path='/agricultura/execucao/editar/:id' component={EditarPlantioManejo} />
                            <PrivateRoute exact path='/agricultura/execucao/custoDireto/novo' component={NovoPlantioManejoCustoDireto} />
                            <PrivateRoute path='/agricultura/execucao/custoDireto/editar/:id' component={EditarPlantioManejoCustoDireto} />
                            <PrivateRoute path='/agricultura/execucao/view/:id' component={ViewPlantioManejo} />

                            <PrivateRoute exact path='/agricultura/colheita' component={Colheita} />
                            <PrivateRoute exact path='/agricultura/colheita/novo' component={NovaColheita} />
                            <PrivateRoute path='/agricultura/colheita/editar/:id' component={EditarColheita} />
                            <PrivateRoute path='/agricultura/colheita/view/:id' component={ViewColheita} />

                            <PrivateRoute exact path='/culturaCadastro' component={CulturaCadastro} />
                            <PrivateRoute exact path='/culturaCadastro/novo' component={NovaCultura} />
                            <PrivateRoute path='/culturaCadastro/editar/:id' component={EditarCultura} />

                            <PrivateRoute exact path='/produtoAgricolaCadastro' component={ProdutoAgricolaCadastro} />
                            <PrivateRoute exact path='/produtoAgricolaCadastro/novo' component={NovaProdutoAgricola} />
                            <PrivateRoute path='/produtoAgricolaCadastro/editar/:id' component={EditarProdutoAgricola} />

                            <PrivateRoute exact path='/agricultura/producao/venda' component={VendaProducaoAgricola} />
                            <PrivateRoute exact path='/agricultura/producao/venda/novo' component={NovaVendaProducaoAgricola} />
                            <PrivateRoute path='/agricultura/producao/venda/editar/:id' component={EditarVendaProducaoAgricola} />

                            <PrivateRoute exact path='/agricultura/producao/relatorioEstoque' component={RelatorioEstoqueProducaoPorPeriodo} />

                            <PrivateRoute exact path='/agricultura/producao/implantacao' component={ImplantacaoEstoqueProducao} />
                            <PrivateRoute exact path='/agricultura/producao/implantacao/novo' component={NovaImplantacaoEstoqueProducao} />
                            <PrivateRoute path='/agricultura/producao/implantacao/editar/:id' component={EditarImplantacaoEstoqueProducao} />

                            <PrivateRoute exact path='/agricultura/producao/baixaManual' component={BaixarManualEstoqueProducao} />
                            <PrivateRoute exact path='/agricultura/producao/baixaManual/novo' component={NovaBaixaManualEstoqueProducao} />
                            <PrivateRoute path='/agricultura/producao/baixaManual/editar/:id' component={EditarBaixaManualEstoqueProducao} />

                            <PrivateRoute exact path='/agricultura/producao/transferencia' component={TransferenciaEstoqueProducao} />
                            <PrivateRoute exact path='/agricultura/producao/transferencia/novo' component={NovaTransferenciaEstoqueProducao} />
                            <PrivateRoute path='/agricultura/producao/transferencia/editar/:id' component={EditarTransferenciaEstoqueProducao} />

                            <PrivateRoute exact path='/agricultura/aplicacao/estagio' component={EstagioAplicacaoCadastro} />
                            <PrivateRoute exact path='/agricultura/aplicacao/estagio/novo' component={NovoEstagioAplicacao} />
                            <PrivateRoute path='/agricultura/aplicacao/estagio/editar/:id' component={EditarEstagioAplicacao} />

                            <PrivateRoute exact path='/financeiro' component={BreadcrumbFinanceiro} />

                            <PrivateRoute exact path='/financeiro/planoContaCadastro' component={PlanoContaCadastro} />
                            <PrivateRoute exact path='/financeiro/planoContaCadastro/novo' component={NovoPlanoConta} />
                            <PrivateRoute path='/financeiro/planoContaCadastro/editar/:id' component={EditarPlanoConta} />

                            <PrivateRoute exact path='/financeiro/contaCaixaCadastro' component={ContaCaixaCadastro} />
                            <PrivateRoute exact path='/financeiro/contaCaixaCadastro/novo' component={NovaContaCaixa} />
                            <PrivateRoute path='/financeiro/contaCaixaCadastro/editar/:id' component={EditarContaCaixa} />

                            {/* <PrivateRoute exact path='/financeiro/contaChequePreDatadoCadastro' component={ContaChequePreDatadoCadastro} />
<PrivateRoute exact path='/financeiro/contaChequePreDatadoCadastro/novo' component={NovaContaChequePre} />
<PrivateRoute path='/financeiro/contaChequePreDatadoCadastro/editar/:id' component={EditarContaChequePre} /> */}

                            <PrivateRoute exact path='/financeiro/contaBancoCadastro' component={ContaBancoCadastro} />
                            <PrivateRoute exact path='/financeiro/contaBancoCadastro/novo' component={NovoBanco} />
                            <PrivateRoute path='/financeiro/contaBancoCadastro/editar/:id' component={EditarBanco} />

                            <PrivateRoute exact path='/financeiro/contaCartaoCadastro' component={ContaCartaoCadastro} />
                            <PrivateRoute exact path='/financeiro/contaCartaoCadastro/novo' component={NovoCartao} />
                            <PrivateRoute path='/financeiro/contaCartaoCadastro/editar/:id' component={EditarCartao} />

                            <PrivateRoute exact path='/financeiro/movimentacoesCaixa' component={ControleContaCaixa} />
                            <PrivateRoute exact path='/financeiro/movimentacoesCaixa/novo' component={NovoLancamentoControleContaCaixa} />
                            <PrivateRoute path='/financeiro/movimentacoesCaixa/editar/:id' component={EditarLancamentoControleContaCaixa} />

                            <PrivateRoute exact path='/financeiro/movimentacoesBancarias' component={ControleContaBanco} />
                            <PrivateRoute exact path='/financeiro/movimentacoesBancarias/novo' component={NovoLancamentoControleContaBanco} />
                            <PrivateRoute path='/financeiro/movimentacoesBancarias/editar/:id' component={EditarLancamentoControleContaBanco} />

                            <PrivateRoute exact path='/financeiro/transferenciaContaBancoCaixa' component={TransferenciaContaBancoCaixa} />
                            <PrivateRoute exact path='/financeiro/transferenciaContaBancoCaixa/novo' component={NovaTransferenciaContaBancoCaixa} />
                            <PrivateRoute path='/financeiro/transferenciaContaBancoCaixa/editar/:id' component={EditarTransferenciaContaBancoCaixa} />

                            <PrivateRoute exact path='/financeiro/fluxoCaixa' component={FluxoCaixa} />

                            <PrivateRoute exact path='/financeiro/contaPagar' component={ContaPagar} />
                            <PrivateRoute exact path='/financeiro/contaPagar/novo' component={NovoLancamentoContaPagar} />
                            <PrivateRoute path='/financeiro/contaPagar/editar/:id' component={EditarLancamentoContaPagar} />
                            <PrivateRoute path='/financeiro/contaPagar/view/:id' component={ViewLancamentoContaPagar} />

                            <PrivateRoute exact path='/financeiro/contaPagar/recorrente' component={ContaPagarRecorrente} />
                            <PrivateRoute exact path='/financeiro/contaPagar/recorrente/novo' component={NovoLancamentoContaPagarRecorrente} />
                            <PrivateRoute path='/financeiro/contaPagar/recorrente/editar/:id' component={EditarLancamentoContaPagarRecorrente} />

                            <PrivateRoute exact path='/financeiro/contaReceber' component={ContaReceber} />
                            <PrivateRoute exact path='/financeiro/contaReceber/novo' component={NovoLancamentoContaReceber} />
                            <PrivateRoute path='/financeiro/contaReceber/editar/:id' component={EditarLancamentoContaReceber} />
                            <PrivateRoute path='/financeiro/contaReceber/view/:id' component={ViewLancamentoContaReceber} />

                            <PrivateRoute exact path='/estoque' component={BreadcrumbEstoque} />

                            <PrivateRoute exact path='/estoque/localEstoqueCadastro' component={LocalEstoqueCadastro} />
                            <PrivateRoute exact path='/estoque/localEstoqueCadastro/novo' component={NovoLocalEstoque} />
                            <PrivateRoute path='/estoque/localEstoqueCadastro/editar/:id' component={EditarLocalEstoque} />

                            <PrivateRoute exact path='/financeiro/entradaNota' component={EntradaEstoque} />
                            <PrivateRoute exact path='/financeiro/entradaNota/novo' component={NovaEntradaEstoque} />
                            <PrivateRoute path='/financeiro/entradaNota/editar/:id' component={EditarEntradaEstoque} />

                            <PrivateRoute path='/fiscal/entrada/importacaoXml' component={ImportacaoXmlEntrada} />

                            <PrivateRoute exact path='/estoque/implantacao' component={ImplantacaoEstoque} />
                            <PrivateRoute exact path='/estoque/implantacao/novo' component={NovaImplantacaoEstoque} />
                            <PrivateRoute path='/estoque/implantacao/editar/:id' component={EditarImplantacaoEstoque} />

                            <PrivateRoute exact path='/estoque/transferencia' component={TransferenciaEstoque} />
                            <PrivateRoute exact path='/estoque/transferencia/novo' component={NovaTransferenciaEstoque} />
                            <PrivateRoute path='/estoque/transferencia/editar/:id' component={EditarTransferenciaEstoque} />

                            <PrivateRoute exact path='/estoque/baixarManual' component={BaixarManualEstoque} />
                            <PrivateRoute exact path='/estoque/baixarManual/novo' component={NovaBaixaManualEstoque} />
                            <PrivateRoute path='/estoque/baixarManual/editar/:id' component={EditarBaixaManualEstoque} />

                            <PrivateRoute exact path='/estoque/movimentacao/estoqueAtual' component={RelatorioEstoqueAtual} />
                            <PrivateRoute exact path='/estoque/movimentacao/reposicaoEstoqueMinimo' component={RelatorioReposicaoEstoqueMinimo} />
                            <PrivateRoute exact path='/estoque/movimentacao/estoquePorPeriodo' component={RelatorioEstoquePorPeriodo} />

                            <PrivateRoute exact path='/fiscal/naturezaOperacaoCadastro' component={NaturezaOperacaoCadastro} />
                            <PrivateRoute exact path='/fiscal/naturezaOperacaoCadastro/novo' component={NovaNaturezaOperacao} />
                            <PrivateRoute path='/fiscal/naturezaOperacaoCadastro/editar/:id' component={EditarNaturezaOperacao} />

                            <PrivateRoute exact path='/fiscal/emitente' component={EmitenteCadastro} />
                            <PrivateRoute exact path='/fiscal/emitente/novo' component={NovoEmitenteCadastro} />
                            <PrivateRoute path='/fiscal/emitente/editar/:id' component={EditarEmitenteCadastro} />

                            <PrivateRoute exact path='/fiscal/monitoramento/nfeSefaz' component={MonitoramentoNFeCompra} />

                            <PrivateRoute exact path='/fiscal/nfe/emissao' component={NFeEmissao} />
                            <PrivateRoute exact path='/fiscal/nfe/emissao/novo' component={NFeEmissaoNew} />

                            <Redirect to='/dashboard' />
                        </Switch>
                    </Suspense>
                </HorizontalLayout>
                );
            } else {
                return (
                    <HorizontalLayout>
                        <Suspense fallback={<PageLoader />}>
                            <Switch location={location}>
                                <PrivateRoute exact path='/dashboard' component={PainelAgrare} />

                                <PrivateRoute exact path='/config' component={Config} />

                                <PrivateRoute exact path='/semPermissao' component={SemPermissao} />

                                <PrivateRoute exact path='/noLicense' component={SemLicenca} />

                                <PrivateRoute exact path='/cadastros' component={BreadcrumbCommon} />

                                <PrivateRoute exact path='/pecuaria' component={BreadcrumbPecuaria} />

                                <PrivateRoute exact path='/minhasFazendas' component={PropriedadeCadastro} />
                                {/* <PrivateRoute exact path='/minhasFazendas/novo' component={NovaPropriedade} /> */}
                                <PrivateRoute path='/minhasFazendas/editar/:id' component={EditarPropriedade} />

                                <PrivateRoute exact path='/seguranca/perfilUsuario' component={Perfil} />
                                <PrivateRoute exact path='/seguranca/perfilUsuario/novo' component={PerfilNew} />
                                <PrivateRoute path='/seguranca/perfilUsuario/editar/:id' component={PerfilEdit} />

                                <PrivateRoute exact path='/seguranca/usuario' component={Usuario} />
                                <PrivateRoute path='/seguranca/usuario/novo' component={UsuarioNew} />
                                <PrivateRoute path='/seguranca/usuario/editar/:id' component={UsuarioEdit} />

                                <PrivateRoute exact path='/talhaoCadastro' component={TalhaoCadastro} />
                                <PrivateRoute exact path='/talhaoCadastro/novo' component={NovoTalhao} />
                                <PrivateRoute path='/talhaoCadastro/editar/:id' component={EditarTalhao} />

                                <PrivateRoute exact path='/funcionarioCadastro' component={FuncionarioCadastro} />
                                <PrivateRoute exact path='/funcionarioCadastro/novo' component={NovoFuncionario} />
                                <PrivateRoute path='/funcionarioCadastro/editar/:id' component={EditarFuncionario} />

                                <PrivateRoute exact path='/funcionarioCargoCadastro' component={FuncionarioCargoCadastro} />
                                <PrivateRoute exact path='/funcionarioCargoCadastro/novo' component={NovoFuncionarioCargo} />
                                <PrivateRoute path='/funcionarioCargoCadastro/editar/:id' component={EditarFuncionarioCargo} />

                                <PrivateRoute exact path='/patrimonioCadastro' component={PatrimonioCadastro} />
                                <PrivateRoute exact path='/patrimonioCadastro/novo' component={NovoPatrimonio} />
                                <PrivateRoute path='/patrimonioCadastro/editar/:id' component={EditarPatrimonio} />

                                <PrivateRoute exact path='/insumoCadastro' component={ProdutoInsumoCadastro} />
                                <PrivateRoute exact path='/insumoCadastro/novo' component={NovoProdutoInsumo} />
                                <PrivateRoute path='/insumoCadastro/editar/:id' component={EditarProdutoInsumo} />

                                <PrivateRoute exact path='/clienteCadastro' component={ClienteCadastro} />
                                <PrivateRoute exact path='/clienteCadastro/novo' component={NovoCliente} />
                                <PrivateRoute path='/clienteCadastro/editar/:id' component={EditarCliente} />

                                <PrivateRoute exact path='/fornecedorCadastro' component={FornecedorCadastro} />
                                <PrivateRoute exact path='/fornecedorCadastro/novo' component={NovoFornecedor} />
                                <PrivateRoute path='/fornecedorCadastro/editar/:id' component={EditarFornecedor} />

                                <PrivateRoute exact path='/transportadoraCadastro' component={TransportadoraCadastro} />
                                <PrivateRoute exact path='/transportadoraCadastro/novo' component={NovaTransportadora} />
                                <PrivateRoute path='/transportadoraCadastro/editar/:id' component={EditarTransportadora} />

                                <PrivateRoute exact path='/pluviometroDigital' component={PluviometroDigital} />
                                <PrivateRoute exact path='/pluviometroDigital/novo' component={NovoPluviometroDigital} />
                                <PrivateRoute path='/pluviometroDigital/editar/:id' component={EditarPluviometroDigital} />

                                <PrivateRoute exact path='/motoristaCadastro' component={MotoristaCadastro} />
                                <PrivateRoute exact path='/motoristaCadastro/novo' component={NovoMotorista} />
                                <PrivateRoute path='/motoristaCadastro/editar/:id' component={EditarMotorista} />

                                <PrivateRoute exact path='/veiculoCadastro' component={VeiculoCadastro} />
                                <PrivateRoute exact path='/veiculoCadastro/novo' component={NovoVeiculo} />
                                <PrivateRoute path='/veiculoCadastro/editar/:id' component={EditarVeiculo} />

                                <PrivateRoute exact path='/identificadorCadastro' component={IdentificadorCadastro} />
                                <PrivateRoute exact path='/identificadorCadastro/novo' component={NovoIdentificador} />
                                <PrivateRoute path='/identificadorCadastro/editar/:id' component={EditarIdentificador} />

                                <PrivateRoute exact path='/unidadeMedidaCadastro' component={UnidadeMedidaCadastro} />
                                <PrivateRoute exact path='/unidadeMedidaCadastro/novo' component={NovoUnidadeMedida} />
                                <PrivateRoute path='/unidadeMedidaCadastro/editar/:id' component={EditarUnidadeMedida} />

                                <PrivateRoute exact path='/financeiro' component={BreadcrumbFinanceiro} />

                                <PrivateRoute exact path='/financeiro/planoContaCadastro' component={PlanoContaCadastro} />
                                <PrivateRoute exact path='/financeiro/planoContaCadastro/novo' component={NovoPlanoConta} />
                                <PrivateRoute path='/financeiro/planoContaCadastro/editar/:id' component={EditarPlanoConta} />

                                <PrivateRoute exact path='/financeiro/contaCaixaCadastro' component={ContaCaixaCadastro} />
                                <PrivateRoute exact path='/financeiro/contaCaixaCadastro/novo' component={NovaContaCaixa} />
                                <PrivateRoute path='/financeiro/contaCaixaCadastro/editar/:id' component={EditarContaCaixa} />

                                {/* <PrivateRoute exact path='/financeiro/contaChequePreDatadoCadastro' component={ContaChequePreDatadoCadastro} />
<PrivateRoute exact path='/financeiro/contaChequePreDatadoCadastro/novo' component={NovaContaChequePre} />
<PrivateRoute path='/financeiro/contaChequePreDatadoCadastro/editar/:id' component={EditarContaChequePre} /> */}

                                <PrivateRoute exact path='/financeiro/contaBancoCadastro' component={ContaBancoCadastro} />
                                <PrivateRoute exact path='/financeiro/contaBancoCadastro/novo' component={NovoBanco} />
                                <PrivateRoute path='/financeiro/contaBancoCadastro/editar/:id' component={EditarBanco} />

                                <PrivateRoute exact path='/financeiro/contaCartaoCadastro' component={ContaCartaoCadastro} />
                                <PrivateRoute exact path='/financeiro/contaCartaoCadastro/novo' component={NovoCartao} />
                                <PrivateRoute path='/financeiro/contaCartaoCadastro/editar/:id' component={EditarCartao} />

                                <PrivateRoute exact path='/financeiro/movimentacoesCaixa' component={ControleContaCaixa} />
                                <PrivateRoute exact path='/financeiro/movimentacoesCaixa/novo' component={NovoLancamentoControleContaCaixa} />
                                <PrivateRoute path='/financeiro/movimentacoesCaixa/editar/:id' component={EditarLancamentoControleContaCaixa} />

                                <PrivateRoute exact path='/financeiro/movimentacoesBancarias' component={ControleContaBanco} />
                                <PrivateRoute exact path='/financeiro/movimentacoesBancarias/novo' component={NovoLancamentoControleContaBanco} />
                                <PrivateRoute path='/financeiro/movimentacoesBancarias/editar/:id' component={EditarLancamentoControleContaBanco} />

                                <PrivateRoute exact path='/financeiro/transferenciaContaBancoCaixa' component={TransferenciaContaBancoCaixa} />
                                <PrivateRoute exact path='/financeiro/transferenciaContaBancoCaixa/novo' component={NovaTransferenciaContaBancoCaixa} />
                                <PrivateRoute path='/financeiro/transferenciaContaBancoCaixa/editar/:id' component={EditarTransferenciaContaBancoCaixa} />

                                <PrivateRoute exact path='/financeiro/fluxoCaixa' component={FluxoCaixa} />

                                <PrivateRoute exact path='/financeiro/contaPagar' component={ContaPagar} />
                                <PrivateRoute exact path='/financeiro/contaPagar/novo' component={NovoLancamentoContaPagar} />
                                <PrivateRoute path='/financeiro/contaPagar/editar/:id' component={EditarLancamentoContaPagar} />
                                <PrivateRoute path='/financeiro/contaPagar/view/:id' component={ViewLancamentoContaPagar} />

                                <PrivateRoute exact path='/financeiro/contaReceber' component={ContaReceber} />
                                <PrivateRoute exact path='/financeiro/contaReceber/novo' component={NovoLancamentoContaReceber} />
                                <PrivateRoute path='/financeiro/contaReceber/editar/:id' component={EditarLancamentoContaReceber} />
                                <PrivateRoute path='/financeiro/contaReceber/view/:id' component={ViewLancamentoContaReceber} />

                                <PrivateRoute exact path='/estoque' component={BreadcrumbEstoque} />

                                <PrivateRoute exact path='/estoque/localEstoqueCadastro' component={LocalEstoqueCadastro} />
                                <PrivateRoute exact path='/estoque/localEstoqueCadastro/novo' component={NovoLocalEstoque} />
                                <PrivateRoute path='/estoque/localEstoqueCadastro/editar/:id' component={EditarLocalEstoque} />

                                <PrivateRoute exact path='/financeiro/entradaNota' component={EntradaEstoque} />
                                <PrivateRoute exact path='/financeiro/entradaNota/novo' component={NovaEntradaEstoque} />
                                <PrivateRoute path='/financeiro/entradaNota/editar/:id' component={EditarEntradaEstoque} />

                                <PrivateRoute path='/fiscal/entrada/importacaoXml' component={ImportacaoXmlEntrada} />

                                <PrivateRoute exact path='/estoque/implantacao' component={ImplantacaoEstoque} />
                                <PrivateRoute exact path='/estoque/implantacao/novo' component={NovaImplantacaoEstoque} />
                                <PrivateRoute path='/estoque/implantacao/editar/:id' component={EditarImplantacaoEstoque} />

                                <PrivateRoute exact path='/estoque/transferencia' component={TransferenciaEstoque} />
                                <PrivateRoute exact path='/estoque/transferencia/novo' component={NovaTransferenciaEstoque} />
                                <PrivateRoute path='/estoque/transferencia/editar/:id' component={EditarTransferenciaEstoque} />

                                <PrivateRoute exact path='/estoque/baixarManual' component={BaixarManualEstoque} />
                                <PrivateRoute exact path='/estoque/baixarManual/novo' component={NovaBaixaManualEstoque} />
                                <PrivateRoute path='/estoque/baixarManual/editar/:id' component={EditarBaixaManualEstoque} />

                                <PrivateRoute exact path='/estoque/movimentacao/estoqueAtual' component={RelatorioEstoqueAtual} />
                                <PrivateRoute exact path='/estoque/movimentacao/reposicaoEstoqueMinimo' component={RelatorioReposicaoEstoqueMinimo} />
                                <PrivateRoute exact path='/estoque/movimentacao/estoquePorPeriodo' component={RelatorioEstoquePorPeriodo} />

                                <PrivateRoute exact path='/fiscal/naturezaOperacaoCadastro' component={NaturezaOperacaoCadastro} />
                                <PrivateRoute exact path='/fiscal/naturezaOperacaoCadastro/novo' component={NovaNaturezaOperacao} />
                                <PrivateRoute path='/fiscal/naturezaOperacaoCadastro/editar/:id' component={EditarNaturezaOperacao} />


                                <PrivateRoute exact path='/pecuaria/procedimentoPecuarioCadastro' component={ProcedimentoPecuarioCadastro} />
                                <PrivateRoute exact path='/pecuaria/procedimentoPecuarioCadastro/novo' component={NovoProcedimentoPecuario} />
                                <PrivateRoute path='/pecuaria/procedimentoPecuarioCadastro/editar/:id' component={EditarProcedimentoPecuario} />

                                <PrivateRoute exact path='/pecuaria/animalEspecieCadastro' component={AnimalEspecieCadastro} />
                                <PrivateRoute exact path='/pecuaria/animalEspecieCadastro/novo' component={NovoAnimalEspecie} />
                                <PrivateRoute path='/pecuaria/animalEspecieCadastro/editar/:id' component={EditarAnimalEspecie} />

                                <PrivateRoute exact path='/pecuaria/animalRacaCadastro' component={AnimalRacaCadastro} />
                                <PrivateRoute exact path='/pecuaria/animalRacaCadastro/novo' component={NovoAnimalRaca} />
                                <PrivateRoute path='/pecuaria/animalRacaCadastro/editar/:id' component={EditarAnimalRaca} />

                                <PrivateRoute exact path='/pecuaria/animalPelagemCadastro' component={AnimalPelagemCadastro} />
                                <PrivateRoute exact path='/pecuaria/animalPelagemCadastro/novo' component={NovoAnimalPelagem} />
                                <PrivateRoute path='/pecuaria/animalPelagemCadastro/editar/:id' component={EditarAnimalPelagem} />

                                <PrivateRoute exact path='/pecuaria/animalCategoriaCadastro' component={AnimalCategoriaCadastro} />
                                <PrivateRoute exact path='/pecuaria/animalCategoriaCadastro/novo' component={NovoAnimalCategoria} />
                                <PrivateRoute path='/pecuaria/animalCategoriaCadastro/editar/:id' component={EditarAnimalCategoria} />

                                <PrivateRoute exact path='/pecuaria/animalLoteCadastro' component={AnimalLoteCadastro} />
                                <PrivateRoute exact path='/pecuaria/animalLoteCadastro/novo' component={NovoAnimalLote} />
                                <PrivateRoute path='/pecuaria/animalLoteCadastro/editar/:id' component={EditarAnimalLote} />

                                <PrivateRoute exact path='/pecuaria/relacaoAnimais' component={AnimalCadastro} />
                                {/* <PrivateRoute exact path='/pecuaria/animalCadastro/novo' component={NovoAnimal} />
                                <PrivateRoute path='/pecuaria/animalCadastro/editar/:id' component={EditarAnimal} /> */}

                                <PrivateRoute exact path='/pecuaria/campoCadastro' component={CampoCadastro} />
                                <PrivateRoute exact path='/pecuaria/campoCadastro/novo' component={NovoCampo} />
                                <PrivateRoute path='/pecuaria/campoCadastro/editar/:id' component={EditarCampo} />

                                <PrivateRoute exact path='/pecuaria/anoPecuarioCadastro' component={AnoPecuarioCadastro} />
                                <PrivateRoute exact path='/pecuaria/anoPecuarioCadastro/novo' component={NovoAnoPecuario} />
                                <PrivateRoute path='/pecuaria/anoPecuarioCadastro/editar/:id' component={EditarAnoPecuario} />

                                <PrivateRoute exact path='/pecuaria/manejo/planejamento' component={ManejoPlanejamentoCadastro} />
                                <PrivateRoute exact path='/pecuaria/manejo/planejamento/novo' component={NovoManejoPlanejamento} />
                                <PrivateRoute path='/pecuaria/manejo/planejamento/editar/:id' component={EditarManejoPlanejamento} />
                                <PrivateRoute exact path='/pecuaria/manejo/planejamento/realizar' component={RealizarManejoPlanejamento} />
                                <PrivateRoute path='/pecuaria/manejo/planejamento/view/:id' component={ViewManejoPlanejamento} />

                                <PrivateRoute exact path='/pecuaria/manejo/execucao' component={ManejoExecucaoCadastro} />
                                <PrivateRoute exact path='/pecuaria/manejo/execucao/novo' component={NovoManejoExecucao} />
                                <PrivateRoute path='/pecuaria/manejo/execucao/editar/:id' component={EditarManejoExecucao} />
                                <PrivateRoute path='/pecuaria/manejo/execucao/view/:id' component={ViewManejoExecucao} />

                                <PrivateRoute exact path='/pecuaria/relatorio/movimentacaoAnimal' component={RelatorioMovimentacaoAnimal} />

                                <PrivateRoute exact path='/pecuaria/evolucao' component={EvolucaoCadastro} />
                                <PrivateRoute exact path='/pecuaria/evolucao/novo' component={NovoEvolucao} />
                                <PrivateRoute path='/pecuaria/evolucao/editar/:id' component={EditarEvolucao} />

                                <PrivateRoute exact path='/pecuaria/transferencia' component={TransferenciaCadastro} />
                                <PrivateRoute exact path='/pecuaria/transferencia/novo' component={NovoTransferencia} />
                                <PrivateRoute path='/pecuaria/transferencia/editar/:id' component={EditarTransferencia} />

                                <PrivateRoute exact path='/pecuaria/movimentacao/compra' component={CompraAnimal} />
                                <PrivateRoute exact path='/pecuaria/movimentacao/compra/novo' component={NovaCompraAnimal} />
                                <PrivateRoute path='/pecuaria/movimentacao/compra/editar/:id' component={EditarCompraAnimal} />

                                <PrivateRoute exact path='/pecuaria/movimentacao/venda' component={VendaAnimal} />
                                <PrivateRoute exact path='/pecuaria/movimentacao/venda/novo' component={NovaVendaAnimal} />
                                <PrivateRoute path='/pecuaria/movimentacao/venda/editar/:id' component={EditarVendaAnimal} />

                                <PrivateRoute exact path='/pecuaria/movimentacao/implantacao' component={ImplantacaoAnimal} />
                                <PrivateRoute exact path='/pecuaria/movimentacao/implantacao/novo' component={NovaImplantacaoAnimal} />
                                <PrivateRoute path='/pecuaria/movimentacao/implantacao/editar/:id' component={EditarImplantacaoAnimal} />

                                <PrivateRoute exact path='/pecuaria/movimentacao/outrasSaidas' component={OutrasSaidaAnimal} />
                                <PrivateRoute exact path='/pecuaria/movimentacao/outrasSaidas/novo' component={NovaOutrasSaidaAnimal} />
                                <PrivateRoute path='/pecuaria/movimentacao/outrasSaidas/editar/:id' component={EditarOutrasSaidaAnimal} />

                                <PrivateRoute exact path='/pecuaria/pesagem' component={ControlePeso} />

                                <PrivateRoute exact path='/fiscal/emitente' component={EmitenteCadastro} />
                                <PrivateRoute exact path='/fiscal/emitente/novo' component={NovoEmitenteCadastro} />
                                <PrivateRoute path='/fiscal/emitente/editar/:id' component={EditarEmitenteCadastro} />

                                <PrivateRoute exact path='/fiscal/monitoramento/nfeSefaz' component={MonitoramentoNFeCompra} />

                                <Redirect to='/dashboard' />
                            </Switch>
                        </Suspense>
                    </HorizontalLayout>
                );
            }
        }
    }
}

export default withRouter(Routes);
