
export const NEW_ENTRADA_ESTOQUE = 'NEW_ENTRADA_ESTOQUE'
export const NEW_ENTRADA_ESTOQUE_SUCCESS = 'NEW_ENTRADA_ESTOQUE_SUCCESS'

export const NEW_FILTER_ENTRADA_ESTOQUE = 'NEW_FILTER_ENTRADA_ESTOQUE'
export const NEW_FILTER_ENTRADA_ESTOQUE_PENDING = 'NEW_FILTER_ENTRADA_ESTOQUE_PENDING'
export const NEW_FILTER_ENTRADA_ESTOQUE_SUCCESS = 'NEW_FILTER_ENTRADA_ESTOQUE_SUCCESS'
export const NEW_FILTER_ENTRADA_ESTOQUE_FAILURE = 'NEW_FILTER_ENTRADA_ESTOQUE_FAILURE'

export const NEW_FILTER_NEXT_PAGE_ENTRADA_ESTOQUE = 'NEW_FILTER_NEXT_PAGE_ENTRADA_ESTOQUE'
export const NEW_FILTER_NEXT_PAGE_ENTRADA_ESTOQUE_PENDING = 'NEW_FILTER_NEXT_PAGE_ENTRADA_ESTOQUE_PENDING'
export const NEW_FILTER_NEXT_PAGE_ENTRADA_ESTOQUE_SUCCESS = 'NEW_FILTER_NEXT_PAGE_ENTRADA_ESTOQUE_SUCCESS'
export const NEW_FILTER_NEXT_PAGE_ENTRADA_ESTOQUE_FAILURE = 'NEW_FILTER_NEXT_PAGE_ENTRADA_ESTOQUE_FAILURE'

export const CREATE_ENTRADA_ESTOQUE = 'CREATE_ENTRADA_ESTOQUE'
export const CREATE_ENTRADA_ESTOQUE_PENDING = 'CREATE_ENTRADA_ESTOQUE_PENDING'
export const CREATE_ENTRADA_ESTOQUE_SUCCESS = 'CREATE_ENTRADA_ESTOQUE_SUCCESS'
export const CREATE_ENTRADA_ESTOQUE_FAILURE = 'CREATE_ENTRADA_ESTOQUE_FAILURE'

export const TABLE_ENTRADA_ESTOQUE = 'TABLE_ENTRADA_ESTOQUE'
export const TABLE_ENTRADA_ESTOQUE_PENDING = 'TABLE_ENTRADA_ESTOQUE_PENDING'
export const TABLE_ENTRADA_ESTOQUE_SUCCESS = 'TABLE_ENTRADA_ESTOQUE_SUCCESS'
export const TABLE_ENTRADA_ESTOQUE_FAILURE = 'TABLE_ENTRADA_ESTOQUE_FAILURE'

export const LIST_ENTRADA_ESTOQUE = 'LIST_ENTRADA_ESTOQUE'
export const LIST_ENTRADA_ESTOQUE_PENDING = 'LIST_ENTRADA_ESTOQUE_PENDING'
export const LIST_ENTRADA_ESTOQUE_SUCCESS = 'LIST_ENTRADA_ESTOQUE_SUCCESS'
export const LIST_ENTRADA_ESTOQUE_FAILURE = 'LIST_ENTRADA_ESTOQUE_FAILURE'

export const FIND_ENTRADA_ESTOQUE = 'FIND_ENTRADA_ESTOQUE'
export const FIND_ENTRADA_ESTOQUE_PENDING = 'FIND_ENTRADA_ESTOQUE_PENDING'
export const FIND_ENTRADA_ESTOQUE_SUCCESS = 'FIND_ENTRADA_ESTOQUE_SUCCESS'
export const FIND_ENTRADA_ESTOQUE_FAILURE = 'FIND_ENTRADA_ESTOQUE_FAILURE'

export const DELETE_ENTRADA_ESTOQUE = 'DELETE_ENTRADA_ESTOQUE'
export const DELETE_ENTRADA_ESTOQUE_PENDING = 'DELETE_ENTRADA_ESTOQUE_PENDING'
export const DELETE_ENTRADA_ESTOQUE_SUCCESS = 'DELETE_ENTRADA_ESTOQUE_SUCCESS'
export const DELETE_ENTRADA_ESTOQUE_FAILURE = 'DELETE_ENTRADA_ESTOQUE_FAILURE'

export const VIEW_DANFE_XML_MANUAL_ENTRADA_ESTOQUE = 'VIEW_DANFE_XML_MANUAL_ENTRADA_ESTOQUE'
export const VIEW_DANFE_XML_MANUAL_ENTRADA_ESTOQUE_PENDING = 'VIEW_DANFE_XML_MANUAL_ENTRADA_ESTOQUE_PENDING'
export const VIEW_DANFE_XML_MANUAL_ENTRADA_ESTOQUE_SUCCESS = 'VIEW_DANFE_XML_MANUAL_ENTRADA_ESTOQUE_SUCCESS'
export const VIEW_DANFE_XML_MANUAL_ENTRADA_ESTOQUE_FAILURE = 'VIEW_DANFE_XML_MANUAL_ENTRADA_ESTOQUE_FAILURE'

export const READ_XML_ENTRADA_ESTOQUE = 'READ_XML_ENTRADA_ESTOQUE'
export const READ_XML_ENTRADA_ESTOQUE_PENDING = 'READ_XML_ENTRADA_ESTOQUE_PENDING'
export const READ_XML_ENTRADA_ESTOQUE_SUCCESS = 'READ_XML_ENTRADA_ESTOQUE_SUCCESS'
export const READ_XML_ENTRADA_ESTOQUE_FAILURE = 'READ_XML_ENTRADA_ESTOQUE_FAILURE'

export const IMPORT_XML_ENTRADA_ESTOQUE = 'IMPORT_XML_ENTRADA_ESTOQUE'
export const IMPORT_XML_ENTRADA_ESTOQUE_PENDING = 'IMPORT_XML_ENTRADA_ESTOQUE_PENDING'
export const IMPORT_XML_ENTRADA_ESTOQUE_SUCCESS = 'IMPORT_XML_ENTRADA_ESTOQUE_SUCCESS'
export const IMPORT_XML_ENTRADA_ESTOQUE_FAILURE = 'IMPORT_XML_ENTRADA_ESTOQUE_FAILURE'

export const SELECT_PRODUCT_ITEM_XML_ENTRADA_ESTOQUE = 'SELECT_PRODUCT_ITEM_XML_ENTRADA_ESTOQUE'
export const SELECT_PRODUCT_ITEM_XML_ENTRADA_ESTOQUE_PENDING = 'SELECT_PRODUCT_ITEM_XML_ENTRADA_ESTOQUE_PENDING'
export const SELECT_PRODUCT_ITEM_XML_ENTRADA_ESTOQUE_SUCCESS = 'SELECT_PRODUCT_ITEM_XML_ENTRADA_ESTOQUE_SUCCESS'
export const SELECT_PRODUCT_ITEM_XML_ENTRADA_ESTOQUE_FAILURE = 'SELECT_PRODUCT_ITEM_XML_ENTRADA_ESTOQUE_FAILURE'
