import { takeLatest, put } from 'redux-saga/effects';
import moment from 'moment';

import {
  contaReceber,
  NEW_CONTA_RECEBER,
  NEW_CONTA_RECEBER_SUCCESS,
} from '@handler';

function* sagaNewContaReceber(action) {

  yield put({ type: NEW_CONTA_RECEBER_SUCCESS, registro: 
    {
      ...contaReceber.newRegistro,
      data: moment().startOf('day').format('YYYY-MM-DD'),
      dataEmissao: moment().startOf('day').format('YYYY-MM-DD'),
      competencia: moment().startOf('day').format('YYYY-MM-DD'),
      dataPrimeiraParcela: moment().startOf('day').format('YYYY-MM-DD'),
      tipoEntidade: 'PESSOA',
    } });
}

export default function* watchNewContaReceber() {
  yield takeLatest(NEW_CONTA_RECEBER, sagaNewContaReceber)
}
