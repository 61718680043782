import { takeLatest, put } from 'redux-saga/effects';
import moment from 'moment';
import {
  patrimonioManutencaoCadastro,
  NEW_PATRIMONIO_MANUTENCAO_CADASTRO,
  NEW_PATRIMONIO_MANUTENCAO_CADASTRO_SUCCESS,
} from '@handler';

function* sagaNewPatrimonioManutencaoCadastro(action) {

  yield put({
    type: NEW_PATRIMONIO_MANUTENCAO_CADASTRO_SUCCESS,
    registro: {
      ...patrimonioManutencaoCadastro.newRegistro,
      dataManutencao: moment().startOf('day').format('YYYY-MM-DD'),
    },
    isCadastroRapido: action.isCadastroRapido,
    // status: true,
  });
}

export default function* watchNewPatrimonioManutencaoCadastro() {
  yield takeLatest(NEW_PATRIMONIO_MANUTENCAO_CADASTRO, sagaNewPatrimonioManutencaoCadastro)
}
