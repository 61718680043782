import {
    LIST_RELATORIO_REPOSICAO_ESTOQUE_MINIMO_PENDING,
    LIST_RELATORIO_REPOSICAO_ESTOQUE_MINIMO_SUCCESS,
    LIST_RELATORIO_REPOSICAO_ESTOQUE_MINIMO_FAILURE,
    LIST_RELATORIO_REPOSICAO_ESTOQUE_MINIMO_PDF_SUCCESS
} from './actionTypes';


const initialState = { estoqueReposicao: null, relatorioPorFornecedor: false, loading: false }


function relatorioReposicaoEstoqueMinimoReducer(state = initialState, action) {

    switch (action.type) {
        case LIST_RELATORIO_REPOSICAO_ESTOQUE_MINIMO_PENDING:
            return {
                ...state,
                loading: true,
            }
        case LIST_RELATORIO_REPOSICAO_ESTOQUE_MINIMO_SUCCESS:
            return {
                ...state,
                relatorioPorFornecedor: action.relatorioPorFornecedor,
                estoqueReposicao: action.estoqueReposicao,
                loading: false
            }
        case LIST_RELATORIO_REPOSICAO_ESTOQUE_MINIMO_PDF_SUCCESS:
            return {
                ...state,
                loading: false
            }
        case LIST_RELATORIO_REPOSICAO_ESTOQUE_MINIMO_FAILURE:
            return {
                ...state,
                relatorioPorFornecedor: false,
                estoqueReposicao: null,
                loading: false
            }
        // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        default:
            return state
    }
}

export { relatorioReposicaoEstoqueMinimoReducer };