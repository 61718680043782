import { takeLatest, put, call } from 'redux-saga/effects';

import { findPatrimonioManutencaoCadastro } from '../service';
import {
  FIND_PATRIMONIO_MANUTENCAO_CADASTRO,
  FIND_PATRIMONIO_MANUTENCAO_CADASTRO_PENDING,
  FIND_PATRIMONIO_MANUTENCAO_CADASTRO_SUCCESS,
  FIND_PATRIMONIO_MANUTENCAO_CADASTRO_FAILURE
} from '../actionTypes';

function* sagaFindPatrimonioManutencaoCadastro(action) {
  yield put({ type: FIND_PATRIMONIO_MANUTENCAO_CADASTRO_PENDING, id: action.id })

  try {

    const registro = yield call(findPatrimonioManutencaoCadastro, action.id);

    yield put({ type: FIND_PATRIMONIO_MANUTENCAO_CADASTRO_SUCCESS, registro: registro })

  } catch (error) {

    yield put({ type: FIND_PATRIMONIO_MANUTENCAO_CADASTRO_FAILURE })
    // console.error(error) // eslint-disable-line

  }
}

export default function* watchFindPatrimonioManutencaoCadastro() {
  yield takeLatest(FIND_PATRIMONIO_MANUTENCAO_CADASTRO, sagaFindPatrimonioManutencaoCadastro)
}
