import { takeLatest, put, call } from 'redux-saga/effects';

import { path, history, storage } from '@commons';

import { createCatalagoProdutoWhiteLabel, updateCatalagoProdutoWhiteLabel } from '../service';
import {
  CREATE_CATALAGO_PRODUTO_WHITE_LABEL,
  CREATE_CATALAGO_PRODUTO_WHITE_LABEL_PENDING,
  CREATE_CATALAGO_PRODUTO_WHITE_LABEL_SUCCESS,
  CREATE_CATALAGO_PRODUTO_WHITE_LABEL_FAILURE,
} from '@handler';

import { AlertSuccess, AlertError } from '@components/common/AlertToast'
function* sagaCreateCatalagoProdutoWhiteLabel(action) {
  yield put({ type: CREATE_CATALAGO_PRODUTO_WHITE_LABEL_PENDING })

  try {
    let user = JSON.parse(storage.get(path.sessionUser));

    if (
      action.data.categoria !== 'DEFENSIVO'
      && action.data.categoria !== 'FERTILIZANTE'
    ) {
      action.data = {
        ...action.data,
        formulacao: '',
        dosagemHa: 0,
      }
    }
    if (
      action.data.categoria !== 'DEFENSIVO'
      && action.data.categoria !== 'FERTILIZANTE'
      && action.data.categoria !== 'CULTIVAR'
      && action.data.categoria !== 'COMBUSTIVEL'
    ) {
      action.data = {
        ...action.data,
        unidadeAplicacao: null,
        configLancAgricultura: false,
        configLancAgriculturaTipo: null,
      }
    } else {
      if (action.data.configLancAgriculturaTipo !== undefined && action.data.configLancAgriculturaTipo !== null &&
        action.data.configLancAgriculturaTipo !== '') {
        action.data = {
          ...action.data,
          configLancAgricultura: true,
        }
      } else {
        action.data = {
          ...action.data,
          configLancAgricultura: false,
        }
      }
    }
    var newCatalagoProduto = {
      ...action.data,
      "identificationCodeWhiteLabel": user.identificationCodeWhiteLabel,
    }

    if (action.data.id === undefined || action.data.id === null) {
      yield call(createCatalagoProdutoWhiteLabel, newCatalagoProduto);
    } else {
      yield call(updateCatalagoProdutoWhiteLabel,
        {
          ...action.data,
          id: action.data.id
        }
      );
    }

    yield put({ type: CREATE_CATALAGO_PRODUTO_WHITE_LABEL_SUCCESS, registro: action.data })

    if (!action.isCadastroRapido) {
      history.push('/corporate/catalagoInsumos');
    }

    AlertSuccess('Registro salvo com sucesso!');

  } catch (error) {
    yield put({ type: CREATE_CATALAGO_PRODUTO_WHITE_LABEL_FAILURE, registro: action.data })

    AlertError('Falha ao salvar o registro! Tente novamente...');
  }

}

export default function* watchCreateCatalagoProdutoWhiteLabel() {
  yield takeLatest(CREATE_CATALAGO_PRODUTO_WHITE_LABEL, sagaCreateCatalagoProdutoWhiteLabel)
}
