import * as Yup from 'yup';

const newRegistro = {
    descricao: '',
    formulacao: '',
    fabricante: '',
    observacao: '',
    status: true,
    categoria: null,
    grupo: null,
    dosagemHa: 0,
    unidadeCompra: null,
    unidadeAplicacao: null,
    controlaEstoque: true,
    unidadeEstoque: null,
    estoqueMinimo: 0,
    custo: 0,
    cultura: null,
    produtoInsumoEstoques: [],
    produtoInsumoFornecedores: [],
    configLancAgricultura: false,
    configLancAgriculturaTipo: null,
    importadoCatalago: false,
};

const validation = Yup.object().shape({
    descricao: Yup.string()
        .min(2, 'O campo deve ter no mínimo 2 caracteres')
        .max(120, 'Campo deve ter menos do que 120 caracteres')
        .required('Este campo é obrigatório'),
    formulacao: Yup.string()
        .max(250, 'Campo deve ter menos do que 250 caracteres'),
    fabricante: Yup.string()
        .max(120, 'Campo deve ter menos do que 120 caracteres'),
    observacao: Yup.string()
        .max(5000, 'Campo deve ter menos do que 5000 caracteres'),
    categoria: Yup.string()
        .typeError('Este campo é obrigatório')
        .required('Este campo é obrigatório'),
    grupo: Yup.string()
        .typeError('Este campo é obrigatório')
        .required('Este campo é obrigatório'),
    unidadeCompra: Yup.object()
        .typeError('Este campo é obrigatório')
        .shape({
            id: Yup.number()
                .typeError('Este campo é obrigatório')
                .required("Este campo é obrigatório"),
        }),
    unidadeAplicacao: Yup.object().when('categoria', {
        is: (categoria) => categoria === 'DEFENSIVO' || categoria === 'FERTILIZANTE'
            || categoria === 'CULTIVAR' || categoria === 'COMBUSTIVEL',
        then: Yup.object()
            .typeError('Este campo é obrigatório')
            .shape({
                id: Yup.number()
                    .typeError('Este campo é obrigatório')
                    .required("Este campo é obrigatório"),
            })
    }).nullable(),
    unidadeEstoque: Yup.object().when('controlaEstoque', {
        is: (controlaEstoque) => controlaEstoque === true,
        then: Yup.object()
            .typeError('Este campo é obrigatório')
            .shape({
                id: Yup.number()
                    .typeError('Este campo é obrigatório')
                    .required("Este campo é obrigatório"),
            })
    }).nullable(),
    estoqueMinimo: Yup.number().when('controlaEstoque', {
        is: (controlaEstoque) => controlaEstoque === true,
        then: Yup.number()
            .typeError('Este campo é obrigatório')
            .min(0, 'Por Favor, informe um valor válido'),
    }),
    custo: Yup.number()
        .typeError('Este campo é obrigatório')
        .min(0, 'Por Favor, informe um valor válido'),
    // CAMPO DOSAGEM
    dosagemHa: Yup.number().when('categoria', {
        is: (categoria) => (
            categoria === 'DEFENSIVO'
            || categoria === 'FERTILIZANTE'),
        then: Yup.number()
            .typeError('Este campo é obrigatório')
            .min(0, 'Por Favor, informe um valor válido'),
    }),
    // SEMENTE E MUDA
    cultura: Yup.object().when('categoria', {
        is: (categoria) => categoria === 'CULTIVAR',
        then: Yup.object()
            .typeError('Este campo é obrigatório')
            .shape({
                id: Yup.number()
                    .typeError('Este campo é obrigatório')
                    .required("Este campo é obrigatório"),
            })
    }).nullable(),
    produtoInsumoFornecedores: Yup.array()
        .of(
            Yup.object().shape({
                fornecedor: Yup.object()
                    .typeError('Este campo é obrigatório')
                    .shape({
                        id: Yup.number()
                            .typeError('Este campo é obrigatório')
                            .required("Este campo é obrigatório"),
                    }),
                codigoProdutoFornecedor: Yup.string()
                    .max(50, 'Campo deve ter menos do que 50 caracteres!'),
            })
        ),
});

export const produtoInsumoCadastro = {
    newRegistro,
    validation
}