import React, { Component } from "react";
import { Collapse } from "reactstrap";
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import classname from "classnames";
import { trackEvent } from "mixpanel";

class Navbar extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    var matchingMenuItem = null;
    var ul = document.getElementById("navigation");
    var items = ul.getElementsByTagName("a");
    for (var i = 0; i < items.length; ++i) {
      if (this.props.location.pathname === items[i].pathname) {
        matchingMenuItem = items[i];
        break;
      }
    }
  }

  closeAllMenu() {
    this.setState({
      MenuCadastroState: false,
    });
    this.props.funcCloseMenu();
  }

  render() {
    const { loginReducer } = this.props;

    return (
      <React.Fragment>
        <div className="topnav">
          <div className="container-fluid">
            <nav className="navbar navbar-light navbar-expand-lg topnav-menu">
              <Collapse
                isOpen={this.props.menuOpen}
                className="navbar-collapse"
              >
                <div id="navigation">
                  <ul className="navbar-nav">
                    <li className="nav-item">
                      <Link className="nav-link" to="/home"
                        onClick={e => {
                          this.closeAllMenu();
                        }}>
                        <i className="mdi mdi-view-dashboard mr-2"></i>Dashboard
                      </Link>
                    </li>

                    {/* <li className="nav-item">
                      <Link className="nav-link" to="/home"
                        onClick={e => {
                          this.closeAllMenu();
                        }}>
                        <i className="fa fa-thermometer-0 mr-2"></i>Atualizações
                      </Link>
                    </li> */}

                    {
                      loginReducer.user.perfil === 'EMPRESA_WHITE_LABEL_ADMIN' ||
                        loginReducer.user.perfil === 'EMPRESA_WHITE_LABEL_FUNC' ||
                        loginReducer.user.perfil === 'PARCEIRO_ADMIN' ||
                        loginReducer.user.perfil === 'PARCEIRO_REPRESENTANTE'
                        ? <li className="nav-item dropdown">
                          <Link
                            className="nav-link dropdown-toggle arrow-none"
                            to="/#"
                            onClick={e => {
                              e.preventDefault();
                              this.setState({ MenuCadastroState: !this.state.MenuCadastroState });
                            }}
                          >
                            <i className="fa fa-building mr-2"></i>On Farm Corporate
                          </Link>

                          <div
                            className={classname("dropdown-menu", {
                              show: this.state.MenuCadastroState
                            })}
                          >
                            <Link to="/corporate/contaProdutor" className="dropdown-item"
                              onClick={e => {
                                this.closeAllMenu();
                              }}>
                              Conta Produtor
                            </Link>
                            <Link to="/corporate/grupoContaProdutor" className="dropdown-item"
                              onClick={e => {
                                this.closeAllMenu();
                              }}>
                              Grupo de Conta Produtor
                            </Link>
                            <hr />
                            <Link to="/corporate/catalagoInsumos" className="dropdown-item"
                              onClick={e => {
                                this.closeAllMenu();
                              }}>
                              Catálago de Insumos
                            </Link>
                            <hr />
                            <Link to="/corporate/usuarios" className="dropdown-item"
                              onClick={e => {
                                this.closeAllMenu();
                              }}>
                              Usuário
                            </Link>
                            {loginReducer.user.perfil === 'EMPRESA_WHITE_LABEL_ADMIN' ||
                              loginReducer.user.perfil === 'EMPRESA_WHITE_LABEL_FUNC'
                              ? <div className="dropdown">
                                <hr />
                                <Link
                                  to="/#"
                                  className="dropdown-item dropdown-toggle arrow-none"
                                  onClick={e => {
                                    e.preventDefault();
                                    this.setState({
                                      EmailState: !this.state.EmailState
                                    });
                                  }}
                                >
                                  Cadastros <div className="arrow-down"></div>
                                </Link>
                                <div
                                  className={classname("dropdown-menu", {
                                    show: this.state.EmailState
                                  })}
                                  aria-labelledby="topnav-email"
                                >
                                  <Link to="/corporate/parceiros" className="dropdown-item"
                                    onClick={e => {
                                      this.closeAllMenu();
                                    }}>
                                    Parceiro
                                  </Link>
                                  <Link to="/corporate/perfilAcesso" className="dropdown-item"
                                    onClick={e => {
                                      this.closeAllMenu();
                                    }}>
                                    Perfil de Acesso
                                  </Link>
                                </div>
                              </div>
                              : <></>}
                          </div>
                        </li> : <></>}

                    <li className="nav-item">
                      <Link className="nav-link" to="/suporte"
                        onClick={e => {
                          trackEvent('Clicou no Menu Central de Ajuda');
                          this.closeAllMenu();
                        }}>
                        <i className="fa fa-headset mr-2"></i>Central de Ajuda
                      </Link>
                    </li>

                  </ul>
                </div>
              </Collapse>
            </nav>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export function mapStateToProps(state) {
  const { loginReducer } = state.entities;
  return { loginReducer };
};

function mapDispatchToProps(dispatch) {
  return {}
}

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(Navbar))