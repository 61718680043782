import { path, httpService } from '@commons';

/////////////////////////////////////////////////////////////////////////////////
const args = { ...path, path: '/unidadeMedidaConversao' };
/////////////////////////////////////////////////////////////////////////////////

export function createUnidadeMedidaConversaoCadastro(payload) {
  return httpService.post(args, payload);
}

export function updateUnidadeMedidaConversaoCadastro(payload) {
  const { id } = payload

  return httpService.put({ ...args, args: `/${id}` }, payload);
}

export function listUnidadeMedidaConversaoCadastro(param) {
  return httpService.get({...args, args: param});
}

export function findUnidadeMedidaConversaoCadastro(id) {
  return httpService.get({ ...args, args: `/${id}` });
}

export function findAllUnidadeMedidaConversaoCadastro() {
  return httpService.get({ ...args, path: '/unidadeMedidaConversao/all' });
}

export function deleteUnidadeMedidaConversaoCadastro(id) {
  return httpService.del({ ...args, args: `/${id}` });
}

export function findConversao(source, target) {
  return httpService.get({ ...args, path: `/unidadeMedidaConversao/findConversao/${source.id}/${target.id}` });
}

export function listAllUnidadeMedidaConversaoGlobal(param) {
  return httpService.getApiManager(`/unidadeMedidaConversaoWhiteLabel/${param ?? ''}`, true);
}
