import { takeLatest, put, call } from 'redux-saga/effects';

import { listUnidadeMedidaCadastro } from '../service';
import {
  TABLE_UNIDADE_MEDIDA_CADASTRO,
  TABLE_UNIDADE_MEDIDA_CADASTRO_PENDING,
  TABLE_UNIDADE_MEDIDA_CADASTRO_SUCCESS,
  TABLE_UNIDADE_MEDIDA_CADASTRO_FAILURE
} from '../actionTypes';

function* sagaListUnidadeMedidaCadastro(action) {
  yield put({ type: TABLE_UNIDADE_MEDIDA_CADASTRO_PENDING })

  try {
    const table = yield call(listUnidadeMedidaCadastro, action.args)
    yield put({ type: TABLE_UNIDADE_MEDIDA_CADASTRO_SUCCESS, table: table })

  } catch (error) {

    yield put({ type: TABLE_UNIDADE_MEDIDA_CADASTRO_FAILURE })
    // console.error(error) // eslint-disable-line

  }
}

export default function* watchListUnidadeMedidaCadastro() {
  yield takeLatest(TABLE_UNIDADE_MEDIDA_CADASTRO, sagaListUnidadeMedidaCadastro)
}