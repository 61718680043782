

import watchLogin from './saga/login';
import watchHasAuthority from './saga/hasAuthority';
import watchHasAuthorityCorporate from './saga/hasAuthorityCorporate';
import watchHasAuthorityPainelAgrare from './saga/hasAuthorityPainelAgrare';
import watchRegister from './saga/register';
import watchRecoverPassword from './saga/recover';

export const loginSaga = [
    watchLogin(),
    watchHasAuthority(),
    watchHasAuthorityCorporate(),
    watchHasAuthorityPainelAgrare(),
    watchRegister(),
    watchRecoverPassword(),
]

export * from './actionTypes';
export * from './reducer';
export * from './hasRequiredRole';
