import { path, httpService } from '@commons';

/////////////////////////////////////////////////////////////////////////////////
const args = { ...path, path: '/fiscal/nfe/emissao' };
/////////////////////////////////////////////////////////////////////////////////

export function createNFeEmissao(payload) {
  return httpService.post(args, payload);
}

export function updateNFeEmissao(payload) {
  const { id } = payload

  return httpService.put({ ...args, args: `/${id}` }, payload);
}

export function findNFeEmissao(id) {
  return httpService.get({ ...args, args: `/${id}` });
}

export function filterNFeEmissao(idPropriedade, datas, where) {
  return httpService.get({ ...args, args: `?propriedade=${idPropriedade}&datas=${datas}${where}` });
}