import { takeLatest, put, call } from 'redux-saga/effects';
import moment from "moment";

import { history, storage } from '@commons';

import {
  planejamento,
  NEW_PLANEJAMENTO,
  NEW_PLANEJAMENTO_SUCCESS,
  CONFIG_SESSION,
  findPlanejamento,
} from '@handler';

function* sagaNewPlanejamento(action) {

  if (action.idPlanejamentoADuplicar == undefined || action.idPlanejamentoADuplicar == null) {
    yield put({
      type: NEW_PLANEJAMENTO_SUCCESS, registro: {
        ...planejamento.newRegistro,
        data: moment().startOf('day').format('YYYY-MM-DD'),
        talhao: action.talhao,
        safra: JSON.parse(storage.get(CONFIG_SESSION)).safraAtiva,
        replicarLancamento: false,
        talhoesList: []
      }
    });
  } else {
    var registro = yield call(findPlanejamento, action.idPlanejamentoADuplicar);
    registro.id = null;

    yield put({
      type: NEW_PLANEJAMENTO_SUCCESS,
      registro: {
        ...registro,
        replicarLancamento: true,
        status: 'PLANEJADO',
        talhao: null,
        aplicacoes: registro.aplicacoes.map(aplicacao => ({
          ...aplicacao,
          id: null,
          atividadeAgro: null,
          status: 'PLANEJADO',
          sync: false,
        })),
        alocacaoFuncionarios: registro.alocacaoFuncionarios.map(funcionario => ({
          ...funcionario,
          id: null,
          atividadeAgro: null,
          status: 'PLANEJADO',
          sync: false,
        })),
        utilizacaoMaquinarios: registro.utilizacaoMaquinarios.map(maquinario => ({
          ...maquinario,
          id: null,
          atividadeAgro: null,
          status: 'PLANEJADO',
          sync: false,
        })),
        talhoesList: [
          {
            id: registro.talhao.id,
            safra: registro.safra,
            talhao: registro.talhao,
            ativo: true,
            area: registro.talhao.area,
          }
        ]
      }
    });
    history.push('/agricultura/planejamento/novo');
  }

}

export default function* watchNewPlanejamento() {
  yield takeLatest(NEW_PLANEJAMENTO, sagaNewPlanejamento)
}
