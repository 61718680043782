import { takeLatest, put, call } from 'redux-saga/effects';

import { getRelatorioAtividadesTalhaoAnalitico } from '../service';
import { CONFIG_SESSION } from '@handler/common/startSession';
import { storage } from '@commons/storage';
import { formatDateDDMMYYYY } from '@components/common/format';
import {
  GET_LOADING_PLANTIO_MANEJO_PENDING,
  GET_LOADING_PLANTIO_MANEJO_SUCCESS,
  RELATORIO_ATIVIDADES_TALHAO_ANALITICO,
} from '../actionTypes';
import { CONST_FILTER_PLANTIO_MANEJO } from '@commons/consts';

function* sagaRelatorioAtividadesTalhaoAnaliticoPlantioManejo(action) {
  yield put({ type: GET_LOADING_PLANTIO_MANEJO_PENDING })

  try {
    const idFazenda = JSON.parse(storage.get(CONFIG_SESSION)).fazendaSelecionada.id;
    const idSafraAtiva = JSON.parse(storage.get(CONFIG_SESSION)).safraAtiva?.id ?? -1;

    let filterSaved = sessionStorage.getItem(CONST_FILTER_PLANTIO_MANEJO) !== null ? JSON.parse(sessionStorage.getItem(CONST_FILTER_PLANTIO_MANEJO)) : null;

    const datas = formatDateDDMMYYYY(filterSaved.dataInicial) + '_' + formatDateDDMMYYYY(filterSaved.dataFinal);

    let idAplicacao = ''
    if (action.idAplicacao !== undefined && action.idAplicacao !== null) {
      idAplicacao = '&id=' + action.idAplicacao
    }

    yield call(getRelatorioAtividadesTalhaoAnalitico, idFazenda, idSafraAtiva, datas, filterSaved.where, idAplicacao, action.mostrarCusto ?? false)

    yield put({
      type: GET_LOADING_PLANTIO_MANEJO_SUCCESS
    });
  } catch (error) {
    yield put({ type: GET_LOADING_PLANTIO_MANEJO_SUCCESS })
    // console.error(error) // eslint-disable-line
  }
}

export default function* watchRelatorioAtividadesTalhaoAnaliticoPlantioManejo() {
  yield takeLatest(RELATORIO_ATIVIDADES_TALHAO_ANALITICO, sagaRelatorioAtividadesTalhaoAnaliticoPlantioManejo)
}