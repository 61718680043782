import * as moment from 'moment'

export function formatDateDDMMYYYY(date) {
  return moment(date, 'YYYY-MM-DD').format('DD/MM/YYYY');
}
export function formatDateYYYYMMDD(date) {
    return moment(date, 'YYYY-MM-DD').format('YYYY-MM-DD');
}

export function isDateValidVerifyDateBetween(from, to, check) {

    var fDate, lDate, cDate;
    fDate = Date.parse(from);
    lDate = Date.parse(to);
    cDate = Date.parse(check);
  
    if ((cDate <= lDate && cDate >= fDate)) {
      return true;
    }
    return false;
  }

  export function formatToDDMMYYYYWithTime(date) {
    return moment(date).format('DD/MM/YYYY HH:mm');
  }
