


import watchNewPatrimonioManutencaoCadastro from './saga/new';
import watchNewFilterPatrimonioManutencao from './saga/newFilter';
import watchNewFilterNextPagePatrimonioManutencao from './saga/newFilterNextPage';
import watchFindPatrimonioManutencaoCadastro from './saga/find';
import watchCreatePatrimonioManutencaoCadastro from './saga/create';
import watchListAllPatrimonioManutencaoCadastro from './saga/listAll';
import watchListPatrimonioManutencaoCadastro from './saga/listTable';
import watchDeletePatrimonioManutencaoCadastro from './saga/delete';
import watchCancelPatrimonioManutencaoCadastroRapido from './saga/cancel';

export const patrimonioManutencaoCadastroSaga = [
    watchNewPatrimonioManutencaoCadastro(),
    watchNewFilterPatrimonioManutencao(),
    watchNewFilterNextPagePatrimonioManutencao(),
    watchFindPatrimonioManutencaoCadastro(),
    watchCreatePatrimonioManutencaoCadastro(),
    watchListAllPatrimonioManutencaoCadastro(),
    watchListPatrimonioManutencaoCadastro(),
    watchDeletePatrimonioManutencaoCadastro(),
    watchCancelPatrimonioManutencaoCadastroRapido(),
]

export * from './actionTypes';
export * from './reducer';
export * from './service';
export * from './entity';
