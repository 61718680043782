import { takeLatest, put } from 'redux-saga/effects';

import {
  funcionarioCargoCadastro,
  NEW_FUNCIONARIO_CARGO_CADASTRO,
  NEW_FUNCIONARIO_CARGO_CADASTRO_SUCCESS,
} from '@handler';

function* sagaNewFuncionarioCargoCadastro(action) {

  yield put({
    type: NEW_FUNCIONARIO_CARGO_CADASTRO_SUCCESS,
    registro: funcionarioCargoCadastro.newRegistro,
    isCadastroRapido: action.isCadastroRapido,
  });
}

export default function* watchNewFuncionarioCargoCadastro() {
  yield takeLatest(NEW_FUNCIONARIO_CARGO_CADASTRO, sagaNewFuncionarioCargoCadastro)
}
