import React, { useEffect, useState } from "react";
import { useSelector } from 'react-redux';
import { Button } from "reactstrap";
import AreasListWindow from '../AreasListWindow';
import WeatherWindow from '../WeatherWindow';
import ActivityWindow from "../ActivityWindow";
import NdviWindow from "../NdviWindow";
import './styles.css';

function MapMenu({
    areas,
    areaSigla,
    isCadastro,
    setCenter,
}) {
    const [isMobile, setIsMobile] = useState(false);
    const [activeWindow, setActiveWindow] = useState(null);

    const mapReducer = useSelector((state) => state.entities.mapReducer);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        window.addEventListener('resize', handleResize);
        handleResize();

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    // Array de janelas correspondentes aos botoes
    const menuWindows = [
        null, // Índice 0 é nulo para nenhum botão ativo inicialmente
        <AreasListWindow
            areas={areas}
            areaSigla={areaSigla}
            isCadastro={isCadastro}
            setCenter={setCenter}
            onClose={() => setActiveWindow(null)}
        />,
        <ActivityWindow
            onClose={() => setActiveWindow(null)}
        />,
        <NdviWindow
            onClose={() => setActiveWindow(null)}
            setCenter={setCenter}
        />,
        <WeatherWindow
            onClose={() => setActiveWindow(null)}
        />,
    ];

    const handleButtonClick = (index) => {
        setActiveWindow(index);
    };

    return (
        <React.Fragment>
            <div className="map-menu-container">
                <Button
                    className={`map-menu-btn btn btn-light ${activeWindow === 1 ? 'active' : ''}`}
                    onClick={() => handleButtonClick(1)}
                >
                    <i className='fa fa-map menu-icon' />
                </Button>

                {!isCadastro && mapReducer.mode !== "KML" && (
                    <>
                        <Button
                            className={`map-menu-btn btn btn-light ${activeWindow === 2 ? 'active' : ''}`}
                            onClick={() => handleButtonClick(2)}>
                            <i className="fa fa-file-invoice menu-icon" />
                        </Button>

                        <Button
                            className={`map-menu-btn btn btn-light ${activeWindow === 3 ? 'active' : ''}`}
                            onClick={() => handleButtonClick(3)}
                        >
                            <i className='fa fa-satellite menu-icon' />
                        </Button>

                        <Button
                            className={`map-menu-btn btn btn-light ${activeWindow === 4 ? 'active' : ''}`}
                            onClick={() => handleButtonClick(4)}
                        >
                            <i className='fa fa-cloud menu-icon' />
                        </Button>
                    </>
                )}
            </div>

            {activeWindow !== null && activeWindow !== 0 && (
                <div className="map-menu-window">
                    {menuWindows[activeWindow]}
                </div>
            )}
        </React.Fragment>
    );
}

export default React.memo(MapMenu);
