import { path, httpService } from '@commons';

/////////////////////////////////////////////////////////////////////////////////
const args = { ...path, path: '/produtoInsumo' };
/////////////////////////////////////////////////////////////////////////////////

export function createProdutoInsumoCadastro(payload) {
  return httpService.post(args, payload);
}

export function updateProdutoInsumoCadastro(payload) {
  const { id } = payload

  return httpService.put({ ...args, args: `/${id}` }, payload);
}

export function listProdutoInsumoCadastro(param) {
  return httpService.get({...args, args: param});
}

export function listInsumosCadernoDeCampo(param) {
  return httpService.get({...args, args: `/searchInsumosCadernoDeCampo${param}`});
}

export function listInsumosItem(param) {
  return httpService.get({...args, args: `/searchInsumosItem${param}`});
}

export function listInsumosCombustivel(param) {
  return httpService.get({...args, args: `/searchInsumosCombustivel${param}`});
}

export function findProdutoInsumoCadastro(id) {
  return httpService.get({ ...args, args: `/${id}` });
}

export function deleteProdutoInsumoCadastro(id) {
  return httpService.del({ ...args, args: `/${id}` });
}

export function importarProdutoWhiteLabel(idProdutoWl) {
  return httpService.post({ ...args, args: `/importarProdutoWhiteLabel/${idProdutoWl}` });
}
