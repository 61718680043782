import { takeLatest, put } from 'redux-saga/effects';
import {
    CANCEL_FUNCIONARIO_CARGO_CADASTRO_RAPIDO,
    CANCEL_FUNCIONARIO_CARGO_CADASTRO_RAPIDO_SUCCESS
} from '../actionTypes';
function* sagaCancelFuncionarioCargoCadastroRapido() {
  yield put({ type: CANCEL_FUNCIONARIO_CARGO_CADASTRO_RAPIDO_SUCCESS})
}
export default function* watchCancelFuncionarioCargoCadastroRapido() {
  yield takeLatest(CANCEL_FUNCIONARIO_CARGO_CADASTRO_RAPIDO, sagaCancelFuncionarioCargoCadastroRapido)
}
