import { takeLatest, put, call } from 'redux-saga/effects';

import { findAllPatrimonioAbastecimentoCadastro } from '../service';
import {
  LIST_PATRIMONIO_ABASTECIMENTO_CADASTRO,
  LIST_PATRIMONIO_ABASTECIMENTO_CADASTRO_PENDING,
  LIST_PATRIMONIO_ABASTECIMENTO_CADASTRO_SUCCESS,
  LIST_PATRIMONIO_ABASTECIMENTO_CADASTRO_FAILURE
} from '../actionTypes';

function* sagaListAllPatrimonioAbastecimentoCadastro(action) {
  yield put({ type: LIST_PATRIMONIO_ABASTECIMENTO_CADASTRO_PENDING })

  try {
    const list = yield call(findAllPatrimonioAbastecimentoCadastro)
    
    yield put({ type: LIST_PATRIMONIO_ABASTECIMENTO_CADASTRO_SUCCESS, list: list })
  } catch (error) {
    yield put({ type: LIST_PATRIMONIO_ABASTECIMENTO_CADASTRO_FAILURE })
  }
}

export default function* watchListAllPatrimonioAbastecimentoCadastro() {
  yield takeLatest(LIST_PATRIMONIO_ABASTECIMENTO_CADASTRO, sagaListAllPatrimonioAbastecimentoCadastro)
}
