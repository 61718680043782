import { takeLatest, put, call } from 'redux-saga/effects';

import {
  UPDATE_PRODUTO_INSUMO_VINCULAR_FORNECEDOR,
  UPDATE_PRODUTO_INSUMO_VINCULAR_FORNECEDOR_PENDING,
  UPDATE_PRODUTO_INSUMO_VINCULAR_FORNECEDOR_FAILURE,
  UPDATE_PRODUTO_INSUMO_VINCULAR_FORNECEDOR_SUCCESS,
  findProdutoInsumoCadastro,
} from '@handler';

import { createProdutoInsumoCadastro, updateProdutoInsumoCadastro } from '../service';

import { AlertSuccess, AlertError } from '@components/common/AlertToast'
import { formatJsonRemoveEmptyOrNull } from '@components/common/format';

function* sagaUpdateProdutoInsumoVincularFornecedor(action) {
  yield put({ type: UPDATE_PRODUTO_INSUMO_VINCULAR_FORNECEDOR_PENDING })

  try {
    const produto = yield call(findProdutoInsumoCadastro, action.produto.id);

    if (produto.produtoInsumoFornecedores === undefined || produto.produtoInsumoFornecedores === null){
      produto.produtoInsumoFornecedores = [];
    }
    produto.produtoInsumoFornecedores.push({
      ...action.data,
      produto: action.produto,
      fornecedor: action.fornecedor
    });

    yield call(updateProdutoInsumoCadastro,
      formatJsonRemoveEmptyOrNull(
        produto
      )
    );

    yield put({ type: UPDATE_PRODUTO_INSUMO_VINCULAR_FORNECEDOR_SUCCESS })

    AlertSuccess('Vínculo do fornecedor realizado com sucesso. Adicione o insumo novamente na nota');

  } catch (error) {
    yield put({ type: UPDATE_PRODUTO_INSUMO_VINCULAR_FORNECEDOR_FAILURE })

    AlertError('Falha ao vincular fornecedor! Tente novamente...');
  }

}

export default function* watchUpdateProdutoInsumoVincularFornecedor() {
  yield takeLatest(UPDATE_PRODUTO_INSUMO_VINCULAR_FORNECEDOR, sagaUpdateProdutoInsumoVincularFornecedor)
}
