import React from 'react';

import {
    Col,
    FormGroup,
    Label,
    FormFeedback,
    Input,
    InputGroup,
    InputGroupAddon
} from 'reactstrap';

import { Field } from 'formik';

import './InputDecimal.css'
import { InfoTooltip } from './InfoTooltip';

export const InputDecimal = (props) => {

    const { col, name, label, placeholder, plusChange, suffix, fixedDecimalLength, renderInfoTooltip, idTooltip,
        titleInfoTooltip, msgInfoTooltip, preffix, onBlur, disabled, bottomText } = props;

    return (
        <Col md={col}>

            <FormGroup>
                <Label htmlFor={'id_' + name} >{label} {" "}
                    {
                        renderInfoTooltip ?
                            <InfoTooltip
                            id={idTooltip === undefined || idTooltip === null ? 'idtool_' + name.split('.').shift() : idTooltip}
                                title={titleInfoTooltip}
                                msg={msgInfoTooltip}
                            />
                            : <React.Fragment />
                    }
                </Label>
                <Field name={name}>
                    {({ field, form, meta }) => (
                        <>
                            <InputGroup>
                                <InputGroupAddon addonType="append">{preffix}</InputGroupAddon>
                                <Input
                                    {...field}
                                    name={name}
                                    type={'number'}
                                    value={field.value ?? ''}
                                    placeholder={placeholder}
                                    id={'id_' + name}
                                    invalid={meta.error && meta.touched}
                                    className='form-control'
                                    disabled={disabled}
                                    autoComplete="off"
                                    onChange={(e) => {
                                        form.handleChange(e);
                                        plusChange(e);
                                    }}
                                    onBlur={(e) => {
                                        const val = e.target.value;
                                        if (val.length > 0) {
                                            e.target.value = Number(val).toFixed(fixedDecimalLength);
                                            form.handleChange(e);
                                            plusChange(e);
                                        }
                                        onBlur(e);
                                    }}
                                    onWheel={(e) => e.target.blur()} // desativa scroll do mouse no campo, para não editar valor
                                />
                                <InputGroupAddon addonType="append">{suffix}</InputGroupAddon>
                                <FormFeedback>{meta.error}</FormFeedback>
                            </InputGroup>
                            <span style={{ fontStyle: 'italic', fontSize: '12px' }}>{bottomText}</span>
                        </>
                    )}
                </Field>
            </FormGroup>

        </Col>
    )
}

InputDecimal.defaultProps = {
    col: 12,
    fixedDecimalLength: 2,
    disabled: false,
    bottomText: '',
    renderInfoTooltip: false,
    titleInfoTooltip: 'Informação',
    plusChange: () => { },
    onBlur: () => { }
};