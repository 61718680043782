
export const NEW_TRANSFERENCIA_ESTOQUE = 'NEW_TRANSFERENCIA_ESTOQUE'
export const NEW_TRANSFERENCIA_ESTOQUE_SUCCESS = 'NEW_TRANSFERENCIA_ESTOQUE_SUCCESS'

export const NEW_FILTER_TRANSFERENCIA_ESTOQUE = 'NEW_FILTER_TRANSFERENCIA_ESTOQUE'
export const NEW_FILTER_TRANSFERENCIA_ESTOQUE_PEDING = 'NEW_FILTER_TRANSFERENCIA_ESTOQUE_PEDING'
export const NEW_FILTER_TRANSFERENCIA_ESTOQUE_SUCCESS = 'NEW_FILTER_TRANSFERENCIA_ESTOQUE_SUCCESS'
export const NEW_FILTER_TRANSFERENCIA_ESTOQUE_FAILURE = 'NEW_FILTER_TRANSFERENCIA_ESTOQUE_FAILURE'

export const NEW_FILTER_NEXT_PAGE_TRANSFERENCIA_ESTOQUE = 'NEW_FILTER_NEXT_PAGE_TRANSFERENCIA_ESTOQUE'
export const NEW_FILTER_NEXT_PAGE_TRANSFERENCIA_ESTOQUE_PENDING = 'NEW_FILTER_NEXT_PAGE_TRANSFERENCIA_ESTOQUE_PENDING'
export const NEW_FILTER_NEXT_PAGE_TRANSFERENCIA_ESTOQUE_SUCCESS = 'NEW_FILTER_NEXT_PAGE_TRANSFERENCIA_ESTOQUE_SUCCESS'
export const NEW_FILTER_NEXT_PAGE_TRANSFERENCIA_ESTOQUE_FAILURE = 'NEW_FILTER_NEXT_PAGE_TRANSFERENCIA_ESTOQUE_FAILURE'

export const CREATE_TRANSFERENCIA_ESTOQUE = 'CREATE_TRANSFERENCIA_ESTOQUE'
export const CREATE_TRANSFERENCIA_ESTOQUE_PENDING = 'CREATE_TRANSFERENCIA_ESTOQUE_PENDING'
export const CREATE_TRANSFERENCIA_ESTOQUE_SUCCESS = 'CREATE_TRANSFERENCIA_ESTOQUE_SUCCESS'
export const CREATE_TRANSFERENCIA_ESTOQUE_FAILURE = 'CREATE_TRANSFERENCIA_ESTOQUE_FAILURE'

export const TABLE_TRANSFERENCIA_ESTOQUE = 'TABLE_TRANSFERENCIA_ESTOQUE'
export const TABLE_TRANSFERENCIA_ESTOQUE_PENDING = 'TABLE_TRANSFERENCIA_ESTOQUE_PENDING'
export const TABLE_TRANSFERENCIA_ESTOQUE_SUCCESS = 'TABLE_TRANSFERENCIA_ESTOQUE_SUCCESS'
export const TABLE_TRANSFERENCIA_ESTOQUE_FAILURE = 'TABLE_TRANSFERENCIA_ESTOQUE_FAILURE'

export const LIST_TRANSFERENCIA_ESTOQUE = 'LIST_TRANSFERENCIA_ESTOQUE'
export const LIST_TRANSFERENCIA_ESTOQUE_PENDING = 'LIST_TRANSFERENCIA_ESTOQUE_PENDING'
export const LIST_TRANSFERENCIA_ESTOQUE_SUCCESS = 'LIST_TRANSFERENCIA_ESTOQUE_SUCCESS'
export const LIST_TRANSFERENCIA_ESTOQUE_FAILURE = 'LIST_TRANSFERENCIA_ESTOQUE_FAILURE'

export const FIND_TRANSFERENCIA_ESTOQUE = 'FIND_TRANSFERENCIA_ESTOQUE'
export const FIND_TRANSFERENCIA_ESTOQUE_PENDING = 'FIND_TRANSFERENCIA_ESTOQUE_PENDING'
export const FIND_TRANSFERENCIA_ESTOQUE_SUCCESS = 'FIND_TRANSFERENCIA_ESTOQUE_SUCCESS'
export const FIND_TRANSFERENCIA_ESTOQUE_FAILURE = 'FIND_TRANSFERENCIA_ESTOQUE_FAILURE'

export const DELETE_TRANSFERENCIA_ESTOQUE = 'DELETE_TRANSFERENCIA_ESTOQUE'
export const DELETE_TRANSFERENCIA_ESTOQUE_PENDING = 'DELETE_TRANSFERENCIA_ESTOQUE_PENDING'
export const DELETE_TRANSFERENCIA_ESTOQUE_SUCCESS = 'DELETE_TRANSFERENCIA_ESTOQUE_SUCCESS'
export const DELETE_TRANSFERENCIA_ESTOQUE_FAILURE = 'DELETE_TRANSFERENCIA_ESTOQUE_FAILURE'
