import { path, httpService } from '@commons';

/////////////////////////////////////////////////////////////////////////////////
const args = { ...path, path: '/patrimonio/manutencao' };
/////////////////////////////////////////////////////////////////////////////////

export function createPatrimonioManutencaoCadastro(payload) {
  return httpService.post(args, payload);
}

export function updatePatrimonioManutencaoCadastro(payload) {
  const { id } = payload

  return httpService.put({ ...args, args: `/${id}` }, payload);
}

export function listPatrimonioManutencaoCadastro(param) {
  return httpService.get({...args, args: param});
}

export function findPatrimonioManutencaoCadastro(id) {
  return httpService.get({ ...args, args: `/${id}` });
}

export function findAllPatrimonioManutencaoCadastro() {
  return httpService.get({ ...args, path: '/patrimonio/manutencao/all' });
}

export function deletePatrimonioManutencaoCadastro(id) {
  return httpService.del({ ...args, args: `/${id}` });
}

export function attachFiles(itemId, payload) {
  return httpService.putAttachFiles({ ...args, args: `/attachFiles/${itemId}` }, payload);
}

export function deleteAttachFiles(itemId, filesIdList) {
  return httpService.putDeleteFiles({ ...args, args: `/deleteFiles/${itemId}` }, filesIdList);
}

export function filterPatrimonioManutencao(idPropriedade, datas, where) {
  return httpService.get({ ...args, args: `/filterManutencao?propriedade=${idPropriedade}&datas=${datas}${where}` });
}
