import { path, httpService } from '@commons';

/////////////////////////////////////////////////////////////////////////////////
const args = { ...path, path: '/estoque/entrada' };
/////////////////////////////////////////////////////////////////////////////////

export function createEntradaEstoque(payload) {
  return httpService.post(args, payload);
}

export function updateEntradaEstoque(payload) {
  const { id } = payload

  return httpService.put({ ...args, args: `/${id}` }, payload);
}

export function listEntradaEstoque(param) {
  return httpService.get({ ...args, args: param });
}

export function listAllEntradaEstoque(idFazenda, datas, where) {
  return httpService.get({ ...args, path: `/estoque/entrada?propriedade=${idFazenda}&datas=${datas}${where}` });
}

export function findEntradaEstoque(id) {
  return httpService.get({ ...args, args: `/${id}` });
}

export function findAllEntradaEstoque() {
  return httpService.get({ ...args, path: '/estoque/entrada/all' });
}

export function deleteEntradaEstoque(id) {
  return httpService.del({ ...args, args: `/${id}` });
}

export function entradaPodeSerEditadaExcluida(id) {
  return httpService.get({ ...args, path: '/estoque/entrada/entradaPodeSerEditadaExcluida/' + id });
}

export function readXmlVerificarArquivoEFornecedor(xml) {
  var bodyFormData = new FormData();
  bodyFormData.append('xml', xml);

  return httpService.postFile({ ...args, path: '/estoque/entrada/importarXML/verificarArquivoEFornecedor/' }, bodyFormData);
}

export function readXmlFile(xml, naturezaOperacao, localEstoqueId, propriedade) {
  var bodyFormData = new FormData();
  bodyFormData.append('xml', xml);

  return httpService.postFile({ ...args, path: '/estoque/entrada/importarXML/arquivo/' + naturezaOperacao.id + "/" + localEstoqueId + "/" + propriedade  }, bodyFormData);
}

export function visualizarDanfeXmlManualFile(idNota) {
  return httpService.getPdf({ ...args, path: `/estoque/entrada/gerarDanfeXmlManual/${idNota}` });
}
