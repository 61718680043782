import { takeLatest, put } from 'redux-saga/effects'

import { storage } from '@commons';
import {
  HAS_AUTHORITY_PAINEL_AGRARE,
  HAS_AUTHORITY_PENDING_PAINEL_AGRARE,
  HAS_AUTHORITY_SUCCESS_PAINEL_AGRARE,
  HAS_AUTHORITY_FAILURE_PAINEL_AGRARE
} from '../actionTypes';
import { CONFIG_SESSION } from '@handler/common/startSession';
import { hasRequiredRole } from '../hasRequiredRole';

function* sagaHasAuthorityPainelAgrare(action) {
  yield put({ type: HAS_AUTHORITY_PENDING_PAINEL_AGRARE })

  try {
    const configSession = JSON.parse(storage.get(CONFIG_SESSION));

    if (configSession.perfilUser !== null) {
      let hasAuthorityPainelAgrare = {
        agricultura: hasRequiredRole('PAINEL_AGRARE_VISAO_AGRIC'),
        pecuaria: hasRequiredRole('PAINEL_AGRARE_VISAO_PEC'),
        financeiro: hasRequiredRole('PAINEL_AGRARE_VISAO_FIN'),
        patrimonio: hasRequiredRole('PAINEL_AGRARE_VISAO_PAT'),
      };

      yield put({ type: HAS_AUTHORITY_SUCCESS_PAINEL_AGRARE, hasAuthorityPainelAgrare: hasAuthorityPainelAgrare });
    } else {
      yield put({ type: HAS_AUTHORITY_FAILURE_PAINEL_AGRARE });
    }

  } catch (error) {
    yield put({ type: HAS_AUTHORITY_FAILURE_PAINEL_AGRARE });
  }

}

export default function* watchHasAuthorityPainelAgrare() {
  yield takeLatest(HAS_AUTHORITY_PAINEL_AGRARE, sagaHasAuthorityPainelAgrare);
}
