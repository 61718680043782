import { path, httpService } from '@commons';

/////////////////////////////////////////////////////////////////////////////////
const args = { ...path, path: '/estoque/implantacao' };
/////////////////////////////////////////////////////////////////////////////////

export function createImplantacaoEstoque(payload) {
  return httpService.post(args, payload);
}

export function updateImplantacaoEstoque(payload) {
  const { id } = payload

  return httpService.put({ ...args, args: `/${id}` }, payload);
}

export function listImplantacaoEstoque(param) {
  return httpService.get({ ...args, args: param });
}

export function findImplantacaoEstoque(id) {
  return httpService.get({ ...args, args: `/${id}` });
}

export function findAllImplantacaoEstoque() {
  return httpService.get({ ...args, path: '/estoque/implantacao/all' });
}

export function deleteImplantacaoEstoque(id) {
  return httpService.del({ ...args, args: `/${id}` });
}

export function filterImplantacaoEstoque(idPropriedade, datas, where) {
  return httpService.get({ ...args, args: `?propriedade=${idPropriedade}&datas=${datas}${where}` });
}

export function entradaPodeSerEditadaExcluida(id) {
  return httpService.get({ ...args, path: '/estoque/implantacao/entradaPodeSerEditadaExcluida/' + id });
}

