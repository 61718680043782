import { takeLatest, put, call } from 'redux-saga/effects';

import { filterImplantacaoEstoque } from '../service';
import { storage } from '@commons/storage';
import {
  NEW_FILTER_NEXT_PAGE_IMPLANTACAO_ESTOQUE,
  NEW_FILTER_NEXT_PAGE_IMPLANTACAO_ESTOQUE_PENDING,
  NEW_FILTER_NEXT_PAGE_IMPLANTACAO_ESTOQUE_SUCCESS,
  NEW_FILTER_NEXT_PAGE_IMPLANTACAO_ESTOQUE_FAILURE,
} from '../actionTypes';
import { CONFIG_SESSION } from '@handler/common/startSession';
import { formatDateDDMMYYYY } from '@components/common/format';
import { CONST_FILTER_IMPLANTACAO_ESTOQUE } from '@commons/consts';

function* sagaNewFilterNextPageImplantacaoEstoque(action) {
  yield put({ type: NEW_FILTER_NEXT_PAGE_IMPLANTACAO_ESTOQUE_PENDING });

  try {
    const idFazenda = JSON.parse(storage.get(CONFIG_SESSION)).fazendaSelecionada.id;

    let filterSaved = sessionStorage.getItem(CONST_FILTER_IMPLANTACAO_ESTOQUE) !== null ? JSON.parse(sessionStorage.getItem(CONST_FILTER_IMPLANTACAO_ESTOQUE)) : null;

    const datas = formatDateDDMMYYYY(filterSaved.dataInicial) + '_' + formatDateDDMMYYYY(filterSaved.dataFinal);

    let newFilter = {
      ...filterSaved,
      rows: action.rows,
      page: action.page,
    };

    const list = yield call(filterImplantacaoEstoque, idFazenda, datas, `&page=${newFilter.page}&size=${newFilter.rows}${newFilter.where}`);

    sessionStorage.setItem(CONST_FILTER_IMPLANTACAO_ESTOQUE, JSON.stringify(newFilter));

    yield put({
      type: NEW_FILTER_NEXT_PAGE_IMPLANTACAO_ESTOQUE_SUCCESS, list: list, filter: newFilter
    });
  } catch (error) {
    yield put({ type: NEW_FILTER_NEXT_PAGE_IMPLANTACAO_ESTOQUE_FAILURE })
  }
}

export default function* watchNewFilterNextPageImplantacaoEstoque() {
  yield takeLatest(NEW_FILTER_NEXT_PAGE_IMPLANTACAO_ESTOQUE, sagaNewFilterNextPageImplantacaoEstoque)
}