import { CONST_ROWS_PER_PAGE } from '@commons/consts';
import {
    NEW_ENTRADA_ESTOQUE_SUCCESS,
    NEW_FILTER_ENTRADA_ESTOQUE_FAILURE,
    NEW_FILTER_ENTRADA_ESTOQUE_PENDING,
    NEW_FILTER_ENTRADA_ESTOQUE_SUCCESS,
    NEW_FILTER_NEXT_PAGE_ENTRADA_ESTOQUE_FAILURE,
    NEW_FILTER_NEXT_PAGE_ENTRADA_ESTOQUE_PENDING,
    NEW_FILTER_NEXT_PAGE_ENTRADA_ESTOQUE_SUCCESS,
    TABLE_ENTRADA_ESTOQUE_PENDING,
    TABLE_ENTRADA_ESTOQUE_SUCCESS,
    TABLE_ENTRADA_ESTOQUE_FAILURE,
    LIST_ENTRADA_ESTOQUE_PENDING,
    LIST_ENTRADA_ESTOQUE_SUCCESS,
    LIST_ENTRADA_ESTOQUE_FAILURE,
    FIND_ENTRADA_ESTOQUE_PENDING,
    FIND_ENTRADA_ESTOQUE_SUCCESS,
    FIND_ENTRADA_ESTOQUE_FAILURE,
    VIEW_DANFE_XML_MANUAL_ENTRADA_ESTOQUE_PENDING,
    VIEW_DANFE_XML_MANUAL_ENTRADA_ESTOQUE_SUCCESS,
    VIEW_DANFE_XML_MANUAL_ENTRADA_ESTOQUE_FAILURE,
    READ_XML_ENTRADA_ESTOQUE_PENDING,
    READ_XML_ENTRADA_ESTOQUE_SUCCESS,
    READ_XML_ENTRADA_ESTOQUE_FAILURE,
    IMPORT_XML_ENTRADA_ESTOQUE_PENDING,
    IMPORT_XML_ENTRADA_ESTOQUE_SUCCESS,
    IMPORT_XML_ENTRADA_ESTOQUE_FAILURE,
    DELETE_ENTRADA_ESTOQUE_PENDING,
    DELETE_ENTRADA_ESTOQUE_SUCCESS,
    DELETE_ENTRADA_ESTOQUE_FAILURE,
    CREATE_ENTRADA_ESTOQUE_PENDING,
    CREATE_ENTRADA_ESTOQUE_SUCCESS,
    CREATE_ENTRADA_ESTOQUE_FAILURE,
    SELECT_PRODUCT_ITEM_XML_ENTRADA_ESTOQUE_PENDING,
    SELECT_PRODUCT_ITEM_XML_ENTRADA_ESTOQUE_SUCCESS,
    SELECT_PRODUCT_ITEM_XML_ENTRADA_ESTOQUE_FAILURE,
} from './actionTypes';


const initialState = {
    list: null,
    loading: false,
    xmlReaded: null,
    filter: { rows: CONST_ROWS_PER_PAGE, page: 0, where: '' },
}


function entradaEstoqueReducer(state = initialState, action) {

    switch (action.type) {
        case NEW_ENTRADA_ESTOQUE_SUCCESS:
            return {
                ...state,
                loading: false,
                registro: action.registro
            }
        case NEW_FILTER_ENTRADA_ESTOQUE_PENDING:
        case NEW_FILTER_NEXT_PAGE_ENTRADA_ESTOQUE_PENDING:
        case LIST_ENTRADA_ESTOQUE_PENDING:
        case FIND_ENTRADA_ESTOQUE_PENDING:
        case DELETE_ENTRADA_ESTOQUE_PENDING:
        case CREATE_ENTRADA_ESTOQUE_PENDING:
        case TABLE_ENTRADA_ESTOQUE_PENDING:
        case IMPORT_XML_ENTRADA_ESTOQUE_PENDING:
        case READ_XML_ENTRADA_ESTOQUE_PENDING:
        case SELECT_PRODUCT_ITEM_XML_ENTRADA_ESTOQUE_PENDING:
        case VIEW_DANFE_XML_MANUAL_ENTRADA_ESTOQUE_PENDING:
            return {
                ...state,
                xmlReaded: null,
                loading: true,
            }
        // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        case NEW_FILTER_NEXT_PAGE_ENTRADA_ESTOQUE_SUCCESS:
            return {
                ...state,
                list: action.list,
                filter: action.filter,
                loading: false
            }
        case NEW_FILTER_NEXT_PAGE_ENTRADA_ESTOQUE_FAILURE:
            return {
                ...state,
                list: null,
                loading: false
            }
        // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        case NEW_FILTER_ENTRADA_ESTOQUE_SUCCESS: {
            return {
                ...state,
                list: action.list,
                filter: action.filter,
                loading: false
            }
        }
        case NEW_FILTER_ENTRADA_ESTOQUE_FAILURE: {
            return {
                ...state,
                loading: false
            }
        }
        // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        case VIEW_DANFE_XML_MANUAL_ENTRADA_ESTOQUE_SUCCESS:
        case VIEW_DANFE_XML_MANUAL_ENTRADA_ESTOQUE_FAILURE: {
            return {
                ...state,
                loading: false
            }
        }
        // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        case LIST_ENTRADA_ESTOQUE_SUCCESS:
            return {
                ...state,
                list: action.list,
                loading: false
            }
        case LIST_ENTRADA_ESTOQUE_FAILURE:
            return {
                ...state,
                list: null,
                loading: false
            }
        // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        case IMPORT_XML_ENTRADA_ESTOQUE_SUCCESS:
            return {
                ...state,
                loading: false
            }
        case IMPORT_XML_ENTRADA_ESTOQUE_FAILURE:
            return {
                ...state,
                loading: false
            }
        // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        case READ_XML_ENTRADA_ESTOQUE_SUCCESS:
            return {
                ...state,
                xmlReaded: action.xmlReaded,
                loading: false
            }
        case READ_XML_ENTRADA_ESTOQUE_FAILURE:
            return {
                ...state,
                xmlReaded: null,
                loading: false
            }
        // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        case SELECT_PRODUCT_ITEM_XML_ENTRADA_ESTOQUE_SUCCESS:
            return {
                ...state,
                xmlReaded: action.xmlReaded,
                loading: false
            }
        case SELECT_PRODUCT_ITEM_XML_ENTRADA_ESTOQUE_FAILURE:
            return {
                ...state,
                loading: false
            }
        // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        case TABLE_ENTRADA_ESTOQUE_SUCCESS:
            return {
                ...state,
                table: action.table,
                loading: false
            }
        case TABLE_ENTRADA_ESTOQUE_FAILURE:
            return {
                ...state,
                table: null,
                loading: false
            }
        // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        case FIND_ENTRADA_ESTOQUE_SUCCESS:
            return {
                ...state,
                registro: action.registro,
                loading: false
            }
        case FIND_ENTRADA_ESTOQUE_FAILURE:
            return {
                ...state,
                registro: null,
                loading: false
            }
        // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        case DELETE_ENTRADA_ESTOQUE_SUCCESS: {
            return {
                ...state,
                xmlReaded: null,
                loading: false
            }
        }
        // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        case CREATE_ENTRADA_ESTOQUE_SUCCESS:
        case CREATE_ENTRADA_ESTOQUE_FAILURE:
            return {
                ...state,
                loading: false,
                xmlReaded: null,
                registro: action.registro
            }
        case DELETE_ENTRADA_ESTOQUE_FAILURE:
            return {
                ...state,
                xmlReaded: null,
                loading: false,
            }
        // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        default:
            return state
    }
}

export { entradaEstoqueReducer };