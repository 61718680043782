import { takeLatest, put, call } from 'redux-saga/effects';

import { findProdutoAgricolaCadastro } from '../service';
import {
  FIND_PRODUTO_AGRICOLA_CADASTRO,
  FIND_PRODUTO_AGRICOLA_CADASTRO_PENDING,
  FIND_PRODUTO_AGRICOLA_CADASTRO_SUCCESS,
  FIND_PRODUTO_AGRICOLA_CADASTRO_FAILURE
} from '../actionTypes';

function* sagaFindProdutoAgricolaCadastro(action) {
  yield put({ type: FIND_PRODUTO_AGRICOLA_CADASTRO_PENDING, id: action.id })

  try {

    const registro = yield call(findProdutoAgricolaCadastro, action.id);

    yield put({ type: FIND_PRODUTO_AGRICOLA_CADASTRO_SUCCESS, registro: registro })

  } catch (error) {

    yield put({ type: FIND_PRODUTO_AGRICOLA_CADASTRO_FAILURE })
    // console.error(error) // eslint-disable-line

  }
}

export default function* watchFindProdutoAgricolaCadastro() {
  yield takeLatest(FIND_PRODUTO_AGRICOLA_CADASTRO, sagaFindProdutoAgricolaCadastro)
}
