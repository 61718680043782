import { takeLatest, put, call } from 'redux-saga/effects';

import { getReposicaoEstoqueMinimo, getReposicaoEstoqueMinimoByFornecedor, getReposicaoEstoqueMinimoByFornecedorPdf, getReposicaoEstoqueMinimoPdf } from '../service';
import {
  LIST_RELATORIO_REPOSICAO_ESTOQUE_MINIMO,
  LIST_RELATORIO_REPOSICAO_ESTOQUE_MINIMO_PENDING,
  LIST_RELATORIO_REPOSICAO_ESTOQUE_MINIMO_SUCCESS,
  LIST_RELATORIO_REPOSICAO_ESTOQUE_MINIMO_FAILURE,
  LIST_RELATORIO_REPOSICAO_ESTOQUE_MINIMO_PDF_SUCCESS
} from '../actionTypes';
import { storage } from '@commons';
import { CONFIG_SESSION } from '@handler/common/startSession';
// import { PRODUTO_INSUMO_GRUPO_ENUM } from '@handler/common/produtoInsumoCadastro';

function* sagaListAllRelatorioReposicaoEstoqueMinimo(action) {
  yield put({ type: LIST_RELATORIO_REPOSICAO_ESTOQUE_MINIMO_PENDING })

  try {
    var where = "";
    if (action.args !== undefined && action.args !== null && action.args.produto !== undefined && action.args.produto !== null) {
      where = '?produto=' + action.args.produto.id
    }
    // if (action.args !== undefined && action.args !== null && action.args.grupoProduto !== undefined && action.args.grupoProduto !== null) {
    //   var grupos = PRODUTO_INSUMO_GRUPO_ENUM;
    //   where = (where === '' ? '?' : where + '&') + 'grupoProduto=' + (grupos.find(element => element.id === action.args.grupoProduto).idBD ?? -1)
    // }
    if (action.args !== undefined && action.args !== null && action.args.usarUnidadeCompra !== undefined && action.args.usarUnidadeCompra !== null) {
      where = (where === '' ? '?' : where + '&') + 'usarUnidadeCompra=' + (action.args.usarUnidadeCompra === 'UNIDADE_COMPRA' ? 'true' : 'false')
    }

    if (action.args !== undefined && action.args !== null && action.args.agruparFornecedor !== undefined
      && action.args.agruparFornecedor !== null && action.args.agruparFornecedor === 'N') {
      if (action.pdf === false) {
        const estoqueReposicao = yield call(getReposicaoEstoqueMinimo, JSON.parse(storage.get(CONFIG_SESSION)).fazendaSelecionada.id, where)

        yield put({ type: LIST_RELATORIO_REPOSICAO_ESTOQUE_MINIMO_SUCCESS, estoqueReposicao: estoqueReposicao, relatorioPorFornecedor: false })
      } else {
        yield call(getReposicaoEstoqueMinimoPdf, JSON.parse(storage.get(CONFIG_SESSION)).fazendaSelecionada.id, where)

        yield put({ type: LIST_RELATORIO_REPOSICAO_ESTOQUE_MINIMO_PDF_SUCCESS })
      }
    } else {
      if (action.args !== undefined && action.args !== null && action.args.fornecedor !== undefined && action.args.fornecedor !== null) {
        where = (where === '' ? '?' : where + '&') + 'fornecedor=' + action.args.fornecedor.id
      }

      if (action.pdf === false) {
        const estoqueReposicao = yield call(getReposicaoEstoqueMinimoByFornecedor, JSON.parse(storage.get(CONFIG_SESSION)).fazendaSelecionada.id, where)

        yield put({ type: LIST_RELATORIO_REPOSICAO_ESTOQUE_MINIMO_SUCCESS, estoqueReposicao: estoqueReposicao, relatorioPorFornecedor: true })
      } else {
        yield call(getReposicaoEstoqueMinimoByFornecedorPdf, JSON.parse(storage.get(CONFIG_SESSION)).fazendaSelecionada.id, where)

        yield put({ type: LIST_RELATORIO_REPOSICAO_ESTOQUE_MINIMO_PDF_SUCCESS })
      }
    }
  } catch (error) {

    yield put({ type: LIST_RELATORIO_REPOSICAO_ESTOQUE_MINIMO_FAILURE })
    // console.error(error) // eslint-disable-line

  }
}

export default function* watchListAllRelatorioReposicaoEstoqueMinimo() {
  yield takeLatest(LIST_RELATORIO_REPOSICAO_ESTOQUE_MINIMO, sagaListAllRelatorioReposicaoEstoqueMinimo)
}