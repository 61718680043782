
export const NEW_PATRIMONIO_MANUTENCAO_CADASTRO = 'NEW_PATRIMONIO_MANUTENCAO_CADASTRO';
export const NEW_PATRIMONIO_MANUTENCAO_CADASTRO_SUCCESS = 'NEW_PATRIMONIO_MANUTENCAO_CADASTRO_SUCCESS';

export const NEW_FILTER_PATRIMONIO_MANUTENCAO = 'NEW_FILTER_PATRIMONIO_MANUTENCAO'
export const NEW_FILTER_PATRIMONIO_MANUTENCAO_PEDING = 'NEW_FILTER_PATRIMONIO_MANUTENCAO_PEDING'
export const NEW_FILTER_PATRIMONIO_MANUTENCAO_SUCCESS = 'NEW_FILTER_PATRIMONIO_MANUTENCAO_SUCCESS'
export const NEW_FILTER_PATRIMONIO_MANUTENCAO_FAILURE = 'NEW_FILTER_PATRIMONIO_MANUTENCAO_FAILURE'

export const NEW_FILTER_NEXT_PAGE_PATRIMONIO_MANUTENCAO = 'NEW_FILTER_NEXT_PAGE_PATRIMONIO_MANUTENCAO'
export const NEW_FILTER_NEXT_PAGE_PATRIMONIO_MANUTENCAO_PENDING = 'NEW_FILTER_NEXT_PAGE_PATRIMONIO_MANUTENCAO_PENDING'
export const NEW_FILTER_NEXT_PAGE_PATRIMONIO_MANUTENCAO_SUCCESS = 'NEW_FILTER_NEXT_PAGE_PATRIMONIO_MANUTENCAO_SUCCESS'
export const NEW_FILTER_NEXT_PAGE_PATRIMONIO_MANUTENCAO_FAILURE = 'NEW_FILTER_NEXT_PAGE_PATRIMONIO_MANUTENCAO_FAILURE'

export const CREATE_PATRIMONIO_MANUTENCAO_CADASTRO = 'CREATE_PATRIMONIO_MANUTENCAO_CADASTRO';
export const CREATE_PATRIMONIO_MANUTENCAO_CADASTRO_PENDING = 'CREATE_PATRIMONIO_MANUTENCAO_CADASTRO_PENDING';
export const CREATE_PATRIMONIO_MANUTENCAO_CADASTRO_SUCCESS = 'CREATE_PATRIMONIO_MANUTENCAO_CADASTRO_SUCCESS';
export const CREATE_PATRIMONIO_MANUTENCAO_CADASTRO_FAILURE = 'CREATE_PATRIMONIO_MANUTENCAO_CADASTRO_FAILURE';

export const CANCEL_PATRIMONIO_MANUTENCAO_CADASTRO_RAPIDO = 'CANCEL_PATRIMONIO_MANUTENCAO_CADASTRO_RAPIDO';
export const CANCEL_PATRIMONIO_MANUTENCAO_CADASTRO_RAPIDO_SUCCESS = 'CANCEL_PATRIMONIO_MANUTENCAO_CADASTRO_RAPIDO_SUCCESS';

export const TABLE_PATRIMONIO_MANUTENCAO_CADASTRO = 'TABLE_PATRIMONIO_MANUTENCAO_CADASTRO';
export const TABLE_PATRIMONIO_MANUTENCAO_CADASTRO_PENDING = 'TABLE_PATRIMONIO_MANUTENCAO_CADASTRO_PENDING';
export const TABLE_PATRIMONIO_MANUTENCAO_CADASTRO_SUCCESS = 'TABLE_PATRIMONIO_MANUTENCAO_CADASTRO_SUCCESS';
export const TABLE_PATRIMONIO_MANUTENCAO_CADASTRO_FAILURE = 'TABLE_PATRIMONIO_MANUTENCAO_CADASTRO_FAILURE';

export const LIST_PATRIMONIO_MANUTENCAO_CADASTRO = 'LIST_PATRIMONIO_MANUTENCAO_CADASTRO';
export const LIST_PATRIMONIO_MANUTENCAO_CADASTRO_PENDING = 'LIST_PATRIMONIO_MANUTENCAO_CADASTRO_PENDING';
export const LIST_PATRIMONIO_MANUTENCAO_CADASTRO_SUCCESS = 'LIST_PATRIMONIO_MANUTENCAO_CADASTRO_SUCCESS';
export const LIST_PATRIMONIO_MANUTENCAO_CADASTRO_FAILURE = 'LIST_PATRIMONIO_MANUTENCAO_CADASTRO_FAILURE';

export const FIND_PATRIMONIO_MANUTENCAO_CADASTRO = 'FIND_PATRIMONIO_MANUTENCAO_CADASTRO';
export const FIND_PATRIMONIO_MANUTENCAO_CADASTRO_PENDING = 'FIND_PATRIMONIO_MANUTENCAO_CADASTRO_PENDING';
export const FIND_PATRIMONIO_MANUTENCAO_CADASTRO_SUCCESS = 'FIND_PATRIMONIO_MANUTENCAO_CADASTRO_SUCCESS';
export const FIND_PATRIMONIO_MANUTENCAO_CADASTRO_FAILURE = 'FIND_PATRIMONIO_MANUTENCAO_CADASTRO_FAILURE';

export const DELETE_PATRIMONIO_MANUTENCAO_CADASTRO = 'DELETE_PATRIMONIO_MANUTENCAO_CADASTRO';
export const DELETE_PATRIMONIO_MANUTENCAO_CADASTRO_PENDING = 'DELETE_PATRIMONIO_MANUTENCAO_CADASTRO_PENDING';
export const DELETE_PATRIMONIO_MANUTENCAO_CADASTRO_SUCCESS = 'DELETE_PATRIMONIO_MANUTENCAO_CADASTRO_SUCCESS';
export const DELETE_PATRIMONIO_MANUTENCAO_CADASTRO_FAILURE = 'DELETE_PATRIMONIO_MANUTENCAO_CADASTRO_FAILURE';
