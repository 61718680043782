import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { Link, useLocation } from "react-router-dom";
import { Dropdown, DropdownToggle, DropdownMenu, Row, Col } from "reactstrap";
import SimpleBar from "simplebar-react";

import IconTextInfoNoRegisterComponent from "@components/common/IconTextInfoNoRegisterComponent";
import {
  TABLE_NOTIFICATION,
  MARK_NOTIFICATION_AS_READ,
} from "@handler";
import { formatToDDMMYYYYWithTime } from '@components/common/format';
import { trackEvent } from 'mixpanel';

function NotificationDropdown({ light }) {
  const dispatch = useDispatch();
  const location = useLocation();
  const [allItems, setAllItems] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [menu, setMenu] = useState(false);
  const [totalUnread, setTotalUnread] = useState(0);
  const [timeoutId, setTimeoutId] = useState(null);

  const notificationReducer = useSelector((state) => state.entities.notificationReducer);

  useEffect(() => {
    setLoading(true);
    dispatch({ type: TABLE_NOTIFICATION, args: `&size=5&page=${0}` });
  }, [dispatch, menu, location.pathname]);

  useEffect(() => {
    if (notificationReducer.table) {
      if (notificationReducer.table.number === 0) {
        setAllItems(notificationReducer.table.content);
        // Set totalUnread based on allItems[0].unreadCounter
        if (notificationReducer.table.content.length > 0) {
          const initialUnreadCounter = notificationReducer.table.content[0].unreadCounter;
          setTotalUnread(initialUnreadCounter);
        }
      }
      setLoading(false);
    }
  }, [notificationReducer.table]);

  useEffect(() => {
    if (menu && allItems.length > 0) {
      // Clear any existing timeout to avoid multiple triggers
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      
      // Set a timeout to delay marking notifications as read
      const newTimeoutId = setTimeout(() => {
        const unreadNotificationIds = allItems.filter(item => !item.readStatus).map(item => item.id);
        if (unreadNotificationIds.length > 0) {
          dispatch({ type: MARK_NOTIFICATION_AS_READ, userNotificationIdList: unreadNotificationIds });
          // Update totalUnread
          setTotalUnread((prevTotalUnread) => prevTotalUnread - unreadNotificationIds.length);
        }
      }, 2000); // 2 seconds delay
      
      setTimeoutId(newTimeoutId);
    }
  }, [menu, allItems]);

  const toggle = () => {
    if (!menu) trackEvent('Notification Dropdown > Acessou o Dropdown de Notificações');
    setMenu(!menu);
  };

  const renderCard = (item) => {
    const { notification } = item;
    const cardClass = item.readStatus === false ? 'bg-unread' : 'bg-read';

    const cardContent = (
      <div className={`media ${cardClass}`}>
        <div className="avatar-xs mr-3">
          <span className="avatar-title bg-primary-color rounded-circle font-size-16">
            <i className='fa fa-bell-o' />
          </span>
        </div>
        <div className="media-body">
          <h6 className="mt-0 mb-1">{notification.title}</h6>
          <div className="font-size-12 text-muted">
            <p className="mb-1">
              {notification.message}
            </p>
          </div>
          {item.propertyName && (
            <p className="font-size-12 text-muted mb-1">
              {item.propertyName}
            </p>
          )}
          <p className="font-size-12 mb-1">
            {formatToDDMMYYYYWithTime(notification.createdAt)}
          </p>
        </div>
      </div>
    );

    return (
      <div key={`notification-card-${item.id}`} className="text-reset notification-item">
        <Link to={`/notifications/${item.id}`} onClick={() => trackEvent('Notification Dropdown > Interação para visualizar detalhes da notificação', {
          user: item.usuario,
          dominio: item.scheme,
          notification: item.notification
        })}>
          {cardContent}
        </Link>
      </div>
    );
  };


  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={toggle}
        className="dropdown d-inline-block"
        tag="li"
      >
        <DropdownToggle
          className="btn header-item noti-icon waves-effect"
          id="page-header-notifications-dropdown"
          tag="button"
        >
          <i className={`fa ${menu ? "fa-bell" : "fa-bell-o"} ${light ? "light-color" : "primary-color"}`} />
          {totalUnread > 0 && (
            <span className="badge badge-danger badge-pill">{totalUnread}</span>
          )}
        </DropdownToggle>

        <DropdownMenu className="dropdown-menu-lg p-0" right>
          <div className="p-3">
            <Row className="align-items-center">
              <Col>
                <h5 className="m-0 font-size-16"> Notificações </h5>
              </Col>
            </Row>
          </div>

          <SimpleBar style={{ height: "230px" }}>
            {allItems.length > 0 ? (
              allItems.map((item) => renderCard(item))
            ) : !isLoading ? (
              <IconTextInfoNoRegisterComponent title='Nenhuma notificação encontrada' />
            ) : null}
          </SimpleBar>

          <div className="p-2 border-top">
            <Row>
              <Link
                className="btn btn-sm btn-link font-size-14 btn-block text-center"
                to="/notifications"
                onClick={() => {
                  trackEvent('Notification Dropdown > Clicou em Ver Todas as Notificações');
                  toggle();
                }}
              >
                Ver Todos
              </Link>
            </Row>
          </div>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
}

export default React.memo(NotificationDropdown);
