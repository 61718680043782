import { takeLatest, put } from 'redux-saga/effects';
import moment from 'moment';

import {
  contaPagar,
  NEW_CONTA_PAGAR,
  NEW_CONTA_PAGAR_SUCCESS,
} from '@handler';

function* sagaNewContaPagar(action) {

  yield put({ type: NEW_CONTA_PAGAR_SUCCESS, registro: 
    {
      ...contaPagar.newRegistro,
      data: moment().startOf('day').format('YYYY-MM-DD'),
      dataEmissao: moment().startOf('day').format('YYYY-MM-DD'),
      competencia: moment().startOf('day').format('YYYY-MM-DD'),
      dataPrimeiraParcela: moment().startOf('day').format('YYYY-MM-DD'),
      tipoEntidade: 'PESSOA',
    } });
}

export default function* watchNewContaPagar() {
  yield takeLatest(NEW_CONTA_PAGAR, sagaNewContaPagar)
}
