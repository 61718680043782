import * as Yup from 'yup';

const newRegistro = {
    data: '',
    identificador: null,
    localEstoque: null,
    cultura: null,
    produtoAgricola: null,
    cultivar: null,
    quantidade: 0,
    culturaUnidadeEstoqueProducao: '',
    obs: '',
};

const validation = Yup.object().shape({
    data: Yup.date()
        .required('Este campo é obrigatório'),
    localEstoque: Yup.object()
        .typeError('Este campo é obrigatório')
        .shape({
            id: Yup.number()
                .typeError('Este campo é obrigatório')
                .required("Este campo é obrigatório"),
        }),
    produtoAgricola: Yup.object()
        .typeError('Este campo é obrigatório')
        .shape({
            id: Yup.number()
                .typeError('Este campo é obrigatório')
                .required("Este campo é obrigatório"),
        }),
    quantidade: Yup.number()
        .typeError('Este campo é obrigatório')
        .positive('Por Favor, informe um valor maior que zero')
        .required("Este campo é obrigatório"),
    obs: Yup.string()
        .max(1000, 'Campo deve ter menos do que 1000 caracteres'),
});

export const implantacaoEstoqueProducao = {
    newRegistro,
    validation
}