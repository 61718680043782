import watchFindNdvi from './saga/find';
import watchListNdvi from './saga/listTable';
import watchListAllNdviBySafra from './saga/listAllBySafra';
import watchListAllLatestNdviBySafra from './saga/listAllLatestBySafra';
import watchSelectNdvi from './saga/select';

export const ndviSaga = [
    watchFindNdvi(),
    watchListNdvi(),
    watchListAllNdviBySafra(),
    watchListAllLatestNdviBySafra(),
    watchSelectNdvi(),
]

export * from './actionTypes';
export * from './reducer';
export * from './service';
export * from './entity';
