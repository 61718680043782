import { takeLatest, put, call } from 'redux-saga/effects';

import { history } from '@commons';

import { createCulturaCadastro, updateCulturaCadastro } from '../service';
import {
  CREATE_CULTURA_CADASTRO,
  CREATE_CULTURA_CADASTRO_PENDING,
  CREATE_CULTURA_CADASTRO_SUCCESS,
  CREATE_CULTURA_CADASTRO_FAILURE,
  findAllSafrasByPropriedade,
  CONFIG_SESSION,
} from '@handler';

import { storage } from '@commons/storage';

import { AlertSuccess, AlertError } from '@components/common/AlertToast'

function* sagaCreateCulturaCadastro(action) {
  yield put({ type: CREATE_CULTURA_CADASTRO_PENDING })

  try {
    delete action.data.categoriaDesc;
    
    if (action.data.id === undefined || action.data.id === null) {
      yield call(createCulturaCadastro, action.data);
    } else {
      yield call(updateCulturaCadastro,
        {
          ...action.data,
          id: action.data.id
        }
      );

      // ATUALIZA SAFRA NA SESSAO
      const safras = yield call(findAllSafrasByPropriedade, JSON.parse(storage.get(CONFIG_SESSION)).fazendaSelecionada.id);

      const configSessionAtual = JSON.parse(storage.get(CONFIG_SESSION));
      let safraSelecionada = null;

      if (safras != null && safras.content != null) {
        if (safras.content.length === 1) {
          safraSelecionada = safras.content[0];
        } else {
          if (configSessionAtual.safraAtiva !== null) {
            for (var i = 0; i < safras.content.length; i++) {
              if (safras.content[i].id == configSessionAtual.safraAtiva.id) {
                safraSelecionada = safras.content[i];
              }
            }
          }
        }

        if (safraSelecionada != null) {
          const configSession = {
            ...configSessionAtual,
            safras: safras.content,
            safraAtiva: safraSelecionada
          }

          yield storage.set(CONFIG_SESSION, JSON.stringify(configSession));
        }
      }
    }

    yield put({ type: CREATE_CULTURA_CADASTRO_SUCCESS, registro: action.data })


    if (!action.isCadastroRapido) {
      history.push('/culturaCadastro');
    }

    AlertSuccess('Registro salvo com sucesso!');

  } catch (error) {
    yield put({ type: CREATE_CULTURA_CADASTRO_FAILURE, registro: action.data })

    AlertError('Falha ao salvar o registro! Tente novamente...');
  }

}

export default function* watchCreateCulturaCadastro() {
  yield takeLatest(CREATE_CULTURA_CADASTRO, sagaCreateCulturaCadastro)
}
