import { takeLatest, put, call } from 'redux-saga/effects';

import { filterPatrimonioAbastecimento } from '../service';
import { storage } from '@commons/storage';
import {
  NEW_FILTER_NEXT_PAGE_PATRIMONIO_ABASTECIMENTO,
  NEW_FILTER_NEXT_PAGE_PATRIMONIO_ABASTECIMENTO_PENDING,
  NEW_FILTER_NEXT_PAGE_PATRIMONIO_ABASTECIMENTO_SUCCESS,
  NEW_FILTER_NEXT_PAGE_PATRIMONIO_ABASTECIMENTO_FAILURE,
} from '../actionTypes';
import { CONFIG_SESSION } from '@handler/common/startSession';
import { formatDateDDMMYYYY } from '@components/common/format';
import { CONST_FILTER_PATRIMONIO_ABASTECIMENTO } from '@commons/consts';

function* sagaNewFilterNextPagePatrimonioAbastecimento(action) {
  yield put({ type: NEW_FILTER_NEXT_PAGE_PATRIMONIO_ABASTECIMENTO_PENDING })

  try {
    const idFazenda = JSON.parse(storage.get(CONFIG_SESSION)).fazendaSelecionada.id;

    let filterSaved = sessionStorage.getItem(CONST_FILTER_PATRIMONIO_ABASTECIMENTO) !== null ? JSON.parse(sessionStorage.getItem(CONST_FILTER_PATRIMONIO_ABASTECIMENTO)) : null;

    const datas = formatDateDDMMYYYY(filterSaved.dataInicial) + '_' + formatDateDDMMYYYY(filterSaved.dataFinal);

    let newFilter = {
      ...filterSaved,
      rows: action.rows,
      page: action.page,
    };

    const list = yield call(filterPatrimonioAbastecimento, idFazenda, datas, `&page=${newFilter.page}&size=${newFilter.rows}${newFilter.where}`);

    sessionStorage.setItem(CONST_FILTER_PATRIMONIO_ABASTECIMENTO, JSON.stringify(newFilter));

    yield put({
      type: NEW_FILTER_NEXT_PAGE_PATRIMONIO_ABASTECIMENTO_SUCCESS, list: list, filter: newFilter
    });
  } catch (error) {

    yield put({ type: NEW_FILTER_NEXT_PAGE_PATRIMONIO_ABASTECIMENTO_FAILURE })
    // console.error(error) // eslint-disable-line
  }
}

export default function* watchNewFilterNextPagePatrimonioAbastecimento() {
  yield takeLatest(NEW_FILTER_NEXT_PAGE_PATRIMONIO_ABASTECIMENTO, sagaNewFilterNextPagePatrimonioAbastecimento)
}