import { path, httpService } from '@commons';

/////////////////////////////////////////////////////////////////////////////////
const args = { ...path };
/////////////////////////////////////////////////////////////////////////////////

export function baixarPagamento(urlTipoConta, idConta, payload) {
  return httpService.post({...args, path: `${urlTipoConta}/${idConta}/baixarConta`}, payload);
}

export function attachFiles(urlTipoConta, contaId, payload) {
  return httpService.putAttachFiles({ ...args, path: urlTipoConta, args: `/attachFiles/${contaId}` }, payload);
}

export function deleteAttachFiles(urlTipoConta, contaId, filesIdList) {
  return httpService.putDeleteFiles({ ...args, path: urlTipoConta, args: `/deleteFiles/${contaId}` }, filesIdList);
}
