import { takeLatest, put } from 'redux-saga/effects';

import {
  unidadeMedidaCadastro,
  NEW_UNIDADE_MEDIDA_CADASTRO,
  NEW_UNIDADE_MEDIDA_CADASTRO_SUCCESS,
} from '@handler';

function* sagaNewUnidadeMedidaCadastro(action) {

  yield put({
    type: NEW_UNIDADE_MEDIDA_CADASTRO_SUCCESS,
    registro: unidadeMedidaCadastro.newRegistro,
    isCadastroRapido: action.isCadastroRapido,
  });
}

export default function* watchNewUnidadeMedidaCadastro() {
  yield takeLatest(NEW_UNIDADE_MEDIDA_CADASTRO, sagaNewUnidadeMedidaCadastro)
}
