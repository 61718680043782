import watchFindNotification from './saga/find';
import watchListNotification from './saga/listTable';
import watchNewFilterNotification from './saga/newFilter';
import watchNewFilterNextPageNotification from './saga/newFilterNextPage';
import watchMarkNotificationAsRead from './saga/markAsRead';
import watchMarkNotificationAllAsRead from './saga/markAllAsRead';

export const notificationSaga = [
    watchFindNotification(),
    watchListNotification(),
    watchNewFilterNotification(),
    watchNewFilterNextPageNotification(),
    watchMarkNotificationAsRead(),
    watchMarkNotificationAllAsRead(),
]

export * from './actionTypes';
export * from './reducer';
export * from './service';
