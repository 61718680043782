import { takeLatest, put } from 'redux-saga/effects';

import {
  veiculoCadastro,
  NEW_VEICULO_CADASTRO,
  NEW_VEICULO_CADASTRO_SUCCESS,
} from '@handler';

function* sagaNewVeiculoCadastro(action) {

  yield put({
    type: NEW_VEICULO_CADASTRO_SUCCESS,
    registro: veiculoCadastro.newRegistro,
    isCadastroRapido: action.isCadastroRapido,
    status: true,
    veiculoProprio: false,
    emplacadoNoBrasil: true,
  });
}

export default function* watchNewVeiculoCadastro() {
  yield takeLatest(NEW_VEICULO_CADASTRO, sagaNewVeiculoCadastro)
}
