import watchNewProdutoAgricolaCadastro from './saga/new';
import watchFindProdutoAgricolaCadastro from './saga/find';
import watchCreateProdutoAgricolaCadastro from './saga/create';
import watchListAllProdutoAgricolaCadastro from './saga/listAll';
import watchListProdutoAgricolaCadastro from './saga/listTable';
import watchDeleteProdutoAgricolaCadastro from './saga/delete';
import watchCancelProdutoAgricolaCadastroRapido from './saga/cancel';

export const produtoAgricolaCadastroSaga = [
    watchNewProdutoAgricolaCadastro(),
    watchFindProdutoAgricolaCadastro(),
    watchCreateProdutoAgricolaCadastro(),
    watchListAllProdutoAgricolaCadastro(),
    watchListProdutoAgricolaCadastro(),
    watchDeleteProdutoAgricolaCadastro(),
    watchCancelProdutoAgricolaCadastroRapido(),
]

export * from './actionTypes';
export * from './reducer';
export * from './service';
export * from './entity';
