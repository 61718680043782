import { CONST_ROWS_PER_PAGE } from '@commons/consts';
import {
    NEW_TRANSFERENCIA_ESTOQUE_SUCCESS,
    TABLE_TRANSFERENCIA_ESTOQUE_PENDING,
    TABLE_TRANSFERENCIA_ESTOQUE_SUCCESS,
    TABLE_TRANSFERENCIA_ESTOQUE_FAILURE,
    LIST_TRANSFERENCIA_ESTOQUE_PENDING,
    LIST_TRANSFERENCIA_ESTOQUE_SUCCESS,
    LIST_TRANSFERENCIA_ESTOQUE_FAILURE,
    FIND_TRANSFERENCIA_ESTOQUE_PENDING,
    FIND_TRANSFERENCIA_ESTOQUE_SUCCESS,
    FIND_TRANSFERENCIA_ESTOQUE_FAILURE,
    DELETE_TRANSFERENCIA_ESTOQUE_PENDING,
    DELETE_TRANSFERENCIA_ESTOQUE_SUCCESS,
    DELETE_TRANSFERENCIA_ESTOQUE_FAILURE,
    CREATE_TRANSFERENCIA_ESTOQUE_PENDING,
    CREATE_TRANSFERENCIA_ESTOQUE_SUCCESS,
    CREATE_TRANSFERENCIA_ESTOQUE_FAILURE,
    NEW_FILTER_TRANSFERENCIA_ESTOQUE_PEDING,
    NEW_FILTER_TRANSFERENCIA_ESTOQUE_SUCCESS,
    NEW_FILTER_TRANSFERENCIA_ESTOQUE_FAILURE,
    NEW_FILTER_NEXT_PAGE_TRANSFERENCIA_ESTOQUE_PENDING,
    NEW_FILTER_NEXT_PAGE_TRANSFERENCIA_ESTOQUE_SUCCESS,
    NEW_FILTER_NEXT_PAGE_TRANSFERENCIA_ESTOQUE_FAILURE,
} from './actionTypes';

const initialState = {
    list: null,
    loading: false,
    filter: { rows: CONST_ROWS_PER_PAGE, page: 0, where: '' },
}

function transferenciaEstoqueReducer(state = initialState, action) {

    switch (action.type) {
        case NEW_TRANSFERENCIA_ESTOQUE_SUCCESS:
            return {
                ...state,
                loading: false,
                registro: action.registro
            }
        case LIST_TRANSFERENCIA_ESTOQUE_PENDING:
        case FIND_TRANSFERENCIA_ESTOQUE_PENDING:
        case DELETE_TRANSFERENCIA_ESTOQUE_PENDING:
        case CREATE_TRANSFERENCIA_ESTOQUE_PENDING:
        case TABLE_TRANSFERENCIA_ESTOQUE_PENDING:
        case NEW_FILTER_TRANSFERENCIA_ESTOQUE_PEDING:
        case NEW_FILTER_NEXT_PAGE_TRANSFERENCIA_ESTOQUE_PENDING:
            return {
                ...state,
                loading: true,
            }
        // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        case LIST_TRANSFERENCIA_ESTOQUE_SUCCESS:
            return {
                ...state,
                list: action.list,
                loading: false
            }
        case LIST_TRANSFERENCIA_ESTOQUE_FAILURE:
            return {
                ...state,
                list: null,
                loading: false
            }
        // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        case TABLE_TRANSFERENCIA_ESTOQUE_SUCCESS:
            return {
                ...state,
                table: action.table,
                loading: false
            }
        case TABLE_TRANSFERENCIA_ESTOQUE_FAILURE:
            return {
                ...state,
                table: null,
                loading: false
            }
        // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        case FIND_TRANSFERENCIA_ESTOQUE_SUCCESS:
            return {
                ...state,
                registro: action.registro,
                loading: false
            }
        case FIND_TRANSFERENCIA_ESTOQUE_FAILURE:
            return {
                ...state,
                registro: null,
                loading: false
            }
        // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        case DELETE_TRANSFERENCIA_ESTOQUE_SUCCESS: {
            return {
                ...state,
                loading: false
            }
        }
        // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        case NEW_FILTER_TRANSFERENCIA_ESTOQUE_SUCCESS: {
            return {
                ...state,
                list: action.list,
                filter: action.filter,
                loading: false
            }
        }
        case NEW_FILTER_TRANSFERENCIA_ESTOQUE_FAILURE: {
            return {
                ...state,
                loading: false
            }
        }
        // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        case NEW_FILTER_NEXT_PAGE_TRANSFERENCIA_ESTOQUE_SUCCESS:
            return {
                ...state,
                list: action.list,
                filter: action.filter,
                loading: false
            }
        case NEW_FILTER_NEXT_PAGE_TRANSFERENCIA_ESTOQUE_FAILURE:
            return {
                ...state,
                list: null,
                loading: false
            }
        // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        case CREATE_TRANSFERENCIA_ESTOQUE_SUCCESS:
        case CREATE_TRANSFERENCIA_ESTOQUE_FAILURE:
            return {
                ...state,
                loading: false,
                registro: action.registro
            }
        case DELETE_TRANSFERENCIA_ESTOQUE_FAILURE:
            return {
                ...state,
                loading: false,
            }
        // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        default:
            return state
    }
}

export { transferenciaEstoqueReducer };