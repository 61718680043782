import { takeLatest, put, call } from 'redux-saga/effects';

import { history } from '@commons';

import { createUnidadeMedidaCadastro, updateUnidadeMedidaCadastro } from '../service';
import {
  CREATE_UNIDADE_MEDIDA_CADASTRO,
  CREATE_UNIDADE_MEDIDA_CADASTRO_PENDING,
  CREATE_UNIDADE_MEDIDA_CADASTRO_SUCCESS,
  CREATE_UNIDADE_MEDIDA_CADASTRO_FAILURE,
} from '@handler';

import { AlertSuccess, AlertError } from '@components/common/AlertToast'

function* sagaCreateUnidadeMedidaCadastro(action) {
  yield put({ type: CREATE_UNIDADE_MEDIDA_CADASTRO_PENDING })

  try {
    if (action.data.id === undefined || action.data.id === null) {
      yield call(createUnidadeMedidaCadastro, action.data);
    } else {
      yield call(updateUnidadeMedidaCadastro,
        {
          ...action.data,
          id: action.data.id
        }
      );
    }

    yield put({ type: CREATE_UNIDADE_MEDIDA_CADASTRO_SUCCESS, registro: action.data })

    if (!action.isCadastroRapido) {
      history.push('/unidadeMedidaCadastro');
    }

    AlertSuccess('Registro salvo com sucesso!');

  } catch (error) {
    yield put({ type: CREATE_UNIDADE_MEDIDA_CADASTRO_FAILURE, registro: action.data })

    AlertError('Falha ao salvar o registro! Tente novamente...');
  }

}

export default function* watchCreateUnidadeMedidaCadastro() {
  yield takeLatest(CREATE_UNIDADE_MEDIDA_CADASTRO, sagaCreateUnidadeMedidaCadastro)
}
