import { takeLatest, put, call } from 'redux-saga/effects';

import { listVendaProducaoAgricola } from '../service';
import {
  TABLE_VENDA_PRODUCAO_AGRICOLA,
  TABLE_VENDA_PRODUCAO_AGRICOLA_PENDING,
  TABLE_VENDA_PRODUCAO_AGRICOLA_SUCCESS,
  TABLE_VENDA_PRODUCAO_AGRICOLA_FAILURE,
} from '../actionTypes';

function* sagaListVendaProducaoAgricola(action) {
  yield put({ type: TABLE_VENDA_PRODUCAO_AGRICOLA_PENDING })

  try {
    const table = yield call(listVendaProducaoAgricola, action.args)
    yield put({ type: TABLE_VENDA_PRODUCAO_AGRICOLA_SUCCESS, table: table })
  } catch (error) {
    yield put({ type: TABLE_VENDA_PRODUCAO_AGRICOLA_FAILURE })
    // console.error(error) // eslint-disable-line
  }
}

export default function* watchListVendaProducaoAgricola() {
  yield takeLatest(TABLE_VENDA_PRODUCAO_AGRICOLA, sagaListVendaProducaoAgricola)
}