import React, { useEffect, useState } from "react";
import axios from "axios";
import { Card, Button } from "reactstrap";
import { CONFIG_SESSION } from '@handler/common/startSession';
import { storage } from '@commons';
import PageLoader from "@components/common/PageLoader";
import IconTextInfoNoRegisterComponent from "@components/common/IconTextInfoNoRegisterComponent";
import './styles.css';
import { getCurrentWeather, getWeatherForecast } from "@commons/weather";

const getWindDirection = (degree) => {
    if (degree > 337.5) return 'Norte';
    if (degree > 292.5) return 'Noroeste';
    if (degree > 247.5) return 'Oeste';
    if (degree > 202.5) return 'Sudoeste';
    if (degree > 157.5) return 'Sul';
    if (degree > 122.5) return 'Sudeste';
    if (degree > 67.5) return 'Leste';
    if (degree > 22.5) return 'Nordeste';
    return 'Norte';
};

const getWeatherIcon = (iconCode) => {
    switch (iconCode) {
        case "01d":
            return <i className="fas fa-sun icon-style"></i>;
        case "01n":
            return <i className="fas fa-moon icon-style"></i>;
        case "02d":
            return <i className="fas fa-cloud-sun icon-style"></i>;
        case "02n":
            return <i className="fas fa-cloud-moon icon-style"></i>;
        case "03d":
        case "03n":
            return <i className="fas fa-cloud icon-style"></i>;
        case "04d":
        case "04n":
            return <i className="fas fa-cloud-meatball icon-style"></i>;
        case "09d":
        case "09n":
            return <i className="fas fa-cloud-showers-heavy icon-style"></i>;
        case "10d":
            return <i className="fas fa-cloud-sun-rain icon-style"></i>;
        case "10n":
            return <i className="fas fa-cloud-moon-rain icon-style"></i>;
        case "11d":
        case "11n":
            return <i className="fas fa-poo-storm icon-style"></i>;
        case "13d":
        case "13n":
            return <i className="fas fa-snowflake icon-style"></i>;
        case "50d":
        case "50n":
            return <i className="fas fa-smog icon-style"></i>;
        default:
            return <i className="fas fa-question icon-style"></i>;
    }
};

const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
};

const removeFeira = (day) => {
    return day.replace("-feira", "");
};

function WeatherWindow({ onClose }) {
    const [weatherData, setWeatherData] = useState(null);
    const [forecast, setForecast] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const fetchWeatherData = async (latitude, longitude) => {
        try {
            const currentWeatherResponse =  await getCurrentWeather(latitude, longitude);
            const forecastResponse = await getWeatherForecast(latitude, longitude);

            setWeatherData(currentWeatherResponse);
            const dailyForecast = forecastResponse.list.filter((item, index) => index % 8 === 0);
            setForecast(dailyForecast);
            setLoading(false);
        } catch (err) {
            setError(err);
            setLoading(false);
        }
    };

    useEffect(() => {
        const configSession = JSON.parse(storage.get(CONFIG_SESSION));
        fetchWeatherData(configSession.fazendaSelecionada.latitude, configSession.fazendaSelecionada.longitude);
    }, []);

    return (
        <div className="map-menu-window-container">
            <div className="map-drawer-header">
                <h4>Clima</h4>
                <Button className="btn btn-light" onClick={onClose}>
                    <i className='fa fa-arrow-left' />
                </Button>
            </div>

            { loading ? (<div className="loading-indicator"><PageLoader /></div>) : (
                <>
                { error ? (
                    <IconTextInfoNoRegisterComponent title='Dados Climáticos Indisponíveis, tente novamente mais tarde.' />
                ) : (
                    <div className="scrollable-div">
                        {weatherData && (
                            <div className="weather-container">
                                <Card className="p-2 mb-2">
                                    <div className="weather-header">
                                        <div>
                                            <h4>{weatherData.name}</h4>
                                            <p>
                                                {capitalizeFirstLetter(
                                                    new Date().toLocaleDateString("pt-BR", { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })
                                                )}
                                            </p>
                                        </div>
                                        <div className="weather-temp">
                                            <span className="current-temp">{Math.round(weatherData.main.temp)}°</span>
                                        </div>
                                    </div>
                                </Card>
                                <Card className="p-2 mb-2">
                                    <div className="weather-description">
                                        <div className="weather-icon">
                                            {getWeatherIcon(weatherData.weather[0].icon)}
                                        </div>
                                        <p>{capitalizeFirstLetter(weatherData.weather[0].description)}</p>
                                    </div>
                                </Card>
                                <div className="weather-info">
                                    <Card className="p-2 mb-2">
                                        <h6>Umidade</h6>
                                        <p>{weatherData.main.humidity}%</p>
                                    </Card>
                                    <Card className="p-2 mb-2">
                                        <h6>Sens. Térmica</h6>
                                        <p>{Math.round(weatherData.main.feels_like)}°</p>
                                    </Card>
                                    <Card className="p-2 mb-2">
                                        <h6>Chuva</h6>
                                        <p>{weatherData.rain ? `${weatherData.rain['1h']}mm` : '0mm'}</p>
                                    </Card>
                                    <Card className="p-2 mb-2">
                                        <h6>Pressão</h6>
                                        <p>{weatherData.main.pressure} hPa</p>
                                    </Card>
                                    <Card className="p-2 mb-2">
                                        <h6>Vento</h6>
                                        <p>{weatherData.wind.speed} km/h</p>
                                    </Card>
                                    <Card className="p-2 mb-2">
                                        <h6>Dir. do Vento</h6>
                                        <p>{getWindDirection(weatherData.wind.deg)}</p>
                                    </Card>
                                </div>
                            </div>
                        )}

                        {forecast.length > 0 && (
                            <Card className="p-2 mb-2">
                                <div className="forecast-info">
                                    <h4 className="forecast-title">Previsão para os próximos dias</h4>
                                    {forecast.map((day, index) => (
                                        <div key={index} className="forecast-day">
                                                <p>
                                                    {removeFeira(
                                                        capitalizeFirstLetter(
                                                            new Date(day.dt * 1000).toLocaleDateString("pt-BR", { weekday: 'long' })
                                                        )
                                                    )}
                                                </p>
                                                <div className="forecast-precipitation">
                                                <i className="fas fa-tint" />
                                                <span>{day.pop * 100}%</span>
                                            </div>
                                            <div className="forecast-icon">
                                                {getWeatherIcon(day.weather[0].icon)}
                                            </div>
                                            <div className="forecast-max-temp">
                                                <span>{Math.round(day.main.temp_max)}°</span>
                                            </div>
                                            <div className="forecast-min-temp">
                                                <span>{Math.round(day.main.temp_min)}°</span>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </Card>
                        )}

                        <div>
                            <p className="weather-source">Fonte: Open Weather</p>
                        </div>
                    </div>
                )}
                </>
            )}
        </div>
    );
}

export default React.memo(WeatherWindow);
