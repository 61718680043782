import { path, httpService } from '@commons';

/////////////////////////////////////////////////////////////////////////////////
const args = { ...path, path: '/vendaProducaoAgricola' };
/////////////////////////////////////////////////////////////////////////////////

export function createVendaProducaoAgricola(payload) {
  return httpService.post(args, payload);
}

export function updateVendaProducaoAgricola(payload) {
  const { id } = payload

  return httpService.put({ ...args, args: `/${id}` }, payload);
}

export function listVendaProducaoAgricola(param) {
  return httpService.get({ ...args, args: '/filterResumed' + param });
}

export function findVendaProducaoAgricola(id) {
  return httpService.get({ ...args, args: `/${id}` });
}

export function findAllVendaProducaoAgricola() {
  return httpService.get({ ...args, path: '/vendaProducaoAgricola/all' });
}

export function deleteVendaProducaoAgricola(id) {
  return httpService.del({ ...args, args: `/${id}` });
}

export function vendaPodeSerEditadaExcluida(id) {
  return true;
  // return httpService.get({ ...args, path: '/vendaProducaoAgricola/entradaPodeSerEditadaExcluida/' + id });
}

export function getLancamentos(idPropriedade, datas, where) {
  return httpService.get({ ...args, args: `/filterResumed?propriedade=${idPropriedade}&datas=${datas}${where}` });
}

export function getLancamentosPdf(idPropriedade, datas, where) {
  return httpService.getPdf({ ...args, args: `/relatorioRelacaoVendasSafraSintetico/pdf?propriedade=${idPropriedade}&datas=${datas}${where}` });
}

export function getLancamentosExcel(idPropriedade, datas, where) {
  return httpService.getFileExcel({ ...args, args: `/relatorioRelacaoVendasSafraSintetico/excel?propriedade=${idPropriedade}&datas=${datas}${where}` });
}