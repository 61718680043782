import { CONST_ROWS_PER_PAGE } from '@commons/consts';
import {
    CANCEL_PATRIMONIO_MANUTENCAO_CADASTRO_RAPIDO,
    CANCEL_PATRIMONIO_MANUTENCAO_CADASTRO_RAPIDO_SUCCESS,
} from '@handler';
import {
    NEW_PATRIMONIO_MANUTENCAO_CADASTRO_SUCCESS,
    NEW_FILTER_PATRIMONIO_MANUTENCAO_PEDING,
    NEW_FILTER_PATRIMONIO_MANUTENCAO_SUCCESS,
    NEW_FILTER_PATRIMONIO_MANUTENCAO_FAILURE,
    NEW_FILTER_NEXT_PAGE_PATRIMONIO_MANUTENCAO_PENDING,
    NEW_FILTER_NEXT_PAGE_PATRIMONIO_MANUTENCAO_SUCCESS,
    NEW_FILTER_NEXT_PAGE_PATRIMONIO_MANUTENCAO_FAILURE,
    TABLE_PATRIMONIO_MANUTENCAO_CADASTRO_PENDING,
    TABLE_PATRIMONIO_MANUTENCAO_CADASTRO_SUCCESS,
    TABLE_PATRIMONIO_MANUTENCAO_CADASTRO_FAILURE,
    LIST_PATRIMONIO_MANUTENCAO_CADASTRO_PENDING,
    LIST_PATRIMONIO_MANUTENCAO_CADASTRO_SUCCESS,
    LIST_PATRIMONIO_MANUTENCAO_CADASTRO_FAILURE,
    FIND_PATRIMONIO_MANUTENCAO_CADASTRO_PENDING,
    FIND_PATRIMONIO_MANUTENCAO_CADASTRO_SUCCESS,
    FIND_PATRIMONIO_MANUTENCAO_CADASTRO_FAILURE,
    DELETE_PATRIMONIO_MANUTENCAO_CADASTRO_PENDING,
    DELETE_PATRIMONIO_MANUTENCAO_CADASTRO_SUCCESS,
    DELETE_PATRIMONIO_MANUTENCAO_CADASTRO_FAILURE,
    CREATE_PATRIMONIO_MANUTENCAO_CADASTRO_PENDING,
    CREATE_PATRIMONIO_MANUTENCAO_CADASTRO_SUCCESS,
    CREATE_PATRIMONIO_MANUTENCAO_CADASTRO_FAILURE,
} from './actionTypes';


const initialState = {
    list: null,
    loading: false,
    filter: { rows: CONST_ROWS_PER_PAGE, page: 0, where: '' },
}


function patrimonioManutencaoCadastroReducer(state = initialState, action) {

    switch (action.type) {
        case NEW_PATRIMONIO_MANUTENCAO_CADASTRO_SUCCESS:
            return {
                ...state,
                loading: false,
                registro: action.registro,
                isCadastroRapido: action.isCadastroRapido ?? false,
            }
        case LIST_PATRIMONIO_MANUTENCAO_CADASTRO_PENDING:
        case FIND_PATRIMONIO_MANUTENCAO_CADASTRO_PENDING:
        case DELETE_PATRIMONIO_MANUTENCAO_CADASTRO_PENDING:
        case CREATE_PATRIMONIO_MANUTENCAO_CADASTRO_PENDING:
        case TABLE_PATRIMONIO_MANUTENCAO_CADASTRO_PENDING:
        case NEW_FILTER_PATRIMONIO_MANUTENCAO_PEDING:
        case NEW_FILTER_NEXT_PAGE_PATRIMONIO_MANUTENCAO_PENDING:
            return {
                ...state,
                loading: true,
            }
        // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        case CANCEL_PATRIMONIO_MANUTENCAO_CADASTRO_RAPIDO:
        case CANCEL_PATRIMONIO_MANUTENCAO_CADASTRO_RAPIDO_SUCCESS:
            return {
                ...state,
                isCadastroRapido: false,
            }
        // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        case LIST_PATRIMONIO_MANUTENCAO_CADASTRO_SUCCESS:
            return {
                ...state,
                list: action.list,
                loading: false
            }
        case LIST_PATRIMONIO_MANUTENCAO_CADASTRO_FAILURE:
            return {
                ...state,
                list: null,
                loading: false
            }
        // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        case TABLE_PATRIMONIO_MANUTENCAO_CADASTRO_SUCCESS:
            return {
                ...state,
                table: action.table,
                loading: false
            }
        case TABLE_PATRIMONIO_MANUTENCAO_CADASTRO_FAILURE:
            return {
                ...state,
                table: null,
                loading: false
            }
        // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        case FIND_PATRIMONIO_MANUTENCAO_CADASTRO_SUCCESS:
            return {
                ...state,
                registro: action.registro,
                loading: false
            }
        case FIND_PATRIMONIO_MANUTENCAO_CADASTRO_FAILURE:
            return {
                ...state,
                registro: null,
                loading: false
            }
        // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        case DELETE_PATRIMONIO_MANUTENCAO_CADASTRO_SUCCESS: {
            return {
                ...state,
                loading: false
            }
        }
        // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        case NEW_FILTER_PATRIMONIO_MANUTENCAO_SUCCESS: {
            return {
                ...state,
                list: action.list,
                filter: action.filter,
                loading: false
            }
        }
        case NEW_FILTER_PATRIMONIO_MANUTENCAO_FAILURE: {
            return {
                ...state,
                loading: false
            }
        }
        // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        case NEW_FILTER_NEXT_PAGE_PATRIMONIO_MANUTENCAO_SUCCESS:
            return {
                ...state,
                list: action.list,
                filter: action.filter,
                loading: false
            }
        case NEW_FILTER_NEXT_PAGE_PATRIMONIO_MANUTENCAO_FAILURE:
            return {
                ...state,
                list: null,
                loading: false
            }
        // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        case CREATE_PATRIMONIO_MANUTENCAO_CADASTRO_SUCCESS:
        case CREATE_PATRIMONIO_MANUTENCAO_CADASTRO_FAILURE:
            return {
                ...state,
                loading: false,
                registro: action.registro,
                isCadastroRapido: false,
            }
        case DELETE_PATRIMONIO_MANUTENCAO_CADASTRO_FAILURE:
            return {
                ...state,
                loading: false,
            }
        // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
        default:
            return state
    }
}

export { patrimonioManutencaoCadastroReducer };