import { takeLatest, put, call } from 'redux-saga/effects';

import { storage } from '@commons';
import {
  NEW_FILTER_NEXT_PAGE_ENTRADA_ESTOQUE,
  NEW_FILTER_NEXT_PAGE_ENTRADA_ESTOQUE_PENDING,
  NEW_FILTER_NEXT_PAGE_ENTRADA_ESTOQUE_SUCCESS,
  NEW_FILTER_NEXT_PAGE_ENTRADA_ESTOQUE_FAILURE,
} from '../actionTypes';
import { CONST_FILTER_ENTRADA_ESTOQUE } from '@commons/consts';
import { listAllEntradaEstoque } from '../service';
import { formatDateDDMMYYYY } from '@components/common/format';
import { CONFIG_SESSION } from '@handler/common/startSession';

function* sagaNewFilterNextPageEntradaEstoque(action) {
  yield put({ type: NEW_FILTER_NEXT_PAGE_ENTRADA_ESTOQUE_PENDING })

  try {
    const idFazenda = JSON.parse(storage.get(CONFIG_SESSION)).fazendaSelecionada.id;

    let filterSaved = sessionStorage.getItem(CONST_FILTER_ENTRADA_ESTOQUE) !== null ? JSON.parse(sessionStorage.getItem(CONST_FILTER_ENTRADA_ESTOQUE)) : null;

    const datas = formatDateDDMMYYYY(filterSaved.dataInicial) + '_' + formatDateDDMMYYYY(filterSaved.dataFinal);

    let newFilter = {
      ...filterSaved,
      rows: action.rows,
      page: action.page,
    };

    const list = yield call(listAllEntradaEstoque, idFazenda, datas, `&page=${newFilter.page}&size=${newFilter.rows}${newFilter.where}`);

    sessionStorage.setItem(CONST_FILTER_ENTRADA_ESTOQUE, JSON.stringify(newFilter));

    yield put({
      type: NEW_FILTER_NEXT_PAGE_ENTRADA_ESTOQUE_SUCCESS, list: list, filter: newFilter
    });
  } catch (error) {
    yield put({ type: NEW_FILTER_NEXT_PAGE_ENTRADA_ESTOQUE_FAILURE })
  }
}

export default function* watchNewFilterNextPageEntradaEstoque() {
  yield takeLatest(NEW_FILTER_NEXT_PAGE_ENTRADA_ESTOQUE, sagaNewFilterNextPageEntradaEstoque)
}