import { takeLatest, put, call } from 'redux-saga/effects';

import { history } from '@commons';

import { createUnidadeMedidaConversaoCadastro, updateUnidadeMedidaConversaoCadastro, findConversao } from '../service';
import {
  CREATE_UNIDADE_MEDIDA_CONVERSAO_CADASTRO,
  CREATE_UNIDADE_MEDIDA_CONVERSAO_CADASTRO_PENDING,
  CREATE_UNIDADE_MEDIDA_CONVERSAO_CADASTRO_SUCCESS,
  CREATE_UNIDADE_MEDIDA_CONVERSAO_CADASTRO_FAILURE,
} from '@handler';

import { AlertSuccess, AlertError, AlertWarning } from '@components/common/AlertToast'

function* sagaCreateUnidadeMedidaConversaoCadastro(action) {
  yield put({ type: CREATE_UNIDADE_MEDIDA_CONVERSAO_CADASTRO_PENDING })

  try {
    if (action.dataList === undefined || action.dataList === null) {
      if (action.data.id === undefined || action.data.id === null) {
        const possuiConversao = yield call(findConversao, action.data.source, action.data.target);

        if (possuiConversao === undefined || possuiConversao === null || possuiConversao === '') {
          yield call(createUnidadeMedidaConversaoCadastro, action.data);
        } else {
          AlertWarning('Conversão não cadastrada. Já existe uma conversão configurada para essas unidades.');
          yield put({ type: CREATE_UNIDADE_MEDIDA_CONVERSAO_CADASTRO_FAILURE, registro: action.data })
          return;
        }
      } else {
        yield call(updateUnidadeMedidaConversaoCadastro,
          {
            ...action.data,
            id: action.data.id
          }
        );
      }

      yield put({ type: CREATE_UNIDADE_MEDIDA_CONVERSAO_CADASTRO_SUCCESS, registro: action.data })

      if (!action.isCadastroRapido) {
        history.push('/unidadeMedidaConversaoCadastro');
      }
      if (action.feedback === undefined || action.feedback === null || action.feedback) {
        AlertSuccess('Registro salvo com sucesso!');
      }
    } else {
      var dataListFor = [];
      if (!Array.isArray(action.dataList)) {
        // Verifica se dataList não é uma lista, então transforma o objeto único em uma lista
        dataListFor = [action.dataList];
      } else {
        dataListFor = action.dataList
      }
      for (const data of dataListFor) {
        const possuiConversao = yield call(findConversao, data.source, data.target);

        if (possuiConversao === undefined || possuiConversao === null || possuiConversao === '') {
          yield call(createUnidadeMedidaConversaoCadastro, data);
        } else {
          yield put({ type: CREATE_UNIDADE_MEDIDA_CONVERSAO_CADASTRO_FAILURE, registro: data })
          return;
        }
      }
      if (action.feedback === undefined || action.feedback === null || action.feedback) {
        AlertSuccess('Registro salvo com sucesso!');
      }
      yield put({ type: CREATE_UNIDADE_MEDIDA_CONVERSAO_CADASTRO_SUCCESS, registro: null })
    }
  } catch (error) {
    yield put({ type: CREATE_UNIDADE_MEDIDA_CONVERSAO_CADASTRO_FAILURE, registro: action.data })

    AlertError('Falha ao salvar o registro! Tente novamente...');
  }

}

export default function* watchCreateUnidadeMedidaConversaoCadastro() {
  yield takeLatest(CREATE_UNIDADE_MEDIDA_CONVERSAO_CADASTRO, sagaCreateUnidadeMedidaConversaoCadastro)
}
